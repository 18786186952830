import classNames from "classnames";
import { FC, memo, useCallback } from "react";

import MediaDeviceControl, {
    MediaDeviceControlCompact,
    MediaDeviceControlNoOptions,
    MediaDeviceControlProps,
} from "@/components/gui/MediaDeviceControl";
import { MediaInputKind, MediaToggleControl, toMediaGroup } from "@/domain/mediaDevices";
import useRtcSessionContext from "@/hooks/rtc/useRtcSessionContext";
import { isMobileBrowser } from "@/misc/mobile";
import { TypedEntries } from "@/misc/types";

export enum CallControlsLocation {
    Default,
    LiveBond,
    Multitasking,
}

export interface CallControlsProps extends Pick<MediaDeviceControlProps, "onToggle"> {
    onLeave?: React.MouseEventHandler<HTMLButtonElement>;
    location: CallControlsLocation;
    showScreenshare?: boolean;
}

export const CallControls: FC<CallControlsProps> = ({
    location,
    onLeave,
    onToggle,
    showScreenshare = true,
}) => {
    const { mediaControls, isMultitasking } = useRtcSessionContext();

    // logical XOR:
    const livenessAppliesHere = isMultitasking === (location === CallControlsLocation.Multitasking);

    const className = classNames(`c-call-controls`, {
        "c-call__controls--fixed": location === CallControlsLocation.Default,
        "c-call-controls--no-options": location === CallControlsLocation.Multitasking,
    });

    const getMediaControlComponents = useCallback(
        ([kind, toggle]: MediaToggleControl) => {
            // Hide the screenshare button when requested, unless sharing is active (so the user
            // always has the opportunity to turn it off if they somehow got into a weird state)
            if (!showScreenshare && kind === MediaInputKind.Display && !toggle.active) {
                return [];
            }
            const key = kind;
            const mediaProps: MediaDeviceControlProps = {
                control: toggle,
                mediaGroup: toMediaGroup(kind),
                onToggle,
            };
            if (isMobileBrowser()) {
                return [<MediaDeviceControlNoOptions key={key} {...mediaProps} />];
            }
            if (location === CallControlsLocation.Multitasking) {
                return [<MediaDeviceControlCompact key={key} {...mediaProps} />];
            }
            return [<MediaDeviceControl key={key} {...mediaProps} />];
        },
        [location, onToggle, showScreenshare],
    );

    return (
        <div className={className}>
            {livenessAppliesHere && mediaControls &&
                TypedEntries(mediaControls)
                    .flatMap(getMediaControlComponents)}
            {onLeave && (
                <button
                    className="c-call-btn c-call-btn--leave"
                    onClick={onLeave}
                    title="Leave call"
                >
                    Leave
                </button>
            )}
        </div>
    );
};

// Explicitly memoise this component to stop unnecessarily re-rendering
const CallControlsMemoed = memo(CallControls);
export default CallControlsMemoed;
