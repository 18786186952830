import {
    ConnectionStatus,
    selectConnectionStatus,
    selectOnlineStatus,
} from "@/features/connection";
import styles from "@/sass/export.module.scss";
import { useAppSelector } from "@/store/redux";

function Dot(props: { colour: string; title: string; }): React.JSX.Element {
    const classes = "c-offline-indicator";

    return (
        <svg className={classes}>
            <title>{props.title}</title>
            <circle r={4} fill={props.colour} cx={4} cy={4} />
        </svg>
    );
}

export default function OfflineIndicator(): React.JSX.Element {
    const status = useAppSelector(selectConnectionStatus);
    const online = useAppSelector(selectOnlineStatus);

    if (!online) return <Dot colour={styles.red_dark} title="You're offline" />;

    switch (status) {
        // Show the indicator whenever we're not Connected (even though
        // it shouldn't really be possible to be showing it in some of
        // these states). Don't show in `ShouldConnect`, as `!online` takes
        // precendence - we remain in `ShouldConnect` until we are `online`.
        case ConnectionStatus.AwaitReconnect:
        case ConnectionStatus.AwaitAuth:
        case ConnectionStatus.AuthFailure:
            return <Dot colour={styles.red_dark} title="You're not connected to Bond" />;
        case ConnectionStatus.ShouldConnect:
        case ConnectionStatus.Connected:
        default:
            return <></>;
    }
}
