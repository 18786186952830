import * as d from "@/domain/domain";
import { Optional } from "@/misc/types";
import { TopLevelViewName, viewUtils } from "./views";

export enum DraftType {
    Channel,
    BondCreation,
    Invalid,
}

export type DraftTarget =
    | { type: DraftType.Invalid; }
    | { type: DraftType.BondCreation; viewName: TopLevelViewName; }
    | { type: DraftType.Channel; channelId: d.ChannelId; };

export type WithDraftTarget = { draftTarget: DraftTarget; };

export const invalidDraftTarget = (): DraftTarget => Object.freeze({ type: DraftType.Invalid });

export const newBondCreationDraftTarget = (viewName: TopLevelViewName): DraftTarget =>
    Object.freeze({
        type: DraftType.BondCreation,
        viewName,
    });

export const newChannelDraftTarget = (channelId: d.ChannelId): DraftTarget =>
    Object.freeze({
        type: DraftType.Channel,
        channelId,
    });

export function isChannelDraftTarget(
    dt: DraftTarget,
): dt is DraftTarget & { type: DraftType.Channel; } {
    return dt.type === DraftType.Channel;
}

export function isBondCreationDraftTarget(
    dt: DraftTarget,
): dt is DraftTarget & { type: DraftType.BondCreation; } {
    return dt.type === DraftType.BondCreation;
}

export const draftTargetToSquadId = (draftTarget: DraftTarget): Optional<d.SquadId> => {
    if (!isBondCreationDraftTarget(draftTarget)) return;

    const view = viewUtils.parseTopLevelName(draftTarget.viewName);

    return viewUtils.isSquad(view) ? view.id : undefined;
};

export function draftTargetsAreEqual(a: DraftTarget, b: DraftTarget): boolean {
    if (a.type !== b.type) return false;
    if (isBondCreationDraftTarget(a) && isBondCreationDraftTarget(b)) {
        return a.viewName === b.viewName;
    }
    if (isChannelDraftTarget(a) && isChannelDraftTarget(b)) {
        return a.channelId === b.channelId;
    }
    return true;
}

export function getChannelOfDraftTarget(dt: DraftTarget): d.ChannelId {
    if (!isChannelDraftTarget(dt)) {
        throw new Error(`No channelId on draft target with type ${dt.type}`);
    }
    return dt.channelId;
}
