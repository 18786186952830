import { useCallback } from "react";

import * as d from "@/domain/domain";
import {
    CallActionStatus,
    selectCallStartStatus,
    selectCurrentParticipantId,
    selectDefaultTranscriptionLanguage,
    setCallStartStatus,
    setCallStartStatusFromCurrentCallId,
    startCall,
} from "@/features/calls";
import { selectActiveStatus } from "@/features/connection";
import { useTakeSingleRtcSessionLock } from "@/hooks/createSharedWebLock";
import { useRtcActionBackoff } from "@/hooks/rtc/useRtcActionBackoff";
import { Optional } from "@/misc/types";
import { useAppDispatch, useAppSelector } from "@/store/redux";
import { useActiveEffect } from "../useActiveEffect";
import useLocalDispatch from "../useLocalDispatch";

const defaultCallName = "New Live Session";

interface UseRtcSessionStartProps {
    bondId: Optional<d.BondId>;
}

export function useRtcSessionStart(props: UseRtcSessionStartProps) {
    const { bondId } = props;
    const dispatch = useAppDispatch();
    const localDispatch = useLocalDispatch();

    // Start the call with backoff + retries
    const { signal: startSignal, getActionReady: getCanStart } = useRtcActionBackoff({
        selectCallStatus: selectCallStartStatus,
        setCallStatusFromCurrentCallId: setCallStartStatusFromCurrentCallId,
    });

    // Start the call when we receive the signal to do so.
    const transcriptionLanguage = useAppSelector(selectDefaultTranscriptionLanguage);
    useActiveEffect(() => {
        if (!bondId) return;
        if (!getCanStart()) return;

        const startCallAction = dispatch(
            startCall({
                name: defaultCallName,
                bondId,
                language: transcriptionLanguage,
            }),
        );

        return () => startCallAction.abort();
    }, [bondId, dispatch, getCanStart, startSignal, transcriptionLanguage]);

    const active = useAppSelector(selectActiveStatus);
    const currentParticipantId = useAppSelector(selectCurrentParticipantId);
    const haveSingleSessionLock = useTakeSingleRtcSessionLock(active || !!currentParticipantId);

    // A callback for the caller to trigger the start call cycle
    const startSession = useCallback(() => {
        if (!haveSingleSessionLock) return;

        localDispatch(setCallStartStatus(CallActionStatus.Ready));
    }, [localDispatch, haveSingleSessionLock]);

    return startSession;
}
