import bondService from "@/api/bonds";
import { streamHandler } from "@/api/stream";
import { transport } from "@/api/transport";
import {
    fromProtoCallId,
    fromProtoRtcSessionId,
    fromProtoTimestamp,
    fromProtoUserId,
    pbBondId,
    pbUserId,
    toProtoCallIdSet,
} from "@/api/util";
import {
    CallKnowledgePreview,
    CallLanguageCode,
    CallMember,
    CallOverview,
    CallParticipationRole,
} from "@/domain/calls";
import * as d from "@/domain/domain";
import { translateAsyncIterable } from "@/misc/iterable";
import log from "@/misc/log";
import { Diff, Optional } from "@/misc/types";
import { Code, ConnectError, createClient } from "@connectrpc/connect";
import * as bonds_pb from "../../gen/proto/bonds/bonds_pb";
import { CallService } from "../../gen/proto/calls/calls_connect";
import * as calls_pb from "../../gen/proto/calls/calls_pb";
import * as domain_pb from "../../gen/proto/domain/domain_pb";

// Re-export proto types for use in the rest of the frontend
export { CallKnowledgeType } from "../../gen/proto/domain/domain_pb";

const service = createClient(CallService, transport);
export default service;

export interface StartCallInBondArgs {
    name: string;
    bondId: d.BondId;
    currentUserId: d.UserId;
    language?: string;
}

export async function startCallInBond(args: StartCallInBondArgs): Promise<Optional<d.CallId>> {
    const callParams = {
        name: args.name,
        initiatorId: pbUserId(args.currentUserId),
        transcription: {
            // If user didn't pick a transcription language, use English transcription
            language: args.language || CallLanguageCode.English,
        },
    };
    const req = new bonds_pb.StartCallViaBondRequest({
        callParameters: callParams,
        bondId: pbBondId(args.bondId),
    });

    try {
        const resp = await bondService.startCallViaBond(req);
        return fromProtoCallId(resp.response?.callId);
    }
    catch (err: any) {
        // Return undefined call ID if call already exists. We will soon discover
        // this new call ID via the bonds stream (hopefully).
        if (err instanceof ConnectError) {
            if (err.code === Code.AlreadyExists) {
                log.warn("Could not start bond call as it already exists.");
                return undefined;
            }
        }

        throw err;
    }
}

function translateCallOverview(co: calls_pb.CallOverview): CallOverview {
    const call: CallOverview = {
        id: fromProtoCallId(co.id),
        sessionId: fromProtoRtcSessionId(co.sessionId),
        startedAt: fromProtoTimestamp(co.startedAt),
        endedAt: fromProtoTimestamp(co.endedAt),
        participants: fromProtoCallParticipants(co.participants),
        participantsByContribution: co.participantsByContribution.map(p => fromProtoUserId(p)),
        initiatorId: fromProtoUserId(co.initiatorId),
        knowledge: fromProtoCallKnowledge(co.knowledgePreview),
    };
    return Object.freeze(call);
}

function fromProtoCallParticipants(pb_participants: calls_pb.CallMember[]): CallMember[] {
    return pb_participants.map((p): CallMember =>
        Object.freeze({
            userId: fromProtoUserId(p.userId),
            role: fromProtoCallParticipationRole(p.role),
        })
    );
}

function fromProtoCallParticipationRole(
    role: Optional<domain_pb.CallParticipationRole>,
): CallParticipationRole {
    const cpr: CallParticipationRole = {
        observing: role?.isObserving,
        publishingMicrophone: role?.isPublishingMicrophone,
        publishingCamera: role?.isPublishingCamera,
        publishingScreenShare: role?.isPublishingScreenShare,
    };
    return Object.freeze(cpr);
}

function fromProtoCallKnowledge(
    knowledge: Optional<calls_pb.CallKnowledgePreview>,
): CallKnowledgePreview {
    const ckp: CallKnowledgePreview = {
        title: knowledge?.title || "",
        summary: knowledge?.summary || "",
        detailedSummary: knowledge?.detailedSummary || "",
    };
    return Object.freeze(ckp);
}

async function* translateCallDiff(v: Diff<d.CallId>) {
    if (v.added?.length) {
        yield new calls_pb.SubCallsRequest({
            callIds: toProtoCallIdSet(v.added),
            addToSub: true,
        });
    }
    if (v.removed?.length) {
        yield new calls_pb.SubCallsRequest({
            callIds: toProtoCallIdSet(v.removed),
            addToSub: false,
        });
    }
}

export type CallWrapper = {
    case: "call";
    call: CallOverview;
};

export type CallDeletedWrapper = {
    case: "deleted";
    deletedId: d.CallId;
};

export type CallOrDeleted = CallWrapper | CallDeletedWrapper;

export const callOrDeletedIsCall = (cod: CallOrDeleted): cod is CallWrapper => cod.case === "call";

function callOverviewParser(
    res: calls_pb.SubCallsResponse,
): CallOrDeleted {
    switch (res.callOrDeleted?.case) {
        case "call": {
            const overview = translateCallOverview(res.callOrDeleted.value);
            return { case: "call", call: overview };
        }
        case "deletedId": {
            const deletedId = fromProtoCallId(res.callOrDeleted.value);
            return { case: "deleted", deletedId: deletedId };
        }
        default:
            throw new Error(`unexpected callOrDeleted case: ${res.callOrDeleted?.case}`);
    }
}

export async function* subCalls(
    reqStream: AsyncIterableIterator<Diff<d.CallId>>,
    signal: AbortSignal,
) {
    const translation = translateAsyncIterable(reqStream, translateCallDiff);

    const stream = service.subCalls(translation, { signal });

    yield* streamHandler(stream, callOverviewParser, subCalls.name);
}
