import { z, ZodSchema } from "zod";

import * as d from "@/domain/domain";
import { isMobileBrowser } from "@/misc/mobile";
import shallowEqual from "@/misc/shallowEqual";
import { Optional } from "@/misc/types";

const uuidSchema = z.string().uuid();

// region View names

/** Names for all known views.
 *
 * Roughly equivalent to all elements that can be rendered by the route table.
 */
export const viewNameSchema = z.enum([
    "inbox",
    "discover",
    "mySquads",
    "newBond",
    "squad",
    "singleBond",
    "inviteRedemption", // sic
    "login",
]);
export const viewNames = viewNameSchema.Enum;

const squadTopLevelViewNamePrefix = "squad-";
/** A string-y name to use for the "squad" view.
 *
 * This is required in places where we need to use the view as an index,
 * but since the view itself is an object we need some coercion to a string.
 */
const squadTopLevelViewNameSchema = z.string()
    .startsWith(squadTopLevelViewNamePrefix)
    .refine(v => uuidSchema.safeParse(v.slice(squadTopLevelViewNamePrefix.length)).success);

/** All top-level view names.
 *
 * This includes top-level view names for mobile and desktop combined. Runtime
 * checks are applied to disallow usage of, e.g. "my squads" as a top-level view
 * on desktop.
 */
export const topLevelViewNameSchema = z.union([
    z.literal(viewNames.inbox),
    z.literal(viewNames.discover),
    z.literal(viewNames.mySquads),
    z.literal(viewNames.inviteRedemption),
    squadTopLevelViewNameSchema,
]);
export type TopLevelViewName = z.infer<typeof topLevelViewNameSchema>;

/** The allowable view names which can be used for `DraftTarget`s for
 * bond-creation.
 */
export const bondCreationBucketViewNameSchema = z.union([
    z.literal(viewNames.inbox),
    z.literal(viewNames.discover),
    squadTopLevelViewNameSchema,
]);
export type BondCreationBucketViewName = z.infer<typeof bondCreationBucketViewNameSchema>;

// region Views

const inboxView = z.object({ view: z.literal(viewNames.inbox) });
const discoverView = z.object({ view: z.literal(viewNames.discover) });
const mySquadsView = z.object({ view: z.literal(viewNames.mySquads) });
const newBondView = z.object({ view: z.literal(viewNames.newBond) });
const loginView = z.object({ view: z.literal(viewNames.login) });

const singleBondView = z.object({
    view: z.literal(viewNames.singleBond),
    id: d.bondIdSchema,
    messageId: d.messageIdSchema.optional(),
    summary: z.boolean().optional(),
});

const squadView = z.object({
    view: z.literal(viewNames.squad),
    id: d.squadIdSchema,
    summary: z.boolean().optional(),
});
/** Converts a string-y squad name to a `squadView`. */
const squadTopLevelViewSchema = squadTopLevelViewNameSchema.transform(v => {
    const uuid = v.slice(squadTopLevelViewNamePrefix.length);
    return viewUtils.squad(d.fromRawSquadId(uuid));
}).pipe(squadView);

const inviteRedemptionView = z.object({
    view: z.literal(viewNames.inviteRedemption),
    inviteCode: z.string().min(1),
});

/** All known views within the app that take part in the viewstacks. */
export const viewSchema = z.discriminatedUnion("view", [
    inboxView,
    discoverView,
    squadView,
    singleBondView,
    mySquadsView,
    newBondView,
    inviteRedemptionView,
    loginView,
]);
export type AppView = z.output<typeof viewSchema>;

/** Helper object for referencing the schemas for the views. */
export const viewSchemaMap = {
    inbox: inboxView,
    discover: discoverView,
    mySquads: mySquadsView,
    newBond: newBondView,
    squad: squadView,
    singleBond: singleBondView,
    inviteRedemption: inviteRedemptionView,
    login: loginView,
};

const newOrSingleBond = [singleBondView, newBondView];
/** Define which views can live as children of other views within a stack.
 */
export const viewTreeSchema: Partial<Record<TopLevelViewName, ZodSchema[]>> = {
    [viewNames.inbox]: [singleBondView, newBondView, inviteRedemptionView],
    [viewNames.discover]: newOrSingleBond,
    [viewNames.squad]: newOrSingleBond,
    [viewNames.mySquads]: [squadView],
};

// region Viewstack

/** A schema for permissible viewstacks.
 *
 * These must be a non-empty array of views, each of which is permissible by
 * the relations defined by `viewTreeSchema`.
 */
export const viewStackSchema = viewSchema.array().nonempty().superRefine((views, ctx) => {
    for (let i = 0; i < views.length - 1; i++) {
        const currentView = views[i];

        const allowedSchemas = viewTreeSchema[currentView.view];

        if (!allowedSchemas) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `${currentView.view} has no allowed next views`,
                path: [i],
            });
            continue;
        }

        const nextView = views[i + 1];
        const isValidTransition = allowedSchemas.some(
            schema => schema.safeParse(nextView).success,
        );

        if (!isValidTransition) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `Invalid view transition ${currentView.view} -> ${nextView.view}`,
                path: [i + 1],
            });
            continue;
        }
    }
});
export type AppViewStack = z.infer<typeof viewStackSchema>;

// region Utilities

/** Helper object for utilities for views. */
export const viewUtils = {
    /** Are two views equal? */
    viewsEqual: (a: Optional<AppView>, b: Optional<AppView>) => shallowEqual(a, b),

    /** Create an "inbox" view. */
    inbox: () => viewSchemaMap.inbox.parse({ view: viewNames.inbox }),
    /** Check if a view is "inbox". */
    isInbox: (v: Optional<AppView>): v is z.infer<typeof viewSchemaMap.inbox> =>
        v?.view === viewNames.inbox,
    /** Check if a stack has one of its frames as "inbox". */
    hasInbox: (stack: Optional<AppViewStack>) => !!stack?.some(viewUtils.isInbox),

    /** Create a "discover" view. */
    discover: () => viewSchemaMap.discover.parse({ view: viewNames.discover }),
    /** Check if a view is "discover". */
    isDiscover: (v: Optional<AppView>): v is z.infer<typeof viewSchemaMap.discover> =>
        v?.view === viewNames.discover,
    /** Check if a stack has one of its frames as "discover". */
    hasDiscover: (stack: Optional<AppViewStack>) => !!stack?.some(viewUtils.isDiscover),

    /** Create a "my squads" view. */
    mySquads: () => viewSchemaMap.mySquads.parse({ view: viewNames.mySquads }),
    /** Check if a view is "my squads". */
    isMySquads: (v: Optional<AppView>): v is z.infer<typeof viewSchemaMap.mySquads> =>
        v?.view === viewNames.mySquads,
    /** Check if a stack has one of its frames as "my squads". */
    hasMySquads: (stack: Optional<AppViewStack>) => !!stack?.some(viewUtils.isMySquads),

    /** Create a "squad" view. */
    squad: (id: d.SquadId, summary?: boolean) =>
        viewSchemaMap.squad.parse({ view: viewNames.squad, id, summary }),
    /** Check if a view is "squad". */
    isSquad: (v: Optional<AppView>): v is z.infer<typeof viewSchemaMap.squad> =>
        v?.view === viewNames.squad,
    /** Check if a stack has one of its frames as "squad" with the given id. */
    hasSquad: (stack: Optional<AppViewStack>, id: d.SquadId) =>
        !!stack?.some(frame => viewUtils.isSquad(frame) && frame.id === id),

    /** Check if a stack has one of its frames as "squad". */
    hasAnySquad: (stack: Optional<AppViewStack>) =>
        !!stack?.some(frame => viewUtils.isSquad(frame)),

    /** Check if the "squad" tab should be highlighted. */
    highlightForSquadTab: (stack: Optional<AppViewStack>) =>
        !!stack?.some(frame => viewUtils.isMySquads(frame) || viewUtils.isSquad(frame)),

    /** Create a "new bond" view. */
    newBond: () => viewSchemaMap.newBond.parse({ view: viewNames.newBond }),
    /** Check if a view is "new bond". */
    isNewBond: (v: Optional<AppView>): v is z.infer<typeof viewSchemaMap.newBond> =>
        v?.view === viewNames.newBond,

    /** Create a "single bond" view. */
    singleBond: (
        id: d.BondId,
        messageId?: d.MessageId,
        summary?: boolean,
    ) => viewSchemaMap.singleBond.parse({
        view: viewNames.singleBond,
        id,
        messageId,
        summary,
    }),
    /** Check if a view is "single bond". */
    isSingleBond: (v: Optional<AppView>): v is z.infer<typeof viewSchemaMap.singleBond> =>
        v?.view === viewNames.singleBond,
    /** Check if a stack has one of its frames as "single bond" with the given id. */
    hasSingleBond: (stack: Optional<AppViewStack>, id: d.BondId) =>
        !!stack?.some(frame => viewUtils.isSingleBond(frame) && frame.id === id),

    /** Create a "invite redemption" view. */
    inviteRedemption: (
        inviteCode: string,
    ) => viewSchemaMap.inviteRedemption.parse({
        view: viewNames.inviteRedemption,
        inviteCode,
    }),
    /** Check if a view is "invite redemption". */
    isInviteRedemption: (
        v: Optional<AppView>,
    ): v is z.infer<typeof viewSchemaMap.inviteRedemption> =>
        v?.view === viewNames.inviteRedemption,

    /** Create a "login" view. */
    login: () => viewSchemaMap.login.parse({ view: viewNames.login }),
    /** Check if a view is "login". */
    isLogin: (v: Optional<AppView>): v is z.infer<typeof viewSchemaMap.login> =>
        v?.view === viewNames.login,

    /** Convert a "squad" view to its string name.  */
    topLevelSquadName: (view: z.infer<typeof viewSchemaMap.squad>): TopLevelViewName =>
        squadTopLevelViewNamePrefix + d.extractUUID(view.id),

    /** Check if a view stack should display the mobile tab bar. */
    isMobileTabsViewShown: (stack: Optional<AppViewStack>) => {
        const leafView = stack?.at(-1);
        return viewUtils.isInbox(leafView) ||
            viewUtils.isMySquads(leafView) ||
            viewUtils.isSquad(leafView) ||
            viewUtils.isDiscover(leafView);
    },

    /** Check if a view is top-level or not.
     *
     * Mobile/desktop dependent!
     */
    isTopLevel: (v: AppView): boolean => {
        const name = viewUtils.toTopLevelName(v);
        const p = topLevelViewNameSchema.safeParse(name);
        if (!p.success) return false;

        // Type versus runtime-value mismatch.
        if (isMobileBrowser() && viewUtils.isSquad(v)) return false;
        if (!isMobileBrowser() && viewUtils.isMySquads(v)) return false;

        return true;
    },
    /** Convert a view to its top-level name, if it is a top-level view.
     *
     * Mobile/desktop dependent!
     */
    toTopLevelName: (v: Optional<AppView>): z.infer<typeof topLevelViewNameSchema> | undefined => {
        switch (v?.view) {
            case viewNames.inbox:
            case viewNames.discover:
                return v.view;
            case viewNames.mySquads:
                return isMobileBrowser() ? v.view : undefined;
            case viewNames.squad:
                return isMobileBrowser() ? undefined : viewUtils.topLevelSquadName(v);
        }
    },
    /** Parse a top-level name to its associated view.
     *
     * Mobile/desktop dependent!
     */
    parseTopLevelName: (viewName: TopLevelViewName): Optional<AppView> => {
        switch (viewName) {
            case viewNames.inbox:
                return viewUtils.inbox();
            case viewNames.discover:
                return viewUtils.discover();
            case viewNames.mySquads:
                return isMobileBrowser() ? viewUtils.mySquads() : undefined;
        }
        const p = squadTopLevelViewSchema.safeParse(viewName);
        return p.success ? p.data : undefined;
    },
};

// region Viewstacks

const problem = (ctx: z.RefinementCtx, msg: string) =>
    ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: msg,
    });

/** Check if the parts of a URL match ["", prefix, {uuid}], and if so,
 * return the UUID. */
const parsePathThenUUID = (
    parts: string[],
    prefix: string,
    ctx: z.RefinementCtx,
): Optional<string> => {
    if (parts.length < 3) {
        problem(ctx, "path does not have enough separators");
        return;
    }

    const issues: string[] = [];
    if (parts[0] !== "") {
        issues.push("path does not start with /");
    }

    if (parts[1] !== prefix) {
        issues.push(`first path segment is not '${prefix}'`);
    }

    const p = uuidSchema.safeParse(parts[2]);
    if (!p.success) {
        issues.push("second path segment is not a UUID");
    }

    if (issues.length > 0) {
        issues.forEach(msg => problem(ctx, msg));
        return;
    }

    return p.data!; // tsc unable to infer this is non-undefined?
};

/** Check if the parts of a URL match our path for individual messages. */
const parsePossibleMessageId = (parts: string[]): Optional<d.MessageId> => {
    if (parts.length < 5 || parts[3] !== "message") return;

    const p = uuidSchema.safeParse(parts[4]);

    return p.success ? d.fromRawMessageId(p.data) : undefined;
};

/** Attempt to coerce a path "/squad/{uuid}" to a "squad" view. */
const squadPathToView = z.string()
    .transform((v, ctx) => {
        const parts = v.split("/");

        const squadId = parsePathThenUUID(parts, "squad", ctx);

        if (!squadId) return z.NEVER;
        const summary = parts[3] === "summary" ? true : undefined;
        return viewUtils.squad(d.fromRawSquadId(squadId), summary);
    })
    .pipe(viewSchemaMap.squad);

/** Attempt to coerce a path "/bond/{uuid}" to a "single bond" view. */
const singleBondPathToView = z.string()
    .transform((v, ctx) => {
        const parts = v.split("/");

        const bondId = parsePathThenUUID(parts, "bond", ctx);

        if (!bondId) return z.NEVER;

        const messageId = parsePossibleMessageId(parts);
        const summary = parts[3] === "summary" ? true : undefined;

        return viewUtils.singleBond(d.fromRawBondId(bondId), messageId, summary);
    })
    .pipe(viewSchemaMap.singleBond);

/** Attempt to coerce a path "/invite/{inviteCode}" to an "invite redemption"
 * view. */
const inviteRedemptionPathToView = z.string()
    .transform((v, ctx) => {
        const parts = v.split("/");

        if (parts[0] !== "") {
            problem(ctx, "path does not start with /");
            return z.NEVER;
        }

        if (parts[1] !== "invite") {
            problem(ctx, "first path segment is not 'invite'");
            return z.NEVER;
        }

        if (!parts[2]) {
            problem(ctx, "second path segment is empty");
            return z.NEVER;
        }

        return viewUtils.inviteRedemption(parts[2]);
    }).pipe(viewSchemaMap.inviteRedemption);

const loginPathToView = z.string().startsWith("/login")
    .transform(viewUtils.login)
    .pipe(viewSchemaMap.login);

/** Convert a path to a view. */
export const pathToView = (path: Optional<string>): Optional<AppView> => {
    if (!path) return;

    switch (path) {
        case "/bond":
        case "/mobile/tab/mybonds":
            return viewUtils.inbox();
        case "/discover":
        case "/mobile/tab/discover":
            return viewUtils.discover();
        case "/mobile/tab/mysquads":
            return viewUtils.mySquads();
        case "/bond/new":
            return viewUtils.newBond();
    }

    const squad = squadPathToView.safeParse(path);
    if (squad.success) return squad.data;

    const bond = singleBondPathToView.safeParse(path);
    if (bond.success) return bond.data;

    const invite = inviteRedemptionPathToView.safeParse(path);
    if (invite.success) return invite.data;

    const login = loginPathToView.safeParse(path);
    if (login.success) return login.data;

    return undefined;
};

/** Convert a view to a path. */
export const viewToPath = (view: Optional<AppView>): Optional<string> => {
    if (!view) return;

    switch (view.view) {
        case viewNames.inbox:
            return isMobileBrowser() ? "/mobile/tab/mybonds" : "/bond";
        case viewNames.discover:
            return isMobileBrowser() ? "/mobile/tab/discover" : "/discover";
        case viewNames.mySquads:
            return "/mobile/tab/mysquads";
        case viewNames.squad:
            return `/squad/${d.extractUUID(view.id)}` + (view.summary ? "/summary" : "");
        case viewNames.newBond:
            return `/bond/new`;
        case viewNames.singleBond: {
            const prefix = `/bond/${d.extractUUID(view.id)}`;

            if (view.messageId) {
                return prefix + `/message/${d.extractUUID(view.messageId)}`;
            }

            return prefix + (view.summary ? "/summary" : "");
        }
        case viewNames.inviteRedemption:
            return `/invite/${view.inviteCode}`;
        case viewNames.login:
            return `/login`;
    }
};

/** Schema describing the part of URL state we require to track viewstacks
 * over browser navigation. */
export const navStateSchema = z.object({
    viewStack: viewStackSchema,
});
export type NavState = z.infer<typeof navStateSchema>;

/** Helper object for utilities for viewstacks. */
export const viewStackUtils = {
    /** The default viewstack. */
    defaultStack: viewStackSchema.parse([viewUtils.inbox()]),

    /** Find the default viewstack for viewing a squad.
     *
     * Mobile/desktop dependent!
     */
    defaultSquadViewStack: (squadId: d.SquadId): AppViewStack => {
        const v = viewUtils.squad(squadId);
        return viewStackSchema.parse(
            isMobileBrowser() ? [viewUtils.mySquads(), v] : [v],
        );
    },

    /** Find the default viewstack for a given view. */
    defaultStackForView: (v: AppView): AppViewStack => {
        switch (v.view) {
            case viewNames.inbox:
            case viewNames.discover:
            case viewNames.mySquads:
                return viewStackSchema.parse([v]);
            case viewNames.squad:
                return viewStackUtils.defaultSquadViewStack(v.id);
            case viewNames.newBond:
            case viewNames.singleBond:
            case viewNames.inviteRedemption:
                return viewStackSchema.parse([viewUtils.inbox(), v]);
            default:
                throw new Error(`No default stack for view ${v}`);
        }
    },

    /** Find a guaranteed-to-be-sane viewstack for viewing a bond. */
    fallbackViewForBond: (id: d.BondId, messageId?: d.MessageId): AppViewStack =>
        viewStackSchema.parse([viewUtils.inbox(), viewUtils.singleBond(id, messageId)]),

    /** Are two viewstacks essentially equal? */
    viewStacksEqual: (a: AppViewStack, b: AppViewStack): boolean => {
        if (a.length !== b.length) return false;

        return !a.some((frame, i) => !viewUtils.viewsEqual(frame, b[i]));
    },

    /** Updates the topmost view in a viewstack using the provided transformation function
     * @returns A new viewstack with the transformed top view
     */
    updateTopOfStack: (
        viewStack: AppViewStack,
        f: (frame: AppView) => AppView,
    ) => {
        const frame = viewStack.at(-1);
        if (frame === undefined) {
            return viewStack;
        }
        const newStack: typeof viewStack = [...viewStack];
        newStack[newStack.length - 1] = f(frame);
        return newStack;
    },
};
