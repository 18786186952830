import { createClient } from "@connectrpc/connect";

import { SearchService } from "../../gen/proto/search/search_connect";
import {
    AttachmentHighlight as AttachmentHighlightProto,
    BondHighlight as BondHighlightProto,
    CallHighlight as CallHighlightProto,
    Highlight as HighlightProto,
    MessageHighlight as MessageHighlightProto,
    Result as ResultProto,
    SearchRequest,
} from "../../gen/proto/search/search_pb";

import { transport } from "@/api/transport";
import {
    fromProtoBlobId,
    fromProtoBondId,
    fromProtoCallId,
    fromProtoMessageId,
    fromProtoUserId,
    pbUserId,
} from "@/api/util";
import { UserId } from "@/domain/domain";
import {
    AttachmentHighlight,
    AttachmentSearchResult,
    BondHighlight,
    BondSearchResult,
    CallHighlight,
    CallSearchResult,
    Highlight,
    MessageHighlight,
    MessageSearchResult,
    SearchResult,
} from "@/domain/search";
import { Optional } from "@/misc/types";

export const service = createClient(SearchService, transport);
export default service;

export interface GetSearchArgs {
    query: string;
    userId: UserId;
}

function isSearchResult(
    item: Optional<SearchResult>,
): item is SearchResult {
    return item !== undefined;
}

export type searchResultResponse = {
    results: Array<SearchResult>;
    tags: [string, string];
};

// TODO handle highlight tags on the frontend API / Features layers
export async function parseSearchResults(
    args: GetSearchArgs,
): Promise<searchResultResponse> {
    const resp = await service.search(
        new SearchRequest({
            query: args.query,
            userId: pbUserId(args.userId),
        }),
    );

    const startTag = resp.highlightStartTag;
    const endTag = resp.highlightEndTag;

    const results = resp?.results?.map(item => {
        if (!item.result.case) {
            console.error("Search result has no case", item);
            return;
        }

        const highlight = item.result.value.highlight;
        if (!highlight) {
            console.error("Search result has no highlight", item);
            return;
        }

        return fromProtoResult(item);
    }).filter(isSearchResult) || [];

    return {
        results: results,
        tags: [startTag, endTag],
    };
}

function fromProtoResult(
    item: ResultProto,
): Optional<SearchResult> {
    switch (item.result.case) {
        case "bond":
            return {
                score: item.score,
                type: item.result.case,
                content: {
                    highlight: fromProtoBondHighlight(
                        item.result.value.highlight as BondHighlightProto,
                    ),
                    bondId: fromProtoBondId(item.result.value.bondId),
                },
            } as BondSearchResult;
        case "message":
            return {
                score: item.score,
                type: item.result.case,
                content: {
                    highlight: fromProtoMessageHighlight(
                        item.result.value.highlight as MessageHighlightProto,
                    ),
                    bondId: fromProtoBondId(item.result.value.bondId),
                    messageId: fromProtoMessageId(item.result.value.messageId),
                    authorId: fromProtoUserId(item.result.value.authorId),
                },
            } as MessageSearchResult;
        case "call":
            return {
                score: item.score,
                type: item.result.case,
                content: {
                    highlight: fromProtoCallHighlight(
                        item.result.value.highlight as CallHighlightProto,
                    ),
                    bondId: fromProtoBondId(item.result.value.bondId),
                    callId: fromProtoCallId(item.result.value.callId),
                    startMessageId: fromProtoMessageId(item.result.value.startMessage),
                    endMessageId: fromProtoMessageId(item.result.value.endMessage),
                },
            } as CallSearchResult;
        case "attachment":
            return {
                score: item.score,
                type: item.result.case,
                content: {
                    highlight: fromProtoAttachmentHighlight(
                        item.result.value.highlight as AttachmentHighlightProto,
                    ),
                    messageId: fromProtoMessageId(item.result.value.messageId),
                    blobId: fromProtoBlobId(item.result.value.blobId),
                    bondId: fromProtoBondId(item.result.value.bondId),
                    mimeType: item.result.value.mimeType,
                },
            } as AttachmentSearchResult;
        default:
            console.error("Unknown search result type", item);
            return;
    }
}

// highlight parse funcs

function fromProtoHighlight(item: HighlightProto): Highlight {
    return {
        snippet: item.snippet!,
        matchedTokens: item.matchedTokens,
    };
}

export function fromProtoBondHighlight(
    item: BondHighlightProto,
): BondHighlight {
    return {
        title: fromProtoHighlight(item.title!),
        summary: fromProtoHighlight(item.summary!),
        detailedSummary: fromProtoHighlight(item.detailedSummary!),
    };
}

export function fromProtoMessageHighlight(
    item: MessageHighlightProto,
): MessageHighlight {
    return {
        text: fromProtoHighlight(item.text!),
    };
}

export function fromProtoCallHighlight(
    item: CallHighlightProto,
): CallHighlight {
    return {
        title: fromProtoHighlight(item.title!),
        shortSummary: fromProtoHighlight(item.shortSummary!),
        detailedSummary: fromProtoHighlight(item.detailedSummary!),
    };
}

export function fromProtoAttachmentHighlight(
    item: AttachmentHighlightProto,
): AttachmentHighlight {
    return {
        filename: fromProtoHighlight(item.filename!),
        shortDescription: fromProtoHighlight(item.shortDescription!),
        longDescription: fromProtoHighlight(item.longDescription!),
    };
}
