import * as d from "@/domain/domain";
import {
    selectBondFollowerIds,
    selectBondInvitedEmails,
    selectBondInvitedPersons,
} from "@/features/bonds";
import { selectPersons, selectUsers } from "@/features/users";
import { Optional } from "@/misc/types";
import { useMemo } from "react";
import { useInterestedPersons } from "./interest/useInterest";
import useSelectorArgs from "./useSelectorArgs";

export default function useBondInvitees(bondId: Optional<d.BondId>) {
    const followerIds = useSelectorArgs(selectBondFollowerIds, bondId);
    const followers = useSelectorArgs(selectUsers, followerIds);

    const invitedEmails = useSelectorArgs(selectBondInvitedEmails, bondId);
    const invitedPersonIds = useSelectorArgs(selectBondInvitedPersons, bondId);
    const invitedPersons = useSelectorArgs(selectPersons, invitedPersonIds);
    useInterestedPersons(invitedPersonIds);

    // Don't show invitees who have already accepted (as invitees)
    return useMemo(() => {
        const followerEmails = followers.map(follower => follower.email);
        const filteredInvitedEmails = invitedEmails.filter(email => !followerEmails.includes(email))
            .sort();
        const filteredInvitedPersons = invitedPersons.filter(person =>
            !followers.some(follower => follower.personId === person.personId)
        ).sort();

        return {
            emails: filteredInvitedEmails,
            persons: filteredInvitedPersons,
        };
    }, [followers, invitedEmails, invitedPersons]);
}
