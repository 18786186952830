import * as d from "@/domain/domain";
import { maybeValidateUUID } from "@/domain/uuid";
import { memoizeOptions } from "@/features/selectors";
import { andThen } from "@/misc/utils";
import merge from "lodash.merge";
import { Params, useParams } from "react-router-dom";
import { createSelector } from "reselect";

export interface UrlParams {
    attachmentIdx?: number;
    attachmentVersion?: number;
    bondId?: d.BondId;
    callId?: d.CallId;
    messageId?: d.MessageId;
    squadId?: d.SquadId;
    opaqueInviteCode?: string;
}

const selectAddressParams = createSelector(
    [(params: Readonly<Params<string>>) => params],
    (params): UrlParams => ({
        attachmentIdx: andThen(params.attachmentIdx, parseInt),
        attachmentVersion: andThen(params.attachmentVersion, parseInt),
        bondId: andThen(andThen(params.bondId, maybeValidateUUID), d.fromRawBondId),
        callId: andThen(andThen(params.callId, maybeValidateUUID), d.fromRawCallId),
        messageId: andThen(andThen(params.messageId, maybeValidateUUID), d.fromRawMessageId),
        squadId: andThen(andThen(params.squadId, maybeValidateUUID), d.fromRawSquadId),
        opaqueInviteCode: params.inviteCode,
    }),
    merge(
        memoizeOptions.weakMapShallow,
        memoizeOptions.weakMapShallowArgs,
    ),
);

export const useAddressParams = () => selectAddressParams(useParams());

export default useAddressParams;
