import { nanoid } from "@reduxjs/toolkit";
import { FC, useCallback, useMemo } from "react";

import RichTextMessageComposer from "@/components/RichTextMessageComposer";
import { EmitterSources, plainifyMarkupDelta } from "@/domain/delta";
import * as d from "@/domain/domain";
import { getDraftMentions, getDraftMessageMarkup } from "@/domain/draftChatContent";
import { newChannelDraftTarget, WithDraftTarget } from "@/domain/draftTarget";
import { isSquadMention } from "@/domain/mentions";
import { DraftChatMessage } from "@/domain/messages";
import { selectCurrentOrgId } from "@/features/auth";
import {
    createBondFromCallThunk,
    createBondFromMessageThunk,
    selectBondCreationAudience,
    selectBondTitleSuggestion,
    selectBondUserSpecifiedTitle,
} from "@/features/bondCreation";
import { updateDraftMarkup } from "@/features/channels";
import { selectActiveStatus } from "@/features/connection";
import { popViewStack } from "@/features/filterPanel";
import { useTakeSingleRtcSessionLock } from "@/hooks/createSharedWebLock";
import useViewStackNavigate from "@/hooks/navigation/useViewStackNavigate";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import log from "@/misc/log";
import { isMobileBrowser } from "@/misc/mobile";
import { Optional } from "@/misc/types";
import { useAppDispatch, useAppSelector } from "@/store/redux";

const placeholder = "Message...";

export type NewBondMessageComposerProps = {
    backAction: () => void;
    tabIndex?: number;
} & WithDraftTarget;

export const NewBondMessageComposer: FC<NewBondMessageComposerProps> = ({
    backAction,
    tabIndex,
    draftTarget,
}) => {
    const dispatch = useAppDispatch();
    const { navigateToBond } = useViewStackNavigate();
    const orgId = useAppSelector(selectCurrentOrgId);

    const userSpecifiedTitle = useSelectorArgs(selectBondUserSpecifiedTitle, draftTarget);
    const aiGeneratedTitle = useSelectorArgs(selectBondTitleSuggestion, draftTarget);
    const audience = useSelectorArgs(selectBondCreationAudience, draftTarget);

    const idempotentKey = useMemo(() => nanoid(), []);

    const createBondFromMsg = useCallback((draft: Optional<DraftChatMessage>) => {
        if (!draft) return;

        // In the future, we'll want this to be context dependent. Hence, don't
        // extract it from the store inside the thunk, do it here.
        if (!orgId) {
            log.warn(`No orgId for new bond from message`);
            return;
        }

        log.info("Creating bond with message");

        // Include explicit squad mentions from the draft in the bond creation request
        const mentions = getDraftMentions(draft.content).filter(isSquadMention);

        dispatch(createBondFromMessageThunk({
            orgId,
            bondTitles: {
                userSpecifiedTitle,
                aiGeneratedTitle,
            },
            msg: draft,
            audience, // Setting an audience separately from the mentions is a phase 3 feature
            mentions,
            idempotentKey,
            draftTarget,
        }))
            .unwrap()
            .then(bond => {
                log.debug("Navigating to new bond", bond.id, "created from message");
                // Remove "new bond" from the current viewstack.
                dispatch(popViewStack());
                navigateToBond({ id: bond.id, draftTarget, audience }, { replace: true });
            })
            .catch(err => {
                log.error("Error creating bond from message", err);
            });
    }, [
        dispatch,
        draftTarget,
        orgId,
        userSpecifiedTitle,
        aiGeneratedTitle,
        audience,
        navigateToBond,
        idempotentKey,
    ]);

    // Only create a bond from a call if we are able to take the rtc session lock.
    // This helps ensure we only create a bond if we are ready to go live in it straight away.
    const active = useAppSelector(selectActiveStatus);
    const haveSingleSessionLock = useTakeSingleRtcSessionLock(active);

    // Currently, this only creates the bond, NOT the call.
    // The rtc hooks start the call in the bond once we have navigated to it from here.
    const createBondFromCall = useCallback((draft: Optional<DraftChatMessage>) => {
        if (!haveSingleSessionLock) return;

        // In the future, we'll want this to be context dependent. Hence, don't
        // extract it from the store inside the thunk, do it here.
        if (!orgId) {
            log.warn(`No orgId for new bond from call`);
            return;
        }

        const transferDraftToChannel = (
            draft: Optional<DraftChatMessage>,
            channelId: d.ChannelId,
        ) => {
            if (!draft) return;

            const newDraftTarget = newChannelDraftTarget(channelId);
            dispatch(
                updateDraftMarkup({
                    draftTarget: newDraftTarget,
                    update: { markup: plainifyMarkupDelta(getDraftMessageMarkup(draft.content)) },
                    source: EmitterSources.API,
                }),
            );
        };

        log.info("Creating bond from call");

        dispatch(createBondFromCallThunk({
            orgId,
            bondTitles: {
                userSpecifiedTitle,
                aiGeneratedTitle,
            },
            audience,
            draftTarget,
            idempotentKey,
        })).unwrap()
            .then(bond => {
                log.debug("Navigating to new bond", bond.id, "created from call");

                transferDraftToChannel(draft, bond.channelId);

                // Remove "new bond" from the current viewstack.
                dispatch(popViewStack());
                navigateToBond({ id: bond.id, draftTarget, audience }, { replace: true });
            })
            .catch(err => {
                log.error("Error creating bond from call", err);
            });
    }, [
        dispatch,
        haveSingleSessionLock,
        orgId,
        userSpecifiedTitle,
        aiGeneratedTitle,
        audience,
        navigateToBond,
        draftTarget,
        idempotentKey,
    ]);

    return (
        <RichTextMessageComposer
            id="comms-input-bond-creation"
            key="comms-input-bond-creation"
            draftTarget={draftTarget}
            msgCompletionAction={createBondFromMsg}
            mediaEnableAction={createBondFromCall}
            escapeAction={backAction}
            bondComposer={true}
            placeholder={placeholder}
            tabIndex={tabIndex}
            autoFocus={!isMobileBrowser()}
        />
    );
};

export default NewBondMessageComposer;
