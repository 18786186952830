import { z } from "zod";

export interface AuthUser {
    // Copied from react-oidc-context -- what is this again?
    url_state?: string;
}

export const TokenResponseSchema = z.object({
    access_token: z.string(),
    token_type: z.enum(["Bearer"]), // TODO
    expires_in: z.number().optional(),
    refresh_token: z.string().optional(),
    scope: z.string().optional(),
    id_token: z.string().optional(),
});
export type TokenResponse = z.infer<typeof TokenResponseSchema>;

export const AuthRequestStateSchema = z.object({
    state: z.string(),
    code_verifier: z.string(),
});
export type AuthRequestState = z.infer<typeof AuthRequestStateSchema>;

// A lot of this could be from discovery. But some of it needs to be hard-coded
// like
export interface OidcConfig {
    client_id: string;
    token_scope: string;
    token_endpoint: string;
    redirect_uri: string;
    authorization_endpoint: string;
}

export interface AuthMachineStorage {
    load(): Promise<any | null>;
    // Writing 'null' should remove the state from storage
    save(state: any | null): Promise<void>;

    dispose?(del: boolean, wait: boolean): Promise<void>;
}
