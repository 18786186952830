// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=js+ts"
// @generated from file users/users.proto (package users, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CompleteUserAvatarImageRequest, CompleteUserAvatarImageResponse, CreateUserAvatarImageRequest, CreateUserAvatarImageResponse, GetAvatarBlobUrlRequest, GetAvatarBlobUrlResponse, GetCohortRequest, GetCohortResponse, GetUsersRequest, GetUsersResponse, ListBondmatesRequest, ListBondmatesResponse, ListOpenBondSquadmatesRequest, ListOpenBondSquadmatesResponse, SubPersonsRequest, SubPersonsResponse, SubUsersRequest, SubUsersResponse } from "./users_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service users.UserService
 */
export const UserService = {
  typeName: "users.UserService",
  methods: {
    /**
     * GetCohort returns the user ids for the full set of users known to the requester.
     *
     * @generated from rpc users.UserService.GetCohort
     */
    getCohort: {
      name: "GetCohort",
      I: GetCohortRequest,
      O: GetCohortResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListBondmates returns the id of all users who share at least one bond with the given user.
     *
     * @generated from rpc users.UserService.ListBondmates
     */
    listBondmates: {
      name: "ListBondmates",
      I: ListBondmatesRequest,
      O: ListBondmatesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListOpenBondSquadmates returns the id of all users who are in a squad which houses
     * at least one open bond which the given user is a part of.
     *
     * @generated from rpc users.UserService.ListOpenBondSquadmates
     */
    listOpenBondSquadmates: {
      name: "ListOpenBondSquadmates",
      I: ListOpenBondSquadmatesRequest,
      O: ListOpenBondSquadmatesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetAvatarBlobUrl returns a signed URL for the avatar image with the given blob ID.
     *
     * @generated from rpc users.UserService.GetAvatarBlobUrl
     */
    getAvatarBlobUrl: {
      name: "GetAvatarBlobUrl",
      I: GetAvatarBlobUrlRequest,
      O: GetAvatarBlobUrlResponse,
      kind: MethodKind.Unary,
    },
    /**
     * CreateUserAvatarImage sets the avatar image for a user.
     *
     * @generated from rpc users.UserService.CreateUserAvatarImage
     */
    createUserAvatarImage: {
      name: "CreateUserAvatarImage",
      I: CreateUserAvatarImageRequest,
      O: CreateUserAvatarImageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * CompleteUserAvatarImage completes the avatar image for a user.
     *
     * @generated from rpc users.UserService.CompleteUserAvatarImage
     */
    completeUserAvatarImage: {
      name: "CompleteUserAvatarImage",
      I: CompleteUserAvatarImageRequest,
      O: CompleteUserAvatarImageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetUsers returns the user overviews for the given user ids.
     *
     * @generated from rpc users.UserService.GetUsers
     */
    getUsers: {
      name: "GetUsers",
      I: GetUsersRequest,
      O: GetUsersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * SubUsers streams the user overviews for the given user ids.
     *
     * @generated from rpc users.UserService.SubUsers
     */
    subUsers: {
      name: "SubUsers",
      I: SubUsersRequest,
      O: SubUsersResponse,
      kind: MethodKind.BiDiStreaming,
    },
    /**
     * SubPersons streams the person overviews for the given person ids.
     *
     * @generated from rpc users.UserService.SubPersons
     */
    subPersons: {
      name: "SubPersons",
      I: SubPersonsRequest,
      O: SubPersonsResponse,
      kind: MethodKind.BiDiStreaming,
    },
  }
};

