import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export interface useNavigateBackProps {
    /** The path to fall back to if we cannot go back */
    defaultPath?: string;
    /** Whether to replace the current location in the history,
     * in the case we're falling back to the default path. */
    replaceDefault?: boolean;
}

export function useNavigateBack(props?: useNavigateBackProps) {
    const defaultPath = props?.defaultPath ?? "/";
    const replaceDefault = props?.replaceDefault ?? true;

    const location = useLocation();
    const navigate = useNavigate();
    const hasPreviousRoute = useMemo(() => location.key !== "default", [location]);

    // A callback to navigate to the last route if it exists, else the default path
    const navigateBack = useCallback(() => {
        if (hasPreviousRoute) {
            navigate(-1);
        }
        else {
            navigate(defaultPath, { replace: replaceDefault });
        }
    }, [
        defaultPath,
        hasPreviousRoute,
        navigate,
        replaceDefault,
    ]);

    return { navigate, navigateBack };
}
