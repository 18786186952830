import { createSelector, nanoid, PayloadAction } from "@reduxjs/toolkit";
import { DateTime, Duration, DurationLike, DurationLikeObject } from "luxon";

import { BackendInfo } from "@/api/users";
import * as d from "@/domain/domain";
import { resetStore } from "@/features/auth";
import { ConnectionStatus, updateConnectionStatus } from "@/features/connection";
import { Optional } from "@/misc/types";
import { createRandomBase64String } from "@/misc/utils";
import { checkPersistor, localStorageStore } from "@/persist/shared";
import { storageRead, storageWrite } from "@/persist/storage";
import { createLocalSlice } from "@/store/localSlice";
import type { RootState, StoreConfig } from "@/store/types";

type SerialisableDurationLike = Exclude<DurationLike, Duration>;

const stores = {
    deviceTag: localStorageStore<string>("meta/deviceTag", 1, undefined, { raw: true }),
};

type IdleState = {
    since?: number; // DateTime in milliseconds
    subscribers: Partial<Record<string, SerialisableDurationLike>>;
};

export type ToastContent = {
    message: string;
    created: number; // DateTime in milliseconds
    // Only allow serialisable `DurationLike` values.
    duration: DurationLikeObject;
};

type CreateToastArgs = {
    message: string;
    duration?: DurationLikeObject;
};

const defaultToastDuration: DurationLikeObject = { seconds: 2 };

export type MetaState = {
    config: StoreConfig;
    deviceTag: d.DeviceTag;
    connectionTag: d.ConnectionTag;
    connectionNumber: number;
    backendInfo?: BackendInfo;
    idbUpgradeRequired: boolean;
    showReportIssueDialog: boolean;
    showSidebar: boolean;
    idle: IdleState;
    wakeLockInterest: number;
    wakeLockHeld: boolean;
    toast: Optional<ToastContent>;
};

export const getDeviceTag = (() => {
    let deviceTag: string | null = null;
    return () => {
        if (deviceTag) {
            return deviceTag;
        }

        const stored = storageRead(stores.deviceTag);
        if (stored) {
            deviceTag = stored;
            return deviceTag;
        }

        deviceTag = createRandomBase64String();
        storageWrite(stores.deviceTag, deviceTag);
        return deviceTag;
    };
})();

export const getInitialMetaState = (
    props?: Partial<Omit<MetaState, "config">> & { config: Partial<StoreConfig>; },
): MetaState => {
    return {
        config: {
            persist: false,
            broadcast: false,
            proxy: false,
            manageAuth: false,
            manageConnection: false,
            startListeners: false,
            debug: false,
            perf: false,
            ...props?.config,
        },
        deviceTag: props?.deviceTag ?? getDeviceTag(),
        connectionNumber: props?.connectionNumber ?? 0,
        connectionTag: props?.connectionTag ?? createRandomBase64String(),
        backendInfo: props?.backendInfo,
        idbUpgradeRequired: props?.idbUpgradeRequired ?? false,
        showReportIssueDialog: props?.showReportIssueDialog ?? false,
        showSidebar: props?.showSidebar ?? true,
        idle: props?.idle ?? { subscribers: {} },
        wakeLockInterest: 0,
        wakeLockHeld: false,
        toast: undefined,
    };
};

const metaSlice = createLocalSlice({
    name: "meta",
    initialState: getInitialMetaState(),
    selectors: {
        backendInfo: state => state.backendInfo,
        deviceTag: state => state.deviceTag,
        connectionTag: state => state.connectionTag,
        connectionNumber: state => state.connectionNumber,
        idbUpgradeRequired: state => state.idbUpgradeRequired,
        showReportIssueDialog: state => state.showReportIssueDialog,
        showSidebar: state => state.showSidebar,
        idleSince: state => state.idle.since,
        idleSubscribers: state => state.idle.subscribers,
        wakeLockInterest: state => state.wakeLockInterest,
        wakeLockHeld: state => state.wakeLockHeld,
        toast: state => state.toast,
    },
    reducers: {
        idbUpgradeRequired: state => {
            state.idbUpgradeRequired = true;
        },
        updateBackendInfo: (state, action: PayloadAction<BackendInfo>) => {
            state.backendInfo = action.payload;
        },
        showReportIssueDialog: (state, action: PayloadAction<boolean>) => {
            state.showReportIssueDialog = action.payload;
        },
        showSidebar: (state, action: PayloadAction<boolean>) => {
            state.showSidebar = action.payload;
        },
        setIdleSince: (state, action: PayloadAction<number>) => {
            state.idle.since = action.payload;
        },
        setNotIdle: state => {
            state.idle.since = undefined;
        },
        addIdleSubscriber: {
            prepare: (timeout: SerialisableDurationLike) => ({
                payload: { id: nanoid(), duration: timeout },
            }),
            reducer: (
                state,
                action: PayloadAction<{ id: string; duration: SerialisableDurationLike; }>,
            ) => {
                state.idle.subscribers[action.payload.id] = action.payload.duration;
            },
        },
        removeIdleSubscriber: (state, action: PayloadAction<string>) => {
            delete state.idle.subscribers[action.payload];
        },

        registerWakeLockInterest: (state, _action: PayloadAction<void>) => {
            state.wakeLockInterest++;
        },
        unregisterWakeLockInterest: (state, _action: PayloadAction<void>) => {
            if (state.wakeLockInterest < 1) {
                // If this happens, this is indicative of a bad problem.
                // Somehow, Redux's knowledge of this is out of sync with reality.
                state.wakeLockInterest = 0;
            }
            else {
                state.wakeLockInterest--;
            }
        },
        setWakeLockHeld: (state, action: PayloadAction<boolean>) => {
            state.wakeLockHeld = action.payload;
        },

        createToast: {
            prepare: (args: CreateToastArgs) => ({
                payload: {
                    message: args.message,
                    created: DateTime.now().toMillis(),
                    duration: args.duration || defaultToastDuration,
                },
            }),
            reducer: (state, action: PayloadAction<ToastContent>) => {
                state.toast = action.payload;
            },
        },
    },
    extraReducers: builder => {
        builder.addCase(
            updateConnectionStatus,
            (state, action: PayloadAction<ConnectionStatus>) => {
                if (action.payload === ConnectionStatus.ShouldConnect) {
                    state.connectionNumber++;
                }
            },
        );
        builder.addCase(resetStore, _state => {
            // Do nothing.
        });
    },
});

const selectors = metaSlice.getSelectors((state: RootState) => state.meta);

export { selectStoreConfig } from "@/store/redux";

export const {
    backendInfo: selectBackendInfo,
    idbUpgradeRequired: selectIdbUpgradeRequired,
    showReportIssueDialog: selectShowReportIssueDialog,
    showSidebar: selectShowSidebar,
    idleSubscribers: selectIdleSubscribers,
    idleSince: selectIdleSince,
    wakeLockInterest: selectWakeLockInterest,
    wakeLockHeld: selectWakeLockHeld,
    toast: selectNewestToast,
} = selectors;

export const selectConnectionIdentifiers = createSelector(
    [selectors.deviceTag, selectors.connectionTag, selectors.connectionNumber],
    (device, tag, n) => ({ deviceTag: device, connTagPrefix: tag, connTagNum: n }),
);

export const {
    idbUpgradeRequired,
    updateBackendInfo,
    showReportIssueDialog,
    showSidebar,
    setIdleSince,
    setNotIdle,
    addIdleSubscriber,
    removeIdleSubscriber,
    registerWakeLockInterest,
    unregisterWakeLockInterest,
    setWakeLockHeld,
    createToast,
} = metaSlice.actions;

export const reducer = metaSlice.reducer;

// Persistence.

export const persistor = {
    stores: {},
};
checkPersistor<MetaState>(persistor);
