// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=js+ts"
// @generated from file squads/squads.proto (package squads, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddSquadMemberRequest, AddSquadMemberResponse, AddSquadsToCrewRequest, AddSquadsToCrewResponse, AddUsersToCrewRequest, AddUsersToCrewResponse, CreateCrewRequest, CreateCrewResponse, CreateOrgRequest, CreateOrgResponse, CreateSquadRequest, CreateSquadResponse, CreateUserRequest, CreateUserResponse, GetCrewsRequest, GetCrewsResponse, GetOrgsRequest, GetOrgsResponse, GetSquadsRequest, GetSquadsResponse, GetUserDefinitionRequest, GetUserDefinitionResponse, ListCrewMembersRequest, ListCrewMembersResponse, ListOrgMembersRequest, ListOrgMembersResponse, ListOrgsRequest, ListOrgsResponse, ListPersonOrgsRequest, ListPersonOrgsResponse, ListSquadmatesRequest, ListSquadmatesResponse, ListSquadMembersRequest, ListSquadMembersResponse, ListSquadsRequest, ListSquadsResponse, ListUsersByPersonRequest, ListUsersByPersonResponse, ListUserSquadsRequest, ListUserSquadsResponse, RemoveSquadsFromCrewRequest, RemoveSquadsFromCrewResponse, RemoveUsersFromCrewRequest, RemoveUsersFromCrewResponse, SetSquadLastReadRequest, SetSquadLastReadResponse, SubFollowedUnreadCountRequest, SubFollowedUnreadCountResponse, SubOrgOverviewsRequest, SubOrgOverviewsResponse, SubSquadLatestActivityRequest, SubSquadLatestActivityResponse, SubSquadOverviewsRequest, SubSquadOverviewsResponse, SubUserSquadLastReadsRequest, SubUserSquadLastReadsResponse, SubUserSquadListsRequest, SubUserSquadListsResponse } from "./squads_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service squads.SquadService
 */
export const SquadService = {
  typeName: "squads.SquadService",
  methods: {
    /**
     * CreatOrg creates a new empty organisation.
     *
     * @generated from rpc squads.SquadService.CreateOrg
     */
    createOrg: {
      name: "CreateOrg",
      I: CreateOrgRequest,
      O: CreateOrgResponse,
      kind: MethodKind.Unary,
    },
    /**
     * CreateSquad creates a new empty squad in the given org.
     *
     * @generated from rpc squads.SquadService.CreateSquad
     */
    createSquad: {
      name: "CreateSquad",
      I: CreateSquadRequest,
      O: CreateSquadResponse,
      kind: MethodKind.Unary,
    },
    /**
     * CreateUser adds a user to an org.
     *
     * @generated from rpc squads.SquadService.CreateUser
     */
    createUser: {
      name: "CreateUser",
      I: CreateUserRequest,
      O: CreateUserResponse,
      kind: MethodKind.Unary,
    },
    /**
     * AddSquadMember adds a user to a squad.
     *
     * @generated from rpc squads.SquadService.AddSquadMember
     */
    addSquadMember: {
      name: "AddSquadMember",
      I: AddSquadMemberRequest,
      O: AddSquadMemberResponse,
      kind: MethodKind.Unary,
    },
    /**
     * SetSquadLastRead sets the last read timestamp for a user in a squad.
     * NOTE: Frontends must not call this RPC with a timestamp derived from their
     * own clock. They should instead use the timestamp they see for the relevant
     * squad's latest activity, as provided by the backend.
     *
     * @generated from rpc squads.SquadService.SetSquadLastRead
     */
    setSquadLastRead: {
      name: "SetSquadLastRead",
      I: SetSquadLastReadRequest,
      O: SetSquadLastReadResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListOrgs returns the ids of all orgs.
     *
     * @generated from rpc squads.SquadService.ListOrgs
     */
    listOrgs: {
      name: "ListOrgs",
      I: ListOrgsRequest,
      O: ListOrgsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetOrgs returns the overviews of the requested organisations.
     *
     * @generated from rpc squads.SquadService.GetOrgs
     */
    getOrgs: {
      name: "GetOrgs",
      I: GetOrgsRequest,
      O: GetOrgsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListSquads returns the ids of all squads in the requested org.
     *
     * @generated from rpc squads.SquadService.ListSquads
     */
    listSquads: {
      name: "ListSquads",
      I: ListSquadsRequest,
      O: ListSquadsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetSquads returns the overviews of the requested squads.
     *
     * @generated from rpc squads.SquadService.GetSquads
     */
    getSquads: {
      name: "GetSquads",
      I: GetSquadsRequest,
      O: GetSquadsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetUserDefinition returns the person ID and org ID that define a user.
     *
     * @generated from rpc squads.SquadService.GetUserDefinition
     */
    getUserDefinition: {
      name: "GetUserDefinition",
      I: GetUserDefinitionRequest,
      O: GetUserDefinitionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetUsersByPerson returns all user ids associated with the given person.
     *
     * @generated from rpc squads.SquadService.ListUsersByPerson
     */
    listUsersByPerson: {
      name: "ListUsersByPerson",
      I: ListUsersByPersonRequest,
      O: ListUsersByPersonResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListOrgMembers returns the user ids of all users in the org.
     *
     * @generated from rpc squads.SquadService.ListOrgMembers
     */
    listOrgMembers: {
      name: "ListOrgMembers",
      I: ListOrgMembersRequest,
      O: ListOrgMembersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListSquadMembers returns the user ids of all users in the squad.
     *
     * @generated from rpc squads.SquadService.ListSquadMembers
     */
    listSquadMembers: {
      name: "ListSquadMembers",
      I: ListSquadMembersRequest,
      O: ListSquadMembersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListUserSquads returns the organisational details regarding a user.
     *
     * @generated from rpc squads.SquadService.ListUserSquads
     */
    listUserSquads: {
      name: "ListUserSquads",
      I: ListUserSquadsRequest,
      O: ListUserSquadsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListPersonOrgs returns the organisational details regarding a person.
     *
     * @generated from rpc squads.SquadService.ListPersonOrgs
     */
    listPersonOrgs: {
      name: "ListPersonOrgs",
      I: ListPersonOrgsRequest,
      O: ListPersonOrgsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListSquadmates returns the user ids of all users who share a squad with the given user.
     *
     * @generated from rpc squads.SquadService.ListSquadmates
     */
    listSquadmates: {
      name: "ListSquadmates",
      I: ListSquadmatesRequest,
      O: ListSquadmatesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * CreateCrew creates a new crew with the given squad and user members
     *
     * @generated from rpc squads.SquadService.CreateCrew
     */
    createCrew: {
      name: "CreateCrew",
      I: CreateCrewRequest,
      O: CreateCrewResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ListCrewMembers returns the user and squad ids of all users and squads in the crew
     *
     * @generated from rpc squads.SquadService.ListCrewMembers
     */
    listCrewMembers: {
      name: "ListCrewMembers",
      I: ListCrewMembersRequest,
      O: ListCrewMembersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetCrews returns the overviews of the requested crews
     *
     * @generated from rpc squads.SquadService.GetCrews
     */
    getCrews: {
      name: "GetCrews",
      I: GetCrewsRequest,
      O: GetCrewsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * AddSquadsToCrew adds a set of squads to the specified crew
     *
     * @generated from rpc squads.SquadService.AddSquadsToCrew
     * @deprecated
     */
    addSquadsToCrew: {
      name: "AddSquadsToCrew",
      I: AddSquadsToCrewRequest,
      O: AddSquadsToCrewResponse,
      kind: MethodKind.Unary,
    },
    /**
     * RemoveSquadsFromCrew removes a set of squads from the specified crew
     *
     * @generated from rpc squads.SquadService.RemoveSquadsFromCrew
     * @deprecated
     */
    removeSquadsFromCrew: {
      name: "RemoveSquadsFromCrew",
      I: RemoveSquadsFromCrewRequest,
      O: RemoveSquadsFromCrewResponse,
      kind: MethodKind.Unary,
    },
    /**
     * AddUsersToCrew adds a set of users to the specified crew
     *
     * @generated from rpc squads.SquadService.AddUsersToCrew
     * @deprecated
     */
    addUsersToCrew: {
      name: "AddUsersToCrew",
      I: AddUsersToCrewRequest,
      O: AddUsersToCrewResponse,
      kind: MethodKind.Unary,
    },
    /**
     * RemoveUsersFromCrew removes a set of users from the specified crew
     *
     * @generated from rpc squads.SquadService.RemoveUsersFromCrew
     * @deprecated
     */
    removeUsersFromCrew: {
      name: "RemoveUsersFromCrew",
      I: RemoveUsersFromCrewRequest,
      O: RemoveUsersFromCrewResponse,
      kind: MethodKind.Unary,
    },
    /**
     * SubUserSquadLists streams the organisational details of all users requested.
     *
     * @generated from rpc squads.SquadService.SubUserSquadLists
     */
    subUserSquadLists: {
      name: "SubUserSquadLists",
      I: SubUserSquadListsRequest,
      O: SubUserSquadListsResponse,
      kind: MethodKind.BiDiStreaming,
    },
    /**
     * SubUserSquadLastReads streams the last read timestamps for all requested squad-user pairs.
     *
     * @generated from rpc squads.SquadService.SubUserSquadLastReads
     */
    subUserSquadLastReads: {
      name: "SubUserSquadLastReads",
      I: SubUserSquadLastReadsRequest,
      O: SubUserSquadLastReadsResponse,
      kind: MethodKind.BiDiStreaming,
    },
    /**
     * SubOrgOverviews streams the overviews for the requested orgs.
     *
     * @generated from rpc squads.SquadService.SubOrgOverviews
     */
    subOrgOverviews: {
      name: "SubOrgOverviews",
      I: SubOrgOverviewsRequest,
      O: SubOrgOverviewsResponse,
      kind: MethodKind.BiDiStreaming,
    },
    /**
     * SubSquadOverviews streams the overviews for the requested squads.
     *
     * @generated from rpc squads.SquadService.SubSquadOverviews
     */
    subSquadOverviews: {
      name: "SubSquadOverviews",
      I: SubSquadOverviewsRequest,
      O: SubSquadOverviewsResponse,
      kind: MethodKind.BiDiStreaming,
    },
    /**
     * SubSquadLatestActivity streams back the current activity for the requested squads.
     * Note: Ideally this would be called SubSquadActivity.
     *
     * @generated from rpc squads.SquadService.SubSquadLatestActivity
     */
    subSquadLatestActivity: {
      name: "SubSquadLatestActivity",
      I: SubSquadLatestActivityRequest,
      O: SubSquadLatestActivityResponse,
      kind: MethodKind.BiDiStreaming,
    },
    /**
     * SubFollowedUnreadCount streams the number of unread followed bonds for a user.
     *
     * @generated from rpc squads.SquadService.SubFollowedUnreadCount
     */
    subFollowedUnreadCount: {
      name: "SubFollowedUnreadCount",
      I: SubFollowedUnreadCountRequest,
      O: SubFollowedUnreadCountResponse,
      kind: MethodKind.ServerStreaming,
    },
  }
};

