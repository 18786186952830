import { SensitiveJSX } from "@/components/gui/SensitiveText";
import TimeAgoCatchup from "@/components/gui/TimeAgoCatchup";
import * as d from "@/domain/domain";
import { SummaryRequestStatus } from "@/domain/intel";
import { viewStackUtils, viewUtils } from "@/domain/views";
import {
    selectUnreadBondsForSquad,
    updateStagedSequenceNumberIfGreater,
} from "@/features/channels";
import { selectCurrentViewStack } from "@/features/filterPanel";
import {
    selectSquadKnowledgeMetadata,
    selectSquadSummary,
    selectSquadSummaryRequestStatus,
} from "@/features/squads";
import useViewStackNavigate from "@/hooks/navigation/useViewStackNavigate";
import useAddressParams from "@/hooks/useAddressParams";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import log from "@/misc/log";
import { isMobileBrowser } from "@/misc/mobile";
import { useAppSelector } from "@/store/redux";
import { useAppDispatch } from "@/store/redux";
import purify from "dompurify";
import { Marked } from "marked";
import { useCallback } from "react";

function MarkAsReadButton(
    { squadId }: { squadId: d.SquadId; },
): React.JSX.Element {
    const knowledgeMetadata = useSelectorArgs(selectSquadKnowledgeMetadata, squadId);
    const dispatch = useAppDispatch();
    const viewStack = useAppSelector(selectCurrentViewStack);
    const { navigateReplace } = useViewStackNavigate();

    const markAsRead = useCallback(() => {
        if (knowledgeMetadata === undefined) {
            log.error(`squad id or knowledge metadata is undefined`);
            return;
        }
        knowledgeMetadata.ChannelReadStates.map(
            st => {
                dispatch(
                    updateStagedSequenceNumberIfGreater({
                        channelId: st.ChannelId,
                        sequenceNumber: st.MaxMessageSequenceNumber,
                    }),
                );
            },
        );

        const newStack = viewStackUtils.updateTopOfStack(viewStack, _ => {
            return viewUtils.squad(squadId, false);
        });
        navigateReplace(newStack, { replace: true });
    }, [knowledgeMetadata, dispatch, viewStack, navigateReplace, squadId]);

    return (
        <button
            className="c-btn-solid c-btn-solid--read"
            disabled={false}
            onClick={markAsRead}
        >
            <div className="c-btn-solid__icon c-btn-solid__icon--read"></div>
            Mark as read
        </button>
    );
}

export function SquadSidebarSummaryView(): React.JSX.Element {
    const { squadId } = useAddressParams();
    const summaryRequestStatus = useSelectorArgs(selectSquadSummaryRequestStatus, squadId);
    const isMobile = isMobileBrowser();
    const summary = useSelectorArgs(selectSquadSummary, squadId);
    const knowledgeMetadata = useSelectorArgs(selectSquadKnowledgeMetadata, squadId);
    const unreadBonds = useSelectorArgs(selectUnreadBondsForSquad, squadId);

    const hasUndefinedValues = !squadId || knowledgeMetadata === undefined || summary == undefined;
    const isHideSidebarStatus = summaryRequestStatus === SummaryRequestStatus.Unspecified;

    if (summaryRequestStatus === SummaryRequestStatus.Error) {
        return <SidebarSummaryErrorMessage message={summary} />;
    }

    if (isMobile || hasUndefinedValues || isHideSidebarStatus) {
        return <></>;
    }
    const squadHasUnreadBonds = unreadBonds.length > 0;
    return (
        <div
            className={"c-sidebar c-summary c-summary--catchup c-summary--open"}
        >
            <div className="c-sidebar__content c-sidebar__content--summary">
                <div className="c-summary__content">
                    <TimeAgoCatchup
                        describeLive={false}
                        from={knowledgeMetadata.TimeOfFirstMessage}
                    />
                    <MarkdownContent summary={summary} />
                </div>
                <div className="c-summary__footer">
                    {squadHasUnreadBonds && <MarkAsReadButton squadId={squadId} />}
                </div>
            </div>
        </div>
    );
}

function MarkdownContent({ summary }: { summary: string; }) {
    const parser = new Marked();
    const parsedSummary = parser.parse(summary).toString();
    return (
        <SensitiveJSX>
            <div
                className="c-summary__generated"
                dangerouslySetInnerHTML={{
                    __html: purify.sanitize(parsedSummary),
                }}
            />
        </SensitiveJSX>
    );
}

export function SidebarSummaryErrorMessage(
    { message }: { message?: string; },
): React.JSX.Element {
    return (
        <div
            className={"c-sidebar c-summary c-summary--open"}
        >
            <div className="c-sidebar__content c-sidebar__content--summary">
                <div className="c-summary__content">
                    Oops, something went wrong. Please report an issue with `Ctrl + b`.
                    {message ? "Error: " + message : ""}
                </div>
            </div>
        </div>
    );
}
