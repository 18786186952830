import { bondIsFollowed, bondIsNotArchived } from "@/domain/bonds";
import * as d from "@/domain/domain";
import { getDominantPresenceMode, getPresenceIcon } from "@/domain/presence";
import { userOverviewToDominantPresence } from "@/domain/users";
import { hamtValues } from "@/ds/hamt";
import {
    selectArchivedBondsSet,
    selectBondLiveParticipantIds,
    selectBondObserverIds,
    selectBonds,
} from "@/features/bonds";
import { bondIsUnread, selectAllStagedSequenceNumbers } from "@/features/channels";
import { createSelectorPair, memoizeOptions } from "@/features/selectors";
import { selectUser } from "@/features/users";
import { createAppSelector, selectCurrentUserId } from "@/store/redux";
import { mobileSquadsTabReadTimesKey, selectAllSquadActivities, selectReadTimes } from "./squads";

// selectUserDominantPresence selects the dominant presence mode of a user.
const selectUserDominantPresence = createAppSelector(
    [selectUser],
    userOverviewToDominantPresence,
    memoizeOptions.weakMap,
);

const selectUnsortedBondParticipants = createAppSelector(
    [selectBondLiveParticipantIds, selectBondObserverIds],
    (live, obs) => (live ?? []).concat(obs),
    memoizeOptions.weakMapShallow,
);

const selectPresenceIconFromBonds = createAppSelector(
    [state => state, (_state, bondIds: d.BondId[]) => bondIds],
    (state, bondIds) =>
        getPresenceIcon(
            getDominantPresenceMode(
                bondIds
                    .flatMap(id => selectUnsortedBondParticipants(state, id))
                    .map(userId => selectUserDominantPresence(state, userId)),
            ),
        ),
    memoizeOptions.weakMap,
);

// selectInboxTopNBonds selects the top N bonds in the inbox.
//
// The default limit is 10.
const selectInboxTopNBonds = createAppSelector(
    [
        selectBonds,
        selectCurrentUserId,
        selectArchivedBondsSet,
        (_state, limit: number = 10) => limit,
    ],
    (bonds, currentUserId, archivedSet, limit) => {
        const predicates = [
            bondIsFollowed(currentUserId),
            bondIsNotArchived(archivedSet),
        ];

        return bonds
            .filter(bo => predicates.every(p => p(bo)))
            .slice(0, limit)
            .map(bo => bo.id);
    },
    memoizeOptions.weakMapShallow,
);

// selectInboxDominantPresenceIcon selects the icon representing the dominant
// presence mode of any user in the top N bonds in the inbox.
//
// The default limit is 10.
const selectInboxDominantPresenceIcon = createAppSelector(
    [
        state => state,
        selectInboxTopNBonds,
    ],
    selectPresenceIconFromBonds,
    memoizeOptions.weakMap,
);

// selectInboxDominantPresenceIconPair selects the icon representing the dominant
// presence mode of any user in the top N bonds in the inbox while registering
// interest in all bonds considered.
//
// The default limit is 10.
export const selectInboxDominantPresenceIconPair = createSelectorPair(
    selectInboxTopNBonds,
    selectInboxDominantPresenceIcon,
);

// selectAllSquadsDominantPresenceIcon selects the icon representing the dominant
// presence mode of any user in the top N bonds in all squads combined.
//
// The default limit is 50.
export const selectAllSquadsDominantPresenceIcon = createAppSelector(
    [
        selectAllSquadActivities,
    ],
    squadActivities =>
        getDominantPresenceMode(
            Array.from(hamtValues(squadActivities))
                .map(sa => sa.dominantPresence)
                .filter(x => x !== undefined),
        ),
    memoizeOptions.weakMap,
);

// selectLatestSquadActivityTimestamp selects the timestamp of the latest activity for
// any squad.
export const selectLatestSquadActivityTimestamp = createAppSelector(
    [
        selectAllSquadActivities,
    ],
    squadActivities => {
        if (!squadActivities) return 0;

        return Array.from(hamtValues(squadActivities))
            .map(sa => sa.latestActivityAt)
            .reduce((previous, current) => (previous < current ? current : previous), 0);
    },
);

// selectMobileSquadsTabHighlighted selects whether any squads have had activity
// since the last time the mobile squads tab was marked as read.
export const selectMobileSquadsTabHighlighted = createAppSelector(
    [
        selectLatestSquadActivityTimestamp,
        selectReadTimes,
    ],
    (latestActivityTime, readTimes) => {
        if (!latestActivityTime || !readTimes) return false;

        return (latestActivityTime > (readTimes[mobileSquadsTabReadTimesKey] ?? 0));
    },
);

// selectInboxUnreadBondsExist selects whether there are any unread bonds in the inbox.
export const selectInboxUnreadBondsExist = createAppSelector(
    [
        selectBonds,
        selectCurrentUserId,
        selectArchivedBondsSet,
        selectAllStagedSequenceNumbers,
    ],
    (bonds, currentUserId, archivedSet, stagedSequenceNumberMap) => {
        const predicates = [
            bondIsFollowed(currentUserId),
            bondIsNotArchived(archivedSet),
            bondIsUnread(stagedSequenceNumberMap),
        ];

        return bonds.some(bo => predicates.every(p => p(bo)));
    },
    memoizeOptions.weakMapShallow,
);
