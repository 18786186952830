import { defaultBackoffState, defaultBackoffUpdater, WithBackoff } from "@/domain/backoff";
import type { BlobCredentials, BlobMetadata, BlobOwnershipDetails } from "@/domain/blobs";
import * as d from "@/domain/domain";
import type { WithDraftTarget } from "@/domain/draftTarget";
import { Optional } from "@/misc/types";

export type OfficialAttachment = {
    id: d.BlobId;
    metadata: BlobMetadata;
    credentials: BlobCredentials;
};

// Local attachments

export enum LocalAttachmentStatus {
    Proposed = "Proposed",
    Credentialed = "Credentialed",
    Uploading = "Uploading",
    Completable = "Completable",
    Uploaded = "Uploaded",
    Failed = "Failed",
}

type LocalAttachment = {
    localId: d.LocalAttachmentId;
    metadata: BlobMetadata;
    status: LocalAttachmentStatus;
    initiatedAt: d.Timestamp;
    ownership: BlobOwnershipDetails;
} & WithDraftTarget;

interface WithCredentials {
    credentials: BlobCredentials;
}

interface WithBlobId {
    blobId: d.BlobId;
}

// Attachment awaiting credentials.
export type ProposedAttachment = LocalAttachment & WithBackoff & {
    status: LocalAttachmentStatus.Proposed;
};

// Attachment awaiting upload.
export type CredentialedAttachment = LocalAttachment & WithBackoff & WithCredentials & {
    status: LocalAttachmentStatus.Credentialed;
};

// Attachment currently being uploaded.
export type UploadingAttachment = LocalAttachment & WithBackoff & WithCredentials & {
    status: LocalAttachmentStatus.Uploading;
    percentCompleted: number;
};

export type CompletableAttachment = LocalAttachment & WithBackoff & WithBlobId & {
    status: LocalAttachmentStatus.Completable;
};

// Attachment finished uploading.
export type UploadedAttachment = LocalAttachment & WithBlobId & {
    status: LocalAttachmentStatus.Uploaded;
};

// Attachment in a failed state; not recoverable.
export type FailedAttachment = LocalAttachment & Partial<WithBlobId> & Partial<WithCredentials> & {
    status: LocalAttachmentStatus.Failed;
    reason?: string;
};

export type AnyLocalAttachment =
    | ProposedAttachment
    | CredentialedAttachment
    | UploadingAttachment
    | CompletableAttachment
    | UploadedAttachment
    | FailedAttachment;

export type AnyAttachment = OfficialAttachment | AnyLocalAttachment;

// Helper functions

export const isOfficialAttachment = (
    attachment: Optional<AnyAttachment>,
): attachment is OfficialAttachment => {
    return !!attachment && "id" in attachment;
};

export const isAnyLocalAttachment = (a: Optional<AnyAttachment>): a is AnyLocalAttachment =>
    !!a && "localId" in a;

export const isProposedAttachment = (a: Optional<AnyLocalAttachment>): a is ProposedAttachment =>
    a?.status === LocalAttachmentStatus.Proposed;

export const isCredentialedAttachment = (
    a: Optional<AnyLocalAttachment>,
): a is CredentialedAttachment => a?.status === LocalAttachmentStatus.Credentialed;

export const isCompletableAttachment = (
    a: Optional<AnyLocalAttachment>,
): a is CompletableAttachment => a?.status === LocalAttachmentStatus.Completable;

export const isUploadingAttachment = (a: Optional<AnyLocalAttachment>): a is UploadingAttachment =>
    a?.status === LocalAttachmentStatus.Uploading;

export const isUploadedAttachment = (a: Optional<AnyLocalAttachment>): a is UploadedAttachment =>
    a?.status === LocalAttachmentStatus.Uploaded;

export const attachmentHasBackoffState = <T extends AnyLocalAttachment>(
    a: Optional<T>,
): a is WithBackoff & T => !!a && "backoffState" in a;

export const createProposedAttachment = (
    args: Omit<ProposedAttachment, "status" | "credentials" | "backoffState">,
): ProposedAttachment => ({
    ...args,
    status: LocalAttachmentStatus.Proposed,
    backoffState: defaultBackoffState(),
});

export const proposedToCredentialedAttachment = (
    a: ProposedAttachment,
    credentials: BlobCredentials,
): CredentialedAttachment => ({
    ...a,
    status: LocalAttachmentStatus.Credentialed,
    credentials,
    backoffState: defaultBackoffState(),
});

export const credentialedToUploadingAttachment = (
    a: CredentialedAttachment,
): UploadingAttachment => {
    if (a.credentials === undefined) {
        throw new Error(`Cannot upload attachment without credentials`);
    }

    return {
        ...a,
        status: LocalAttachmentStatus.Uploading,
        percentCompleted: 0,
        credentials: a.credentials,
        backoffState: defaultBackoffState(),
    };
};

export const uploadingAttachmentProgress = (
    a: UploadingAttachment,
    percentCompleted: number,
): UploadingAttachment => {
    return {
        ...a,
        percentCompleted: percentCompleted,
    };
};

// Not used - uploads currently serialised per attachment.
export const uploadingToProposedAttachment = (
    { backoffState, percentCompleted: _, ...rest }: UploadingAttachment,
): ProposedAttachment => {
    return {
        ...rest,
        status: LocalAttachmentStatus.Proposed,
        backoffState: defaultBackoffUpdater(backoffState.attempts),
    };
};

export const uploadingToCompletableAttachment = (
    { backoffState: _, percentCompleted: __, credentials, ...a }: UploadingAttachment,
): CompletableAttachment => {
    return {
        ...a,
        blobId: credentials.blobId,
        status: LocalAttachmentStatus.Completable,
        backoffState: defaultBackoffState(),
    };
};

export const completableToUploadedAttachment = (
    { backoffState: _, ...a }: CompletableAttachment,
): UploadedAttachment => ({
    ...a,
    status: LocalAttachmentStatus.Uploaded,
});

// Not used - attachments immediately purged on failures.
export const toFailedAttachment = (
    a: UploadingAttachment | ProposedAttachment,
    reason?: string,
): FailedAttachment => {
    if (isProposedAttachment(a)) {
        const { backoffState: _, ...rest } = a;
        return {
            ...rest,
            status: LocalAttachmentStatus.Failed,
            reason,
        };
    }

    const { backoffState: _, percentCompleted: __, ...rest } = a;
    return {
        ...rest,
        status: LocalAttachmentStatus.Failed,
        reason,
    };
};
