import { BlockBlobClient } from "@azure/storage-blob";
import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";

import {
    AnyLocalAttachment,
    createProposedAttachment,
    credentialedToUploadingAttachment,
    isCompletableAttachment,
    isCredentialedAttachment,
    isProposedAttachment,
    LocalAttachmentStatus,
    uploadingAttachmentProgress,
    uploadingToCompletableAttachment,
} from "@/domain/attachments";
import { credentialsValidWithin } from "@/domain/blobs";
import * as d from "@/domain/domain";
import {
    completeAttachmentThunk,
    deleteAttachmentFromDraftThunk,
    getAttachmentCredentialsThunk,
    selectCompletableAttachmentIds,
    selectCredentialedAttachmentIds,
    selectLocalAttachment,
    selectProposedAttachments,
    updateLocalAttachment,
    updateLocalAttachmentBackoff,
} from "@/features/chats";
import createSharedWebLock from "@/hooks/createSharedWebLock";
import { useConnectedEffect } from "@/hooks/useConnectedEffect";
import useQueueProcessor from "@/hooks/useQueueProcessor";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { fileNamePreparer } from "@/misc/attachments";
import log from "@/misc/log";
import { useAppDispatch, useAppSelector } from "@/store/redux";
import { usingOverseer } from "@/workers/setup";

const [
    useInspectSingleAttachmentManagerLock,
    useTakeSingleAttachmentManagerLock,
] = createSharedWebLock("beyond-single-attachment");

// The number of times one of the processors can encounter an error on an enqueued
// attachment before giving up.
const maxRetries = 5;

export const fileStashMap = new Map<d.LocalAttachmentId, File>();
export const FileStashContext = createContext(fileStashMap);

/** Fetch upload credentials for ProposedAttachments.
 *
 * Only fetch for attachments that the local tab has chosen.
 */
function ProposedProcessor(): React.JSX.Element {
    const dispatch = useAppDispatch();

    const fileStash = useContext(FileStashContext);

    const needCredentials = useAppSelector(selectProposedAttachments);

    // Combine backoff state per-attachment and across all attachments.
    // That means: if an attempt fails, it gets a certain backoff period,
    // and the processor also applies its own backoff before attempting the
    // next attachment (whether or not it is the one that failed).
    // n.b. `useQueueProcessor` with the `filter` argument is brittle w/r/t
    // to changes to the map without a corresponding change to the ids, so
    // the order of updates matters here.
    const {
        target: targetId,
        succeeded,
        failed,
    } = useQueueProcessor(needCredentials, fileStash);

    // We need to use a ref here because the attachment with id `targetId`
    // might change mid-request, but we don't want our request to re-fire in
    // such cases. We also need all our updates to the attachment to not rely on
    // the draft target as it exists at the start of the request.
    const targetRef = useRef<AnyLocalAttachment | undefined>();

    // Always keep this up-to-date.
    const targetAttachment = useSelectorArgs(selectLocalAttachment, targetId);
    targetRef.current = targetAttachment;

    const cleanupAttachment = useCallback((localId: d.LocalAttachmentId) => {
        if (!localId) return;

        dispatch(deleteAttachmentFromDraftThunk({ localId }))
            .unwrap()
            .catch(e => {
                log.error(`Failed to remove attachment ${localId} from draft`, e);
            })
            .finally(() => {
                fileStash.delete(localId);
                failed();
            });
    }, [dispatch, fileStash, failed]);

    useConnectedEffect(() => {
        const target = targetRef.current;

        if (!targetId || !target) return;

        if (!isProposedAttachment(target)) {
            log.error(
                `Attachment ${targetId} in inconsistent state, Proposed vs ${
                    LocalAttachmentStatus[target.status]
                }`,
            );
            // This will persist even after restarts; safest just to purge it.
            return cleanupAttachment(targetId);
        }

        if (target.backoffState.attempts >= maxRetries) {
            log.warn(`Attachment ${targetId} credential fetch reached max number of retries`);
            // TODO: rather than just remove the attachment, we should display
            // the error, and let the user remove it from the draft
            return cleanupAttachment(targetId);
        }

        const file = fileStash.get(targetId);
        if (!file) {
            log.warn(`Attachment ${targetId} missing file in stash`);
            // Inconsistent state; we need to purge the attachment.
            return cleanupAttachment(targetId);
        }

        dispatch(getAttachmentCredentialsThunk(target))
            .unwrap()
            .then(() => {
                succeeded();
            }, (e: any) => {
                //  Backoff state also updated by thunk rejection slice reducer
                log.info("Problem when getting attachment upload credentials, retrying:", e);
                failed(false);
            });
    }, [
        targetId,
        dispatch,
        fileStash,
        succeeded,
        failed,
        cleanupAttachment,
    ]);

    return <></>;
}

export type BlockBlobClientFactory = (url: string) => BlockBlobClient;

/** Upload attachments that have credentials to the blob store.
 *
 * Only upload attachments that the local tab has chosen.
 */
function CredentialedProcessor(
    { clientFactory }: { clientFactory: BlockBlobClientFactory; },
): React.JSX.Element {
    const dispatch = useAppDispatch();

    const fileStash = useContext(FileStashContext);

    const uploadableAttachments = useAppSelector(selectCredentialedAttachmentIds);

    // n.b. `useQueueProcessor` with the `filter` argument is brittle w/r/t
    // to changes to the map without a corresponding change to the ids, so
    // the order of updates matters here.
    const {
        target: targetId,
        succeeded,
        failed,
    } = useQueueProcessor(uploadableAttachments, fileStash);

    // We need to use a ref here because the attachment with id `targetId`
    // might change mid-request, but we don't want our request to re-fire in
    // such cases. We also need all our updates to the attachment to not rely on
    // the draft target as it exists at the start of the request.
    const targetRef = useRef<AnyLocalAttachment | undefined>();

    // Always keep this up-to-date.
    const targetAttachment = useSelectorArgs(selectLocalAttachment, targetId);
    targetRef.current = targetAttachment;

    const cleanupAttachment = useCallback((localId: d.LocalAttachmentId) => {
        if (!localId) return;

        dispatch(deleteAttachmentFromDraftThunk({ localId }))
            .unwrap()
            .catch()
            .finally(() => {
                fileStash.delete(localId);
                failed();
            });
    }, [dispatch, fileStash, failed]);

    const [currentUpload, setCurrentUpload] = useState<{
        targetId: d.LocalAttachmentId;
        abortController: AbortController;
    }>();

    // If the attachment is removed from the store, then we should abort the upload. This
    // happens when the user clicks the "clear attachments" button.
    useEffect(() => {
        if (!currentUpload) return;
        if (currentUpload?.targetId == targetId && !targetAttachment) {
            log.info("Cancelling upload for attachment", targetId);
            currentUpload?.abortController.abort("cancelled");
        }
    }, [targetAttachment, currentUpload, targetId]);

    useConnectedEffect(() => {
        const target = targetRef.current;

        if (!targetId || !target) return;

        if (!isCredentialedAttachment(target)) {
            log.error(
                `Attachment ${targetId} in inconsistent state, wanted Credentialed, got ${
                    LocalAttachmentStatus[target.status]
                }`,
            );
            // This will persist even after restarts; safest just to purge it.
            return cleanupAttachment(targetId);
        }

        if (target.backoffState.attempts >= maxRetries) {
            log.warn(`Attachment ${targetId} data upload reached max number of retries`);
            // TODO: rather than just remove the attachment, we should display
            // the error, and let the user remove it from the draft
            return cleanupAttachment(targetId);
        }

        const targetAsUploading = credentialedToUploadingAttachment(target);

        const { credentials } = targetAsUploading;
        if (!credentialsValidWithin(credentials, 30)) {
            log.info(`Credentials for attachment ${targetId} expired`);
            const { credentials: _, ...withoutCreds } = target;
            const downgradedAttachment = createProposedAttachment(withoutCreds);
            dispatch(updateLocalAttachment(downgradedAttachment));
            return succeeded();
        }

        const file = fileStash.get(target.localId);
        if (!file) {
            // If we reach here, we may have leaked something into the backend.
            // Let the backend clear it up.
            log.warn(
                `Attachment ${target.localId} about to be uploaded, but missing from file cache`,
            );
            return cleanupAttachment(targetId);
        }

        dispatch(updateLocalAttachment(targetAsUploading));

        const uploadFileName = fileNamePreparer(file.name);

        const abortController = new AbortController();
        setCurrentUpload({ targetId, abortController });

        dispatch(
            updateLocalAttachment(
                uploadingAttachmentProgress(targetAsUploading, 1),
            ),
        );

        // Pick a block size such we get reasonable onProgress callbacks. Ensure it's not
        // too small or large, and otherwise favour at least 20 updates.
        const uploadBlockSize = Math.ceil(
            Math.max(64 * 1024, Math.min(1 * 1024 * 1024, file.size / 20)),
        );

        clientFactory(credentials.url)
            .uploadData(file, {
                maxSingleShotSize: 64 * 1024,
                blockSize: uploadBlockSize,
                blobHTTPHeaders: {
                    blobContentType: file.type,
                    blobCacheControl: "max-age=31536000, immutable",
                    blobContentDisposition: `attachment;filename="${uploadFileName}"`,
                },
                onProgress: ev => {
                    const percent = Math.min(1, ev.loadedBytes / (file.size ? file.size : 1)) *
                        100.0;
                    log.info(
                        `Attachment upload progress: ${
                            percent.toFixed(0)
                        }%, ${ev.loadedBytes} of ${file.size}`,
                    );

                    if (percent > 0) {
                        dispatch(
                            updateLocalAttachment(
                                uploadingAttachmentProgress(targetAsUploading, percent),
                            ),
                        );
                    }
                },
                abortSignal: abortController.signal,
            })
            .then(
                _ => {
                    const targetAsCompletable = uploadingToCompletableAttachment(targetAsUploading);
                    dispatch(updateLocalAttachment(targetAsCompletable));

                    fileStash.delete(targetId);

                    succeeded();
                },
                (e: any) => {
                    if (e.name === "AbortError") {
                        failed();
                        return;
                    }

                    log.info(`Attachment ${targetId} failed to upload`, e);
                    // Serialise uploads - don't try to upload another attachment
                    dispatch(updateLocalAttachmentBackoff(targetId));
                    failed(false);
                },
            ).finally(() => {
                setCurrentUpload(undefined);
            });
    }, [
        targetId,
        dispatch,
        clientFactory,
        cleanupAttachment,
        fileStash,
        succeeded,
        failed,
    ]);

    return <></>;
}

/** "Complete" the attachment by telling the backend it has finished uploading.
 *
 * In a setup with an overseer, this will run completions for attachments
 * across all open tabs.
/
 */
function CompletableProcessor(): React.JSX.Element {
    const dispatch = useAppDispatch();

    const completeableIds = useAppSelector(selectCompletableAttachmentIds);

    const needLock = usingOverseer();
    const haveLock = useInspectSingleAttachmentManagerLock();

    // Combine backoff state per-attachment and across all attachments.
    // That means: if an attempt fails, it gets a certain backoff period,
    // and the processor also applies its own backoff before attempting the
    // next attachment (whether or not it is the one that failed).
    const {
        target: localId,
        succeeded,
        failed,
    } = useQueueProcessor(completeableIds);

    // We need to use a ref here because the attachment with id `targetId`
    // might change mid-request, but we don't want our request to re-fire in
    // such cases. We also need all our updates to the attachment to not rely on
    // the draft target as it exists at the start of the request.
    const targetRef = useRef<AnyLocalAttachment | undefined>();

    // Always keep this up-to-date.
    const targetAttachment = useSelectorArgs(selectLocalAttachment, localId);
    targetRef.current = targetAttachment;

    useConnectedEffect(() => {
        const target = targetRef.current;

        if (!localId || !target || (needLock && !haveLock)) return;

        if (!isCompletableAttachment(target)) {
            log.error(
                `Attachment ${localId} in inconsistent state: expected Completable, got ${
                    LocalAttachmentStatus[target.status]
                }`,
            );
            // Purge, let the backend deal with anything leaked.
            dispatch(deleteAttachmentFromDraftThunk({ localId }));
            return succeeded();
        }

        if (target.backoffState.attempts >= maxRetries) {
            log.warn(`Attachment ${localId} completer reached max number of retries`);
            // TODO: rather than just remove the attachment, we should display
            // the error, and let the user remove it from the draft
            dispatch(deleteAttachmentFromDraftThunk({ localId }));
            return failed(false);
        }

        dispatch(completeAttachmentThunk(target))
            .unwrap()
            .then(
                () => {
                    succeeded();
                },
                (e: any) => {
                    log.info("Completing attachment failed: retrying", e);
                    failed(false);
                },
            );
    }, [
        localId,
        dispatch,
        succeeded,
        failed,
        haveLock,
        needLock,
    ]);

    return <></>;
}

export default function AttachmentManager(
    props: { clientFactory: BlockBlobClientFactory; },
): React.JSX.Element {
    useTakeSingleAttachmentManagerLock(usingOverseer());

    return (
        <>
            <ProposedProcessor />
            <CredentialedProcessor clientFactory={props.clientFactory} />
            <CompletableProcessor />
        </>
    );
}
