import { BrowserStore, IdbStore, isBrowserStore, isIdbStore } from "@/persist/store";

export type IdbStoreUpdate<
    T extends IdbStore<K, V>,
    K extends string = string,
    V = any,
> = [
    store: T,
    id: K,
    value?: V,
];

export type IdbStoreUpdateWithClear<
    T extends IdbStore<K, V>,
    K extends string = string,
    V = any,
> =
    | IdbStoreUpdate<T, K, V>
    | [store: T, clear: typeof clearKey];

export type AnyIdbStoreUpdate<
    K extends string = string,
    V = any,
    T extends IdbStore<K, V> = IdbStore<K, V>,
> =
    | IdbStoreUpdate<T, K, V>
    | IdbStoreUpdateWithClear<T, K, V>;

export type BrowserStoreUpdate<T extends BrowserStore<V>, V = any> = [
    store: T,
    value?: V,
];

// We need a version of the type paramatrised primarily over V, not BrowserStore<V>
export type AnyBrowserStoreUpdate<
    V = any,
    T extends BrowserStore<V> = BrowserStore<V>,
> = BrowserStoreUpdate<T, V>;

// A persistence update of [store, clearKey] will clear the specified store.
export const clearKey = { "clear-store": true };

/** The data needed to know what and where to persist an item (or remove it!)
 */
export type PersistenceUpdate<K extends string = string, V = any> =
    | AnyIdbStoreUpdate<K, V>
    | AnyBrowserStoreUpdate<V>;

export const isIdbStoreUpdate = <
    K extends string,
    V,
    T extends PersistenceUpdate<K, V> = PersistenceUpdate<K, V>,
>(
    u: T,
): u is T & AnyIdbStoreUpdate<K, V> => isIdbStore(u[0]);

export const isBrowserStoreUpdate = <
    V,
    T extends PersistenceUpdate<string, V> = PersistenceUpdate<string, V>,
>(
    u: T,
): u is T & AnyBrowserStoreUpdate<V> => isBrowserStore(u[0]);

export const valueUpdateToStoreUpdate =
    <K extends string, V>(updates: PersistenceUpdate[], store: IdbStore<K, V>) =>
    (k: K, _: V | undefined, v: V | undefined) =>
        updates.push([
            store,
            k,
            v,
        ]);
