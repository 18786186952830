import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { listUsersByPerson } from "@/api/squads";
import type { BearerToken } from "@/api/transport";
import * as d from "@/domain/domain";
import { UserDefinition } from "@/domain/users";
import { createProxiedAsyncThunk } from "@/features/proxiedThunk";
import { unaryThunkHandler } from "@/features/thunk";
import { Optional } from "@/misc/types";
import { checkPersistor, localStorageStore } from "@/persist/shared";
import { getStorageMethods } from "@/persist/storage";
import type { HydrateArgs, RWTransaction } from "@/persist/types";
import { createAppSelector } from "@/store/redux";
import type { RootState } from "@/store/types";

export enum AuthStatus {
    NotStarted,
    // The page is loading.
    Initialising,
    // We have confirmed we do not have any valid credentials.
    Unauthenticated,
    // We have an access token.
    Authenticated,
    // We are refreshing our access token.
    Refreshing,
    // We are throwing away any authentication data we have, and
    // will purge any cached data we have.
    ClearingAuthentication,
    // After all auth state has been cleared on logout, today tabs need to refresh to
    // restart the auth process / get the right store setup.
    LoggedOutReloadRequired,
}

export const fetchCurrentPersonUserRefs = createProxiedAsyncThunk<UserDefinition[], void>(
    "squads/fetchCurrentPersonUserRefs",
    async (_, thunkAPI) => {
        const personId = selectCurrentPersonId(thunkAPI.getState());
        if (!personId) {
            return thunkAPI.rejectWithValue({ error: `no personId in OIDC state` });
        }

        return await unaryThunkHandler(
            thunkAPI,
            listUsersByPerson(personId),
            `fetchCurrentPersonUserRefs ${personId}`,
        );
    },
);

export interface OidcState {
    token: BearerToken;
    personId: d.PersonId;
}

export interface AuthState {
    oidc?: OidcState;
    authInviteCode: Optional<string>;
    userId: Optional<d.UserId>;
    orgId: Optional<d.OrgId>;
    status: AuthStatus;
    alternativeLoginUsers: Array<UserDefinition>;
}

const getInitialState = (props?: Partial<AuthState>): AuthState => ({
    oidc: props?.oidc,
    authInviteCode: undefined,
    userId: props?.userId,
    orgId: props?.orgId,
    status: props?.status ?? AuthStatus.NotStarted,
    alternativeLoginUsers: [],
});

// * preferredUserId *
// The value held in AuthState.userId is the ID of the user who is currently
// logged in to this page. It is also stored in local storage, and used by the
// page on startup to determine which user to seed the websocket connection
// with, and which IndexedDB to load.
// Because of this, upon changing the value of AuthState.userId we must also
// reload the page (or redirect to e.g. the login page) to ensure that the
// websocket connection is coherent, and the correct IndexedDB is loaded.

const authSlice = createSlice({
    name: "auth",
    initialState: getInitialState(),
    selectors: {
        oidc: state => state.oidc,
        token: state => state.oidc?.token,
        authInviteCode: state => state.authInviteCode,
        orgId: state => state.orgId,
        status: state => state.status,
        preferredUserId: state => state.userId,
        alternativeLoginUsers: state => state.alternativeLoginUsers,
    },
    reducers: {
        updateOidc: (state, { payload: oidc }: PayloadAction<OidcState>) => {
            if (state.oidc && state.oidc.personId !== oidc.personId) {
                state.userId = undefined;
                state.orgId = undefined;
            }

            state.oidc = oidc;
        },
        updateAuthInviteCode: (state, { payload: code }: PayloadAction<Optional<string>>) => {
            state.authInviteCode = code;
        },
        clearOidc: state => {
            state.oidc = undefined;
        },
        updateCurrentUserId: (state, { payload: userId }: PayloadAction<Optional<d.UserId>>) => {
            // Useful for tests, but avoid for real code.
            state.userId = userId;
        },
        updateCurrentOrgId: (state, { payload: orgId }: PayloadAction<Optional<d.OrgId>>) => {
            // Useful for tests, but avoid for real code.
            state.orgId = orgId;
        },
        updateStatus: (state, { payload: status }: PayloadAction<AuthStatus>) => {
            state.status = status;

            if (status === AuthStatus.ClearingAuthentication) {
                state.oidc = undefined;
                state.authInviteCode = undefined;
                state.userId = undefined;
                state.orgId = undefined;
            }
        },
        reset: state => {
            // Keep the status as it was as this is used in the signout logic
            return getInitialState({
                status: state.status,
            });
        },

        // These actions start the auth worker in the listener middleware
        startAuthForUser: (
            state,
            { payload: ids }: PayloadAction<{ userId?: d.UserId; orgId?: d.OrgId; }>,
        ) => {
            if (ids.userId !== state.userId || ids.orgId !== state.orgId) {
                state.userId = ids.userId;
                state.orgId = ids.orgId;
                state.status = AuthStatus.Initialising;
            }
        },
        updateCurrentPersonIdForTest: (
            state,
            { payload: personId }: PayloadAction<d.PersonId>,
        ) => {
            state.oidc = { token: "", personId };
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchCurrentPersonUserRefs.fulfilled, (state, { payload: users }) => {
            state.alternativeLoginUsers = users;
        });
    },
});

export { selectCurrentUserId } from "@/store/redux";

const authSelectors = authSlice.getSelectors((state: RootState) => state.auth);
export const {
    status: selectAuthStatus,
    token: selectBearerToken,
    authInviteCode: selectAuthInviteCode,
    orgId: selectCurrentOrgId,
    preferredUserId: selectPreferredUserId,
    alternativeLoginUsers: selectAlternativeLogins,
} = authSelectors;

export const {
    reset: resetStore,
    updateCurrentUserId,
    updateCurrentOrgId,
    updateOidc,
    updateAuthInviteCode,
    clearOidc,
    updateStatus: updateAuthStatus,
    startAuthForUser,
    updateCurrentPersonIdForTest,
} = authSlice.actions;

export const selectCurrentPersonId = createAppSelector(
    [authSelectors.oidc],
    oidcstate => {
        return oidcstate?.personId;
    },
);

export const resetAuth = () => updateAuthStatus(AuthStatus.ClearingAuthentication);
export const isClearingAuth = (state: RootState) =>
    authSelectors.status(state) === AuthStatus.ClearingAuthentication;

export const reducer = authSlice.reducer;

// Persistence.

const deprecatedPreferredUserId = localStorageStore<d.UserId>("auth/preferredUserId", 1, 22);

const preferredUserOrgId = localStorageStore<{ userId: d.UserId; orgId: d.OrgId; }>(
    "auth/preferredUserOrgId",
    23,
);
export const {
    get: getLocalPreferredUserOrgId,
    set: setLocalPreferredUserOrgId,
    clear: clearLocalPreferredUserOrgId,
} = getStorageMethods(preferredUserOrgId);

export const hydrate = async ({ storage }: HydrateArgs) => {
    const storedUserId = storage ? getLocalPreferredUserOrgId() : undefined;

    return getInitialState({
        status: AuthStatus.Initialising,
        userId: storedUserId?.userId,
    });
};

const migrate = async (_tx: RWTransaction, _oldVersion: number) => {
    if (
        deprecatedPreferredUserId.untilVersion &&
        _oldVersion <= deprecatedPreferredUserId.untilVersion
    ) {
        const methods = getStorageMethods(deprecatedPreferredUserId);
        const old = methods.get();
        if (old) {
            methods.clear();
        }
    }
};

export const persistor = {
    stores: {},
    hydrate,
    migrate,
};
checkPersistor<AuthState>(persistor);
