import classNames from "classnames";
import { Ref, useMemo } from "react";

import * as d from "@/domain/domain";
import {
    getCallId,
    getMessageCallStatus,
    getMsgTs,
    MessageCallContext,
    SquadAccessGrantedMessage,
} from "@/domain/messages";
import { selectCurrentUserId } from "@/features/auth";
import { selectSortedUserIdsBySquadIdPair, selectSquadById } from "@/features/squads";
import { selectUser } from "@/features/users";
import { useInViewInterest } from "@/hooks/interest/useInViewInterest";
import useMergedRefs from "@/hooks/useMergedRefs";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import useSortedUsers from "@/hooks/useSortedUsers";
import Avatar from "../gui/Avatar";
import TimeAgo from "../gui/TimeAgo";

export interface SquadAccessGrantedMessageViewProps {
    msg: SquadAccessGrantedMessage;
    messageContentRef?: Ref<HTMLDivElement>;
    currentCallId?: d.CallId;
}

export const SquadAccessGrantedMessageView = (
    props: SquadAccessGrantedMessageViewProps,
): React.JSX.Element => {
    const { msg, messageContentRef, currentCallId } = props;
    const { actorId, squadId } = msg;

    const inViewRef = useInViewInterest({
        userIds: actorId,
        squadIds: squadId,
        callIds: currentCallId,
    });
    const ref = useMergedRefs(messageContentRef, inViewRef);

    const ts = useMemo(() => getMsgTs(msg), [msg]);

    const callId = getCallId(msg);
    const callStatus = getMessageCallStatus(callId, currentCallId);

    const currentUserId = useSelectorArgs(selectCurrentUserId);
    const squad = useSelectorArgs(selectSquadById, squadId);
    const actor = useSelectorArgs(selectUser, actorId);
    const textContent = `was added by ${actorId === currentUserId ? `you` : actor?.name}`;

    if (!actor || !squad) {
        <div className="c-message c-message--unknown">
            {`Unknown user ${actorId} or squad ${squadId}`}
        </div>;
    }

    const internalProps: SquadAccessGrantedMessageViewInternalProps = {
        squadId,
        squadName: squad?.name,
        ts,
        content: textContent,
        callStatus,
        messageContentRef: ref,
    };
    return <SquadAccessGrantedMessageViewInternal {...internalProps} />;
};

interface SquadAccessGrantedMessageViewInternalProps {
    squadId?: d.SquadId;
    squadName?: string;
    ts?: Date;
    content: string;
    callStatus: MessageCallContext;
    messageContentRef?: Ref<HTMLDivElement>;
}

const SquadAccessGrantedMessageViewInternal = (
    props: SquadAccessGrantedMessageViewInternalProps,
): React.JSX.Element => {
    const { squadId, squadName, ts, content, messageContentRef, callStatus } = props;

    const sortedSquadUserIds = useSortedUsers(
        selectSortedUserIdsBySquadIdPair,
        squadId,
    );

    const isEnded = callStatus === MessageCallContext.EndedCall;
    const isLive = callStatus === MessageCallContext.LiveCall;
    const containerClassNames = classNames("c-message", {
        "c-message--live": isLive,
        "c-message--ended": isEnded,
    });

    return (
        <div className={containerClassNames}>
            <div className="c-message__content">
                {(squadName || ts) && (
                    <div className="c-message__meta c-message__meta--event">
                        {squadName &&
                            (
                                <span className="c-message__author">
                                    {squadName}
                                </span>
                            )}
                        {ts && (
                            <span className="c-message__timestamp">
                                <TimeAgo from={ts?.valueOf() || 0} live={true} precise={true} />
                            </span>
                        )}
                    </div>
                )}
                {sortedSquadUserIds && (
                    <div className="c-message__squad">
                        {sortedSquadUserIds.map(id => (
                            <Avatar
                                key={id}
                                id={id}
                                showPresence={false}
                                hidden={!squadName}
                                size="squad"
                            />
                        ))}
                    </div>
                )}
                <div ref={messageContentRef} className="c-message__action">
                    {content}
                </div>
            </div>
        </div>
    );
};
