import { Optional } from "@/misc/types";
import { clamp } from "@/misc/utils";
import { RefObject, useCallback, useEffect, useRef, useState, WheelEventHandler } from "react";

// TODO: (fun project!) make the scrolling feel more natural
//       when extending an existing scroll action

const easeOutSine = (x: number): number => Math.sin((x * Math.PI) / 2);

/**
 * Describes an ongoing smooth scroll action from an origin offset to a target
 */
interface ScrollAction {
    origin: number;
    target: number;
    remainingMs: number;
    durationMs: number;
}

/**
 * Allows an element to be smooth scrolled horizontally with the mouse wheel. This should
 * be used sparingly as it breaks the usual user expectation for how scrolling should work.
 *
 * Accepts a ref to the target element, and returns a 'wheel' event handler that should be
 * attached to the same element in the JSX
 */
export const useHorizontalScroll = (
    elementRef: RefObject<HTMLElement>,
    stepScale: number = 0.75,
    stepDurationMs: number = 350,
): WheelEventHandler<HTMLElement> => {
    const [scrolling, setScrolling] = useState(false);
    const action = useRef<Optional<ScrollAction>>(undefined);

    // Animate the scroll action (if any) using requestAnimationFrame
    useEffect(() => {
        if (!scrolling) return;

        let lastFrame = performance.now();
        let animationFrameId: number;

        const animate = () => {
            const now = performance.now();
            const elapsed = now - lastFrame;
            lastFrame = now;

            if (action.current === undefined || elementRef.current === null) {
                // If either the action or element ref are unexpectedly gone, just
                // clean up and break out of the animation loop
                action.current = undefined;
                setScrolling(false);
                return;
            }

            if (action.current.remainingMs <= 0) {
                // When the full duration has elapsed, scroll precisely and end the scroll action
                elementRef.current.scrollTo({
                    left: action.current.target,
                    behavior: "instant",
                });
                action.current = undefined;
                setScrolling(false);
                return;
            }

            // Animate the scroll action with an ease-out timing function. Having no ease-in
            // makes it look less janky when a new action starts halfway through the last
            const a = easeOutSine(1 - action.current.remainingMs / action.current.durationMs);
            const nextLeft = (1 - a) * action.current.origin + a * action.current.target;
            elementRef.current.scrollTo({
                left: nextLeft,
                behavior: "instant",
            });
            action.current.remainingMs -= elapsed;

            animationFrameId = requestAnimationFrame(animate);
        };

        animationFrameId = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(animationFrameId);
    }, [scrolling, elementRef]);

    // Create or update the scroll action on 'wheel' event
    return useCallback(
        e => {
            if (e.shiftKey || e.ctrlKey) return;
            if (elementRef.current === null) return;
            const elem = elementRef.current;

            e.preventDefault();

            const lastTarget = action.current?.target ?? elem.scrollLeft;
            const unclampedTarget = lastTarget + stepScale * e.deltaY;
            const target = clamp(0, elem.scrollWidth - elem.clientWidth)(unclampedTarget);

            action.current = {
                origin: elem.scrollLeft,
                target,
                remainingMs: stepDurationMs,
                durationMs: stepDurationMs,
            };
            setScrolling(true);
        },
        [elementRef, stepScale, stepDurationMs],
    );
};

export default useHorizontalScroll;
