import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";

import {
    completeAttachment,
    createAttachment,
    flattenOfficialMessageAndAttachments,
    getAttachmentBlobUrl,
    GetAttachmentBlobUrlArgs,
    getAttachmentKnowledges,
    getAttachmentKnowledgesArgs,
    OfficialMessageAndAttachments,
    OfficialMessagesBundle,
    sendMessageViaBond,
    streamMessages,
} from "@/api/chats";
import {
    AnyLocalAttachment,
    attachmentHasBackoffState,
    CompletableAttachment,
    completableToUploadedAttachment,
    FailedAttachment,
    isCompletableAttachment,
    isCredentialedAttachment,
    isProposedAttachment,
    isUploadedAttachment,
    OfficialAttachment,
    ProposedAttachment,
    proposedToCredentialedAttachment,
    UploadedAttachment,
} from "@/domain/attachments";
import { defaultBackoffUpdater } from "@/domain/backoff";
import { ExpiringSasUrl } from "@/domain/blobs";
import * as d from "@/domain/domain";
import {
    draftTargetsAreEqual,
    getChannelOfDraftTarget,
    isChannelDraftTarget,
} from "@/domain/draftTarget";
import {
    AnyOfficialMessage,
    AnyUnsentLocalMessage,
    isOfficialChatMessage,
    UnsentChatMessage,
} from "@/domain/messages";
import { createHAMT, hamtGet, hamtGetMany, hamtValues, ImmutableHAMT } from "@/ds/hamt";
import { resetStore, selectCurrentUserId } from "@/features/auth";
import { clearDraftThunk } from "@/features/bondCreation";
import { selectBondIdByChannelId } from "@/features/bonds";
import {
    addAttachmentsToDraft,
    catchupMessages,
    clearAttachmentsFromDraft,
    getMessageThunk,
    selectLocalSequenceNumber,
    stageMessageForChannel,
} from "@/features/channels";
import { selectVisibleChannelInterest } from "@/features/interest";
import { createLocalAsyncThunk } from "@/features/localThunk";
import { multiRpcStreamManager } from "@/features/middleware";
import { createProxiedAsyncThunk } from "@/features/proxiedThunk";
import { memoizeOptions } from "@/features/selectors";
import { createStreamingAsyncThunk } from "@/features/streamingThunk";
import { unaryThunkHandler } from "@/features/thunk";
import log from "@/misc/log";
import { ExpandType, Mutable, Optional } from "@/misc/types";
import { checkPersistor, fetchIdbStore, idbStore } from "@/persist/shared";
import type { HydrateArgs, IdbStoreUpdate, PersistenceUpdate } from "@/persist/types";
import { valueUpdateToStoreUpdate } from "@/persist/updates";
import { createAppSelector, thunkRequiresUserId } from "@/store/redux";
import type { RootState } from "@/store/types";

/** Sending messages
 *
 * 1. The user writes out a message in the `MessageComposer`. This forms a
 * `DraftChatMessage` in the store. The user then presses send.
 *
 * 2. A few extra pieces of metadata are added, creating an `UnsentChatMessage`.
 *
 * 3. `stageMessageForChannel` is dispatched, which "stages" the
 * `UnsentChatMessage`, adding it to:
 *   - `state.messages.unsent`, which has type
 *     `Record<UnsentMessageId, UnsentChatMessage>`. This is the canonical
 *     location for the message and metadata itself.
 *   - a per-channel `curatedList` - this list of ids determines the ordering of
 *     messages shown in the channel. In order to always show something sensible
 *     in the `ChatView`, we must keep referring to our local copy of the
 *     message until we receive the "official" message in a fetch from the
 *     server or from a subscription to the channel.
 *   - a per-channel `unsent` list, a list of ids of all the messages for a
 *     channel that we still need to send to the server.
 *
 * 4. The `MessageSender` component selects all the heads of the per-channel
 * `unsent` lists.
 *
 * 5. It chooses which, if any, is the next one to attempt sending.
 *   - If any messages have not been tried, they can be tried immediately.
 *   - If all messages are waiting to be retried, it delays until we reach the
 *     specified retry interval for the earliest retry opportunity. (If a new
 *     message is sent that changes the collection returned by 4., the wait is
 *     cancelled and we re-evaluate the situation immediately.)
 *   - If all outstanding messages have "permanent errors", it does nothing.
 *
 * This behaviour can obviously be improved, as it is unlikely that failures are
 * independent across channels, and we currently will retry any "temporary"
 * errors every second until we succeed. There's currently no way to initiate a
 * retry for "permanent" errors. We'll also need to use the websocket connection
 * state to determine if we should attempt to send at all.
 *
 * 6. Upon a successful send, `sendMessageThunk.fulfilled` is dispatched, and we
 *    update the `confirmedId` of the unsent message, but keep using our
 *    `localId` to reference the message.
 *
 * 7. If there is an error, it is classified as "temporary" or "permanent", and
 *    the unsent message is updated with that information.
 *
 * 8. Later, if the send was successful, we receive the full message from the
 *    server, and dispatch a `messageStreamed` action. This updates our
 *    references from `UnsentChatMessage.localId` => `ChatMessage.id`, and
 *    removes the copy of the message from `state.messages.unsent`.
 *
 * Still to think about:
 *
 * * edits/deletions whilst still unsent
 *   Probably a case of staging a new message and having logic in the channel
 *   map to reconcile these. The range of cases you have to deal with gets quite
 *   hairy here (e.g. original message send completed but without confirmation,
 *   deletion requested - you have to keep the deletion request around until you
 *   can be sure the original is delivered or not).
 *
 * * fetching older messages, and adding them to the `curatedList`
 *   Management of this is probably best based on pages of messages, with the
 *   `ChatView` stitching together pages of messages that surround the current
 *   messages being viewed. The complication here comes from message edits and
 *   deletions, as well as potentially differing views of the ordering of
 *   messages between client and server.
 *
 * * embeds/attachments
 *   We'll need to think about how uploading of files works around
 *   disconnections.
 */

const hamts = {
    messages: createHAMT(({ id }: AnyOfficialMessage) => id),
    attachments: createHAMT(({ id }: OfficialAttachment) => id),
    unsentMessages: createHAMT(({ localId }: AnyUnsentLocalMessage) => localId),
    localAttachments: createHAMT(({ localId }: AnyLocalAttachment) => localId),
};

export const selectUnsentMessageById = (
    unsents: ImmutableHAMT<d.UnsentMessageId, AnyUnsentLocalMessage>,
    id: Optional<d.UnsentMessageId>,
) => id ? hamtGet(unsents, id) : undefined;

export const streamChannelMessages = createStreamingAsyncThunk<
    d.ChannelId,
    Readonly<OfficialMessageAndAttachments>
>(
    "messages/stream",
    {
        rpc: ({ state, signal, arg: channelId }) => {
            const userId = selectCurrentUserId(state)!;
            const localSeqNo = selectLocalSequenceNumber(state, channelId);
            return streamMessages({
                userId,
                channelId,
                startSequenceNumber: (localSeqNo ?? 0) + 1,
            }, signal);
        },
        logId: ({ arg: channelId }) => `chats channel:${channelId}`,
        parser: ({ thunkAPI, arg: channelId, content: bundles }) => {
            const flat = flattenOfficialMessageAndAttachments(
                bundles as Mutable<typeof bundles[0]>[],
                channelId,
            );
            thunkAPI.dispatch(streamedMessages(flat));
        },
    },
);

export const visibleChannelMessageStreamingManager = multiRpcStreamManager(
    selectVisibleChannelInterest,
    streamChannelMessages,
    "streamMessages",
);

// Check for "messageId" on the returned object to
// know if the send attempt was successful.
export const sendMessageThunk = createLocalAsyncThunk(
    "messages/send",
    async (msg: UnsentChatMessage, thunkAPI) => {
        const state = thunkAPI.getState();

        const senderId = selectCurrentUserId(state)!;

        if (!isChannelDraftTarget(msg.draftTarget)) {
            log.error(`Unable to send message - draftTarget is for bond creation`);
            return thunkAPI.rejectWithValue({ error: `sendMessageViaBond requires a draftTarget` });
        }

        const channelId = getChannelOfDraftTarget(msg.draftTarget);
        const bondId = selectBondIdByChannelId(state, channelId);
        if (!bondId) {
            log.error(`Unable to send message in channel ${channelId} - no corresponding bond`);
            return thunkAPI.rejectWithValue({ error: "no bond for channel" });
        }

        log.info(`Sending message: ${msg.localId} on ${channelId}`);

        const officialAttachmentIds = selectOfficialAttachmentIdsForMessage(state, msg.localId);

        return await sendMessageViaBond({
            msg,
            bondId,
            channelId,
            senderId,
            officialAttachmentIds,
        });
    },
    thunkRequiresUserId,
);

export const getAttachmentCredentialsThunk = createProxiedAsyncThunk(
    "messages/attachments/credentials",
    async (attachment: ProposedAttachment, thunkAPI) => {
        const { localId } = attachment;

        const credentials = await unaryThunkHandler(
            thunkAPI,
            createAttachment(attachment),
            `createAttachment ${localId}`,
        );

        return {
            localId,
            credentials,
        };
    },
);

export const completeAttachmentThunk = createProxiedAsyncThunk(
    "messages/attachments/complete",
    async (attachment: CompletableAttachment, thunkAPI) => {
        const { localId, blobId } = attachment;

        const state = thunkAPI.getState();
        const completerId = selectCurrentUserId(state);

        if (!completerId) {
            return thunkAPI.rejectWithValue(`Cannot complete attachment without a user id`);
        }

        await unaryThunkHandler(
            thunkAPI,
            completeAttachment({ completerId, blobId }),
            `completeAttachment ${localId} ${blobId}`,
        );

        return localId;
    },
);

export const getAttachmentBlobUrlThunk = createProxiedAsyncThunk(
    "messages/attachments/getSasUrl",
    async (args: GetAttachmentBlobUrlArgs, thunkAPI) => {
        const expiringSasUrl = await unaryThunkHandler(
            thunkAPI,
            getAttachmentBlobUrl(args),
            `getAttachmentBlobUrl ${args.blobId}`,
        );

        return { expiringSasUrl, blobId: args.blobId };
    },
);

export const deleteAttachmentFromDraftThunk = createProxiedAsyncThunk(
    "messages/attachments/delete",
    ({ localId }: { localId: d.LocalAttachmentId; }, thunkAPI) => {
        const state = thunkAPI.getState();

        const attachment = selectLocalAttachment(state, localId);
        if (!attachment) {
            return thunkAPI.rejectWithValue({ error: `No local attachment with id ${localId}` });
        }

        const { draftTarget } = attachment;

        return { localId, draftTarget };
    },
);

export const getAttachmentKnowledge = createProxiedAsyncThunk(
    "messages/attachments/getKnowledge",
    async ({ blobId }: { blobId: d.BlobId; }, thunkAPI) => {
        const args: getAttachmentKnowledgesArgs = {
            blobIds: [blobId],
        };
        const knowledges = await unaryThunkHandler(
            thunkAPI,
            getAttachmentKnowledges(args),
            `getAttachmentKnowledges ${args.blobIds}`,
        );

        if (knowledges.length === 0) {
            log.error(`No knowledges found for`, blobId);
            return;
        }
        else if (knowledges.length) {
            log.warn(
                `Multiple knowledges (${knowledges.length}) found for`,
                blobId,
                ". Returning first knowledge.",
            );
        }
        const knowledge = knowledges[0];

        return { knowledge, blobId };
    },
);

type MessageUpdate =
    | IdbStoreUpdate<typeof stores.messages>
    | IdbStoreUpdate<typeof stores.unsent>
    | IdbStoreUpdate<typeof stores.attachments>
    | IdbStoreUpdate<typeof stores.localAttachments>;

export interface MessagesState {
    msgs: ImmutableHAMT<d.MessageId, AnyOfficialMessage>;
    attachments: ImmutableHAMT<d.BlobId, OfficialAttachment>;
    unsent: ImmutableHAMT<d.UnsentMessageId, AnyUnsentLocalMessage>;
    localAttachments: ImmutableHAMT<d.LocalAttachmentId, AnyLocalAttachment>;
    updates: MessageUpdate[];
}

type GetInitialStateArgs = ExpandType<
    & Partial<
        Omit<MessagesState, "msgs" | "unsent" | "updates" | "attachments" | "localAttachments">
    >
    & {
        msgs?: Record<d.MessageId, AnyOfficialMessage>;
        attachments?: Record<d.BlobId, OfficialAttachment>;
        unsent?: Record<d.UnsentMessageId, AnyUnsentLocalMessage>;
        localAttachments?: Record<d.LocalAttachmentId, AnyLocalAttachment>;
    }
>;
const getInitialState = (props?: GetInitialStateArgs): MessagesState => ({
    msgs: hamts.messages.initial(props?.msgs),
    unsent: hamts.unsentMessages.initial(props?.unsent),
    attachments: hamts.attachments.initial(props?.attachments),
    localAttachments: hamts.localAttachments.initial(props?.localAttachments),
    updates: [],
});

// The messages slice is intentionally quite dumb - store messages based
// on their id (either "real" id or "local" id), and allow looking up the same.
export const messagesSlice = createSlice({
    name: "messages",
    initialState: getInitialState(),
    selectors: {
        unsentMessages: state => state.unsent,

        attachments: state => state.attachments,
        selectAttachment: (state, id: Optional<d.BlobId>): Optional<OfficialAttachment> =>
            id && hamtGet(state.attachments, id),
        selectAttachmentsById: (state, ids: Array<d.BlobId>) => hamtGetMany(state.attachments, ids),

        localAttachments: state => state.localAttachments,
        selectLocalAttachment: (
            state,
            id: Optional<d.LocalAttachmentId>,
        ): Optional<AnyLocalAttachment> => id ? hamtGet(state.localAttachments, id) : undefined,
    },
    reducers: {
        streamedMany: (
            state,
            { payload: { msgs, attachments } }: PayloadAction<OfficialMessagesBundle>,
        ) => {
            const currentState = current(state);
            const updates: MessageUpdate[] = [];

            if (msgs.length > 0) {
                state.msgs = hamts.messages.setMany(
                    currentState.msgs,
                    msgs,
                    valueUpdateToStoreUpdate(updates, stores.messages),
                );
            }

            if (attachments && attachments.length > 0) {
                state.attachments = hamts.attachments.setMany(
                    currentState.attachments,
                    attachments,
                    valueUpdateToStoreUpdate(updates, stores.attachments),
                );
            }

            const haveLocalMessageWithId = (
                id: Optional<d.UnsentMessageId>,
            ): id is d.UnsentMessageId => {
                return !!(id && hamtGet(state.unsent, id));
            };

            const unsents = msgs
                .filter(isOfficialChatMessage)
                .map(msg => msg.content.id)
                .filter(haveLocalMessageWithId);

            if (unsents.length > 0) {
                state.unsent = hamts.unsentMessages.removeMany(
                    currentState.unsent,
                    unsents,
                    valueUpdateToStoreUpdate(updates, stores.unsent),
                );
            }

            if (updates.length > 0) {
                state.updates = updates;
            }
        },

        testInsertUnsentMessage: (
            state,
            { payload: [_, msg] }: PayloadAction<[d.ChannelId, AnyUnsentLocalMessage]>,
        ) => {
            state.unsent = hamts.unsentMessages.set(current(state).unsent, msg);
        },

        updateLocalAttachment: (
            state,
            { payload: attachment }: PayloadAction<AnyLocalAttachment>,
        ) => {
            const { localId } = attachment;

            const currentState = current(state).localAttachments;

            if (!hamtGet(currentState, localId)) return;

            state.updates = [];
            state.localAttachments = hamts.localAttachments.set(
                currentState,
                attachment,
                valueUpdateToStoreUpdate(state.updates, stores.localAttachments),
            );
        },

        updateLocalAttachmentBackoff: (
            state,
            { payload: localId }: PayloadAction<d.LocalAttachmentId>,
        ) => {
            const currentState = current(state).localAttachments;

            const attachment = hamtGet(currentState, localId);
            if (!attachmentHasBackoffState(attachment)) return;

            const { backoffState: { attempts } } = attachment;
            const backedOff = { ...attachment, backoffState: defaultBackoffUpdater(attempts) };

            state.updates = [];
            state.localAttachments = hamts.localAttachments.set(
                currentState,
                backedOff,
                valueUpdateToStoreUpdate(state.updates, stores.localAttachments),
            );
        },

        upsertAttachmentBlobUrlsForTest: (
            state,
            { payload: updates }: PayloadAction<
                { expiringSasUrl: ExpiringSasUrl; blobId: d.BlobId; }[]
            >,
        ) => {
            const currentState = current(state).attachments;
            const newAttachments = updates.flatMap(({ blobId, expiringSasUrl }) => {
                const attachment = hamtGet(currentState, blobId);
                if (!attachment || !attachment.credentials) return [];

                return [{
                    ...attachment,
                    credentials: {
                        ...attachment.credentials,
                        ...expiringSasUrl,
                    },
                }];
            });

            state.attachments = hamts.attachments.setMany(currentState, newAttachments);
        },
    },

    extraReducers: builder => {
        builder.addCase(
            stageMessageForChannel.fulfilled,
            (state, { payload: [_channelId, unsentMsg] }) => {
                state.updates = [];
                state.unsent = hamts.unsentMessages.set(
                    current(state).unsent,
                    unsentMsg,
                    valueUpdateToStoreUpdate(state.updates, stores.unsent),
                );
            },
        );

        builder.addCase(sendMessageThunk.fulfilled, (state, { payload: [_, msg] }) => {
            state.updates = [];
            state.unsent = hamts.unsentMessages.set(
                current(state).unsent,
                msg,
                valueUpdateToStoreUpdate(state.updates, stores.unsent),
            );
        });

        const updateMessages = (
            state: MessagesState,
            { msgs, attachments }: OfficialMessagesBundle,
        ) => {
            const currentState = current(state);
            const updates: MessageUpdate[] = [];

            if (msgs.length > 0) {
                state.msgs = hamts.messages.setMany(
                    currentState.msgs,
                    msgs,
                    valueUpdateToStoreUpdate(updates, stores.messages),
                );
            }

            if (attachments && attachments.length > 0) {
                state.attachments = hamts.attachments.setMany(
                    currentState.attachments,
                    attachments,
                    valueUpdateToStoreUpdate(updates, stores.attachments),
                );
            }

            if (updates.length > 0) {
                state.updates = updates;
            }
        };
        builder.addCase(
            catchupMessages.fulfilled,
            (state, { payload }) => updateMessages(state, payload),
        );

        builder.addCase(
            getMessageThunk.fulfilled,
            (state, { payload }) => updateMessages(state, payload),
        );

        builder.addCase(
            getAttachmentBlobUrlThunk.fulfilled,
            (state, { payload: { blobId, expiringSasUrl } }) => {
                const currentState = current(state).attachments;

                const attachment = hamtGet(currentState, blobId);
                if (!attachment || !attachment.credentials) return;

                const newAttachment = {
                    ...attachment,
                    credentials: {
                        ...attachment.credentials,
                        ...expiringSasUrl,
                    },
                };

                state.updates = [];

                hamts.attachments.set(
                    currentState,
                    newAttachment,
                    valueUpdateToStoreUpdate(state.updates, stores.attachments),
                );
            },
        );

        builder.addCase(
            getAttachmentCredentialsThunk.fulfilled,
            (state, { payload: { localId, credentials } }) => {
                // draftTarget might change mid-request, so always update the
                // latest version.
                const currentState = current(state).localAttachments;

                const attachment = hamtGet(currentState, localId);
                if (!isProposedAttachment(attachment)) return;

                const newAttachment = proposedToCredentialedAttachment(attachment, credentials);

                state.updates = [];

                state.localAttachments = hamts.localAttachments.set(
                    currentState,
                    newAttachment,
                    valueUpdateToStoreUpdate(state.updates, stores.localAttachments),
                );
            },
        );

        builder.addCase(
            getAttachmentCredentialsThunk.rejected,
            (state, { meta: { arg: { localId } } }) => {
                // draftTarget might change mid-request, so always update the
                // latest version.
                const currentState = current(state).localAttachments;

                const attachment = hamtGet(currentState, localId);
                if (!isProposedAttachment(attachment)) return;

                state.updates = [];

                const { backoffState: { attempts } } = attachment;
                const backedOff = {
                    ...attachment,
                    backoffState: defaultBackoffUpdater(attempts),
                };
                state.localAttachments = hamts.localAttachments.set(
                    currentState,
                    backedOff,
                    valueUpdateToStoreUpdate(state.updates, stores.localAttachments),
                );
            },
        );

        builder.addCase(
            completeAttachmentThunk.fulfilled,
            (state, { payload: localId }) => {
                const currentState = current(state).localAttachments;

                const attachment = hamtGet(currentState, localId);
                if (!isCompletableAttachment(attachment)) return;

                state.updates = [];

                const completedAttachment = completableToUploadedAttachment(attachment);
                state.localAttachments = hamts.localAttachments.set(
                    currentState,
                    completedAttachment,
                    valueUpdateToStoreUpdate(state.updates, stores.localAttachments),
                );
            },
        );

        builder.addCase(
            addAttachmentsToDraft,
            (state, { payload: attachments }) => {
                if (attachments.length === 0) return;

                state.updates = [];
                state.localAttachments = hamts.localAttachments.setMany(
                    current(state).localAttachments,
                    attachments,
                    valueUpdateToStoreUpdate(state.updates, stores.localAttachments),
                );
            },
        );

        builder.addCase(
            deleteAttachmentFromDraftThunk.fulfilled,
            (state, { payload: { localId } }) => {
                state.updates = [];
                state.localAttachments = hamts.localAttachments.remove(
                    current(state).localAttachments,
                    localId,
                    valueUpdateToStoreUpdate(state.updates, stores.localAttachments),
                );
            },
        );

        builder.addCase(
            clearAttachmentsFromDraft,
            (state, { payload: draftTarget }) => {
                // TODO: consider keeping an index in the other direction?
                // Probably only very few local attachments at any point in time.
                const attachmentsIds = Array
                    .from(hamtValues(state.localAttachments))
                    .filter(({ draftTarget: adt }) => draftTargetsAreEqual(draftTarget, adt))
                    .map(({ localId }) => localId);

                if (attachmentsIds.length === 0) return;

                state.updates = [];
                state.localAttachments = hamts.localAttachments.removeMany(
                    current(state).localAttachments,
                    attachmentsIds,
                    valueUpdateToStoreUpdate(state.updates, stores.localAttachments),
                );
            },
        );

        builder.addCase(
            clearDraftThunk.fulfilled,
            (state, { payload: { attachments } }) => {
                if (!attachments || attachments.length === 0) return;

                state.updates = [];

                state.localAttachments = hamts.localAttachments.removeMany(
                    current(state).localAttachments,
                    attachments.map(({ localId }) => localId),
                    valueUpdateToStoreUpdate(state.updates, stores.localAttachments),
                );
            },
        );

        builder.addCase(resetStore, _state => {
            return getInitialState();
        });
    },
});

const sliceSelectors = messagesSlice.getSelectors<RootState>(state => state.messages);

export const {
    unsentMessages: selectUnsentMessages,
    selectLocalAttachment,
} = sliceSelectors;

const {
    localAttachments: selectLocalAttachments,
} = sliceSelectors;

type SortableAttachment = Exclude<AnyLocalAttachment, UploadedAttachment | FailedAttachment>;
const sortAttachments = (a: SortableAttachment, b: SortableAttachment): number =>
    (a.backoffState.nextAttempt ?? a.initiatedAt) - (b.backoffState.nextAttempt ?? b.initiatedAt);

export const selectLocalAttachmentsById = createAppSelector(
    [selectLocalAttachments, (_, ids?: d.LocalAttachmentId[]) => ids],
    (attachments, ids) => ids ? hamtGetMany(attachments, ids).filter(a => !!a) : [],
    memoizeOptions.weakMapShallow,
);

export const selectProposedAttachments = createAppSelector(
    [selectLocalAttachments],
    attachments =>
        Array.from(hamtValues(attachments))
            .filter(isProposedAttachment)
            .sort(sortAttachments)
            .map(a => a.localId),
    memoizeOptions.weakMapShallow,
);

export const selectAttachmentMimeTypeById = createAppSelector(
    [sliceSelectors.selectAttachmentsById],
    attachments => attachments.map(a => a?.metadata.mimeType),
    memoizeOptions.weakMapShallow,
);

export const selectCredentialedAttachmentIds = createAppSelector(
    [selectLocalAttachments],
    attachments =>
        Array.from(hamtValues(attachments))
            .filter(isCredentialedAttachment)
            .sort(sortAttachments)
            .map(a => a.localId),
    memoizeOptions.weakMapShallow,
);

export const selectCompletableAttachmentIds = createAppSelector(
    [selectLocalAttachments],
    attachments =>
        Array.from(hamtValues(attachments))
            .filter(isCompletableAttachment)
            .map(a => a.localId),
    memoizeOptions.weakMapShallow,
);

const selectOfficialMsg = (
    state: RootState,
    id: Optional<d.MessageId>,
): Optional<AnyOfficialMessage> => id && hamtGet(state.messages.msgs, id);

const selectUnsentMsg = (
    state: RootState,
    id: Optional<d.UnsentMessageId>,
): Optional<AnyUnsentLocalMessage> => id ? hamtGet(state.messages.unsent, id) : undefined;

export const selectMessage = (state: RootState, id: Optional<d.AnyMessageId>) =>
    selectOfficialMsg(state, id as d.MessageId) ??
        selectUnsentMsg(state, id as d.UnsentMessageId);

export const selectOfficialAttachmentIdsForMessage = createAppSelector(
    [state => state, selectUnsentMsg],
    (state, msg) =>
        msg?.attachmentIds
            .map(a => selectLocalAttachment(state, a))
            .filter(isUploadedAttachment)
            .map(a => a.blobId) ?? [],
    memoizeOptions.weakMapShallow,
);

export const selectAttachment = (state: RootState, id: Optional<d.AnyAttachmentId>) =>
    sliceSelectors.selectAttachment(state, id as d.BlobId) ??
        selectLocalAttachment(state, id as d.LocalAttachmentId);

const selectOfficialAttachment = (state: RootState, id: Optional<d.AnyAttachmentId>) =>
    sliceSelectors.selectAttachment(state, id as d.BlobId);
export const selectOfficialAttachments: (
    state: RootState,
    _: d.AnyAttachmentId[],
) => Optional<OfficialAttachment>[] = createAppSelector(
    [
        (state, _) => (id: d.AnyAttachmentId) => selectOfficialAttachment(state, id),
        (_sel, ids?: d.AnyAttachmentId[]) => ids,
    ],
    (attachmentGetter, ids) => {
        if (!ids || ids.length === 0) return [];

        const x = ids.map(id => attachmentGetter(id));
        return x;
    },
    memoizeOptions.weakMapShallow,
);

export const {
    streamedMany: streamedMessages,
    upsertAttachmentBlobUrlsForTest,
    testInsertUnsentMessage,
    updateLocalAttachment,
    updateLocalAttachmentBackoff,
} = messagesSlice.actions;

export const messageStreamed = (msg: AnyOfficialMessage, attachments: OfficialAttachment[] = []) =>
    streamedMessages({ channelId: msg.channelId, msgs: [msg], attachments });

export const reducer = messagesSlice.reducer;

// Persistence.

const stores = {
    messages: idbStore<d.MessageId, AnyOfficialMessage>("messages-2", 14),

    unsent: idbStore<d.UnsentMessageId, AnyUnsentLocalMessage>("unsent-messages-2", 14),

    attachments: idbStore<d.BlobId, OfficialAttachment>("attachments", 14),
    localAttachments: idbStore<d.LocalAttachmentId, AnyLocalAttachment>("local-attachments", 14),
};

const persist = (
    previousState: MessagesState,
    currentState: MessagesState,
): PersistenceUpdate[] => {
    if (previousState.updates == currentState.updates) {
        return [];
    }

    return currentState.updates;
};

const hydrate = async ({ idb: conn }: HydrateArgs) => {
    if (!conn) return getInitialState();

    const msgs = await fetchIdbStore(conn, stores.messages);
    const unsent = await fetchIdbStore(conn, stores.unsent);
    const attachments = await fetchIdbStore(conn, stores.attachments);
    const localAttachments = await fetchIdbStore(conn, stores.localAttachments);

    return getInitialState({ msgs, unsent, attachments, localAttachments });
};

export const persistor = {
    stores,
    hydrate,
    persist,
};
checkPersistor<MessagesState>(persistor);
