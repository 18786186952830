import log from "@/misc/log";
import { nanoid } from "@reduxjs/toolkit";

// navigator.locks.query() returns a "clientId". I'm not sure how to grab it
// via other APIs that will always be available. Hence just use that API
// directly: take a lock with a unique name, list locks, find client ID.
async function myClientId(): Promise<string> {
    const name = `temporary-lock-` + nanoid();
    let ret = "unknown-client-id";

    await navigator.locks.request(name, { mode: "exclusive" }, async () => {
        const locks = await navigator.locks.query();
        const mylock = (locks.held || []).find(lock => lock.name === name);
        if (mylock?.clientId) ret = mylock?.clientId;
    });

    return ret;
}

// Workaround a bug we've seen on Safari, both on MacOS and the iOS webview. What appears to
// happen is that on some cases of page refresh (`window.location.reload()`), web locks that
// are held at that point are NOT automatically cleaned up.
//
// That is bad! They are cleaned up if the tab is closed. And we haven't found any evidence
// of this happening on a shared worker (yet). And this works nicely on Chrome. But on Safari,
// we need a workaround to have vaguely sane locking semantics.
//
// So what we do to detect this is store something to detect a previous session in
// sessionStorage. sessionStorage gives us just what we want here: something we can persist
// information in just across refresh of this tab. The unique piece of a lock that is included
// for free in the locks API is a `clientId`. On Safari, this appears to be a UUID. So all we
// need to do is store the clientId we had in our last session, and if we notice any locks
// held by that early on startup, kill the locks.
//
// All that remains is a strategy to kill the lock. Luckily, the web lock API has a completely
// bizarre feature of "stealing" a lock. By taking the lock with `{ steal: true }`, then
// immediately releasing it, we effectively remove the lock from the previous session. There is
// no harm in doing so in this case, given the lock is completely stale (in a normal case it is
// unlocked when the callback function returns/resolves, but there is no code running anymore
// from that defunct session in this case!)
//
// Versions tested against when this comment was originally written:
//  - MacOS 15.1.1, Safari 18.0.1 (20619.1.26.31.7)
//  - iOS 18.1.1
//
// It's not nice needing to await this in the critical path of startup, but it doesn't appear
// to take any noticeable time.
async function webLocksNotReleasedOnReloadWorkaround() {
    const sessionStorageName = `last-client-id`;
    const myId = await myClientId();

    const lastClientId = window.sessionStorage.getItem(sessionStorageName);

    if (lastClientId && lastClientId !== myId) {
        const locks = await navigator.locks.query();
        const awaited = await Promise.all(
            locks.held
                ?.filter(lock => lock.clientId === lastClientId)
                .map(async lock => {
                    log.info(`Found stale lock from previous session: killing it via steal`, lock);
                    if (lock.name) {
                        await navigator.locks.request(lock.name, { steal: true }, () => {
                            // Nothing: immediately surrender the lock
                        });
                    }
                }) || [],
        );
        if (awaited.length > 0) {
            log.info(`Killed ${awaited.length} stale locks from previous session`);
        }
    }

    window.sessionStorage.setItem(sessionStorageName, myId);
}

export async function safariBugWorkarounds() {
    // Safe to call on all browsers, so we're better off avoiding a heuristic to
    // detect whether we're running on Safari/WebKit, which is always going to be
    // fragile.
    // Ref: https://stackoverflow.com/questions/7944460/detect-safari-browser
    await webLocksNotReleasedOnReloadWorkaround();
}
