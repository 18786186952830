import { useOutletContext } from "react-router-dom";

import SensitiveText from "@/components/gui/SensitiveText";
import TimeAgoCatchup from "@/components/gui/TimeAgoCatchup";
import * as d from "@/domain/domain";
import { SummaryRequestStatus } from "@/domain/intel";
import { selectChannelIdByBondId } from "@/features/bonds";
import { selectBondSummaryRequestStatus, selectDeltaKnowledgeBond } from "@/features/bonds";
import { selectIsRead, updateStagedSequenceNumberIfGreater } from "@/features/channels";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { BondChildRouteContext } from "@/misc/bondChildRouteContext";
import log from "@/misc/log";
import { isMobileBrowser } from "@/misc/mobile";
import { Optional } from "@/misc/types";
import { useAppDispatch, useAppSelector } from "@/store/redux";
import { useCallback } from "react";
import { SidebarSummaryErrorMessage } from "./SquadSidebarSummaryView";

function MarkAsReadButton(
    { bondId, isDisabled }: { bondId: Optional<d.BondId>; isDisabled: boolean; },
): React.JSX.Element {
    const knowledge = useSelectorArgs(selectDeltaKnowledgeBond, bondId);
    const channelId = useAppSelector(selectChannelIdByBondId(bondId));
    const dispatch = useAppDispatch();

    const markAsRead = useCallback(() => {
        if (!channelId || knowledge?.lastSummarisedSeq === undefined) {
            log.error(`channel id or knowledge is undefined`);
            return;
        }
        dispatch(
            updateStagedSequenceNumberIfGreater({
                channelId: channelId,
                sequenceNumber: knowledge.lastSummarisedSeq,
            }),
        );
    }, [channelId, knowledge?.lastSummarisedSeq, dispatch]);

    return (
        <button
            className="c-btn-solid c-btn-solid--read"
            disabled={isDisabled}
            onClick={isDisabled ? () => {} : markAsRead}
        >
            <div className="c-btn-solid__icon c-btn-solid__icon--read"></div>
            Mark as read
        </button>
    );
}

function SummaryListContent(
    { summaryItems }: { summaryItems: string[]; },
): React.JSX.Element {
    if (summaryItems.length === 0) return <></>;
    return (
        <div className="c-summary__generated">
            <ul>
                {summaryItems.map((item, idx) => {
                    return (
                        <li key={idx}>
                            <SensitiveText>{item}</SensitiveText>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

export function BondSidebarSummaryView(): React.JSX.Element {
    const { bondId } = useOutletContext() as BondChildRouteContext;
    const channelId = useSelectorArgs(selectChannelIdByBondId(bondId));
    const knowledge = useSelectorArgs(selectDeltaKnowledgeBond, bondId);
    const isMobile = isMobileBrowser();
    const sidebarStatus = useSelectorArgs(selectBondSummaryRequestStatus, bondId);
    const isBondRead = useSelectorArgs(selectIsRead, channelId);
    const hasUndefinedValues = !bondId || knowledge === undefined;
    const isHideSidebarStatus = sidebarStatus !== SummaryRequestStatus.Done &&
        sidebarStatus !== SummaryRequestStatus.Error;

    if (sidebarStatus === SummaryRequestStatus.Error) {
        return <SidebarSummaryErrorMessage />;
    }

    if (isMobile || hasUndefinedValues || isHideSidebarStatus) {
        return <></>;
    }

    return (
        <div
            className={"c-sidebar c-summary c-summary--open"}
        >
            <div className="c-sidebar__content c-sidebar__content--summary">
                <div className="c-summary__content">
                    {knowledge.deltaSummaryBond.length !== 0 && (
                        <TimeAgoCatchup
                            describeLive={false}
                            from={knowledge.firstNewMessageTs}
                            liveSessionStartNum={knowledge.liveSessionStartedAt}
                        />
                    )}
                    <SummaryListContent summaryItems={knowledge.deltaSummaryBond} />
                    {knowledge.deltaSummaryBondLive.length !== 0 && (
                        <TimeAgoCatchup
                            describeLive={true}
                            from={knowledge.firstNewMessageTs}
                            liveSessionStartNum={knowledge.liveSessionStartedAt}
                        />
                    )}
                    <SummaryListContent summaryItems={knowledge.deltaSummaryBondLive} />
                </div>
                <div className="c-summary__footer">
                    {!isBondRead && <MarkAsReadButton bondId={bondId} isDisabled={false} />}
                </div>
            </div>
        </div>
    );
}
