function polyfillAbortSignalAny() {
    if (!("any" in AbortSignal)) {
        Object.defineProperty(AbortSignal, "any", {
            value: (signals: AbortSignal[]) => {
                const controller = new AbortController();

                for (const signal of signals) {
                    if (signal.aborted) {
                        controller.abort(signal.reason);
                        return controller.signal;
                    }
                }

                signals.forEach(signal => {
                    signal.addEventListener("abort", () => {
                        if (controller.signal.aborted) {
                            return;
                        }
                        controller.abort(signal.reason);
                    });
                });

                return controller.signal;
            },
        });
    }
}

export function polyfillMissingMethods() {
    polyfillAbortSignalAny();
}
