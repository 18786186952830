import * as d from "@/domain/domain";
import {
    selectSquadLatestActivityDelayed,
    selectUnreadBondsForSquad,
} from "@/features/channels.ts";
import {
    clearDeltaKnowledgeSquad,
    selectSquadKnowledgeMetadata,
    selectSquadSummaryRequestStatus,
} from "@/features/squads";
import { isMobileBrowser } from "@/misc/mobile";
import { useAppDispatch } from "@/store/redux";
import { useAppSelector } from "@/store/redux";

import { SummaryRequestStatus } from "@/domain/intel";
import { viewStackUtils, viewUtils } from "@/domain/views";
import { selectChannelIdByBondId, selectLiveCallIdByBondId } from "@/features/bonds";
import {
    clearDeltaKnowledgeBond,
    selectBondSidebarSummarySavedPublishedSeqNum,
    selectBondSummaryRequestStatus,
    selectDeltaKnowledgeBond,
} from "@/features/bonds";
import { selectCallById } from "@/features/calls";
import { selectMaxSequenceNumber } from "@/features/channels.ts";
import { selectCurrentViewStack } from "@/features/filterPanel";
import { fetchDeltaKnowledgeBondThunk, fetchDeltaKnowledgeSquadThunk } from "@/features/intel";
import useViewStackNavigate from "@/hooks/navigation/useViewStackNavigate";
import usePrevious from "@/hooks/usePrevious";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { Optional } from "@/misc/types";
import { useCallback, useEffect } from "react";

export function SummaryButtonSquad(
    { squadId }: { squadId: Optional<d.SquadId>; },
): React.JSX.Element {
    const summaryRequestStatus = useSelectorArgs(selectSquadSummaryRequestStatus, squadId);
    const knowledgeMetadata = useSelectorArgs(selectSquadKnowledgeMetadata, squadId);
    const squadLastActivityAt = useSelectorArgs(selectSquadLatestActivityDelayed, squadId);
    const unreadBonds = useSelectorArgs(selectUnreadBondsForSquad, squadId);
    const isMobile = isMobileBrowser();

    const knowledgeIsUpToDate = knowledgeMetadata !== undefined &&
        squadLastActivityAt !== undefined &&
        knowledgeMetadata.LastActivityAt >= squadLastActivityAt;
    const squadHasUnreadBonds = unreadBonds.length > 0;

    const dispatch = useAppDispatch();

    const viewStack = useAppSelector(selectCurrentViewStack);
    const { navigateReplace } = useViewStackNavigate();
    const prevSummaryRequestStatus = usePrevious(summaryRequestStatus);

    const toggleDisplaySummary = useCallback((visible?: boolean) => {
        if (!squadId) return;
        const newStack = viewStackUtils.updateTopOfStack(viewStack, frame => {
            const summary = viewUtils.isSquad(frame) ? !frame.summary : undefined;
            return viewUtils.squad(squadId, visible ?? summary);
        });
        navigateReplace(newStack, { replace: true });
    }, [
        viewStack,
        navigateReplace,
        squadId,
    ]);

    const fetchAndOrToggleSidebarSummary = useCallback(() => {
        if (!squadId) return;
        const isProcessingRequest = summaryRequestStatus === SummaryRequestStatus.ProcessingRequest;
        const fetchNewKnowledge = squadHasUnreadBonds && !knowledgeIsUpToDate;
        if (!isProcessingRequest && fetchNewKnowledge) {
            dispatch(
                fetchDeltaKnowledgeSquadThunk({
                    squadId,
                }),
            );
        }
        if (knowledgeIsUpToDate) {
            toggleDisplaySummary();
        }
    }, [
        dispatch,
        squadId,
        summaryRequestStatus,
        squadHasUnreadBonds,
        knowledgeIsUpToDate,
        toggleDisplaySummary,
    ]);

    useEffect(() => {
        if (
            prevSummaryRequestStatus === SummaryRequestStatus.ProcessingRequest &&
            summaryRequestStatus === SummaryRequestStatus.Done
        ) {
            toggleDisplaySummary(true);
        }
    }, [prevSummaryRequestStatus, summaryRequestStatus, toggleDisplaySummary]);

    const handleError = useCallback(() => {
        if (!squadId) return;
        dispatch(
            clearDeltaKnowledgeSquad(squadId),
        );
    }, [squadId, dispatch]);

    if (!squadId || isMobile) return <></>;
    switch (summaryRequestStatus) {
        case SummaryRequestStatus.ProcessingRequest:
            return (
                <SummaryButtonSpinner
                    onClick={fetchAndOrToggleSidebarSummary}
                />
            );
        case SummaryRequestStatus.Error:
            return (
                <SummaryButtonInternal
                    isDisabled={knowledgeIsUpToDate}
                    clickable={true}
                    onClick={handleError}
                />
            );
        case SummaryRequestStatus.Done:
            return (
                <SummaryButtonInternal
                    isDisabled={knowledgeIsUpToDate}
                    clickable={true}
                    onClick={fetchAndOrToggleSidebarSummary}
                />
            );
        default: // Status: hide sidebar
            return (
                <SummaryButtonInternal
                    isDisabled={knowledgeIsUpToDate}
                    clickable={squadHasUnreadBonds || knowledgeIsUpToDate}
                    onClick={fetchAndOrToggleSidebarSummary}
                />
            );
    }
}

function SummaryButtonSpinner({ onClick }: { onClick: () => void; }): React.JSX.Element {
    return (
        <button
            className="c-btn-generate-summary"
            title="Generating summary"
            onClick={onClick}
        >
        </button>
    );
}

function SummaryButtonInternal(
    { isDisabled, clickable, onClick }: {
        isDisabled: boolean;
        clickable: boolean;
        onClick: () => void;
    },
): React.JSX.Element {
    return (
        <button
            className={isDisabled && clickable ? `c-btn-summary-disabled-but-clickable`
                : `c-btn-summary`}
            title="View summary"
            onClick={onClick}
            disabled={!clickable}
        >
        </button>
    );
}

export function SummaryButtonBond({ bondId }: { bondId: d.BondId; }): React.JSX.Element {
    const channelId = useAppSelector(selectChannelIdByBondId(bondId));
    const summaryRequestStatus = useSelectorArgs(selectBondSummaryRequestStatus, bondId);
    const savedPublishedSeqNum = useSelectorArgs(
        selectBondSidebarSummarySavedPublishedSeqNum,
        bondId,
    );
    const maxSequenceNumber = useSelectorArgs(selectMaxSequenceNumber, channelId) ?? 0;
    const deltaKnowledge = useSelectorArgs(selectDeltaKnowledgeBond, bondId);
    const isMobile = isMobileBrowser();

    const bondHasKnowledge = deltaKnowledge !== undefined;
    const knowledgeIsUpToDate = bondHasKnowledge &&
        deltaKnowledge !== undefined &&
        savedPublishedSeqNum !== undefined &&
        deltaKnowledge.lastReadSeqNumber >= savedPublishedSeqNum
        && deltaKnowledge.lastSummarisedSeq === maxSequenceNumber;
    const bondHasNewContent = savedPublishedSeqNum !== undefined &&
        maxSequenceNumber > savedPublishedSeqNum;

    const liveCallId = useSelectorArgs(selectLiveCallIdByBondId, bondId);
    const liveCall = useSelectorArgs(selectCallById, liveCallId);
    const isLiveCall = liveCall !== undefined && !liveCall.endedAt;
    // participantsByContributions is a list of call participants with
    // transcript events in the call. If this list is empty, then the call does
    // not have any transcript events
    const callHasTranscriptEvents = liveCall !== undefined &&
        liveCall.participantsByContribution.length > 0;

    const dispatch = useAppDispatch();

    const viewStack = useAppSelector(selectCurrentViewStack);
    const { navigateReplace } = useViewStackNavigate();
    const prevSummaryRequestStatus = usePrevious(summaryRequestStatus);

    const toggleDisplaySummary = useCallback((visible?: boolean) => {
        const newStack = viewStackUtils.updateTopOfStack(viewStack, frame => {
            const summary = viewUtils.isSingleBond(frame) ? !frame.summary : undefined;
            return viewUtils.singleBond(bondId, undefined, visible ?? summary);
        });
        navigateReplace(newStack, { replace: true });
    }, [
        viewStack,
        navigateReplace,
        bondId,
    ]);

    const fetchAndOrToggleSidebarSummary = useCallback(() => {
        if (savedPublishedSeqNum === undefined) return;
        const isProcessingRequest = summaryRequestStatus === SummaryRequestStatus.ProcessingRequest;
        const fetchNewKnowledge = isLiveCall ? !knowledgeIsUpToDate
            : (!knowledgeIsUpToDate && bondHasNewContent);
        if (!isProcessingRequest && fetchNewKnowledge) {
            dispatch(
                fetchDeltaKnowledgeBondThunk({
                    bondId,
                    lastReadSequenceNumber: savedPublishedSeqNum,
                }),
            );
        }
        if (knowledgeIsUpToDate || (bondHasKnowledge && !bondHasNewContent)) {
            toggleDisplaySummary();
        }
    }, [
        isLiveCall,
        dispatch,
        bondId,
        savedPublishedSeqNum,
        summaryRequestStatus,
        knowledgeIsUpToDate,
        toggleDisplaySummary,
        bondHasKnowledge,
        bondHasNewContent,
    ]);

    useEffect(() => {
        if (
            prevSummaryRequestStatus === SummaryRequestStatus.ProcessingRequest &&
            summaryRequestStatus === SummaryRequestStatus.Done
        ) {
            toggleDisplaySummary(true);
        }
    }, [prevSummaryRequestStatus, summaryRequestStatus, toggleDisplaySummary]);

    const handleError = useCallback(() => {
        dispatch(
            clearDeltaKnowledgeBond({
                bondId,
            }),
        );
    }, [bondId, dispatch]);

    const currentView = viewStack[viewStack.length - 1];
    const displayingSidebar = viewUtils.isSingleBond(currentView) && currentView.summary;
    const buttonIsClickable = isLiveCall ? callHasTranscriptEvents
        : bondHasKnowledge || bondHasNewContent;
    if (isMobile || !channelId || !bondId) return <></>;

    const displayButtonAsDisabled = isLiveCall ? knowledgeIsUpToDate
        : bondHasKnowledge || !bondHasNewContent;
    switch (summaryRequestStatus) {
        case SummaryRequestStatus.ProcessingRequest:
            return (
                <SummaryButtonSpinner
                    onClick={fetchAndOrToggleSidebarSummary}
                />
            );
        case SummaryRequestStatus.Error:
            return (
                <SummaryButtonInternal
                    isDisabled={displayButtonAsDisabled}
                    clickable={true}
                    onClick={handleError}
                />
            );
        default:
            return (
                <SummaryButtonInternal
                    isDisabled={displayButtonAsDisabled}
                    clickable={displayingSidebar || buttonIsClickable}
                    onClick={fetchAndOrToggleSidebarSummary}
                />
            );
    }
}
