import classNames from "classnames";

import * as d from "@/domain/domain";
import {
    archiveBond,
    followBond,
    selectBondById,
    selectBondIsArchived,
    selectCurrentLiveBondId,
} from "@/features/bonds";
import {
    selectIsRead,
    selectPublishedSequenceNumber,
    updateStagedSequenceNumberForTests,
    updateStagedSequenceNumberToLocalMax,
    updateUserReadSequenceNumber,
} from "@/features/channels";
import { selectKnownSquadNames } from "@/features/squads";
import useOutsideClick from "@/hooks/useOutsideClick";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { selectCurrentUserId, useAppDispatch, useAppSelector } from "@/store/redux";
import React, { useCallback, useRef, useState } from "react";

function toggleIsReadStatus(
    isRead: boolean,
    curPubSeqNo: number,
    dispatch: ReturnType<typeof useAppDispatch>,
    channelId: d.ChannelId,
) {
    if (isRead) {
        if (curPubSeqNo !== undefined && curPubSeqNo > 0) {
            // This is a nasty bodge to get mark-as-unread in without any bigger changes to
            // the system. We need to update our local staged sequence number down, then
            // update the cloud, else we'll just tell the cloud it was the original number
            // immediately.
            // Yuck!
            const sequenceNumber = curPubSeqNo - 1;

            dispatch(
                updateStagedSequenceNumberForTests({
                    channelId,
                    sequenceNumber,
                }),
            );
            dispatch(updateUserReadSequenceNumber({ channelId, sequenceNumber }));
        }
    }
    else {
        dispatch(
            updateStagedSequenceNumberToLocalMax(channelId),
        );
    }
}

export function BondDismissButton(
    { id, channelId, isArchived, isMobile }: {
        id: d.BondId;
        channelId?: d.ChannelId;
        isArchived: boolean;
        isMobile: boolean;
    },
): React.JSX.Element {
    const dispatch = useAppDispatch();

    const onClick: React.MouseEventHandler<HTMLButtonElement> = e => {
        e.stopPropagation();
        const shouldArchive = !isArchived;

        dispatch(
            archiveBond({
                bondId: id,
                archive: shouldArchive,
                channelId: channelId,
            }),
        );
    };

    const actionName = {
        1: ["Restore bond", "Restore"],
        0: ["Dismiss bond", "Dismiss"],
    }[isArchived ? 1 : 0];

    if (isMobile) {
        const classes = classNames("c-btn-card", {
            "c-btn-dismiss": !isArchived,
            "c-btn-restore": isArchived,
        });
        const divClasses = classNames({
            "c-btn-dismiss__icon": !isArchived,
            "c-btn-restore__icon": isArchived,
        });

        return (
            <button className={classes} onClick={onClick} aria-label={actionName[0]}>
                <div className={divClasses}></div>
                {actionName[1]}
            </button>
        );
    }

    const classes = classNames({
        "c-btn-icon-dismiss": !isArchived,
        "c-btn-icon-restore": isArchived,
    });

    return (
        <button
            className={classes}
            aria-label={actionName[0]}
            title={actionName[0]}
            onClick={onClick}
        >
            {actionName[1]}
        </button>
    );
}

export function DiscoverBondFollowButton(
    { id, isFollowed, isMobile }: { id: d.BondId; isFollowed: boolean; isMobile: boolean; },
) {
    const dispatch = useAppDispatch();

    // Prevent leaving the bond while in a live session
    const liveBondId = useAppSelector(selectCurrentLiveBondId);
    const cannotLeaveBond = isFollowed && liveBondId == id;

    const onClick: React.MouseEventHandler<HTMLButtonElement> = e => {
        e.stopPropagation();
        if (!cannotLeaveBond) {
            const shouldFollow = !isFollowed;
            dispatch(followBond({ bondId: id, follow: shouldFollow }));
        }
    };

    const classes = classNames("c-btn-join-bond", {
        "c-btn-join-bond--desktop": !isMobile,
    });

    return (
        <button
            className={classes}
            aria-label="Join Bond"
            title="Join Bond"
            onClick={onClick}
            disabled={cannotLeaveBond}
        >
            Join Bond
        </button>
    );
}

export function BondFollowButton(
    { id, isFollowed, isMobile }: { id: d.BondId; isFollowed: boolean; isMobile: boolean; },
) {
    const dispatch = useAppDispatch();

    // Prevent leaving the bond while in a live session
    const liveBondId = useAppSelector(selectCurrentLiveBondId);
    const cannotLeaveBond = isFollowed && liveBondId == id;

    const onClick: React.MouseEventHandler<HTMLButtonElement> = e => {
        e.stopPropagation();
        if (!cannotLeaveBond) {
            const shouldFollow = !isFollowed;
            dispatch(followBond({ bondId: id, follow: shouldFollow }));
        }
    };

    const actionName = {
        2: ["Cannot leave bond while live", ""],
        1: ["Leave bond", "Leave"],
        0: ["Join bond", "Join"],
    }[isFollowed ? (cannotLeaveBond ? 2 : 1) : 0];

    if (isMobile) {
        const classes = classNames("c-btn-card", {
            "c-btn-leave": isFollowed,
            "c-btn-join": !isFollowed,
        });
        const divClasses = classNames({
            "c-btn-leave__icon": isFollowed,
            "c-btn-join__icon": !isFollowed,
        });

        return (
            <button
                className={classes}
                onClick={onClick}
                aria-label={actionName[0]}
                disabled={cannotLeaveBond}
            >
                <div className={divClasses}></div>
                {actionName[1]}
            </button>
        );
    }

    const classes = classNames({
        "c-btn-icon-join": !isFollowed,
        "c-btn-icon-leave": isFollowed,
    });

    return (
        <button
            className={classes}
            aria-label={actionName[0]}
            title={actionName[0]}
            onClick={onClick}
            disabled={cannotLeaveBond}
        >
            {actionName[1]}
        </button>
    );
}

export function BondReadButton(
    { channelId, isRead, isMobile }: {
        channelId?: d.ChannelId;
        isRead: boolean;
        isMobile: boolean;
    },
) {
    const dispatch = useAppDispatch();
    const curPubSeqNo = useSelectorArgs(selectPublishedSequenceNumber, channelId);

    const onClick: React.MouseEventHandler<HTMLButtonElement> = e => {
        e.stopPropagation();
        if (!channelId) {
            return;
        }
        toggleIsReadStatus(isRead, curPubSeqNo ?? 0, dispatch, channelId);
    };

    const actionName = {
        1: ["Mark bond as unread", "Unread"],
        0: ["Mark bond as read", "Read"],
    }[isRead ? 1 : 0];

    if (isMobile) {
        const classes = classNames("c-btn-card", {
            "c-btn-read": !isRead,
            "c-btn-unread": isRead,
        });
        const divClasses = classNames({
            "c-btn-read__icon": !isRead,
            "c-btn-unread__icon": isRead,
        });

        return (
            <button className={classes} onClick={onClick} aria-label={actionName[0]}>
                <div className={divClasses}></div>
                {actionName[1]}
            </button>
        );
    }

    const classes = classNames({
        "c-btn-icon-read": !isRead,
        "c-btn-icon-unread": isRead,
    });

    return (
        <button
            className={classes}
            aria-label={actionName[0]}
            title={actionName[0]}
            onClick={onClick}
        >
            {actionName[1]}
        </button>
    );
}

interface BondActionTopbarButtonProps {
    name: string;
    iconClass: string;
    callback: () => void;
    isDisabled?: boolean;
    setIsDropdownVisible: (visible: boolean) => void;
}

function BondActionTopbarButton(props: BondActionTopbarButtonProps): React.JSX.Element {
    const {
        name,
        iconClass,
        callback,
        isDisabled,
        setIsDropdownVisible,
    } = props;

    const buttonCls = classNames("c-bond-dropdown__option", isDisabled && "is-disabled");
    const iconCls = classNames("c-bond-dropdown__icon", `c-bond-dropdown__icon--${iconClass}`);

    return (
        <button
            className={buttonCls}
            aria-label={name}
            onClick={e => {
                e.stopPropagation();
                callback();
                setIsDropdownVisible(false);
            }}
            disabled={isDisabled}
        >
            {name}
            <div className={iconCls}>
            </div>
        </button>
    );
}

export function BondTopbarActions({ bondId }: { bondId: d.BondId; }): React.JSX.Element {
    const dispatch = useAppDispatch();

    const bond = useSelectorArgs(selectBondById, bondId);
    const squadIds = bond?.squadIds || [];
    const channelId = bond?.channelId;

    const currentUserId = useAppSelector(selectCurrentUserId);
    const squadNames = useSelectorArgs(selectKnownSquadNames, squadIds);

    const isArchived = useSelectorArgs(selectBondIsArchived, bondId) || false;
    const isRead = useSelectorArgs(selectIsRead, channelId);
    const isPrivate = squadNames.length === 0;
    const isFollowed = currentUserId && bond && bond.followers.includes(currentUserId) || false;
    const liveBondId = useAppSelector(selectCurrentLiveBondId);
    const cannotLeaveBond = isFollowed && liveBondId == bondId;

    const curPubSeqNo = useSelectorArgs(selectPublishedSequenceNumber, channelId);

    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    const showActionsButtonRef = useRef<HTMLButtonElement | null>(null);
    const [dropdownRef] = useOutsideClick<HTMLDivElement>((target: EventTarget | null) => {
        if (showActionsButtonRef.current?.contains(target as Node)) return;
        setIsDropdownVisible(false);
    });

    const toggleDropdown = (_x: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setIsDropdownVisible(z => !z);
    };

    const toggleIsReadStatusCallback = useCallback(() => {
        if (!channelId || !curPubSeqNo) {
            return;
        }
        toggleIsReadStatus(isRead, curPubSeqNo, dispatch, channelId);
    }, [dispatch, isRead, channelId, curPubSeqNo]);

    const joinLeaveCallback = useCallback(() => {
        if (bondId) {
            const shouldFollow = !isFollowed;
            dispatch(
                followBond({
                    bondId: bondId,
                    follow: shouldFollow,
                }),
            );
        }
    }, [dispatch, bondId, isFollowed]);

    const dismissRestoreCallback = useCallback(() => {
        if (bondId) {
            const shouldArchive = !isArchived;
            dispatch(
                archiveBond({
                    bondId: bondId,
                    archive: shouldArchive,
                    channelId: channelId,
                }),
            );
        }
    }, [dispatch, bondId, isArchived, channelId]);

    const actions = [
        {
            name: isRead ? "Mark as unread" : "Mark as read",
            iconClass: isRead ? "unread" : "read",
            callback: toggleIsReadStatusCallback,
        },
        {
            isHidden: isPrivate,
            name: isFollowed ? (cannotLeaveBond ? "Cannot leave live bond" : "Leave bond")
                : "Join bond",
            iconClass: isFollowed ? "leave" : "join",
            callback: joinLeaveCallback,
            isDisabled: cannotLeaveBond,
        },
        {
            name: isArchived ? "Restore" : "Dismiss",
            iconClass: isArchived ? "restore" : "dismiss",
            callback: dismissRestoreCallback,
        },
    ];

    const buttonCls = classNames("c-btn-options", isDropdownVisible && "is-selected");
    const divCls = classNames("c-bond-dropdown__options", isDropdownVisible && "is-visible"); // for the div on line 407

    if (!bondId) {
        return <></>;
    }

    return (
        <div className="c-bond-dropdown">
            <button
                onClick={toggleDropdown}
                className={buttonCls}
                title={`Show options`}
                aria-label={`Show options`}
                ref={showActionsButtonRef}
            >
                Options
            </button>
            <div
                className={divCls}
                ref={dropdownRef}
            >
                {actions.map((action, index) => (
                    !action.isHidden &&
                    (
                        <BondActionTopbarButton
                            setIsDropdownVisible={setIsDropdownVisible}
                            key={index}
                            {...action}
                        />
                    )
                ))}
            </div>
        </div>
    );
}
