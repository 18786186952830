import { Ref, useState } from "react";
import { useInView } from "react-intersection-observer";

import { IncompatibleMessage } from "@/domain/messages";
import { getMessageThunk } from "@/features/channels";
import { useConnectedEffect } from "@/hooks/useConnectedEffect";
import useMergedRefs from "@/hooks/useMergedRefs";
import { useAppDispatch } from "@/store/redux";

export interface IncompatibleMessageViewProps {
    msg: IncompatibleMessage;
    messageContentRef?: Ref<HTMLDivElement>;
}

export const IncompatibleMessageView = (props: IncompatibleMessageViewProps): React.JSX.Element => {
    const { msg, messageContentRef } = props;

    const dispatch = useAppDispatch();
    const { ref: inViewRef, inView } = useInView();
    const [hasBeenInView, setHasBeenInView] = useState(false);
    useConnectedEffect(() => {
        if (inView && !hasBeenInView) {
            // Get a new copy of the message.
            dispatch(getMessageThunk({ messageId: msg.id, channelId: msg.channelId }));
            // Stop us doing this again if inView changes back to true due to scrolling.
            setHasBeenInView(true);
        }
    }, [dispatch, hasBeenInView, inView, msg.channelId, msg.id]);

    const ref = useMergedRefs(messageContentRef, inViewRef);

    return (
        <div ref={ref}>
            Your client cannot show this message. Please refresh or update.
        </div>
    );
};
