import * as Sentry from "@sentry/react";
import {
    setContext as sentrySetContext,
    setTag as sentrySetTag,
    setUser as sentrySetUser,
} from "@sentry/react";
import { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import { CloseButton } from "@/components/buttons/Close";
import { FeatureFlagged } from "@/components/FeatureFlags";
import { fromRawSentryCorrelationUrn, RtcSessionId, SentryCorrelationId } from "@/domain/domain";
import { UserOverview } from "@/domain/users";
import { makeSentryReportThunk } from "@/features/connection";
import { MetaInterestCounterKey } from "@/features/interest";
import {
    selectBackendInfo,
    selectConnectionIdentifiers,
    selectShowReportIssueDialog,
    showReportIssueDialog,
} from "@/features/meta";
import { selectCurrentUser } from "@/features/users";
import { useMetaInterest } from "@/hooks/interest/useInterest";
import { getRecentRtcStats, getRtcStats } from "@/hooks/rtc/useRtcClient";
import useDialogOutsideClick from "@/hooks/useDialogOutsideClick";
import useLocalDispatch from "@/hooks/useLocalDispatch";
import { getConnectionRttStats } from "@/misc/connectionRttWatcher";
import log, { getArchivedLogsFormatted } from "@/misc/log";
import { Optional } from "@/misc/types";
import { useAppDispatch, useAppSelector } from "@/store/redux";
import { v4 } from "uuid";

function SentryReportDialogInternal(
    { show, close: closeCallback, currentUser }: {
        show: boolean;
        close: () => void;
        currentUser: Optional<UserOverview>;
    },
) {
    const dialogRef = useRef<HTMLDialogElement>(null);
    const descriptionRef = useRef<HTMLTextAreaElement>(null);
    const formRef = useRef<HTMLFormElement>(null);
    const fileRef = useRef<HTMLInputElement>(null);
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [description, setDescription] = useState("");

    const dispatch = useAppDispatch();
    const sendToBackend = useCallback(async (correlationId: SentryCorrelationId) => {
        await dispatch(makeSentryReportThunk({ correlationId }));
    }, [dispatch]);

    useEffect(() => {
        if (descriptionRef.current) {
            descriptionRef.current.focus();
        }
    }, []);

    const close = useCallback(() => {
        setDescription("");
        formRef.current?.reset();
        closeCallback();
    }, [closeCallback, formRef]);

    const sendToSentry = useCallback(async () => {
        const rawCorrelationId = v4();
        const correlationId = fromRawSentryCorrelationUrn(rawCorrelationId);

        log.info(
            "User send feedback requested, sending to Sentry",
            description,
            currentUser,
            correlationId,
        );

        sentrySetTag("correlationId", correlationId);

        log.info(`Getting RTC stats...`);
        const currentRtcDump = await getRtcStats();
        const recentRtcDump = getRecentRtcStats();
        log.info(`Getting connection RTT stats...`);
        const currentRttDump = await getConnectionRttStats();

        if (!description) {
            throw new Error("No description provided, not sending feedback");
        }

        const replay = Sentry.getReplay();
        if (replay) {
            log.info(`Flushing replay for user feedback`);
            try {
                await replay.flush();
            }
            catch (e) {
                console.warn("Failed to upload replay in user feedback", e);
            }
        }

        log.info(`Getting archived logs...`);
        const archivedLogLines = await getArchivedLogsFormatted();
        const attachments: {
            filename: string;
            data: string | Uint8Array;
        }[] = [
            { filename: "log.txt", data: archivedLogLines.join("\n") },
        ];

        if (currentRttDump) {
            attachments.push({
                filename: "connection-rtt-stats.txt",
                data: currentRttDump,
            });
        }

        const pushRtcFile = (isJoined: boolean) => (dump: string[], id: RtcSessionId) =>
            attachments.push({
                filename: `rtc-${isJoined ? "curr" : "last"}-${id}.txt`,
                data: dump.join("\n"),
            });
        if (currentRtcDump.size > 0) {
            currentRtcDump.forEach(pushRtcFile(true));
        }
        if (recentRtcDump.lastSessionId && recentRtcDump.lastSessionStats.length > 0) {
            pushRtcFile(false)(recentRtcDump.lastSessionStats, recentRtcDump.lastSessionId);
        }

        if ((fileRef.current?.files?.length ?? 0) > 0) {
            log.info(`Getting file attachment contents...`);
        }

        for (let i = 0; i < (fileRef.current?.files?.length ?? 0); i++) {
            const file = fileRef.current?.files?.item(i);
            if (file) {
                const data = await file.arrayBuffer();
                attachments.push({ filename: file.name, data: new Uint8Array(data) });
            }
        }

        const event_message = "Event for send feedback: " + rawCorrelationId;

        log.info(`Capturing sentry event...`);
        const eid = Sentry.captureEvent({
            message: event_message,
            user: {
                id: currentUser?.id,
            },
        }, {
            attachments,
        });

        log.info(`Capturing sentry user feedback...`);
        Sentry.captureUserFeedback({
            event_id: eid,
            comments: description,
            email: currentUser?.email,
            name: currentUser?.name || "unknown user",
        });

        log.info(`Feedback captured, event ID ${eid} message ${event_message}`);

        close();

        return correlationId;
    }, [currentUser, description, close]);

    const onSubmit = useCallback((e: FormEvent) => {
        e.preventDefault();
        sendToSentry()
            .then(
                sendToBackend,
                err => {
                    log.error(`Failed to send feedback to Sentry: ${err}`);
                },
            )
            .catch(
                err => {
                    log.error(`Failed to send sentry correlation ID: ${err}`);
                },
            );
    }, [sendToSentry, sendToBackend]);

    useEffect(() => {
        if (show) {
            dialogRef.current?.showModal();
        }
        else {
            dialogRef.current?.close?.();
        }
    }, [show, dialogRef]);

    useEffect(() => {
        setSubmitEnabled(description.length > 0);
    }, [description]);

    const closeIfOutside = useDialogOutsideClick(dialogRef, close);

    useEffect(() => {
        const check = (e: KeyboardEvent) => {
            if (show && e.key === "Escape") {
                close();
            }
        };

        document.addEventListener("keypress", check, { capture: true });
        return () => {
            document.removeEventListener("keypress", check, { capture: true });
        };
    }, [show, close]);
    useMetaInterest(show, MetaInterestCounterKey.BlockHotkey);

    // Bug: autoFocus broken inside <dialog /> https://github.com/facebook/react/issues/23301
    useEffect(() => {
        if (!descriptionRef.current) return;
        descriptionRef.current.setAttribute("autofocus", "true");
    }, []);

    return (
        <dialog
            ref={dialogRef}
            className="c-dialog c-dialog--report-issue"
            onMouseDown={closeIfOutside}
        >
            <header className="c-dialog__header c-dialog__header--centered">
                <h1 className="c-dialog__title">Report issue 🐛</h1>
                <CloseButton side="right" onClick={close} />
            </header>

            <div className="c-dialog__content">
                <form onSubmit={onSubmit} ref={formRef}>
                    <fieldset>
                        <div className="c-form-element c-form-element--inline">
                            <label className="c-label c-label--inline" htmlFor="userfeedback_name">
                                Name:
                            </label>
                            <input
                                type="text"
                                id="userfeedback_name"
                                readOnly={true}
                                value={currentUser?.name || ""}
                                className="c-input"
                            />
                        </div>
                        <div className="c-form-element c-form-element--inline">
                            <label className="c-label c-label--inline" htmlFor="userfeedback_email">
                                Email:
                            </label>
                            <input
                                type="email"
                                id="userfeedback_email"
                                readOnly={true}
                                value={currentUser?.email || ""}
                                className="c-input"
                            />
                        </div>
                        <div className="c-form-element c-form-element--margin">
                            <label
                                className="c-label c-label--top"
                                htmlFor="userfeedback_description"
                            >
                                Description:
                            </label>
                            <textarea
                                id="userfeedback_description"
                                rows={4}
                                ref={descriptionRef}
                                value={description}
                                onChange={() => setDescription(descriptionRef.current?.value || "")}
                                tabIndex={0}
                                className="c-textarea"
                            />
                        </div>
                        <div className="c-form-element c-form-element--upload">
                            <label
                                className="c-label c-label--inline"
                                htmlFor="userfeedback_attachment"
                            >
                                Attachments:
                            </label>
                            <input
                                type="file"
                                id="userfeedback_attachment"
                                name="userfeedback_attachment"
                                multiple={true}
                                ref={fileRef}
                                className="cp-btn c-fileupload c-fileupload--report"
                                title="Upload attachments"
                            />
                        </div>
                    </fieldset>
                    <div className="c-btn-group">
                        <input
                            type="submit"
                            className="c-btn-submit-report"
                            disabled={!submitEnabled}
                            value="Send report"
                        />
                    </div>
                </form>
            </div>
        </dialog>
    );
}

// https://developer.mozilla.org/en-US/docs/Web/API/Navigator/platform
function keyboardShortcut() {
    if (
        navigator.platform.indexOf("Mac") === 0 ||
        navigator.platform === "iPhone"
    ) {
        return ["⌘-B", "meta+b"]; // command key
    }
    return ["Ctrl-B", "ctrl+b"];
}

export function SentryReportButton(
    { buttonClassNames = "c-btn c-btn-report" }: { buttonClassNames?: string; },
) {
    const currentUser = useAppSelector(selectCurrentUser);
    const deviceIds = useAppSelector(selectConnectionIdentifiers);
    const backendInfo = useAppSelector(selectBackendInfo);
    const dispatch = useLocalDispatch();
    const showDialog = useCallback(() => dispatch(showReportIssueDialog(true)), [dispatch]);

    useEffect(() => {
        if (!currentUser) return;
        sentrySetUser({
            id: currentUser.id,
            email: currentUser.email,
            fullName: currentUser.name,
        });
    }, [currentUser]);

    useEffect(() => {
        if (!deviceIds) return;
        sentrySetContext("connection", deviceIds);
    }, [deviceIds]);

    useEffect(() => {
        if (!backendInfo) return;
        sentrySetContext("backendInfo", backendInfo);
    }, [backendInfo]);

    return (
        <FeatureFlagged flag={"report-issue-button"} match={true}>
            <button
                type="button"
                onClick={() => showDialog()}
                className={buttonClassNames}
            >
                Report issue 🐛
                <span className="c-btn-report__shortcut">{keyboardShortcut()[0]}</span>
            </button>
        </FeatureFlagged>
    );
}

export function SentryReportDialog() {
    const dispatch = useAppDispatch();
    const showDialog = useCallback((show: boolean) => dispatch(showReportIssueDialog(show)), [
        dispatch,
    ]);
    const isDialogVisible = useAppSelector(selectShowReportIssueDialog);
    const currentUser = useAppSelector(selectCurrentUser);

    useHotkeys(
        keyboardShortcut()[1],
        () => showDialog(!isDialogVisible),
        {
            preventDefault: true,
            enableOnFormTags: ["textarea"],
        },
        [showDialog, isDialogVisible],
    );

    return (
        <FeatureFlagged flag={"report-issue-button"} match={true}>
            <SentryReportDialogInternal
                show={isDialogVisible}
                close={() => showDialog(false)}
                currentUser={currentUser}
            />
        </FeatureFlagged>
    );
}

// For usage when we're in a state where we are not signed in, e.g. a login view
export function SentryReportButtonNoRedux(
    { buttonClassNames = "c-btn c-btn-report" }: { buttonClassNames?: string; },
) {
    const [showingDialog, setShowingDialog] = useState(false);

    useHotkeys(
        keyboardShortcut()[1],
        () => setShowingDialog(x => !x),
        {
            preventDefault: true,
            enableOnFormTags: ["textarea"],
        },
        [setShowingDialog],
    );

    return (
        <>
            <button
                type="button"
                onClick={() => setShowingDialog(true)}
                className={buttonClassNames}
            >
                Report issue 🐛
                <span className="c-btn-report__shortcut">{keyboardShortcut()[0]}</span>
            </button>
            <SentryReportDialogInternal
                show={showingDialog}
                close={() => setShowingDialog(false)}
                currentUser={undefined}
            />
        </>
    );
}
