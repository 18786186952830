import { useCallback, useEffect } from "react";

import NativeNotificationManager from "@/components/managers/NativeNotificationManager";
import log from "@/misc/log";
import { persistenceTransactionFailed } from "@/persist/idb";
import { App } from "@capacitor/app";

export default function Capacitor(): React.JSX.Element {
    const addListeners = useCallback(async () => {
        const resumeListener = await App.addListener("resume", () => {
            log.info("Native app resumed");

            if (persistenceTransactionFailed) {
                log.warn(`Reloading due to previous failed persistence transaction!`);
                window.location.reload();
            }
        });

        const pauseListener = await App.addListener("pause", () => {
            log.info("Native app suspended");
        });

        const appStateChangeListener = await App.addListener("appStateChange", state => {
            log.info("Native app state changed. Currently active: ", state.isActive);
        });

        return () => {
            [resumeListener, pauseListener, appStateChangeListener].forEach(listener =>
                listener.remove()
            );
        };
    }, []);

    useEffect(() => {
        addListeners()
            .catch(e => {
                log.error("An error occured while adding capacitor listeners", e);
            });
    }, [addListeners]);

    return <NativeNotificationManager />;
}
