import { Optional } from "@/misc/types";
import { UIEventHandler, useCallback, useState } from "react";

interface useScrollDetectorResult {
    atStart: boolean;
    atEnd: boolean;
    onScroll: UIEventHandler<HTMLElement>;
}

/**
 * Detects when an element is scrolled to the start and/or end of its scrollable region. Returns
 * these values, along with a scroll event handler that must be attached to the element
 */
export const useScrollDetector = (
    direction: Optional<"horizontal" | "vertical">,
    initialAtStart?: boolean,
    initialAtEnd?: boolean,
): useScrollDetectorResult => {
    direction ??= "vertical";

    const [atStart, setAtStart] = useState(initialAtStart ?? false);
    const [atEnd, setAtEnd] = useState(initialAtEnd ?? false);
    const onScroll = useCallback<UIEventHandler<HTMLElement>>(e => {
        const elem = e.currentTarget;
        if (elem === null) return;

        switch (direction) {
            case "horizontal":
                setAtStart(elem.scrollLeft < 1);
                setAtEnd(elem.scrollWidth - elem.scrollLeft - elem.clientWidth < 1);
                break;
            case "vertical":
                setAtStart(elem.scrollTop < 1);
                setAtEnd(elem.scrollHeight - elem.scrollTop - elem.clientHeight < 1);
                break;
        }
    }, [direction]);
    return { atStart, atEnd, onScroll };
};

export default useScrollDetector;
