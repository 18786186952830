import { DateTime, Duration } from "luxon";
import { useEffect } from "react";

import useTimerTarget from "@/hooks/useTimerTarget";

type UseIntervalActionProps = {
    action: (now: DateTime) => void;
    interval: number;
};

/** @function
 * Run an action at a regular interval.
 *
 * @param action the action to run
 * @param interval the interval at which to run the action
 */
export default function useIntervalAction(props: UseIntervalActionProps) {
    const { action, interval } = props;

    const { now, setTarget } = useTimerTarget();

    useEffect(() => {
        action(now);

        setTarget(now.plus(Duration.fromMillis(interval)));
    }, [action, interval, now, setTarget]);
}
