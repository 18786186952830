import { useCallback, useEffect, useState } from "react";
import TinyGesture from "tinygesture";

type Optional<T> = T | null;

enum SwipeDirection {
    Left = "left",
    Right = "right",
}

let activeSwipeId: Optional<string> = null;
let activeSwipeDirection: SwipeDirection | null = null;

const SWIPE_ID_PREFIX = "swipe-";

function transition(div: HTMLDivElement, translateX: number, transition: boolean) {
    if (transition) {
        div.style.transition = "transform 0.15s ease-in-out";
    }
    else {
        div.style.transition = "";
    }
    if (translateX != 0) {
        div.style.transform = `translateX(${translateX}px)`;
    }
    else {
        div.style.transform = "";
    }
}

interface SliderOptions {
    revealWidthRight?: number;
    revealWidthLeft?: number;
    snapThreshold?: number;
    swipeSensitivity?: number;
}

const useSlider = (
    id: string,
    options: SliderOptions = {},
): [React.RefCallback<HTMLDivElement>, string] => {
    const [divRef, setDivRef] = useState<HTMLDivElement | null>(null);
    const domId = `${SWIPE_ID_PREFIX}${id}`;

    const refCb = useCallback((node: HTMLDivElement | null) => {
        if (node) node.id = domId;
        setDivRef(node);
    }, [domId]);

    const {
        revealWidthRight: optRevealWidthRight,
        revealWidthLeft: optRevealWidthLeft,
        snapThreshold: optSnapThreshold,
        swipeSensitivity: optSwipeSensitivity,
    } = options;

    useEffect(() => {
        if (!divRef) return;
        let swiped = 0;
        let startOffset = 0;
        let lastDirection: "left" | "right" | null = null;
        const decelerationOnOverflow = 4;
        const revealWidthRight = optRevealWidthRight ?? 160;
        const revealWidthLeft = optRevealWidthLeft ?? 80;
        const snapThreshold = optSnapThreshold ?? 1.5;
        const swipeSensitivity = optSwipeSensitivity ?? 1;
        const snapWidthLeft = revealWidthLeft / snapThreshold;
        const snapWidthRight = revealWidthRight / snapThreshold;

        let swipeDirection: "horizontal" | "vertical" | null = null;

        const gesture = new TinyGesture(divRef, {
            mouseSupport: true,
            diagonalSwipes: false,
            threshold: (type, _self) => {
                if (type === "y") return 25;
                return Math.max(
                    25,
                    Math.floor(
                        0.15 *
                            (type === "x"
                                ? window.innerWidth || document.body.clientWidth
                                : window.innerHeight || document.body.clientHeight),
                    ),
                );
            },
        });

        const resetSwipe = () => {
            swiped = 0;
            startOffset = 0;
            lastDirection = null;
            if (divRef) {
                transition(divRef, 0, true);
            }
        };

        const closeActiveSwipe = () => {
            if (activeSwipeId && activeSwipeId !== domId) {
                const prevSwipe = document.getElementById(activeSwipeId);
                if (prevSwipe) {
                    transition(prevSwipe as HTMLDivElement, 0, true);
                }
                activeSwipeId = null;
                activeSwipeDirection = null;
            }
        };

        const handlePanMove = (_event: Event) => {
            if (!divRef || gesture.touchMoveX === null) return;

            const currentDirection = gesture.touchMoveX > 0 ? SwipeDirection.Left
                : SwipeDirection.Right;

            // Close active swipe if directions are opposite
            if (activeSwipeDirection && currentDirection !== activeSwipeDirection) {
                closeActiveSwipe();
            }

            if (lastDirection && currentDirection !== lastDirection) {
                resetSwipe();
                return;
            }

            if (!lastDirection) {
                closeActiveSwipe();
                lastDirection = currentDirection;
            }

            let touchMoveX = gesture.touchMoveX;

            if (
                gesture.swipingDirection !== null && swipeDirection === null &&
                (gesture.swipingDirection === "horizontal" ||
                    gesture.swipingDirection === "vertical")
            ) {
                swipeDirection = gesture.swipingDirection;
            }

            if (swipeDirection === "vertical") {
                touchMoveX = 0;
            }

            const getX = (x: number) => {
                x = x * swipeSensitivity;
                if (x > 0) {
                    if (x < revealWidthLeft) return x;
                    return (x - revealWidthLeft) / decelerationOnOverflow + revealWidthLeft;
                }
                else {
                    if (x > -revealWidthRight) return x;
                    return (x + revealWidthRight) / decelerationOnOverflow - revealWidthRight;
                }
            };

            const newX = getX(startOffset + touchMoveX);
            transition(divRef, newX, false);

            if (newX >= snapWidthLeft) {
                swiped = revealWidthLeft;
                activeSwipeId = domId;
                activeSwipeDirection = SwipeDirection.Left;
            }
            else if (newX <= -snapWidthRight) {
                swiped = -revealWidthRight;
                activeSwipeId = domId;
                activeSwipeDirection = SwipeDirection.Right;
            }
            else {
                swiped = 0;
                if (activeSwipeId === domId) {
                    activeSwipeId = null;
                    activeSwipeDirection = null;
                }
            }
        };

        const handlePanEnd = () => {
            if (!divRef) return;
            if (swiped === 0) {
                resetSwipe();
            }
            else {
                startOffset = swiped;
                activeSwipeId = domId;
            }
            transition(divRef, swiped, true);
            swipeDirection = null;
        };

        const handleTap = () => {
            if (!divRef) return;
            resetSwipe();
            if (activeSwipeId === domId) {
                activeSwipeId = null;
                activeSwipeDirection = null;
            }
        };

        gesture.on("panmove", handlePanMove);
        gesture.on("panend", handlePanEnd);
        gesture.on("tap", handleTap);

        return () => {
            if (divRef) {
                resetSwipe();
                if (activeSwipeId === domId) {
                    activeSwipeId = null;
                    activeSwipeDirection = null;
                }
            }
            gesture.destroy();
        };
    }, [
        domId,
        divRef,
        optRevealWidthRight,
        optRevealWidthLeft,
        optSnapThreshold,
        optSwipeSensitivity,
    ]);

    return [refCb, domId];
};

export default useSlider;
