import {
    createEntityAdapter,
    createSelector,
    createSlice,
    current,
    EntityState,
    PayloadAction,
} from "@reduxjs/toolkit";

import {
    addSquadMember,
    type AddSquadMemberArgs,
    createOrg,
    createSquad,
    createUser,
    type CreateUserArgs,
    listPersonOrgs,
    type ListPersonOrgsResponse,
    listUsersByPerson,
    OrgOverviewOrDeleted,
    separateOrgOverviewOrDeleteds,
    separateSquadActivityOrDeleteds,
    separateSquadOverviewOrDeleteds,
    setSquadLastRead,
    SquadActivityOrDeleted,
    SquadObservations,
    SquadOverviewOrDeleted,
    subOrgOverviews,
    subSquadActivity,
    subSquadObservers,
    subSquadOverviews,
    subUserSquadLastReads,
    subUserSquadLists,
    UserSquadLastRead,
} from "@/api/squads";
import * as d from "@/domain/domain";
import { DeltaSummarySquadMetadata } from "@/domain/intel";
import { SummaryRequestStatus } from "@/domain/intel";
import * as migrateV17 from "@/domain/migrate/v17";
import * as migrateV26 from "@/domain/migrate/v26";
import { getPresenceIcon, UserObservation } from "@/domain/presence";
import { OrgOverview, SquadActivity, SquadOverview } from "@/domain/squads";
import { userSortByNickname } from "@/domain/users";
import { createHAMT, hamtGet, ImmutableHAMT } from "@/ds/hamt";
import {
    resetStore,
    selectCurrentOrgId,
    selectCurrentPersonId,
    selectCurrentUserId,
} from "@/features/auth";
import {
    selectOrgsInterest,
    selectSquadsInterest,
    startManagingInterest,
} from "@/features/interest";
import { createProxiedAsyncThunk } from "@/features/proxiedThunk";
import {
    createSelectorPair,
    memoizeOptions,
    shallowArrayEqualSelectorCreator,
} from "@/features/selectors";
import { createStreamingAsyncThunk } from "@/features/streamingThunk";
import { unaryThunkHandler } from "@/features/thunk";
import log from "@/misc/log";
import { arraysAreEqual, setUnion } from "@/misc/primatives";
import { Diff, Mutable, Optional, optionalFriendly, partialRecord, TypedKeys } from "@/misc/types";
import {
    checkPersistor,
    clearKey,
    fetchIdbStore,
    idbStore,
    localStorageStore,
    simpleIdbTranslate,
} from "@/persist/shared";
import type {
    HydrateArgs,
    IdbStoreUpdate,
    IdbStoreUpdateWithClear,
    PersistenceUpdate,
    RWTransaction,
} from "@/persist/types";
import { valueUpdateToStoreUpdate } from "@/persist/updates";
import { type AppAppendListener, appAppendListener } from "@/store/middleware";
import { createAppSelector } from "@/store/redux";
import type { RootState } from "@/store/types";
import { channelsSlice } from "./channels";
import { selectUsers } from "./users";

export const mobileSquadsTabReadTimesKey = "mobileSquadsTabReadTimesKey";

const squadsAdapter = createEntityAdapter({
    selectId: (squad: SquadOverview) => squad.id,
});

const orgsAdapter = createEntityAdapter({
    selectId: (org: OrgOverview) => org.id,
});

export const streamOrgOverviews = createStreamingAsyncThunk<
    AsyncIterableIterator<Diff<d.OrgId>>,
    OrgOverviewOrDeleted
>(
    "squads/streamOrgOverviews",
    {
        rpc: ({ arg, signal }) => subOrgOverviews(arg, signal),
        parser: ({ thunkAPI, content: msgs }) => {
            const [toUpsert, toDelete] = separateOrgOverviewOrDeleteds(msgs);
            thunkAPI.dispatch(upsertOrgs(toUpsert.map(msg => msg.value)));
            thunkAPI.dispatch(deleteOrgs(toDelete.map(msg => msg.deletedId)));
        },
    },
);

export const streamSquadOverviews = createStreamingAsyncThunk<
    AsyncIterableIterator<Diff<d.SquadId>>,
    SquadOverviewOrDeleted
>(
    "squads/streamSquadOverviews",
    {
        rpc: ({ arg, signal }) => subSquadOverviews(arg, signal),
        parser: ({ thunkAPI, content: msgs }) => {
            const [toUpsert, toDelete] = separateSquadOverviewOrDeleteds(msgs);
            thunkAPI.dispatch(upsertSquads(toUpsert.map(msg => msg.value)));
            thunkAPI.dispatch(deleteSquads(toDelete.map(msg => msg.deletedId)));
        },
    },
);

export const subUserSquadListsThunk = createStreamingAsyncThunk<
    AsyncIterableIterator<Diff<d.UserId>>,
    Readonly<d.SquadId[]>
>(
    "squads/subUserSquadLists",
    {
        rpc: ({ arg, signal }) => subUserSquadLists(arg, signal),
        parser: ({ thunkAPI, content }) =>
            content.forEach(squadIds =>
                thunkAPI.dispatch(
                    squadSlice.actions.updateCurrentUserSquads(
                        squadIds,
                    ),
                )
            ),
    },
);

// `startManagingInterest` needs a selector returning a list as input,
// but for selecting our own squads it's only ever our current user.
// So this selector is just a simple adaptor.
const selectCurrentUserIdAsSingletonList = createAppSelector(
    [selectCurrentUserId],
    userId => [userId].filter(x => !!x),
);

export const startSubUserSquadListsStream = (
    appendListener: AppAppendListener = appAppendListener,
) => startManagingInterest(
    selectCurrentUserIdAsSingletonList,
    subUserSquadListsThunk,
    "subUserSquadLists",
    appendListener,
);

export const subSquadActivityThunk = createStreamingAsyncThunk<
    AsyncIterableIterator<Diff<d.SquadId>>,
    SquadActivityOrDeleted
>(
    "squads/subSquadLatestActivity",
    {
        rpc: ({ arg, signal }) => subSquadActivity(arg, signal),
        parser: ({ thunkAPI, content: activities }) => {
            const [toUpsert, toDelete] = separateSquadActivityOrDeleteds(activities);
            thunkAPI.dispatch(upsertSquadActivities(toUpsert.map(msg => msg.activity)));
            thunkAPI.dispatch(deleteSquadActivities(toDelete.map(msg => msg.deletedId)));
        },
    },
);

export const startSubSquadLatestActivityStream = (
    appendListener: AppAppendListener = appAppendListener,
) => startManagingInterest(
    selectCurrentAndInterestedSquadIds,
    subSquadActivityThunk,
    "subSquadActivity",
    appendListener,
);

export const fetchCurrentPersonMemberships = createProxiedAsyncThunk<ListPersonOrgsResponse, void>(
    "squads/fetchCurrentPersonMemberships",
    async (_, thunkAPI) => {
        const store = thunkAPI.getState();
        const currentPersonId = selectCurrentPersonId(store);
        if (!currentPersonId) {
            return thunkAPI.rejectWithValue({
                error: `Cannot fetch current memberships with empty person id`,
            });
        }

        return await unaryThunkHandler(
            thunkAPI,
            listPersonOrgs(currentPersonId),
            `fetchCurrentPersonMemberships ${currentPersonId}`,
        );
    },
);

export const fetchUsersForPerson = createProxiedAsyncThunk(
    "squads/fetchUsersForPerson",
    async ({ personId }: { personId: d.PersonId; }, thunkAPI) => {
        log.info(`Fetching users for person ${personId}`);

        return await unaryThunkHandler(
            thunkAPI,
            listUsersByPerson(personId),
            `fetchUsersForPerson ${personId}`,
        );
    },
);

export const subSquadObserversThunk = createStreamingAsyncThunk<
    AsyncIterableIterator<Diff<d.SquadId>>,
    SquadObservations
>(
    "bonds/subSquadObservers",
    {
        rpc: ({ arg, signal }) => subSquadObservers(arg, signal),
        parser: ({ thunkAPI, content }) => {
            content
                .filterLatest(obs => obs.viewId)
                .forEach(msg => {
                    thunkAPI.dispatch(streamedSquadObservers(msg));
                });
        },
    },
);

interface CreateOrgArgs {
    name: string;
}
export const createNewOrg = createProxiedAsyncThunk(
    "squads/createOrg",
    async (args: CreateOrgArgs, thunkAPI) => {
        const store = thunkAPI.getState();
        const personId = selectCurrentPersonId(store);

        if (args.name == "" || !personId) {
            throw thunkAPI.rejectWithValue({
                error: `Tried to create org without name or user ID`,
            });
        }

        log.info(`Creating org`);

        return await unaryThunkHandler(
            thunkAPI,
            createOrg({
                name: args.name,
                initialPerson: personId,
            }),
            `createOrg ${personId}`,
        );
    },
);

export const addUserToOrg = createProxiedAsyncThunk(
    "squads/createUser",
    async (args: CreateUserArgs, thunkAPI) => {
        log.info(`Adding person ${args.personId} to org ${args.orgId}`);

        return await unaryThunkHandler(
            thunkAPI,
            createUser(args),
            `createUser ${args.personId} to ${args.orgId}`,
        );
    },
);

interface CreateSquadArgs {
    name: string;
    orgId: d.OrgId;
}
export const createNewSquad = createProxiedAsyncThunk(
    "squads/createSquad",
    async (args: CreateSquadArgs, thunkAPI) => {
        if (args.name == "") {
            throw thunkAPI.rejectWithValue({
                error: `Tried to create squad without name or user ID`,
            });
        }

        const store = thunkAPI.getState();
        const userId = selectCurrentUserId(store)!;

        log.info(`Creating squad`);

        return await unaryThunkHandler(
            thunkAPI,
            createSquad({
                orgId: args.orgId,
                name: args.name,
                initialUser: userId,
            }),
            `createSquad ${args.orgId} ${userId}`,
        );
    },
);

export const addUserToSquad = createProxiedAsyncThunk(
    "squads/addSquadMember",
    async (args: AddSquadMemberArgs, thunkAPI) => {
        log.info(`Adding user ${args.userId} to squad ${args.squadId}`);

        return await unaryThunkHandler(
            thunkAPI,
            addSquadMember(args),
            `addSquadMember ${args.userId} to ${args.squadId}`,
        );
    },
);

export const setSquadLastReadThunk = createProxiedAsyncThunk(
    "squads/setSquadLastRead",
    async ({ squadIds, lastRead }: { squadIds: d.SquadId[]; lastRead: d.Timestamp; }, thunkAPI) => {
        const userId = selectCurrentUserId(thunkAPI.getState())!;

        return await unaryThunkHandler(
            thunkAPI,
            setSquadLastRead({ userId, squadIds, lastRead }),
            `resetSquadLastRead ${squadIds.join()} to ${lastRead}`,
        );
    },
);

export const subUserSquadLastReadsThunk = createStreamingAsyncThunk<
    AsyncIterableIterator<Diff<d.SquadId>>,
    UserSquadLastRead
>(
    "squads/subUserSquadLastReads",
    {
        rpc: ({ arg, signal, state }) => {
            const userId = selectCurrentUserId(state)!;
            return subUserSquadLastReads(arg, userId, signal);
        },
        parser: ({ thunkAPI, content: lastReads }) =>
            thunkAPI.dispatch(
                channelsSlice.actions.streamedSquadLastReads(lastReads),
            ),
    },
);

export const startSubUserSquadLastReadsStream = (
    appendListener: AppAppendListener = appAppendListener,
) => startManagingInterest(
    selectCurrentAndInterestedSquadIds,
    subUserSquadLastReadsThunk,
    "subUserSquadLastReads",
    appendListener,
);

interface SquadSidebarSummaryInfo {
    status?: SummaryRequestStatus;
    summary?: string;
    metadata?: DeltaSummarySquadMetadata;
}

const hamts = {
    squadActivities: createHAMT(({ squadId }: SquadActivity) => squadId),
    observers: createHAMT((v: SquadObservations) => v.viewId),
};

export interface SquadState {
    orgs: EntityState<OrgOverview, d.OrgId>;
    squads: EntityState<SquadOverview, d.SquadId>;
    currentPersonOrgIds: Array<d.OrgId>;
    currentUserSquadIds: Array<d.SquadId>;
    squadActivities: ImmutableHAMT<d.SquadId, SquadActivity>;
    sidebarSummaryInfo: Record<d.SquadId, SquadSidebarSummaryInfo>;
    observers: ImmutableHAMT<d.SquadId, SquadObservations>;
    readTimes: Record<string, number>;
    updates: SquadsUpdate[];
}

const getInitialState = (
    props?: {
        orgs?: Record<d.OrgId, OrgOverview>;
        squads?: Record<d.SquadId, SquadOverview>;
        userOrgs?: Array<d.OrgId>;
        userSquads?: Array<d.SquadId>;
        squadActivities?: Record<d.SquadId, SquadActivity>;
        readTimes?: Record<string, number>;
    },
): SquadState => ({
    orgs: orgsAdapter.getInitialState({}, props?.orgs ?? {}),
    squads: squadsAdapter.getInitialState({}, props?.squads ?? {}),
    currentPersonOrgIds: props?.userOrgs ?? [],
    currentUserSquadIds: props?.userSquads ?? [],
    squadActivities: hamts.squadActivities.initial(props?.squadActivities),
    sidebarSummaryInfo: {},
    observers: hamts.observers.initial(),
    readTimes: props?.readTimes ?? {},
    updates: [],
});

export const squadSlice = createSlice({
    name: "squads",
    initialState: getInitialState(),
    selectors: {
        currentPersonOrgIds: state => state.currentPersonOrgIds,
        currentUserSquadIds: state => state.currentUserSquadIds,
        observers: (state, squadId: Optional<d.SquadId>): Optional<UserObservation[]> =>
            squadId && hamtGet(state.observers, squadId)?.observations,
        readTimes: state => state.readTimes,
        sidebarSummaryInfo: (
            state,
            squadId: Optional<d.SquadId>,
        ): Optional<SquadSidebarSummaryInfo> => squadId && state.sidebarSummaryInfo[squadId],
        squadActivities: state => state.squadActivities,
        squadActivity: (state, squadId: Optional<d.SquadId>): Optional<SquadActivity> =>
            squadId && hamtGet(state.squadActivities, squadId),
    },
    reducers: {
        upsertOrgs: (
            state,
            { payload: orgs }: PayloadAction<Array<OrgOverview>>,
        ) => {
            orgsAdapter.upsertMany(state.orgs, orgs);
            state.updates = orgs.map(org => [stores.orgs, org.id, org]);
        },
        deleteOrgs: (
            state,
            { payload: orgIds }: PayloadAction<Array<d.OrgId>>,
        ) => {
            orgsAdapter.removeMany(state.orgs, orgIds);
            state.updates = orgIds.map(id => [stores.orgs, id]);
        },
        upsertSquads: (
            state,
            { payload: squads }: PayloadAction<Array<SquadOverview>>,
        ) => {
            squadsAdapter.upsertMany(state.squads, squads);
            state.updates = squads.map(squad => [stores.squads, squad.id, squad]);
        },
        deleteSquads: (
            state,
            { payload: squadIds }: PayloadAction<Array<d.SquadId>>,
        ) => {
            squadsAdapter.removeMany(state.squads, squadIds);
            state.updates = squadIds.map(id => [stores.squads, id]);
        },
        updateCurrentUserSquads: (
            state,
            { payload: squadIds }: PayloadAction<Readonly<Array<d.SquadId>>>,
        ) => {
            state.currentUserSquadIds = squadIds as Mutable<typeof squadIds>;
            state = updatePersistedSquadMembership(state);
        },
        // Useful for testing and for ladle stories.
        updateMemberships: (
            state,
            action: PayloadAction<{ orgIds: d.OrgId[]; squadIds: d.SquadId[]; }>,
        ) => {
            state.currentPersonOrgIds = action.payload.orgIds;
            state.currentUserSquadIds = action.payload.squadIds;

            state = updatePersistedSquadMembership(state);
            state = updatePersistedOrgMembership(state);
        },
        streamedSquadObservers: (
            state,
            { payload: observers }: PayloadAction<SquadObservations>,
        ) => {
            state.observers = hamts.observers.set(current(state).observers, observers);
        },
        markSquadTabRead: (state, action: PayloadAction<number>) => {
            const newTs = action.payload;

            if ((state.readTimes[mobileSquadsTabReadTimesKey] ?? 0) < newTs) {
                state.readTimes[mobileSquadsTabReadTimesKey] = newTs;

                state.updates = [[stores.readTimes, mobileSquadsTabReadTimesKey, newTs]];
            }
        },
        upsertSquadActivities: (
            state,
            { payload: activities }: PayloadAction<SquadActivity[]>,
        ) => {
            if (activities.length === 0) return;

            state.updates = [];

            state.squadActivities = hamts.squadActivities.setMany(
                current(state).squadActivities,
                activities,
                valueUpdateToStoreUpdate(state.updates, stores.squadActivities),
            );
        },
        updateSquadSummaryRequestStatus: (
            state,
            { payload: { squadId, status } }: PayloadAction<
                { squadId: d.SquadId; status: SummaryRequestStatus; }
            >,
        ) => {
            if (!state.sidebarSummaryInfo[squadId]) {
                state.sidebarSummaryInfo[squadId] = { status: status };
            }
            state.sidebarSummaryInfo[squadId].status = status;
        },
        updateSquadKnowledgeMetadata: (
            state,
            { payload: { squadId, metadata } }: PayloadAction<
                { squadId: d.SquadId; metadata: DeltaSummarySquadMetadata; }
            >,
        ) => {
            if (!state.sidebarSummaryInfo[squadId]) {
                state.sidebarSummaryInfo[squadId] = { metadata: metadata };
            }
            state.sidebarSummaryInfo[squadId].metadata = metadata;
        },
        clearSquadSummary: (state, { payload: squadId }: PayloadAction<d.SquadId>) => {
            if (!state.sidebarSummaryInfo[squadId]) return;
            delete state.sidebarSummaryInfo[squadId].summary;
        },
        clearDeltaKnowledgeSquad: (
            state,
            { payload: squadId }: PayloadAction<d.SquadId>,
        ) => {
            if (!state.sidebarSummaryInfo[squadId]) return;
            delete state.sidebarSummaryInfo[squadId].metadata;
            delete state.sidebarSummaryInfo[squadId].summary;
        },
        pushDeltaSummarySquad(
            state,
            { payload: { squadId, summary } }: PayloadAction<
                { squadId: d.SquadId; summary: string; }
            >,
        ) {
            if (!state.sidebarSummaryInfo[squadId]) {
                return;
            }
            if (state.sidebarSummaryInfo[squadId].summary !== undefined) {
                state.sidebarSummaryInfo[squadId].summary += summary;
            }
            else {
                state.sidebarSummaryInfo[squadId].summary = summary;
            }
        },
        deleteSquadActivities: (
            state,
            { payload: squadIds }: PayloadAction<Array<d.SquadId>>,
        ) => {
            if (squadIds.length === 0) return;

            state.updates = [];

            state.squadActivities = hamts.squadActivities.removeMany(
                current(state).squadActivities,
                squadIds,
                valueUpdateToStoreUpdate(state.updates, stores.squadActivities),
            );
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchCurrentPersonMemberships.fulfilled, (state, action) => {
            state.currentPersonOrgIds = action.payload.orgIds;

            state = updatePersistedOrgMembership(state);
        });
        builder.addCase(resetStore, _state => {
            return getInitialState();
        });
    },
});

function updatePersistedSquadMembership(state: SquadState): SquadState {
    state.updates = [[stores.squadMembership, clearKey]];
    state.currentUserSquadIds.forEach(squadId => {
        state.updates.push([stores.squadMembership, squadId, true]);
    });
    return state;
}

function updatePersistedOrgMembership(state: SquadState): SquadState {
    state.updates = [[stores.orgMembership, clearKey]];
    state.currentPersonOrgIds.forEach(orgId => {
        state.updates.push([stores.orgMembership, orgId, true]);
    });
    return state;
}

export const {
    upsertOrgs,
    deleteOrgs,
    upsertSquads,
    deleteSquads,
    updateMemberships,
    streamedSquadObservers,
    markSquadTabRead,
    pushDeltaSummarySquad,
    clearSquadSummary,
    clearDeltaKnowledgeSquad,
    updateSquadSummaryRequestStatus,
    updateSquadKnowledgeMetadata,
    upsertSquadActivities,
    deleteSquadActivities,
} = squadSlice.actions;

const selectors = squadSlice.getSelectors((state: RootState) => state.squads);
export const {
    currentPersonOrgIds: selectCurrentOrgIds,
    currentUserSquadIds: selectCurrentSquadIds,
    readTimes: selectReadTimes,
    sidebarSummaryInfo: selectSquadSidebarSummaryInfo,
    squadActivities: selectAllSquadActivities,
} = selectors;

const squadSelectors = squadsAdapter.getSelectors<RootState>(
    state => state.squads.squads,
);
const orgSelectors = orgsAdapter.getSelectors<RootState>(
    state => state.squads.orgs,
);

export const selectSquadIds = squadSelectors.selectIds;
export const selectOrgIds = orgSelectors.selectIds;
export const selectOrg = optionalFriendly(orgSelectors.selectById);

export const selectCurrentOrg = (state: RootState): Optional<OrgOverview> =>
    selectOrg(state, selectCurrentOrgId(state));

// Select our own orgs, and any additional orgs with interest
const selectCurrentAndInterestedOrgIds = createAppSelector(
    [selectCurrentOrgIds, selectOrgsInterest],
    (currentIds, interestedIds) => currentIds.concat(interestedIds).removeDuplicates(),
    memoizeOptions.lruShallow,
);
export const startOrgInterestStream = (
    appendListener: AppAppendListener = appAppendListener,
) => startManagingInterest(
    selectCurrentAndInterestedOrgIds,
    streamOrgOverviews,
    "subOrgOverviews",
    appendListener,
);

// Select our own squads, and any additional squads with interest
const selectCurrentAndInterestedSquadIds = createAppSelector(
    [selectCurrentSquadIds, selectSquadsInterest],
    (currentIds, interestedIds) => currentIds.concat(interestedIds).removeDuplicates(),
    memoizeOptions.lruShallow,
);
export const startSquadInterestStream = (
    appendListener: AppAppendListener = appAppendListener,
) => startManagingInterest(
    selectCurrentAndInterestedSquadIds,
    streamSquadOverviews,
    "subSquadOverviews",
    appendListener,
);

const selectSquadIdsOrderedBySquadName = createSelector(
    [squadSelectors.selectAll],
    sos => sos.sort((a, b) => a.name.localeCompare(b.name)).map(bov => bov.id),
);

export const selectCurrentSquadIdsOrderedBySquadName = createSelector(
    [selectCurrentSquadIds, selectSquadIdsOrderedBySquadName],
    (currentSquadIds, orderedSquadIds) =>
        orderedSquadIds.filter(id => currentSquadIds.includes(id)),
);

export const selectSquadSummary = createSelector(
    [selectSquadSidebarSummaryInfo],
    sidebarSummaryInfo => sidebarSummaryInfo?.summary,
);

export const selectSquadKnowledgeMetadata = createSelector(
    [selectSquadSidebarSummaryInfo],
    sidebarSummaryInfo => sidebarSummaryInfo?.metadata,
);

export const selectSquadSummaryRequestStatus = createSelector(
    [selectSquadSidebarSummaryInfo],
    sidebarSummaryInfo => sidebarSummaryInfo?.status,
);

export const selectAllSquads = squadSelectors.selectAll;

export const selectSquadById = optionalFriendly(squadSelectors.selectById);

const selectSquadEntities = partialRecord(squadSelectors.selectEntities);
const isSquad = (so: Optional<SquadOverview>): so is SquadOverview => !!so;

export const selectUserIdsBySquadIds = createSelector(
    [selectSquadEntities, (_, ids: d.SquadId[]) => ids],
    (squadRecord, ids) =>
        ids.map(id => squadRecord[id])
            .filter(isSquad)
            .flatMap(so => so.userIds)
            .removeDuplicates(),
    memoizeOptions.weakMapShallow,
);

const selectUserIdsBySquadId = createAppSelector(
    [selectSquadById],
    so => so?.userIds ?? [],
    memoizeOptions.weakMapShallow,
);

const squadUsersAdjustments = { sort: userSortByNickname };
const selectSortedUsersBySquadId = (state: RootState, id: Optional<d.SquadId>) => {
    const userIds = selectUserIdsBySquadId(state, id);
    return selectUsers(state, userIds, squadUsersAdjustments);
};

export const selectSortedUserIdsBySquadIdPair = createSelectorPair(
    selectUserIdsBySquadId,
    createAppSelector(
        [selectSortedUsersBySquadId],
        uos => uos.map(uo => uo.id),
        memoizeOptions.weakMapShallow,
    ),
);

export const selectSquads = createAppSelector(
    [selectSquadEntities, (_, ids: d.SquadId[]) => ids],
    (squadMap, ids) => ids.map(id => squadMap[id]).filter(isSquad),
    memoizeOptions.weakMapShallow,
);

const selectOrgEntities = partialRecord(orgSelectors.selectEntities);
const isOrg = (oo: Optional<OrgOverview>): oo is OrgOverview => !!oo;

export const selectOrgs = createAppSelector(
    [selectOrgEntities, (_, ids: d.OrgId[]) => ids],
    (orgMap, ids) => ids.map(id => orgMap[id]).filter(isOrg),
    memoizeOptions.weakMapShallow,
);

export const selectSquadmateIds = shallowArrayEqualSelectorCreator(
    [selectSquadEntities],
    squadMap =>
        Object.values(squadMap)
            .flatMap(so => so?.userIds ?? [])
            .removeDuplicates(),
);

export const selectSquadIdsContainingUserIds = createAppSelector(
    [selectAllSquads, (_, userIds: d.UserId[]) => userIds],
    (squads, userIds) =>
        squads.filter(so => userIds.every(userId => so.userIds.includes(userId)))
            .map(so => so.id),
    memoizeOptions.weakMapShallow,
);

const squadArraysAreEqual = function (a: SquadOverview[], b: SquadOverview[]): boolean {
    if (a.length != b.length) return false;
    return a.every((v, i) =>
        v.id === b[i].id && v.name === b[i].name && arraysAreEqual(v.userIds, b[i].userIds)
    );
};

const selectSquadEntitiesByIds = createAppSelector(
    [selectSquadEntities, (_, ids: d.SquadId[]) => ids],
    (squadMap, ids) => ids.map(id => squadMap[id]).filter(isSquad),
    {
        ...memoizeOptions.weakMapShallow,
        memoizeOptions: {
            resultEqualityCheck: squadArraysAreEqual,
        },
    },
);

function sortSquadOverviewsByAudienceSize(a: SquadOverview, b: SquadOverview): number {
    return b.userIds.length - a.userIds.length;
}

export const selectKnownSquadNamesSortedByAudienceSize = shallowArrayEqualSelectorCreator(
    [selectSquadEntitiesByIds],
    squads =>
        squads
            .sort(sortSquadOverviewsByAudienceSize)
            .map(so => so.name),
);

const squadNames = shallowArrayEqualSelectorCreator(
    [selectSquadEntities, (_, ids: d.SquadId[]) => ids],
    (squadMap, ids) =>
        ids.map(id => squadMap[id])
            .filter(isSquad)
            .map(so => so.name),
);

export const selectKnownSquadNames = shallowArrayEqualSelectorCreator(
    [squadNames],
    (names: Optional<string>[]) => names.filter(n => n) as string[],
);

const observationSetSelector = createAppSelector(
    [selectors.observers],
    obs => obs && new Set(obs),
    memoizeOptions.weakMapShallow,
);

const setIsNotUndefined = <T>(s: Set<T> | undefined): s is Set<T> => !!s;

export const selectObservationsForSquadIds = createAppSelector(
    [state => state, (_, ids: d.SquadId[]) => ids],
    (state, ids) => {
        const sets = ids.map(id => observationSetSelector(state, id));
        return setUnion(...sets.filter(setIsNotUndefined));
    },
    memoizeOptions.weakMapShallow,
);

export const startSquadObserversInterestStream = (
    appendListener: AppAppendListener = appAppendListener,
) => startManagingInterest(
    selectSquadsInterest,
    subSquadObserversThunk,
    "squadObserversManager",
    appendListener,
);

export const selectSquadLatestActivity = createAppSelector(
    [selectors.squadActivity],
    activity => activity?.latestActivityAt,
);

export const selectSquadDominantPresenceIcon = createAppSelector(
    [selectors.squadActivity],
    activity => getPresenceIcon(activity?.dominantPresence),
);

export const reducer = squadSlice.reducer;

// Persistence.

const stores = {
    orgs: idbStore<d.OrgId, OrgOverview>("orgs-2", 17),
    _orgs1: idbStore<d.OrgId, migrateV17.OrgOverview>("orgs", 1, 16),
    squads: idbStore<d.SquadId, SquadOverview>("squads", 1),
    orgMembership: idbStore<d.OrgId, true>("org-membership", 16),
    squadMembership: idbStore<d.SquadId, true>("squad-membership", 16),
    _squadMembership1: localStorageStore<d.SquadId[]>("memberships/squad", 1, 15),
    _squadActivities1: idbStore<d.SquadId, d.Timestamp>("squad-activity-times", 24, 25),
    squadActivities: idbStore<d.SquadId, SquadActivity>("squad-activity", 26),
    readTimes: idbStore<string, number>("read-times", 21),
};

type SquadsUpdate =
    | IdbStoreUpdate<typeof stores.orgs>
    | IdbStoreUpdate<typeof stores.squads>
    | IdbStoreUpdateWithClear<typeof stores.orgMembership>
    | IdbStoreUpdateWithClear<typeof stores.squadMembership>
    | IdbStoreUpdate<typeof stores.squadActivities>
    | IdbStoreUpdate<typeof stores.readTimes>;

const persist = (
    previousState: SquadState,
    currentState: SquadState,
): PersistenceUpdate[] => {
    if (previousState.updates == currentState.updates) {
        return [];
    }

    return currentState.updates;
};

const hydrate = async ({ idb: conn }: HydrateArgs) => {
    if (!conn) return getInitialState();

    const orgs = await fetchIdbStore(conn, stores.orgs);
    const squads = await fetchIdbStore(conn, stores.squads);

    const userOrgs = await fetchIdbStore(conn, stores.orgMembership);
    const userSquads = await fetchIdbStore(conn, stores.squadMembership);

    const squadActivities = await fetchIdbStore(conn, stores.squadActivities);
    const readTimes = await fetchIdbStore(conn, stores.readTimes);

    return getInitialState({
        orgs,
        squads,
        userOrgs: TypedKeys(userOrgs),
        userSquads: TypedKeys(userSquads),
        squadActivities,
        readTimes,
    });
};

const migrate = async (tx: RWTransaction, oldVersion: number) => {
    if (oldVersion < 17) {
        await simpleIdbTranslate(
            tx,
            stores._orgs1,
            stores.orgs,
            migrateV17.translateOrgsOverview,
        );
    }
    if (oldVersion < 26) {
        await simpleIdbTranslate(
            tx,
            stores._squadActivities1,
            stores.squadActivities,
            migrateV26.translateLatestActivities,
        );
    }
};

export const persistor = {
    stores,
    hydrate,
    persist,
    migrate,
};
checkPersistor<SquadState>(persistor);
