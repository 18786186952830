import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import * as d from "@/domain/domain";
import log from "@/misc/log";
import * as clapi from "../api/client";

import { checkPersistor } from "../persist/shared";
import type { RootState } from "../store/types";
import { resetStore, selectCurrentUserId } from "./auth";
import { createProxiedAsyncThunk } from "./proxiedThunk";
import { createStreamingAsyncThunk } from "./streamingThunk";

export const sustainBrowserFocusReport = createStreamingAsyncThunk("calls/reportBrowserFocus", {
    rpc: ({ signal }) => clapi.sustainBrowserFocusReport(signal),
    parser: () => {},
});

export const sustainTypingActivityReport = createStreamingAsyncThunk("calls/reportTypingActivity", {
    rpc: ({ signal }) => clapi.sustainTypingActivityReport(signal),
    parser: () => {},
});

interface MakeSentryReportArgs {
    correlationId: d.SentryCorrelationId;
}
export const makeSentryReportThunk = createProxiedAsyncThunk(
    "connection/makeSentryReport",
    async (args: MakeSentryReportArgs, thunkAPI) => {
        try {
            return await clapi.makeSentryReport(args.correlationId);
        }
        catch (err) {
            log.error(`Exception when getting access token:`, err);
            return thunkAPI.rejectWithValue({
                error: "Failed to send sentry correlation ID to backend",
            });
        }
    },
);

export enum ConnectionStatus {
    // Waiting for auth credentials (new or renewed).
    AwaitAuth,
    // Should attempt to connect.
    ShouldConnect,
    // Websocket is connected.
    Connected,
    // Waiting in backoff for reconnection.
    AwaitReconnect,
    // Auth error on websocket.
    AuthFailure,
}

export interface ConnectionState {
    /** Whether the tab is visible, in-focus and recently interacted with.
     *
     * This is a *local* property to the tab.
     * The overseer has a meta-interest key for the union across all tabs.
     */
    active: boolean;
    // Whether the client is connected to the internet
    online: boolean;
    // Whether the connection has timed out
    hasHealthCheckFailed: boolean;
    // The status of the client's connection to our backend
    status: ConnectionStatus;
}

const getInitialState = (props?: Partial<ConnectionState>): ConnectionState => ({
    active: true,
    online: navigator.onLine,
    status: props?.status ?? ConnectionStatus.AwaitAuth,
    hasHealthCheckFailed: false,
});

const connSlice = createSlice({
    name: "connection",
    initialState: getInitialState(),
    selectors: {
        active: state => state.active,
        online: state => state.online,
        status: state => state.status,
        hasHealthCheckFailed: state => state.hasHealthCheckFailed,
    },
    reducers: {
        updateActive: (state, { payload: active }: PayloadAction<boolean>) => {
            state.active = active;
        },
        updateOnline: (state, { payload: online }: PayloadAction<boolean>) => {
            state.online = online;
        },
        updateStatus: (state, action: PayloadAction<ConnectionStatus>) => {
            state.status = action.payload;
        },
        updateHasHealthCheckFailed: (
            state,
            { payload: hasHealthCheckFailed }: PayloadAction<boolean>,
        ) => {
            state.hasHealthCheckFailed = hasHealthCheckFailed;
        },
    },
    extraReducers: builder => {
        builder.addCase(resetStore, _state => {
            return getInitialState();
        });
    },
});

const connSelectors = connSlice.getSelectors((state: RootState) => state.connection);
export const {
    active: selectActiveStatus,
    online: selectOnlineStatus,
    status: selectConnectionStatus,
    hasHealthCheckFailed: selectHasHealthCheckFailedStatus,
} = connSelectors;

const statusIs = (status: ConnectionStatus) => (state: RootState) =>
    connSelectors.status(state) === status;
export const selectConnected = statusIs(ConnectionStatus.Connected);
export const selectNotConnected = (state: RootState) => !selectConnected(state);

export const {
    updateActive: updateActiveStatus,
    updateOnline: updateOnlineStatus,
    updateStatus: updateConnectionStatus,
    updateHasHealthCheckFailed: updateHasHealthCheckFailedStatus,
} = connSlice.actions;

export const selectConnectedWithUserId = (state: RootState) => {
    return selectConnected(state) && selectCurrentUserId(state) !== undefined;
};
export const selectNotConnectedWithUserId = (state: RootState) => {
    return !selectConnectedWithUserId(state);
};

// For test use only.
export const updateTestConnected = (connected: boolean) =>
    updateConnectionStatus(connected ? ConnectionStatus.Connected : ConnectionStatus.AwaitAuth);

export const reducer = connSlice.reducer;

// Persistence.

export const persistor = {
    stores: {},
};
checkPersistor<ConnectionState>(persistor);
