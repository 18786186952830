import { App, URLOpenListenerEvent } from "@capacitor/app";
import { ActionPerformed, PushNotifications } from "@capacitor/push-notifications";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as d from "@/domain/domain";
import { reportNotificationTokenThunk } from "@/features/notifications";
import useViewStackNavigate from "@/hooks/navigation/useViewStackNavigate";
import useBackoff from "@/hooks/useBackoff";
import { useConnectedEffect } from "@/hooks/useConnectedEffect";
import { isNativeAndroidPlatform, isNativePlatform } from "@/misc/capacitor";
import log from "@/misc/log";
import { useAppDispatch } from "@/store/redux";

const createDefaultNotificationChannelIfAndroid = async () => {
    if (isNativeAndroidPlatform) {
        await PushNotifications.createChannel({
            id: "beyond_default_notification_channel", // Matches the default channel specified in AndroidManifest.xml.
            name: "Bond",
            description: "The primary notification channel for the Bond app.",
            sound: "notification.mp3", // The file name of a sound file should be specified relative to the android app `res/raw` directory.
            visibility: 0, // Private level: Show this notification on all lockscreens, but conceal sensitive or private information on secure lockscreens.
            lights: true,
            lightColor: "#6A87D0", // Matches the "blue" token in _color.scss.
            vibration: true,
        });
    }
};

const registerNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === "prompt") {
        permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== "granted") {
        log.warn("User denied notification permissions!");
        return;
    }

    await PushNotifications.register();
};

// TODO: move to a listener-middleware
function NotificationTokenManager(): React.JSX.Element {
    const dispatch = useAppDispatch();

    const [token, setToken] = useState("");
    const [succeeded, setSucceeded] = useState(false);
    const { actionIsPermitted, actionBegin } = useBackoff();

    const { navigateToBond } = useViewStackNavigate();

    useConnectedEffect(() => {
        if (!token || !actionIsPermitted || succeeded) return;

        const backoffCallback = actionBegin();

        dispatch(reportNotificationTokenThunk({ token }))
            .unwrap()
            .then(
                () => {
                    backoffCallback(true);
                    setSucceeded(true);
                },
                () => backoffCallback(false),
            );
    }, [actionBegin, actionIsPermitted, dispatch, token, succeeded]);

    const addNotificationListeners = useCallback(async () => {
        await PushNotifications.addListener("registration", token => {
            setToken(token.value);
        });

        await PushNotifications.addListener("registrationError", err => {
            log.error("Registration error: ", err.error);
        });

        await PushNotifications.addListener("pushNotificationReceived", () => {
            log.info("Push notification received");
        });

        await PushNotifications.addListener(
            "pushNotificationActionPerformed",
            (action: ActionPerformed) => {
                log.info("Push notification action performed");
                try {
                    const bondId = (action.notification.data as { bondId: string; }).bondId;
                    if (!bondId) {
                        return;
                    }

                    const id = d.fromRawBondId(bondId);

                    navigateToBond({ id });
                }
                catch (e) {
                    log.error("Failed to navigate to bond from notification", e);
                }
            },
        );
    }, [navigateToBond]);

    const removeNotificationListeners = useCallback(async () => {
        await PushNotifications.removeAllListeners();
    }, []);

    useEffect(() => {
        createDefaultNotificationChannelIfAndroid()
            .then(addNotificationListeners)
            .then(registerNotifications)
            .catch(e => {
                log.error("An error occured while initialising native notifications", e);
            });

        return () => {
            removeNotificationListeners();
        };
    }, [addNotificationListeners, removeNotificationListeners]);

    // TODO: Deep linking will continue to fail until morale improves.
    // I can't easily test this myself. I think it should work, if the path
    // is valid. But it would be good to guess a viewstack here, rather than
    // reactively in the ViewStackManager.
    const navigate = useNavigate();
    useEffect(() => {
        const listenerPromise = App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
            try {
                const url = new URL(event.url);
                const slug = url.pathname + url.search;
                if (slug) {
                    log.info("Navigating to a deep link", slug);
                    navigate(slug);
                }
            }
            catch (e) {
                log.error("An error occured while navigating to a deep link", e);
            }
        });

        return () => {
            listenerPromise.then(listener => listener.remove());
        };
    }, [navigate]);

    return <></>;
}

export default function NativeNotificationManager(): React.JSX.Element {
    return (
        <>
            {isNativePlatform && <NotificationTokenManager />}
        </>
    );
}
