import classNames from "classnames";

import { SidebarLinks } from "@/components/SidebarLinks";
import { SquadAndMyBondsSelector } from "@/components/SquadAndMyBondsSelector";
import { UserInfoAndOrgSelector } from "@/components/UserInfoAndOrgSelector";
import { useSidebar } from "@/context/SidebarContext";
import useCurrentBondIsLive from "@/hooks/useCurrentBondIsLive";
import useOutsideClick from "@/hooks/useOutsideClick";
import { isMobileBrowser } from "@/misc/mobile";

export function SidebarView(): React.JSX.Element {
    const { isOpen, toggleSidebar } = useSidebar();
    const isMobile = isMobileBrowser();

    const bondIsLive = useCurrentBondIsLive();

    const [sidebarPanelRef] = useOutsideClick<HTMLDivElement>(
        toggleSidebar,
        {
            active: isMobile && isOpen,
            stopOutsideClicks: true,
        },
    );

    const asideClasses = classNames("l-aside c-sidebar", {
        "l-aside--open": isOpen,
        "l-aside--closed": !isOpen,
        "l-aside--desktop": !isMobile,
    });

    const buttonClasses = classNames("c-btn-menu", {
        "c-btn-menu--desktop": !isMobile,
        "c-btn-menu--live": bondIsLive && !isOpen,
    });

    return (
        <div ref={sidebarPanelRef}>
            <button
                className={buttonClasses}
                title={isOpen ? "Hide sidebar" : "Show sidebar"}
                onClick={toggleSidebar}
            >
                {isOpen ? "Hide sidebar" : "Show sidebar"}
            </button>
            <aside className={asideClasses}>
                {!isMobile ? (
                    <div className="c-sidebar__content">
                        <div className="c-sidebar__top">
                            <SquadAndMyBondsSelector showMyBonds={true} location="isSidebar" />
                        </div>
                        <div className="c-sidebar__bottom">
                            <UserInfoAndOrgSelector />
                        </div>
                    </div>
                ) : (
                    <div className="c-sidebar__content">
                        <SidebarLinks />
                        <UserInfoAndOrgSelector />
                    </div>
                )}
            </aside>
        </div>
    );
}
