import * as d from "@/domain/domain";
import { Optional } from "@/misc/types";
import { PresenceMode } from "./presence";

export interface OrgOverview {
    id: d.OrgId;
    name: string;
    personal: boolean;
}

export interface SquadOverview {
    id: d.SquadId;
    name: string;
    userIds: Array<d.UserId>;
}

export interface SquadActivity {
    squadId: d.SquadId;
    latestActivityAt: d.Timestamp;
    dominantPresence: Optional<PresenceMode>;
}

export function getOrgName(org: Optional<OrgOverview>): string {
    return org ? (org.personal ? "" : org.name) : "Unknown org";
}
