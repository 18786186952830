import classNames from "classnames";
import { MouseEventHandler } from "react";

interface ComposerButtonProps {
    extraStyle: string;
    onClick: MouseEventHandler<HTMLElement>;
    content: string;
    disabled?: boolean;
    buttonRef?: React.RefObject<HTMLButtonElement>;
    title?: string;
}

export default function ComposerButton(props: ComposerButtonProps): React.JSX.Element {
    const classes = classNames("c-btn-composer", props.extraStyle);
    return (
        <div title={props.title}>
            <button
                className={classes}
                onClick={props.onClick}
                disabled={props.disabled}
                ref={props.buttonRef}
            >
                {props.content}
            </button>
        </div>
    );
}
