import classNames from "classnames";
import { useCallback, useLayoutEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import NewBondAudienceInput from "@/components/NewBondAudienceInput";
import NewBondMessageComposer from "@/components/NewBondMessageComposer";
import { clearDraftThunk } from "@/features/bondCreation";
import { selectDraftTargetForBondCreationFromCurrentView } from "@/features/filterPanel";
import { MetaInterestCounterKey, selectInterestInKey } from "@/features/interest";
import useViewStackNavigate from "@/hooks/navigation/useViewStackNavigate";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { useAppDispatch, useAppSelector } from "@/store/redux";

export const NewBondView = (): React.JSX.Element => {
    const dispatch = useAppDispatch();
    const { navigatePop } = useViewStackNavigate();

    const draftTarget = useAppSelector(selectDraftTargetForBondCreationFromCurrentView);

    const backAction = useCallback(() => {
        // TODO: "Are you sure?" check before clearing
        dispatch(clearDraftThunk(draftTarget));
        navigatePop();
    }, [dispatch, draftTarget, navigatePop]);

    // Navigate back when escape is pressed
    const hotkeyBlocked = useSelectorArgs(
        selectInterestInKey,
        MetaInterestCounterKey.BlockHotkey,
    );

    const hotkeyBlockedWrapper = useCallback(() => hotkeyBlocked, [hotkeyBlocked]);
    useHotkeys("esc", backAction, {
        ignoreEventWhen: hotkeyBlockedWrapper,
        enableOnFormTags: ["input", "textarea"],
    });

    // Animation experiment
    const [entering, setEntering] = useState(true);
    useLayoutEffect(() => setEntering(false), []);
    const classes = classNames("c-content c-content--new-bond", {
        "c-content--entering": entering,
    });

    return (
        <div className={classes}>
            <NewBondAudienceInput draftTarget={draftTarget} tabIndex={2} />
            <NewBondMessageComposer
                draftTarget={draftTarget}
                backAction={backAction}
                tabIndex={3}
            />
        </div>
    );
};
