import React from "react";

import { BondId } from "@/domain/domain";
import usePrivacyDomainString from "@/hooks/usePrivacyDomainString";
import { Optional } from "@/misc/types";

export default function BondPrivacyDomain(
    { id, includeInvited }: { id: Optional<BondId>; includeInvited: boolean; },
): React.JSX.Element {
    const privacyDomainString = usePrivacyDomainString({
        bondId: id,
        includeInvited: includeInvited,
    });
    return (
        <>
            {privacyDomainString}
        </>
    );
}
