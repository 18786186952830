import { subscribeToLoginEvents } from "@/auth/channel";
import { clearLoginSessionStorage, getLoginRedirectUri } from "@/auth/login";
import { AuthStatus, selectAuthStatus } from "@/features/auth";
import log from "@/misc/log";
import { useAppSelector } from "@/store/redux";
import { useEffect } from "react";

function reload() {
    const uri = getLoginRedirectUri();
    clearLoginSessionStorage();
    if (uri) window.location.href = uri;
    else window.location.href = "/bond";
}

export default function useRefreshOnLogin() {
    const authStatus = useAppSelector(selectAuthStatus);

    // It's a pretty funky state to find yourself on a login view but be
    // authenticated. This happens if the shared worker is running, and we
    // navigate a tab to a login view.
    useEffect(() => {
        if (authStatus === AuthStatus.Authenticated) {
            log.info("User is authenticated, redirecting to bond");
            reload();
        }
    }, [authStatus]);

    // If another tab logs in, then this should result in navigating back
    // to the right authenticated page.
    useEffect(() => {
        return subscribeToLoginEvents(() => {
            log.info("Received login event, reloading");
            reload();
        });
    }, []);
}
