// Prior art:
// https://github.com/clojure/clojurescript/blob/master/src/main/cljs/cljs/core.cljs
// https://github.com/mattbierner/hamt_plus
//
// Future work:
// - zodify
// - better/more `initial` argument forms - generator?
// - `update` function
// - derived indexes
// (oldTrie: ImmutableHAMT<Key, Val>, changes: ValueChanges<Key, Val>)
// to track changes to the derived values.

import { djb2Hash } from "@/misc/hash";
import { TypedEntries } from "@/misc/types";
import { range } from "@/misc/utils";

type Id = string;
type Hash = number;
type HashFn<K extends string = string> = (k: K) => Hash;

type ValueNode<K extends string, V> = {
    type: "value";
    id: Id;
    hash: Hash;
    key: K;
    value: V;
};

type CollisionNode<K extends string, V> = {
    type: "collision";
    id: Id;
    hash: Hash;
    values: Partial<Record<K, V>>;
};

type LeafNode<K extends string, V> = ValueNode<K, V> | CollisionNode<K, V>;

type BranchNode<K extends string, V> = {
    type: "branch";
    id: Id;
    children: Array<Node<K, V>>;
};

export type Node<K extends string, V> =
    | LeafNode<K, V>
    | BranchNode<K, V>
    | undefined;

const isValueNode = <K extends string, V>(node: Node<K, V>): node is ValueNode<K, V> =>
    node?.type === "value";
const isCollisionNode = <K extends string, V>(node: Node<K, V>): node is CollisionNode<K, V> =>
    node?.type === "collision";
const isBranchNode = <K extends string, V>(node: Node<K, V>): node is BranchNode<K, V> =>
    node?.type === "branch";

export type ImmutableHAMT<K extends string, V> = Readonly<{
    root: Node<K, V>;
    mutable: false;
}>;

// Exported for HAMT logger
export type TrieChanges<K extends string, V> = Map<Id, Node<K, V>>;
type ValueChanges<K extends string, V> = Map<K, V | undefined>;

const getCurrentNode = <K extends string, V>(
    id: Id,
    original: Node<K, V>,
    trieChanges: TrieChanges<K, V>,
): [Node<K, V>, boolean] => {
    const changedNode = trieChanges.get(id);

    // This checks for deleted nodes too!
    if (changedNode || trieChanges.has(id)) return [changedNode, true];

    return [original, false];
};

export type TransientHAMT<K extends string, V> = Readonly<{
    root: Node<K, V>;
    mutable: true;
    trieChanges: TrieChanges<K, V>;
    valueChanges: ValueChanges<K, V>;
    original: ImmutableHAMT<K, V>;
}>;

export type HAMT<K extends string, V> = ImmutableHAMT<K, V> | TransientHAMT<K, V>;

export const hamtIsImmutable = <K extends string, V, H extends HAMT<K, V> = HAMT<K, V>>(
    hamt: H | undefined,
): hamt is ImmutableHAMT<K, V> & H => !!hamt && !hamt.mutable;
export const hamtIsTransient = <K extends string, V, H extends HAMT<K, V> = HAMT<K, V>>(
    hamt: H | undefined,
): hamt is TransientHAMT<K, V> & H => !!hamt?.mutable;

export const BITS_PER_LEVEL = 5;
const BRANCHING_FACTOR = 1 << BITS_PER_LEVEL;
const MASK = BRANCHING_FACTOR - 1;
const idAlphabet = "0123456789abcdefghijklmnopqrstuv";
if (idAlphabet.length !== BRANCHING_FACTOR) {
    throw new Error(`HAMT id alphabet length must match branching factor`);
}

/** Get the index into a branch that this hash will be placed.
 * @param h hash value
 * @params l level into the HAMT
 * @return a number `0 <= n < BRANCHING_FACTOR`
 */
const getIndex = (h: Hash, level: number): number => (h >>> (level * BITS_PER_LEVEL)) & MASK;

const getIndexAtLevel = <K extends string, V>(
    node: LeafNode<K, V>,
    level: number,
): number => getIndex(node.hash, level);

/** Get the id of the node that this hash value will use.
 *
 * This is the least-significant `level * BITS_PER_LEVEL` bits of the hash.
 * Use this to give nodes a unique key with which they can be stored.
 *
 * @param h hash value
 * @params l level into the HAMT
 * @return a string suitable for usage as a storage key
 */
const getIdAtLevel = (h: Hash, level: number): Id =>
    range(level)
        .map(i => getIndex(h, i))
        .map(n => idAlphabet[n])
        .join("");

const updateIdForLevel = <K extends string, V>(
    node: LeafNode<K, V>,
    level: number,
): typeof node => ({
    ...node,
    id: getIdAtLevel(node.hash, level),
});

/** Create a new value node.
 * @param id the LSBs of the hashed key, for the required level in the HAMT
 * @param key the key for the value
 * @param value the value to store
 * @return a new value node
 */
const valueNode = <K extends string, V>(
    id: Id,
    key: K,
    value: V,
    hash: Hash,
): ValueNode<K, V> => ({
    type: "value",
    id,
    key,
    value,
    hash,
});

/** Create a new branch node.
 * @param id the LSBs of the hashed key, for the required level in the HAMT
 * @return a new branch node
 */
const branch = <K extends string, V>(id: Id): BranchNode<K, V> => ({
    type: "branch",
    id,
    children: new Array(BRANCHING_FACTOR),
});

/** Create a new collision node.
 * @param node original leaf node
 * @param key new key to add
 * @param value new value to add
 * @return a new collision node
 */
const collisionFromValue = <K extends string, V>(
    node: ValueNode<K, V>,
    key: K,
    value: V,
): CollisionNode<K, V> => ({
    type: "collision",
    id: node.id,
    hash: node.hash,
    values: {
        [node.key]: node.value,
        [key]: value,
    } as Partial<Record<K, V>>,
});

// Helper functions. Work recursively down the HAMT as necessary.

const getHelper = <K extends string, V>(
    node: Node<K, V>,
    hash: Hash,
    key: K,
    level: number = 0,
) => {
    if (!node) return undefined;

    if (isValueNode(node)) return node.key === key ? node.value : undefined;
    if (isCollisionNode(node)) return node.values[key];

    const index = getIndex(hash, level);

    return getHelper(node.children[index], hash, key, level + 1);
};

/** Add as many intermediate branch nodes as necessary to be able to separate
 * two value nodes.
 */
const valueNodeCombineResolver = <K extends string, V>(
    id: Id,
    level: number,
    oldNode: LeafNode<K, V>,
    newNode: LeafNode<K, V>,
    trieChanges: TrieChanges<K, V>,
): BranchNode<K, V> => {
    const newBranch = branch<K, V>(id);

    const indexOfOldNode = getIndexAtLevel(oldNode, level);
    const indexOfNewNode = getIndexAtLevel(newNode, level);

    if (indexOfOldNode === indexOfNewNode) {
        const nextBranch = valueNodeCombineResolver(
            getIdAtLevel(oldNode.hash, level + 1),
            level + 1,
            oldNode,
            newNode,
            trieChanges,
        );

        const index = getIndexAtLevel(oldNode, level);
        newBranch.children[index] = nextBranch;
    }
    else {
        oldNode = updateIdForLevel(oldNode, level + 1);
        newBranch.children[indexOfOldNode] = oldNode;

        newNode = updateIdForLevel(newNode, level + 1);
        newBranch.children[indexOfNewNode] = newNode;

        trieChanges.set(oldNode.id, oldNode);
        trieChanges.set(newNode.id, newNode);
    }

    trieChanges.set(id, newBranch);
    return newBranch;
};

const setHelper = <K extends string, V>(
    startingNode: Node<K, V>,
    hash: Hash,
    key: K,
    value: V,
    level: number,
    trieChanges: TrieChanges<K, V>,
    valueChanges: ValueChanges<K, V>,
): Node<K, V> => {
    const id = getIdAtLevel(hash, level);

    const [node, alreadyChanged] = getCurrentNode(id, startingNode, trieChanges);

    if (!node) {
        // Nothing already present - add to empty spot.
        valueChanges.set(key, value);

        const newNode = valueNode(id, key, value, hash);
        trieChanges.set(id, newNode);
        return newNode;
    }

    if (isValueNode(node)) {
        if (node.key === key && node.value === value) {
            // Key matches, and value unchanged.
            return node;
        }

        valueChanges.set(key, value);

        if (node.key === key) {
            // Replace current node.
            if (alreadyChanged) {
                node.value = value;
                return node;
            }
            else {
                const newNode: Node<K, V> = { ...node, value };
                trieChanges.set(id, newNode);
                return newNode;
            }
        }

        if (node.hash === hash) {
            // Total hash collision. Replace with CollisionNode.
            const collision = collisionFromValue(node, key, value);
            trieChanges.set(id, collision);
            return collision;
        }

        // Add new branches until nodes can be separated.
        // `id` is incorrect here, but we will recalculate it inside the
        // helper anyway.
        const newLeaf = valueNode(id, key, value, hash);
        return valueNodeCombineResolver(id, level, node, newLeaf, trieChanges);
    }

    if (isCollisionNode(node)) {
        if (Object.prototype.hasOwnProperty.call(node.values, key) && node.values[key] === value) {
            // Value unchanged.
            return node;
        }

        valueChanges.set(key, value);

        if (node.hash !== hash) {
            // New key has a different hash: make a new branch and push
            // the CollisionNode and the LeafNode onto it.
            const newLeaf = valueNode(id, key, value, hash);
            return valueNodeCombineResolver(id, level, node, newLeaf, trieChanges);
        }

        // `key` not present, but `hash` matches the CollisionNode's - add to `values`.
        if (alreadyChanged) {
            node.values[key] = value;
            return node;
        }
        else {
            const newNode = {
                ...node,
                values: { ...node.values, [key]: value },
            };
            trieChanges.set(id, newNode);
            return newNode;
        }
    }

    // Update to a pre-existing branch.
    const index = getIndex(hash, level);
    const newChild = setHelper(
        node.children[index],
        hash,
        key,
        value,
        level + 1,
        trieChanges,
        valueChanges,
    );

    if (newChild === node.children[index]) {
        // Child node is unchanged, so we can return unchanged.
        return node;
    }

    if (alreadyChanged) {
        node.children[index] = newChild;
        return node;
    }

    const newNode: Node<K, V> = { ...node, id, children: Array.from(node.children) };
    newNode.children[index] = newChild;

    trieChanges.set(id, newNode);

    return newNode;
};

/** Find if an array of children nodes is collapsible.
 *
 * @param children an array to test
 * @return if the array has 1 value node, its `index >= 0`; otherwise
 * a negative number:
 * -1 => all undefined (logic bug)
 * -2 => contains a branch node
 * -3 => 2 or more value nodes
 * `undefined` members.
 */
const findCollapsibleIndex = <K extends string, V>(children: Node<K, V>[]): number => {
    let index = -1;

    for (let i = 0; i < BRANCHING_FACTOR; i++) {
        const r = children[i];
        if (!r) continue;
        if (isBranchNode(r)) return -2;
        if (index !== -1) return -3;
        index = i;
    }

    return index;
};

const removeHelper = <K extends string, V>(
    startingNode: Node<K, V>,
    hash: Hash,
    key: K,
    level: number,
    trieChanges: TrieChanges<K, V>,
    valueChanges: ValueChanges<K, V>,
): Node<K, V> => {
    const id = getIdAtLevel(hash, level);

    const [node, alreadyChanged] = getCurrentNode(id, startingNode, trieChanges);

    if (!node) return undefined;

    if (isValueNode(node)) {
        if (node.key === key) {
            // Exact match - remove key.
            valueChanges.set(key, undefined);

            trieChanges.set(id, undefined);
            return undefined;
        }

        // Not a match - return unchanged.
        return node;
    }

    if (isCollisionNode(node)) {
        if (!Object.prototype.hasOwnProperty.call(node.values, key)) {
            // Key not found - return unchanged.
            return node;
        }

        valueChanges.set(key, undefined);

        const entries = Object.entries(node.values);
        switch (entries.length) {
            case 1:
                throw new Error(`Collision node found with only 1 value`);
            case 2: {
                // Convert back to a value node.
                const [otherKey, otherValue] = entries[0][0] === key ? entries[1] : entries[0];
                const newLeaf = valueNode<K, V>(id, otherKey as K, otherValue as V, hash);
                trieChanges.set(id, newLeaf);
                return newLeaf;
            }
            default: {
                // Update `values` to remove `key`.
                if (alreadyChanged) {
                    delete node.values[key];
                    return node;
                }
                else {
                    const newNode = { ...node, values: { ...node.values } };
                    delete newNode.values[key];
                    trieChanges.set(id, newNode);
                    return newNode;
                }
            }
        }
    }

    // Update pre-existing branch node.
    const index = getIndex(hash, level);
    const newChildren = alreadyChanged ? node.children : Array.from(node.children);

    const newChild = removeHelper(
        node.children[index],
        hash,
        key,
        level + 1,
        trieChanges,
        valueChanges,
    );
    if (newChild) {
        newChildren[index] = newChild;
    }
    else {
        delete newChildren[index];
    }

    // Collapse up a level if possible.
    const onlyChildIndex = findCollapsibleIndex(newChildren);
    if (onlyChildIndex >= 0) {
        const startingChildNode = newChildren[onlyChildIndex] as ValueNode<K, V>;

        const [childNode, childChanged] = getCurrentNode(
            startingChildNode.id,
            startingChildNode,
            trieChanges,
        );

        const newChildNode = childChanged ? childNode : { ...startingChildNode, id };

        if (childChanged && childNode) {
            childNode.id = id;
        }

        trieChanges.set(startingChildNode.id, undefined);
        trieChanges.set(id, newChildNode);

        return newChildNode;
    }

    if (alreadyChanged) {
        return node;
    }

    const newNode: Node<K, V> = { ...node, id, children: newChildren };
    trieChanges.set(id, newNode);
    return newNode;
};

type InitialData<A, K extends string = string, V = A> = Record<K, V> | Array<A>;
export type HAMTInitialDataProvider<A, K extends string = string, V = A> =
    | InitialData<A, K, V>
    | (() => InitialData<A, K, V>);

/** Type to track changes to help persist to external store.
 *
 * In future, to manage derived indexes. */
export type HAMTDelta<K extends string, V> = [
    k: K,
    v1: V | undefined,
    v2: V | undefined,
];

export type HAMTChangeProcessor<K extends string, V> =
    | ((k: K, v1: V | undefined, v2: V | undefined) => void)
    | HAMTDelta<K, V>[];

export interface HAMTOperations<Arg, K extends string = string, V = Arg> {
    /** Get an initial value for a HAMT, based on some initial contents.
     *
     * @param for HAMTs with a `keyFn`, an array of items to add; or an object
     * with the requested keys and values; or, a function returning one of
     * these things.
     * @return a HAMT containing the passed-in data
     */
    initial: (data?: HAMTInitialDataProvider<Arg, K>) => ImmutableHAMT<K, V>;

    /** Update a HAMT with a given value, using the keyFn.
     *
     * @param hamt the HAMT
     * @param value the value to set
     * @param changes optional array/function to process deltas of the changes to
     * stored values, if passed an ImmutableHAMT
     * @return an updated HAMT with the new value set
     */
    set: <H extends HAMT<K, V>>(
        hamt: H,
        value: Arg,
        changes?: HAMTChangeProcessor<K, V>,
    ) => H;

    /** Update a HAMT with a given value, manually specifying the key.
     *
     * @param hamt the HAMT
     * @param value the value to set
     * @param key the key to use
     * @param changes optional array/function to process deltas of the changes to
     * stored values, if passed an ImmutableHAMT
     * @return an updated HAMT with the new value set
     */
    setByKey: <H extends HAMT<K, V>>(
        hamt: H,
        key: K,
        value: Arg,
        changes?: HAMTChangeProcessor<K, V>,
    ) => H;

    /** Update a HAMT with the given objects, using the keyFn.
     *
     * @param hamt the HAMT
     * @param values the values to set
     * @param changes optional array/function to process deltas of the changes to
     * stored values, if passed an ImmutableHAMT
     * @return an updated HAMT with the new value set
     */
    setMany: <H extends HAMT<K, V>>(
        hamt: H,
        values: Arg[],
        changes?: HAMTChangeProcessor<K, V>,
    ) => H;

    /** Remove a key and its associated value from the HAMT.
     *
     * @param hamt the HAMT
     * @param key the key to remove
     * @param changes optional array/function to process deltas of the changes to
     * stored values, if passed an ImmutableHAMT
     * @return an updated HAMT with the key removed
     */
    remove: <H extends HAMT<K, V>>(
        hamt: H,
        key: K,
        changes?: HAMTChangeProcessor<K, V>,
    ) => H;

    /** Remove multiple keys and associated values from a HAMT.
     *
     * @param hamt the HAMT
     * @param values the keys to remove
     * @param changes optional array/function to process deltas of the changes to
     * stored values, if passed an ImmutableHAMT
     * @return an updated HAMT with keys removed
     */
    removeMany: <H extends HAMT<K, V>>(
        hamt: H,
        keys: K[],
        changes?: HAMTChangeProcessor<K, V>,
    ) => H;

    /** Remove all keys and associated values from a HAMT.
     *
     * n.b. if applying this to a transient HAMT, all previous structure will
     * be forgotten; it is expected that anything persisted against the HAMT
     * will be cleared at a suitable synchronisation point.
     *
     * @param hamt the HAMT
     * @param changes optional array/function to process deltas of the changes to
     * stored values, if passed an ImmutableHAMT
     * @return an updated HAMT with keys removed
     */
    removeAll: <H extends HAMT<K, V>>(
        hamt: H,
        changes?: HAMTChangeProcessor<K, V>,
    ) => H;

    /** Make a transient version of the HAMT.
     *
     * This allows applying lots of operations to the HAMT, without the
     * overhead of making an immutable version after each operation.
     *
     * Use `toImmutable()` to return you an immutable reference when
     * you're finished.
     *
     * @param immutableHamt the HAMT to make immutable
     * @return a transient HAMT
     */
    toTransient: <H extends ImmutableHAMT<K, V>>(immutableHamt: H) => TransientHAMT<K, V>;

    /** Produce an immutable reference to a transient HAMT.
     *
     * @param transientHamt the HAMT to make immutable
     * @param changes optional array/function to process deltas of the changes
     * since the HAMT was made transient
     * @return an immutable HAMT
     */
    toImmutable: <H extends TransientHAMT<K, V>>(
        transientHamt: H,
        changes?: HAMTChangeProcessor<K, V>,
    ) => ImmutableHAMT<K, V>;
}

/** Get a value from a HAMT, given the key used to `set` it.
 *
 * @param hamt the HAMT
 * @param key the key used to `set` the value
 * @param hashFn hash function to use to derive a hash, optional with default
 * @return the value, or `undefined` if not found
 */
export const hamtGet = <K extends string, V>(
    hamt: HAMT<K, V>,
    key: K,
    hashFn: HashFn = djb2Hash,
): V | undefined => getHelper(hamt.root, hashFn(key), key, 0);

/** Get many values stored in a HAMT, give the keys used to `set` them.
 *
 * @param hamt the HAMT
 * @param keys the keys to lookup
 * @param hashFn optional override for the hash function
 * @return the values, with `undefined` in-place if the key not found
 */
export const hamtGetMany = <K extends string, V>(
    hamt: HAMT<K, V>,
    keys: K[],
    hashFn: HashFn = djb2Hash,
): (V | undefined)[] => keys.map(k => getHelper(hamt.root, hashFn(k), k, 0));

/** Infer a HAMT type from the value of the functions created by createHAMT.
 *
 * Warning: this seems to be very taxing for the tsc LSP. It is perhaps
 * advisable to avoid using it. */
export type HAMTFromOperations<Ops> = Ops extends HAMTOperations<any, infer K, infer V> ?
    ImmutableHAMT<K, V>
    : never;

const genericHAMT = <Arg, K extends string = string, V = Arg>(
    keyFn: ((a: Arg) => K) | undefined,
    valFn: (a: Arg) => V,
    hashFn: HashFn = djb2Hash,
): HAMTOperations<Arg, K, V> => {
    const tryToReuseNode = <H extends HAMT<K, V>>(
        original: H,
        newRoot: Node<K, V>,
    ): H => {
        if (original.root === newRoot) return original;

        return { ...original, root: newRoot };
    };

    const processChanges = <K extends string, V>(
        original: Node<K, V>,
        valueChanges: ValueChanges<K, V>,
        changes: HAMTChangeProcessor<K, V>,
    ) => {
        if (typeof changes !== "function" && !Array.isArray(changes)) {
            throw new Error(`Invalid argument 'changes': ${typeof changes}`);
        }

        for (const [k, v] of valueChanges.entries()) {
            const originalV = getHelper(original, hashFn(k), k, 0);
            if (originalV === v) continue;

            if (typeof changes === "function") {
                changes(k, originalV, v);
            }
            else {
                changes.push([k, originalV, v]);
            }
        }
    };

    const freezeTrieChanges = (trieChanges: TrieChanges<K, V>) => {
        for (const o of trieChanges.values()) {
            if (!o) continue;

            Object.freeze(o);
            if (isValueNode(o)) Object.freeze(o.value);
            else if (isCollisionNode(o)) {
                Object.freeze(o.values);
                Object.values(o.values).map(Object.freeze);
            }
            else Object.freeze(o.children);
        }
    };

    const setByKey = <H extends HAMT<K, V>>(
        hamt: H,
        key: K,
        value: Arg,
        changes?: HAMTChangeProcessor<K, V>,
    ): H => {
        const trieChanges: TrieChanges<K, V> = hamt.mutable ? hamt.trieChanges : new Map();
        const valueChanges: ValueChanges<K, V> = hamt.mutable ? hamt.valueChanges : new Map();

        const val = Object.freeze(valFn(value));

        const newRoot = setHelper(hamt.root, hashFn(key), key, val, 0, trieChanges, valueChanges);

        if (!hamt.mutable) {
            if (changes) {
                processChanges(hamt.root, valueChanges, changes);
            }

            freezeTrieChanges(trieChanges);
        }

        return tryToReuseNode(hamt, newRoot);
    };

    const set = <H extends HAMT<K, V>>(
        hamt: H,
        value: Arg,
        changes?: HAMTChangeProcessor<K, V>,
    ): H => {
        if (!keyFn) throw new Error(`HAMT.set requires a keyFn`);
        const key = keyFn(value);
        return setByKey(hamt, key, value, changes);
    };

    const remove = <H extends HAMT<K, V>>(
        hamt: H,
        key: K,
        changes?: HAMTChangeProcessor<K, V>,
    ): H => {
        const trieChanges: TrieChanges<K, V> = hamt.mutable ? hamt.trieChanges : new Map();
        const valueChanges: ValueChanges<K, V> = hamt.mutable ? hamt.valueChanges : new Map();

        const newRoot = removeHelper(hamt.root, hashFn(key), key, 0, trieChanges, valueChanges);

        if (!hamt.mutable) {
            if (changes) {
                processChanges(hamt.root, valueChanges, changes);
            }

            freezeTrieChanges(trieChanges);
        }

        return tryToReuseNode(hamt, newRoot);
    };

    const toTransient = <H extends ImmutableHAMT<K, V>>(
        hamt: H,
    ): TransientHAMT<K, V> => ({
        root: hamt.root,
        mutable: true,
        trieChanges: new Map(),
        valueChanges: new Map(),
        original: hamt,
    });

    const toImmutable = <H extends TransientHAMT<K, V>>(
        hamt: H,
        changes?: HAMTChangeProcessor<K, V>,
    ): ImmutableHAMT<K, V> => {
        if (changes) {
            processChanges(hamt.original.root, hamt.valueChanges, changes);
        }

        freezeTrieChanges(hamt.trieChanges);

        return Object.freeze({
            root: hamt.root,
            mutable: false,
        });
    };

    const initial = (data?: HAMTInitialDataProvider<Arg, K>): ImmutableHAMT<K, V> => {
        const empty: HAMT<K, V> = Object.freeze({ root: undefined, mutable: false });

        if (!data) return empty;

        data = typeof data === "function" ? data() : data;
        let transient = toTransient(empty);

        if (Array.isArray(data)) {
            if (!keyFn) throw new Error(`HAMT.initial(array) requires a keyFn`);
            transient = data.reduce((s, i) => set(s, i), transient);
        }
        else if (typeof data === "object") {
            transient = TypedEntries(data).reduce(
                (s, [k, v]) => setByKey(s, k, v),
                transient,
            );
        }

        return toImmutable(transient);
    };

    return {
        initial,

        set,
        setByKey,
        setMany: <H extends HAMT<K, V>>(
            hamt: H,
            values: Arg[],
            changes?: HAMTChangeProcessor<K, V>,
        ): H => {
            if (hamtIsTransient(hamt)) {
                return values.reduce((h, a) => set(h, a), hamt);
            }
            else if (hamtIsImmutable<K, V, H>(hamt)) {
                const initial = toTransient(hamt);
                const final = values.reduce((h, a) => set(h, a), initial);
                return toImmutable(final, changes) as H;
            }
            else {
                throw new Error(`setMany on HAMT neither mutable nor immutable`);
            }
        },
        remove,
        removeMany: <H extends HAMT<K, V>>(
            hamt: H,
            keys: K[],
            changes?: HAMTChangeProcessor<K, V>,
        ): H => {
            if (hamtIsTransient(hamt)) {
                return keys.reduce((h, a) => remove(h, a), hamt);
            }
            else if (hamtIsImmutable<K, V, H>(hamt)) {
                const initial = toTransient(hamt);
                const final = keys.reduce((h, a) => remove(h, a), initial);
                return toImmutable(final, changes) as H;
            }
            else {
                throw new Error(`setMany on HAMT neither mutable nor immutable`);
            }
        },
        removeAll: <H extends HAMT<K, V>>(
            hamt: H,
            changes?: HAMTChangeProcessor<K, V>,
        ): H => {
            if (hamtIsTransient(hamt)) {
                // API allows forgetting `trieChanges` and `valueChanges` here.
                return toTransient(initial()) as H;
            }
            else if (hamtIsImmutable<K, V, H>(hamt)) {
                const valueChanges: ValueChanges<K, V> = new Map();

                for (const k of hamtKeys(hamt)) {
                    valueChanges.set(k, undefined);
                }

                if (changes) {
                    processChanges(hamt.root, valueChanges, changes);
                }

                return initial() as H;
            }
            else {
                throw new Error(`setMany on HAMT neither mutable nor immutable`);
            }
        },

        toTransient,
        toImmutable,
    };
};

/** Create a set of functions for managing a hash-array mapped trie (HAMT).
 *
 * This is a persistent, serialisable datastructure that implements an API
 * similar to that of `Map`.
 *
 * "Persistent" means that a given reference to a HAMT is immutable. "Changes"
 * to the HAMT result in new references to HAMTs being created, which contain
 * the requested differences. The original will *always* remain unchanged.
 *
 * This implementation allows using "transient" versions of the HAMT, which are
 * by themselves *not* immutable, but allow the application of many consecutive
 * operations to a HAMT whilst avoiding the overhead of making each iterim
 * configuration fully immutable. You should always return to an immutable
 * form once you have done the work you wish to do.
 *
 * This implementation can also track the changes made over operations. The aim
 * of this is to allow easy determination of what changes should be pushed to
 * disk.
 *
 * The operations that "change" the HAMT all allow passing an array or a
 * function, into which value-level changes are written or called with. Changes
 * are only written for operations on an immutable HAMT, or in the final
 * `toImmutable()` call on a transient HAMT.
 *
 * There are different modes to using a HAMT:
 * 1. as a map of objects, keyed over one of their properties
 * 3. as a set
 * 2. as a key/value store (a la Record)
 * 4. as reverse-lookup over a collection of objects
 *
 * For 1, specify a `keyFn` to take the values you wish to store and find the
 * key on which they will be indexed.
 *
 * For 2 and 3, no `keyFn` needs to be specified, but you must use the
 * `setByKey` function.
 *
 * For 4, both a `keyFn` and a `valFn` need to be specified.
 *
 * It is possible, though probably not wise, to mix and match calls to `set`
 * and `setByKey`.
 *
 * @param keyFn a function to "choose" the key for indexing the stored values
 * @param hashFn a hashing function on the string-y key. Hash collisions are
 * acceptable (but obviously undesirable!) - all values associated to a unique
 * key are retained.
 * @return a collection of functions for working with a HAMT
 */
export const createHAMT = <A, K extends string = string>(
    keyFn: (a: A) => K,
    hashFn: HashFn = djb2Hash,
) => genericHAMT<A, K, A>(keyFn, a => a, hashFn);

/** Like `createHAMT`, but for maintaining just a set of string-y values. */
export const createHAMTSet = <K extends string = string>(
    hashFn: HashFn = djb2Hash,
) => genericHAMT<K, K, K>(k => k, v => v, hashFn);

/** Like `createHAMT`, but for storing values whose corresponding key
 * is not already contained on the value itself. */
export const createHAMTRecord = <K extends string, V>(
    hashFn: HashFn = djb2Hash,
) => genericHAMT<V, K, V>(undefined, a => a, hashFn);

/** Like `createHAMT`, but for maintaining an index over 2 properties on an
 * object. */
export const createHAMTIndex = <A, K extends string, V>(
    keyFn: (a: A) => K,
    valFn: (a: A) => V,
    hashFn: HashFn = djb2Hash,
) => genericHAMT<A, K, V>(keyFn, valFn, hashFn);

function* descend<Key extends string, Val, T>(
    node: Node<Key, Val>,
    cb: (node: LeafNode<Key, Val>) => T[],
): IterableIterator<T> {
    if (!node) return;
    else if (isValueNode(node) || isCollisionNode(node)) {
        yield* cb(node);
    }
    else {
        for (const child of node.children) yield* descend(child, cb);
    }
}

export const hamtKeys = <Key extends string>(
    hamt: ImmutableHAMT<Key, unknown>,
): IterableIterator<Key> =>
    descend(hamt.root, node => {
        if (isValueNode(node)) return [node.key as Key];
        else return Object.keys(node.values) as Key[];
    });

export const hamtValues = <Val>(
    hamt: ImmutableHAMT<string, Val>,
): IterableIterator<Val> =>
    descend(hamt.root, node => {
        if (isValueNode(node)) return [node.value as Val];
        else return Object.values(node.values) as Val[];
    });

export const hamtEntries = <Key extends string, Val>(
    hamt: ImmutableHAMT<Key, Val>,
): IterableIterator<[Key, Val]> =>
    descend(hamt.root, node => {
        if (isValueNode(node)) return [[node.key as Key, node.value as Val]];
        else return Object.entries(node.values) as [Key, Val][];
    });
