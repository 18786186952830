import * as bonds_pb from "../../gen/proto/bonds/bonds_pb";
import * as chats_pb from "../../gen/proto/chats/chats_pb";

import bondService from "@/api/bonds";
import chatService from "@/api/chats";
import { streamHandler } from "@/api/stream";
import {
    bigintToNumber,
    fromProtoChannelId,
    pbChannelId,
    pbUserId,
    toProtoBondSet,
} from "@/api/util";
import { SequenceNumberOrDeleted } from "@/domain/channels";
import * as d from "@/domain/domain";
import { translateAsyncIterable } from "@/misc/iterable";
import { Diff } from "@/misc/types";

function msgSeqNumsParser(res: bonds_pb.SubBondMsgSeqNumsResponse): SequenceNumberOrDeleted {
    switch (res.response?.sequenceNumberOrDeleted.case) {
        case "sequenceNumber":
            return {
                case: "sequenceNumber",
                value: {
                    channelId: fromProtoChannelId(
                        res.response.sequenceNumberOrDeleted.value.channelId,
                    ),
                    sequenceNumber: bigintToNumber(
                        res.response.sequenceNumberOrDeleted.value.maxSequenceNumber,
                    ),
                },
            };
        case "deletedId":
            return {
                case: "deletedId",
                value: fromProtoChannelId(res.response.sequenceNumberOrDeleted.value),
            };
        default:
            throw new Error(
                `unknown sequenceNumberOrDeleted case ${res.response?.sequenceNumberOrDeleted?.case}`,
            );
    }
}

const translateMsgSeqNumsDiff = (strUserId: d.UserId) => {
    const userId = pbUserId(strUserId);

    return async function* (v: Diff<d.BondId>) {
        if (v.added?.length) {
            yield new bonds_pb.SubBondMsgSeqNumsRequest({
                userId,
                addToSub: true,
                bondIds: toProtoBondSet(v.added),
            });
        }
        if (v.removed?.length) {
            yield new bonds_pb.SubBondMsgSeqNumsRequest({
                userId,
                addToSub: false,
                bondIds: toProtoBondSet(v.removed),
            });
        }
    };
};

interface SubBondMsgSeqNumsArgs {
    userId: d.UserId;
}

export async function* subBondMsgSeqNums(
    reqStream: AsyncIterableIterator<Diff<d.BondId>>,
    { userId }: SubBondMsgSeqNumsArgs,
    signal: AbortSignal,
) {
    const translation = translateAsyncIterable(reqStream, translateMsgSeqNumsDiff(userId));

    const stream = bondService.subBondMsgSeqNums(translation, { signal });

    yield* streamHandler(stream, msgSeqNumsParser, subBondMsgSeqNums.name);
}

interface MarkReadArgs {
    userId: d.UserId;
    channelId: d.ChannelId;
    sequenceNumber: number;
}

export async function markRead(args: MarkReadArgs) {
    const req = new chats_pb.SetUserReadSequenceNumberRequest({
        userId: pbUserId(args.userId),
        channelId: pbChannelId(args.channelId),
        sequenceNumber: BigInt(args.sequenceNumber),
    });

    return chatService.setUserReadSequenceNumber(req);
}
