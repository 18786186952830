import {
    createEntityAdapter,
    createSelector,
    createSlice,
    current,
    EntityState,
    PayloadAction,
} from "@reduxjs/toolkit";

import {
    completeAvatarImage,
    CompleteAvatarImageArgs,
    createAvatarImage,
    CreateAvatarImageArgs,
    fetchAvatarBlobDownloadUrl,
    fetchContactsBook,
    fetchPersonByEmail,
    FetchPersonByEmailArgs,
    isValidAvatarMimeType,
    PersonDeletedDU,
    PersonOverviewDU,
    PersonOverviewOrDeleted,
    subPersons,
    subUsers,
    UserDeletedDU,
    UserOverviewDU,
    UserOverviewOrDeleted,
} from "@/api/users";
import * as d from "@/domain/domain";
import * as migrateV22 from "@/domain/migrate/v22";
import {
    AvatarBlob,
    PersonOverview,
    UploadableAvatarImage,
    UserFilter,
    UserOverview,
    UserSort,
} from "@/domain/users";
import { createHAMT, hamtGet, ImmutableHAMT } from "@/ds/hamt";
import { resetStore, selectCurrentUserId } from "@/features/auth";
import {
    selectPersonsInterest,
    selectUsersInterest,
    startManagingInterest,
} from "@/features/interest";
import { createProxiedAsyncThunk } from "@/features/proxiedThunk";
import { memoizeOptions } from "@/features/selectors";
import { createStreamingAsyncThunk } from "@/features/streamingThunk";
import { unaryThunkHandler } from "@/features/thunk";
import { Diff, Optional, optionalFriendly, partialRecord } from "@/misc/types";
import { separateDiscriminatedUnion } from "@/misc/utils";
import { checkPersistor, fetchIdbStore, idbStore, simpleIdbTranslate } from "@/persist/shared";
import type {
    HydrateArgs,
    IdbStoreUpdate,
    PersistenceUpdate,
    RWTransaction,
} from "@/persist/types";
import { valueUpdateToStoreUpdate } from "@/persist/updates";
import { type AppAppendListener, appAppendListener } from "@/store/middleware";
import { createAppSelector } from "@/store/redux";
import type { RootState } from "@/store/types";

const usersAdapter = createEntityAdapter({
    selectId: (user: UserOverview) => user.id,
});

export const subUsersThunk = createStreamingAsyncThunk<
    AsyncIterableIterator<Diff<d.UserId>>,
    UserOverviewOrDeleted
>(
    "users/subUsers",
    {
        rpc: ({ arg, signal }) => subUsers(arg, signal),
        parser: ({ thunkAPI, content }) => {
            const [deleted, overviews] = separateDiscriminatedUnion<
                UserDeletedDU,
                UserOverviewDU
            >(msg => msg.case === "deletedId", content);

            if (deleted.length > 0) {
                const ids = deleted.map(id => id.value);
                thunkAPI.dispatch(deleteUsers(ids));
            }

            if (overviews.length > 0) {
                const users = overviews.map(uo => uo.value).filterLatest(uo => uo.id);
                thunkAPI.dispatch(updateUsers(users));
            }
        },
    },
);
export const startUserInterestStream = (
    appendListener: AppAppendListener = appAppendListener,
) => startManagingInterest(selectUsersInterest, subUsersThunk, "subUsers", appendListener);

const personsAdapter = createEntityAdapter({
    selectId: (person: PersonOverview) => person.personId,
});

export const subPersonsThunk = createStreamingAsyncThunk<
    AsyncIterableIterator<Diff<d.PersonId>>,
    PersonOverviewOrDeleted
>(
    "users/subPersons",
    {
        rpc: ({ arg, signal }) => subPersons(arg, signal),
        parser: ({ thunkAPI, content }) => {
            const [deleted, overviews] = separateDiscriminatedUnion<
                PersonDeletedDU,
                PersonOverviewDU
            >(msg => msg.case === "deletedId", content);

            if (deleted.length > 0) {
                const ids = deleted.map(id => id.value);
                thunkAPI.dispatch(deletePersons(ids));
            }

            if (overviews.length > 0) {
                const persons = overviews.map(po => po.value).filterLatest(po => po.personId);
                thunkAPI.dispatch(updatePersons(persons));
            }
        },
    },
);
export const startPersonInterestStream = (
    appendListener: AppAppendListener = appAppendListener,
) => startManagingInterest(selectPersonsInterest, subPersonsThunk, "subPersons", appendListener);

export const retrieveAvatarDownloadBlob = createProxiedAsyncThunk(
    "users/fetchAvatarBlob",
    async (args: { blobId: d.BlobId; }, thunkAPI) => {
        return await unaryThunkHandler(
            thunkAPI,
            fetchAvatarBlobDownloadUrl(args),
            `fetchAvatarBlobUrl ${args.blobId}`,
        );
    },
);

export const getPersonByEmail = createProxiedAsyncThunk(
    "users/fetchUserByEmail",
    async (args: FetchPersonByEmailArgs, thunkAPI) => {
        return await unaryThunkHandler(
            thunkAPI,
            fetchPersonByEmail(args),
            `fetchUserByEmail`,
        );
    },
);

export const fetchContactsBookThunk = createProxiedAsyncThunk<d.UserId[], void>(
    "users/fetchContactsBook",
    async (_, thunkAPI) => {
        const store = thunkAPI.getState();
        const userId = selectCurrentUserId(store)!;

        const contactIds = await unaryThunkHandler(
            thunkAPI,
            fetchContactsBook({ userId }),
            `fetchContactsBook`,
        );
        return contactIds;
    },
);

type getAvatarUploadCredentialsThunkArgs = CreateAvatarImageArgs & {
    uploadableAvatarId: d.UploadableAvatarId;
};
export const getAvatarUploadCredentialsThunk = createProxiedAsyncThunk(
    "users/avatarUpload/credentials",
    async (args: getAvatarUploadCredentialsThunkArgs, thunkAPI) => {
        const credentials = await unaryThunkHandler(
            thunkAPI,
            createAvatarImage(args),
            `createAvatarImage ${args.uploadableAvatarId}`,
        );

        return {
            uploadableAvatarId: args.uploadableAvatarId,
            uploaderId: args.ownership.uploaderId,
            credentials,
        };
    },
);

interface CompleteAvatarUploadThunkArgs extends CompleteAvatarImageArgs {
    uploadableAvatarId: d.UploadableAvatarId;
}
export const completeAvatarUploadThunk = createProxiedAsyncThunk(
    "users/avatarUpload/complete",
    async (args: CompleteAvatarUploadThunkArgs, thunkAPI) => {
        await unaryThunkHandler(
            thunkAPI,
            completeAvatarImage(args),
            `completeAvatarImage ${args.uploadableAvatarId}`,
        );

        return {
            uploadableAvatarId: args.uploadableAvatarId,
        };
    },
);

const hamts = {
    avatarBlobs: createHAMT(({ blobId }: AvatarBlob) => blobId),
};

export interface UsersState {
    users: EntityState<UserOverview, d.UserId>;
    persons: EntityState<PersonOverview, d.PersonId>;
    avatarBlobs: ImmutableHAMT<d.BlobId, AvatarBlob>;
    uploadableAvatar: Optional<UploadableAvatarImage>;
    updates: UsersUpdate[];
}

const getInitialState = (
    props?: {
        users?: Record<d.UserId, UserOverview>;
        persons?: Record<d.PersonId, PersonOverview>;
        avatarBlobs?: Record<d.BlobId, AvatarBlob>;
    },
): UsersState => ({
    users: usersAdapter.getInitialState({}, props?.users ?? {}),
    persons: personsAdapter.getInitialState({}, props?.persons ?? {}),
    updates: [],
    avatarBlobs: hamts.avatarBlobs.initial(props?.avatarBlobs ?? {}),
    uploadableAvatar: undefined,
});

export const usersSlice = createSlice({
    name: "users",
    initialState: getInitialState(),
    selectors: {
        uploadableAvatar: state => state.uploadableAvatar,
        avatarBlob: (state, blobId: Optional<d.BlobId>): Optional<AvatarBlob> =>
            blobId && hamtGet(state.avatarBlobs, blobId),
    },
    reducers: {
        updateUser: (state, action: PayloadAction<UserOverview>) => {
            const user = action.payload;
            state.users = usersAdapter.upsertOne(state.users, user);
            state.updates = [[stores.users, user.id, user]];
        },
        updateUsers: (state, action: PayloadAction<UserOverview[]>) => {
            state.users = usersAdapter.upsertMany(state.users, action.payload);
            state.updates = action.payload.map(uo => [stores.users, uo.id, uo]);
        },
        deleteUser: (state, { payload: id }: PayloadAction<d.UserId>) => {
            state.users = usersAdapter.removeOne(state.users, id);
            state.updates = [[stores.users, id]];
        },
        deleteUsers: (state, { payload: ids }: PayloadAction<d.UserId[]>) => {
            state.users = usersAdapter.removeMany(state.users, ids);
            state.updates = ids.map(id => [stores.users, id]);
        },
        updatePersons: (state, { payload: persons }: PayloadAction<PersonOverview[]>) => {
            state.persons = personsAdapter.upsertMany(state.persons, persons);
            state.updates = persons.map(po => [stores.persons, po.personId, po]);
        },
        deletePersons: (state, { payload: ids }: PayloadAction<d.PersonId[]>) => {
            state.persons = personsAdapter.removeMany(state.persons, ids);
            state.updates = ids.map(id => [stores.persons, id]);
        },
        clearUploadableAvatar: state => {
            state.uploadableAvatar = undefined;
        },
    },
    extraReducers: builder => {
        builder.addCase(resetStore, _state => {
            return getInitialState();
        });
        builder.addCase(
            getAvatarUploadCredentialsThunk.fulfilled,
            (state, { payload }) => {
                state.uploadableAvatar = {
                    localId: payload.uploadableAvatarId,
                    uploaderId: payload.uploaderId,
                    credentials: payload.credentials,
                };
            },
        );
        builder.addCase(
            retrieveAvatarDownloadBlob.fulfilled,
            (state, { payload: blob }) => {
                if (!blob) return;

                state.updates = [];
                state.avatarBlobs = hamts.avatarBlobs.set(
                    current(state).avatarBlobs,
                    blob,
                    valueUpdateToStoreUpdate(state.updates, stores.avatars),
                );
            },
        );
    },
});

export const {
    updateUser,
    updateUsers,
    deleteUser,
    deleteUsers,
    updatePersons,
    deletePersons,
    clearUploadableAvatar,
} = usersSlice.actions;

const selectors = usersSlice.getSelectors((state: RootState) => state.users);

const usersSelectors = usersAdapter.getSelectors<RootState>(state => state.users.users);
export const selectAllTestUsers = usersSelectors.selectAll;

export const selectAllUserIds = usersSelectors.selectIds;

export const selectUser = optionalFriendly(usersSelectors.selectById);

export const selectUserName = createSelector([selectUser], user => user?.name);

export const selectCurrentUser = (state: RootState) => {
    const userId = selectCurrentUserId(state);
    return selectUser(state, userId);
};

const haveUserOverview = (u: Optional<UserOverview>): u is UserOverview => !!u;

/** Select user overviews from the store, given an array of ids, and optionally
 * filter and sort the returned overviews.
 *
 * n.b. `adjustments` should be the same object each call or memoized correctly.
 *
 * @param state the `RootState`
 * @param ids an array of `UserIds`
 * @param adjustments an optional object with optional `filter` and optional
 * `sort`, which will be applied if provided
 * @returns an array of `UserOverviews`, filtered by `filter`, sorted by `sort`
 */
export const selectUsers = createAppSelector(
    [
        partialRecord(usersSelectors.selectEntities),
        (_sel, ids?: d.UserId[]) => ids,
        (_sel, _ids, adjustments?: { filter?: UserFilter; sort?: UserSort; }) => adjustments,
    ],
    (users, ids, adjs) => {
        if (!ids || ids.length === 0) return [];

        const uos = ids.map(id => users[id]).filter(haveUserOverview);
        const filtered = (adjs && adjs.filter) ? uos.filter(adjs.filter) : uos;
        return (adjs && adjs.sort) ? filtered.sort(adjs.sort) : filtered;
    },
    memoizeOptions.weakMapShallow,
);

export const selectAllUsersExceptCurrent = createAppSelector(
    [usersSelectors.selectAll, state => selectCurrentUserId(state)],
    (users, currentUserId) => users.filter(u => u.id !== currentUserId),
);

const personsSelectors = personsAdapter.getSelectors<RootState>(state => state.users.persons);
export const selectPerson = optionalFriendly(personsSelectors.selectById);

export const selectPersons = createAppSelector(
    [
        partialRecord(personsSelectors.selectEntities),
        (_sel, ids?: d.PersonId[]) => ids,
    ],
    (persons, ids) => {
        if (!ids || ids.length === 0) return [];
        return ids.map(id => persons[id]).filter(p => !!p);
    },
    memoizeOptions.weakMapShallow,
);

export const selectUploadableAvatar = selectors.uploadableAvatar;

export const selectAvatarBlob = selectors.avatarBlob;

export const selectAvatarPathForNotifs: (
    state: RootState,
    user: Optional<d.UserId>,
) => Optional<string> = createAppSelector(
    [(state, _) => state, selectUser],
    (state, user) => {
        if (!user) {
            // Don't return an avatar path if we're not given a user ID
            return undefined;
        }
        if (!user.picture) {
            return undefined;
        }

        const blob = selectAvatarBlob(state, user.picture.blobId);

        if (!blob || !isValidAvatarMimeType(blob.metadata.mimeType)) {
            return undefined;
        }

        return blob.publicUrl;
    },
);

export const reducer = usersSlice.reducer;

// Persistence.

const stores = {
    users: idbStore<d.UserId, UserOverview>("users", 1),
    persons: idbStore<d.PersonId, PersonOverview>("persons", 27),

    _avatars2: idbStore<d.BlobId, migrateV22.AvatarBlob>("avatar-blobs-2", 14, 21),
    avatars: idbStore<d.BlobId, AvatarBlob>("avatar-blobs-3", 22),
};

type UsersUpdate =
    | IdbStoreUpdate<typeof stores.users>
    | IdbStoreUpdate<typeof stores.persons>
    | IdbStoreUpdate<typeof stores.avatars>;

const persist = (
    previousState: UsersState,
    currentState: UsersState,
): PersistenceUpdate[] => {
    if (previousState.updates == currentState.updates) {
        return [];
    }

    return currentState.updates;
};

const hydrate = async ({ idb: conn }: HydrateArgs) => {
    if (!conn) return getInitialState();

    const users = await fetchIdbStore(conn, stores.users);
    const persons = await fetchIdbStore(conn, stores.persons);
    const avatarBlobs = await fetchIdbStore(conn, stores.avatars);

    return getInitialState({ users, persons, avatarBlobs });
};

const migrate = async (tx: RWTransaction, oldVersion: number) => {
    if (oldVersion < 22) {
        await simpleIdbTranslate(
            tx,
            stores._avatars2,
            stores.avatars,
            migrateV22.translateAvatarBlob,
        );
    }
};

export const persistor = {
    stores,
    hydrate,
    persist,
    migrate,
};
checkPersistor<UsersState>(persistor);
