import { useCallback, useEffect, useState } from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";

import { completeAuthRequest, updateUserDetails } from "@/api/auth";
import { finalLoginSteps } from "@/auth/login";
import log from "@/misc/log";
import { isMobileBrowser } from "@/misc/mobile";
import classNames from "classnames";
import { z } from "zod";
import useRefreshOnLogin from "./useRefreshOnLogin";

// The shape of the location state passed from PasscodeView
const LocationStateSchema = z.object({
    codeVerifier: z.string(),
    userId: z.string(),
});

// Very very basic guess at a nickname -- if the user gives us a two-pronged name, pick the
// first one. Else give up and just use the whole name.
function guessNickname(name: string): string {
    const subnames = name.split(" ");

    if (subnames.length === 2) {
        return subnames[0];
    }

    return name;
}

export default function UserDetailsView(): React.JSX.Element {
    const [params, _] = useSearchParams();
    const authReqId = params.get("auth_request_id");

    const location = useLocation();
    const locationState = LocationStateSchema.safeParse(location.state);
    const { codeVerifier, userId } = locationState.success ? locationState.data : {};

    const [isFailed, setIsFailed] = useState(false);
    const [name, setName] = useState("");
    const [continueButtonEnabled, setContinueButtonEnabled] = useState(false);
    const [updateInProgress, setUpdateInProgress] = useState(false);

    const updateDetails = useCallback(async () => {
        try {
            setUpdateInProgress(true);

            if (!authReqId || !codeVerifier || !name || !userId) {
                setIsFailed(true);
                return;
            }

            const displayName = name;
            const nickname = guessNickname(name);

            const resp = await updateUserDetails(userId, displayName, nickname, {
                authRequestId: authReqId,
                codeVerifier,
            });
            if (!resp.isSuccess) {
                log.error("Failed to update user details:", resp.reason);
                setIsFailed(true);
                return;
            }

            const completeAuthResp = await completeAuthRequest(authReqId, codeVerifier);
            if (!completeAuthResp.isSuccess) {
                log.error("Failed to complete auth request:", completeAuthResp.reason);
                setIsFailed(true);
                return;
            }
            const { callbackUri } = completeAuthResp.response;

            const cbResponse = await fetch(callbackUri, {
                headers: {
                    "X-Avos-302-To-200": "true",
                },
            });

            const [_id, _usersAndOrgs, _redirectUri] = await finalLoginSteps(cbResponse);

            // See PasscodeView.tsx
        }
        catch (err) {
            log.error("Failed to update user details", err);
            setIsFailed(true);
        }
        finally {
            setUpdateInProgress(false);
        }
    }, [authReqId, codeVerifier, userId, name]);

    useEffect(() => {
        const handleKeyPress = (e: KeyboardEvent) => {
            if (e.key === "Enter") {
                updateDetails();
            }
        };
        document.addEventListener("keypress", handleKeyPress);

        return () => {
            document.removeEventListener("keypress", handleKeyPress);
        };
    }, [updateDetails]);

    useRefreshOnLogin();

    useEffect(() => {
        setContinueButtonEnabled(name.length > 0 && name.length < 1000 && !updateInProgress);
    }, [name, updateInProgress]);

    const notImplementedYet = () => {
        alert("Not implemented yet");
    };

    if (!authReqId || !userId) {
        log.error("No auth request ID or user ID provided");
        return <Navigate to="/login" />;
    }

    const isMobile = isMobileBrowser();
    const columnClasses = classNames("c-section-signin__column c-section-signin__column--dark", {
        "c-section-signin__column--mobile": isMobile,
    });
    const signinClasses = classNames("c-signin", {
        "c-signin--desktop": !isMobile,
    });
    const legendClasses = classNames("c-signin__legend", {
        "c-signin__legend--desktop": !isMobile,
    });
    const profileOptionClasses = classNames("c-signin__profile-options", {
        "c-signin__profile-options--desktop": !isMobile,
    });

    return (
        <main className="">
            <section className="c-section-signin">
                <div className={columnClasses}>
                    <h1 className="c-logo c-logo--signin">Bond</h1>
                </div>
                <div className="c-section-signin__column">
                    <fieldset className={signinClasses}>
                        <h2 className={legendClasses}>
                            Make yourself recognizable
                        </h2>
                        <div className="c-signin__element">
                            <input
                                type="text"
                                id="name"
                                autoComplete="off"
                                maxLength={100}
                                className="c-signin__input"
                                placeholder=" "
                                value={name}
                                onChange={e => setName(e.target.value)}
                            />
                            <label htmlFor="name" className="c-signin__label">Your name</label>
                        </div>
                        <div className={profileOptionClasses}>
                            <button className="c-btn-profile" onClick={notImplementedYet}>
                                <div className="c-btn-profile__icon c-btn-profile__icon--portrait">
                                </div>
                                Upload portrait
                            </button>
                            <button className="c-btn-profile" onClick={notImplementedYet}>
                                <div className="c-btn-profile__icon c-btn-profile__icon--selfie">
                                </div>
                                Take selfie
                            </button>
                        </div>
                        <button
                            type="submit"
                            title="Continue"
                            className="c-btn-signin"
                            disabled={!continueButtonEnabled}
                            onClick={updateDetails}
                        >
                            Continue
                        </button>
                        {isFailed && (
                            <div className="c-signin__error">
                                Error. Please try again.
                            </div>
                        )}
                    </fieldset>
                </div>
            </section>
        </main>
    );
}
