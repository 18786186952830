import { SuggestionDomain, SuggestionsList } from "@/components/SuggestionsList";
import { calcBorderBoxSize } from "@/misc/css";
import { BoxSize } from "@/misc/types";
import classNames from "classnames";
import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import useClientBounds from "../../hooks/useClientBounds";
import { Point } from "../../misc/types";

const coordCalc = (
    anchor: number,
    altAnchor: number,
    invertAnchor: boolean,
    clampBehaviour: ClampBehaviour,
    size: number,
    viewportSize: number,
): number => {
    if (invertAnchor) {
        const regular = Math.min(anchor, viewportSize) - size;
        const alt = Math.max(size, altAnchor);
        const altClamped = Math.min(alt, viewportSize) - size;
        if (clampBehaviour == "clamp") {
            return Math.max(size, regular);
        }
        else {
            return (anchor - size > 0) ? regular : altClamped;
        }
    }
    else {
        const regular = Math.max(0, anchor);
        const alt = Math.min(altAnchor, viewportSize) - size;
        const altClamped = Math.max(0, alt);
        if (clampBehaviour == "clamp") {
            return Math.min(regular, viewportSize - size);
        }
        else {
            return (anchor + size < viewportSize) ? regular : altClamped;
        }
    }
};

type ClampBehaviour = "flip-alt" | "clamp";
type HorizontalAnchor = "left" | "right";
type VerticalAnchor = "top" | "bottom";

interface AutoCompleteProps<D extends any[]> {
    anchor: Point;
    altAnchor?: Point;
    horizontalAnchor?: HorizontalAnchor;
    verticalAnchor?: VerticalAnchor;
    horizontalClampBehaviour?: ClampBehaviour;
    verticalClampBehaviour?: ClampBehaviour;
    suggestionDomain: SuggestionDomain<D>;
    onSuggestionsChange?: (suggestionGroups: SuggestionDomain<any[]>) => void;
    query: string;
}

export const AutoComplete = <D extends any[]>(
    props: AutoCompleteProps<D>,
): React.JSX.Element => {
    const { anchor, suggestionDomain, onSuggestionsChange, query } = props;
    const altAnchor = props.altAnchor ?? anchor;
    const horizontalAnchor = props.horizontalAnchor ?? "left";
    const verticalAnchor = props.verticalAnchor ?? "top";
    const horizontalClampBehaviour = props.horizontalClampBehaviour ?? "flip-alt";
    const verticalClampBehaviour = props.verticalClampBehaviour ?? "flip-alt";

    const [wrapperDiv, setWrapperDiv] = useState<HTMLDivElement | null>(null);

    // Track the size of the dialog
    const [size, setSize] = useState<BoxSize>({ width: 0, height: 0 });
    const updateSize = useCallback(() => {
        if (wrapperDiv === null) return;
        setSize(calcBorderBoxSize(wrapperDiv));
    }, [wrapperDiv]);
    useLayoutEffect(updateSize, [updateSize]);

    // Position the dropdown on screen, moving to the other side of the anchor if necessary
    const bounds = useClientBounds();
    const x = useMemo(
        () =>
            coordCalc(
                anchor.x,
                altAnchor.x,
                horizontalAnchor == "right",
                horizontalClampBehaviour,
                size.width,
                bounds.layoutViewportSize.width,
            ),
        [
            anchor.x,
            altAnchor.x,
            horizontalAnchor,
            horizontalClampBehaviour,
            size.width,
            bounds.layoutViewportSize.width,
        ],
    );
    const y = useMemo(
        () =>
            coordCalc(
                anchor.y,
                altAnchor.y,
                verticalAnchor == "bottom",
                verticalClampBehaviour,
                size.height,
                bounds.layoutViewportSize.height,
            ),
        [
            anchor.y,
            altAnchor.y,
            verticalAnchor,
            verticalClampBehaviour,
            size.height,
            bounds.layoutViewportSize.height,
        ],
    );

    const onSuggestionsChangeInternal = useCallback((suggestionGroups: SuggestionDomain<any[]>) => {
        onSuggestionsChange?.(suggestionGroups);
        updateSize();
    }, [onSuggestionsChange, updateSize]);

    const classes = classNames("c-autocomplete", {
        "c-autocomplete--hydrating": !size.width || !size.height,
    });

    const style = {
        left: (isNaN(x)) ? undefined : x,
        top: (isNaN(y)) ? undefined : y,
    };

    return (
        <div
            ref={setWrapperDiv}
            className={classes}
            style={style}
        >
            <SuggestionsList
                domain={suggestionDomain}
                query={query}
                arrowKeysActive={true}
                allowTab={true}
                useUnselectedState={false}
                groupClassName={"c-autocomplete__squad"}
                suggestionClassName={"c-suggestion"}
                onSuggestionsChange={onSuggestionsChangeInternal}
            />
        </div>
    );
};
