import { RGB, rgbToOklch } from "@/components/gui/colours";
import { getPalette } from "@/misc/color-thief";
import { useCallback } from "react";

export function useColourExtraction(defaultColour: string) {
    const scoreColour = useCallback((color: RGB): number => {
        const [l, c, _] = rgbToOklch(color);
        const lightnessPenalty = Math.abs(l - 0.5);
        const chromaPenalty = -c / 0.37;
        const rWeight = 0.95;

        const cost = lightnessPenalty * rWeight + chromaPenalty * (1 - rWeight);
        return -cost;
    }, []);

    const getBestColour = useCallback(
        (palette: RGB[]): RGB => {
            return palette.reduce((best, current, _) => {
                const currentScore = scoreColour(current);
                const bestScore = scoreColour(best);
                return currentScore > bestScore ? current : best;
            });
        },
        [scoreColour],
    );

    const loadImageAndExtractColour = useCallback(
        (imageSource: string): Promise<string> => {
            return new Promise(resolve => {
                const img = new Image();
                img.crossOrigin = "Anonymous";
                img.src = imageSource;
                img.onload = () => {
                    try {
                        const palette = getPalette(img, 8);
                        const [r, g, b] = getBestColour(palette);
                        resolve(`rgb(${r}, ${g}, ${b}, 0.6)`);
                    }
                    catch (error) {
                        console.error("Error getting colour:", error);
                        resolve(defaultColour);
                    }
                };
                img.onerror = () => {
                    console.error("Error loading image");
                    resolve(defaultColour);
                };
            });
        },
        [getBestColour, defaultColour],
    );

    return loadImageAndExtractColour;
}
