import { BlobCredentials, BlobMetadata } from "@/domain/blobs";
import * as d from "@/domain/domain";
import { getDominantPresenceMode, hasLivePresence, PresenceMode } from "@/domain/presence";
import { timeAgo } from "@/misc/timeAgo";
import { Optional } from "@/misc/types";

type ConnectedStatus = {
    connected: true;
    connectedAt: d.Timestamp;
} | {
    connected: false;
    disconnectedAt: d.Timestamp;
};

type ActiveStatus = {
    active: true;
    activeSince: d.Timestamp;
} | {
    active: false;
    inactiveSince: d.Timestamp;
};

export type PersonalActivity =
    & {
        presenceModes: PresenceMode[];
    }
    & ConnectedStatus
    & ActiveStatus;

export type PersonalAvatar = {
    blobId: d.BlobId;
};

// TODO: replace with equivalent of "OfficialAttachment"
export type AvatarBlob = {
    blobId: d.BlobId;
    publicUrl: string;
    metadata: BlobMetadata;
};

export interface PersonOverview {
    personId: d.PersonId;
    name: string;
    nickname: string;
    email: string;
    activity?: PersonalActivity;
    picture?: PersonalAvatar;
}

export interface UserDefinition {
    userId: d.UserId;
    personId: d.PersonId;
    orgId: d.OrgId;
}

export interface UserOverview extends PersonOverview {
    id: d.UserId;
    orgId: d.OrgId;
}

export interface UploadableAvatarImage {
    localId: d.UploadableAvatarId;
    uploaderId: d.UserId;
    credentials: BlobCredentials;
}

export function getPersonFromUser(user: Optional<UserOverview>): Optional<PersonOverview> {
    if (!user) {
        return undefined;
    }
    return {
        personId: user.personId,
        name: user.name,
        nickname: user.nickname,
        email: user.email,
        activity: user.activity,
        picture: user.picture,
    };
}

export function getPersonFullName(person?: PersonOverview): string {
    return person?.name || "";
}

export function getUserFullName(user?: UserOverview): string {
    return getPersonFullName(getPersonFromUser(user));
}

export function getPersonNickname(person?: PersonOverview): string {
    return person?.nickname || getPersonFullName(person);
}

export function getUserNickname(user?: UserOverview): string {
    return getPersonNickname(getPersonFromUser(user));
}

export function getPersonDisplayName(person?: PersonOverview): string {
    if (person?.nickname && person?.name) {
        return `${person.nickname} (${person.name})`;
    }
    return getPersonNickname(person);
}

export function getUserDisplayName(user?: UserOverview): string {
    return getPersonDisplayName(getPersonFromUser(user));
}

export function userOverviewToDominantPresence(user?: UserOverview): PresenceMode {
    if (!user) return PresenceMode.Unknown;

    const modes = user.activity?.presenceModes;
    if (!modes || !modes.length) return PresenceMode.Unknown;

    return getDominantPresenceMode(modes) ?? PresenceMode.Unknown;
}

export function userOverviewToPresenceString(user?: UserOverview): string {
    if (!user) return "";

    const name = user.name;

    const dominant = userOverviewToDominantPresence(user);
    if (!dominant) return name;

    return `${name} - ${dominant.toString()}`;
}

export function asActivityRecencyText(activity?: PersonalActivity | false): string {
    if (!activity) {
        return "";
    }
    if (activity.active) {
        return "";
    }
    if (!activity.connected) {
        return " (disconnected " + timeAgo({ from: activity.disconnectedAt }) + ")";
    }
    return " (" + timeAgo({ from: activity.inactiveSince }) + ")";
}

export function asScreenShareText(name: string) {
    return `${name}'s Screen`;
}

export type UserFilter = (u: UserOverview) => boolean | undefined;
export const userIsActive = (uo: UserOverview): boolean => !!(uo.activity?.active);

export type UserSort = (a: UserOverview, b: UserOverview) => number;

type UserBondActivitySets = {
    observerUserSet?: Set<d.UserId>;
    mentionUserSet?: Set<d.UserId>;
    unreadUserSet?: Set<d.UserId>;
};

type UserBondLiveActivitySets = UserBondActivitySets & {
    videoUserSet?: Set<d.UserId>;
    liveUserSet?: Set<d.UserId>;
};

interface LiveActivityArgs {
    // The current logged in user ID
    currentUserId?: d.UserId;
    // Whether to order the current user first
    orderCurrentUserFirst?: boolean;
    // Whether to sort by activity and split ties on inactivity
    sortByActivity?: boolean;
}

/** @function Compare users by presence and activity in a bond. Namely sort by:
 *  - Whether user is the current logged in user (optional)
 *  - Video live presence
 *  - Live presence (i.e. video/audio/screenshare)
 *  - Being active (optional)
 *  - Observing the bond
 *  - Mentioned the user in the bond
 *  - Sent a message in the bond
 *  - Finally break ties by (optionally inactivity), nickname, then ID
 *
 * @param activitySets the sets of user IDs representing different user activity
 * bands in the bond (e.g. observers/mentioners/unreads)
 * @param args the parameters for sorting the live activity.
 * @returns a UserSort function, taking two user overviews, and returning a number:
 * 0 for "equal", negative for "first argument bigger", positive for "second argument bigger"
 */
export const userSortByBondLiveActivity = (
    activitySets: UserBondLiveActivitySets,
    args?: LiveActivityArgs,
): UserSort =>
(a, b) => {
    const { videoUserSet, liveUserSet, observerUserSet, mentionUserSet, unreadUserSet } =
        activitySets;
    const { currentUserId, orderCurrentUserFirst, sortByActivity } = args ?? {};

    const score = (user: UserOverview) => {
        const userId = user.id;
        const uAct = user.activity;

        const isCurrentUser = orderCurrentUserFirst && userId === currentUserId;
        const isVideo = !!videoUserSet?.size && videoUserSet.has(userId);
        const isLive = liveUserSet?.size ? liveUserSet.has(userId)
            : hasLivePresence(uAct?.presenceModes);
        const isActive = sortByActivity && (isLive || uAct?.active);
        const isObserver = isLive || observerUserSet?.has(userId);
        const hasMention = mentionUserSet?.has(userId);
        const hasUnread = unreadUserSet?.has(userId);

        const contributions = [
            isCurrentUser,
            isVideo,
            isLive,
            isActive,
            isObserver,
            hasMention,
            hasUnread,
        ];

        // Sum{i in [0, n=contributions.length-1]} ( 1{contributions[i]} * 2 ** ((n - 1) - i) )
        return contributions.reduce((t, v) => 2 * t + (v ? 1 : 0), 0);
    };

    const inactiveSince = (act: Optional<PersonalActivity>) =>
        !act?.active && act?.inactiveSince || 0;
    const aInactiveSince = inactiveSince(a.activity);
    const bInactiveSince = inactiveSince(b.activity);

    return (score(b) - score(a)) ||
        (sortByActivity && (bInactiveSince - aInactiveSince)) ||
        userSortByNickname(a, b);
};

/** @function Compare users by their bond activity.
 *
 * Ties optionally broken by inactive time, then by nickname.
 *
 * @param activitySets the sets of user IDs representing different user activity
 * bands in the bond (e.g. observers/mentioners/unreads)
 * @param sortByActivity whether to sort by activity. Default false.
 * @returns a UserSort function, taking two user overviews, and returning a number:
 * 0 for "equal", negative for "first argument bigger", positive for "second argument bigger"
 */
export const userSortByBondActivity = (
    activitySets: UserBondActivitySets,
    sortByActivity = false,
): UserSort => userSortByBondLiveActivity(activitySets, { sortByActivity });

/** @function Compare users by their presence levels.
 *
 * Ties broken by nickname if the users match in presence level.
 *
 * @param a a `UserOverview`
 * @param b a `UserOverview`
 * @returns a number, 0 for "equal", negative for "first argument bigger",
 * positive for "second argument bigger"
 */
export const userSortByPresence: UserSort = userSortByBondActivity({});

/** @function Compare users by presence and their activity.
 *
 * Ties broken by time inactive, and then by nickname, if the users match in presence level.
 *
 * @param a a `UserOverview`
 * @param b a `UserOverview`
 * @returns a number, 0 for "equal", negative for "first argument bigger",
 * positive for "second argument bigger"
 */
export const userSortByActivity: UserSort = userSortByBondActivity({}, true);

/** @function Sort users by their nickname, name, then by ID.
 *
 * @param a a `UserOverview`
 * @param b a `UserOverview`
 * @returns a number, 0 for "equal", negative for "first argument bigger",
 * positive for "second argument bigger"
 */
export const userSortByNickname: UserSort = (a, b) => {
    const aName = getUserNickname(a);
    const bName = getUserNickname(b);

    if (aName && !bName) {
        return -1;
    }
    else if (!aName && bName) {
        return 1;
    }

    return (aName && bName && aName.localeCompare(bName)) ||
        a.id.localeCompare(b.id);
};

export type UserMapper<T> = (u: UserOverview) => T;
