import { BondArchivedInteraction } from "@/components/messages/BondArchivedInteraction";
import { CallId } from "@/domain/domain";
import { selectCallById } from "@/features/calls";
import { useInViewInterest } from "@/hooks/interest/useInViewInterest";
import useMergedRefs from "@/hooks/useMergedRefs";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { Ref } from "react";

export interface CallMessageViewProps {
    callId?: CallId;
    messageContentRef?: Ref<HTMLDivElement>;
}

export const CallMessageView = (props: CallMessageViewProps): React.JSX.Element => {
    const { callId, messageContentRef } = props;

    const inViewRef = useInViewInterest({
        callIds: callId,
    });

    const ref = useMergedRefs(messageContentRef, inViewRef);

    return (
        <div ref={ref}>
            <CallMessageViewInternal callId={callId} />
        </div>
    );
};

const CallMessageViewInternal = (props: CallMessageViewProps): React.JSX.Element => {
    const { callId } = props;

    const call = useSelectorArgs(selectCallById, callId);

    const inViewRef = useInViewInterest({
        userIds: call?.participants.map(p => p.userId),
    });

    if (!call) {
        return (
            <div className="c-message c-message--unknown">
                {`Unknown call ${callId}`}
            </div>
        );
    }

    // A call is live iff it has no end time.
    // Live calls are displayed at the bottom of the chat in a BondLiveInteraction.
    if (!call.endedAt) {
        return <></>;
    }

    return (
        <div ref={inViewRef}>
            <BondArchivedInteraction callId={call.id} />
        </div>
    );
};
