import classNames from "classnames";
import { useCallback, useEffect } from "react";

import SquadEntry from "@/components/buttons/BondsTopbarSquadEntry";
import LinkToView from "@/components/navigation/LinkToView";
import { viewUtils } from "@/domain/views";
import { selectNumberOfFollowedUnreadMentions } from "@/features/filteredBonds";
import { selectDiscoverSelected, selectInboxSelected } from "@/features/filterPanel";
import {
    selectInboxDominantPresenceIconPair,
    selectLatestSquadActivityTimestamp,
    selectMobileSquadsTabHighlighted,
} from "@/features/presence";
import { setQuery } from "@/features/search";
import { markSquadTabRead, selectCurrentSquadIdsOrderedBySquadName } from "@/features/squads";
import useDebouncedFollowedUnreadCount from "@/hooks/useDebouncedFollowedUnreadCount";
import usePresenceIcon from "@/hooks/usePresenceIcon";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { isMobileBrowser } from "@/misc/mobile";
import { useAppDispatch, useAppSelector } from "@/store/redux";
import { FeatureFlagged } from "./FeatureFlags";

const inboxView = viewUtils.inbox();

function MyBondsEntry() {
    const dispatch = useAppDispatch();

    const debouncedUnreadCount = useDebouncedFollowedUnreadCount();

    const mentionsCount = useSelectorArgs(selectNumberOfFollowedUnreadMentions);

    const myBondsSelected = useAppSelector(selectInboxSelected);

    const presenceIconString = usePresenceIcon(selectInboxDominantPresenceIconPair);

    const classes = classNames(
        "c-sidebar__my-bonds c-btn-mybonds",
        myBondsSelected && "is-selected",
    );

    const clearSearch = useCallback(() => {
        dispatch(setQuery(""));
    }, [dispatch]);

    return (
        <LinkToView view={inboxView} className={classes} onClick={clearSearch}>
            <div className="c-btn-mybonds__label">
                <div className="c-btn-mybonds__icon" />
                Inbox
            </div>

            <div className="c-sidebar-counts">
                {presenceIconString && (
                    <span
                        className="c-icon-animated"
                        style={{
                            backgroundImage: `url("${presenceIconString}")`,
                        }}
                    />
                )}
                {mentionsCount > 0 && (
                    <div
                        className={`c-mentions-count ${
                            mentionsCount > 99 ? "c-mentions-count--large" : ""
                        }`}
                    >
                        {mentionsCount}
                    </div>
                )}
                {debouncedUnreadCount > 0 && (
                    <div
                        className={`c-bond-count ${
                            debouncedUnreadCount > 99 ? "c-bond-count--large" : ""
                        }`}
                    >
                        {debouncedUnreadCount}
                    </div>
                )}
            </div>
        </LinkToView>
    );
}

const discoverView = viewUtils.discover();

function MyBondsDiscover() {
    const count = 0;
    const discoverSelected = useAppSelector(selectDiscoverSelected);

    const classes = classNames("c-btn-discover-main", discoverSelected && "is-selected");
    const countClasses = classNames("c-bond-count", count > 99 && "c-bond-count--large");

    return (
        <LinkToView view={discoverView} className={classes}>
            <div className="c-btn-discover-main__label">
                <div className="c-btn-discover-main__icon" />
                Discover
            </div>
            {count > 0 && (
                <div className={countClasses}>
                    {count}
                </div>
            )}
        </LinkToView>
    );
}

export function SquadAndMyBondsSelector(
    props: {
        showMyBonds: boolean;
        location?: "isSidebar";
    },
): React.JSX.Element {
    const isMobile = isMobileBrowser();
    const allMySquadIds = useAppSelector(selectCurrentSquadIdsOrderedBySquadName);
    const mobileSquadsTabUnread = useSelectorArgs(selectMobileSquadsTabHighlighted);
    const latestSquadActivityTs = useSelectorArgs(selectLatestSquadActivityTimestamp);

    const dispatch = useAppDispatch();

    const classes = classNames("c-squad-listing", {
        "c-squad-listing--sidebar": props.location === "isSidebar",
    });
    const wrapperClasses = classNames({
        "c-content c-content--squads": isMobile && props.location !== "isSidebar",
    });

    // Keep the mobile squads tab marked as read on mobile while this is rendered.
    useEffect(() => {
        if (isMobile && mobileSquadsTabUnread) {
            dispatch(markSquadTabRead(latestSquadActivityTs));
        }
    }, [dispatch, isMobile, mobileSquadsTabUnread, latestSquadActivityTs]);

    const squadList = (
        <div className={classes}>
            {allMySquadIds.map(squadId => <SquadEntry key={squadId} id={squadId} />)}
        </div>
    );

    return (
        <>
            {props.showMyBonds && (
                <>
                    <MyBondsEntry />
                    <FeatureFlagged flag="discover-feature" match={true} wrapWithDiv={false}>
                        <MyBondsDiscover />
                    </FeatureFlagged>
                </>
            )}
            {isMobile ? (
                <div className={wrapperClasses}>
                    {squadList}
                </div>
            ) : squadList}
        </>
    );
}
