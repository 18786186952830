import { useEffect, useMemo } from "react";

import { viewStackUtils } from "@/domain/views";
import { selectSquadById } from "@/features/squads";
import useViewStackNavigate from "@/hooks/navigation/useViewStackNavigate";
import useAddressParams from "@/hooks/useAddressParams";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { SquadChildRouteContext } from "@/misc/squadChildRouteContext";
import { Optional } from "@/misc/types";
import BondsListView from "@/views/BondsListView";
import { Outlet } from "react-router-dom";
import { ShowSearchResultsWhenAvailable } from "./SearchView";

export function SquadView(): React.JSX.Element {
    const { navigateReplace } = useViewStackNavigate();
    const { squadId } = useAddressParams();
    const squad = useSelectorArgs(selectSquadById, squadId);

    const shouldRedirect = !squadId || !squad;

    useEffect(() => {
        if (shouldRedirect) {
            const t = setTimeout(() => {
                navigateReplace(viewStackUtils.defaultStack);
            }, 1 * 1000);

            return () => {
                clearTimeout(t);
            };
        }
    }, [navigateReplace, shouldRedirect]);

    // Memoise the outlet context to avoid unnecessary rerenders
    const outletCtx: Optional<SquadChildRouteContext> = useMemo(
        () => squadId ? { squadId } : undefined,
        [squadId],
    );

    if (shouldRedirect) {
        return <h1>No squad found. Redirecting...</h1>;
    }

    return (
        <ShowSearchResultsWhenAvailable>
            <section className="c-content c-content--squad">
                <BondsListView />
                <Outlet context={outletCtx} />
            </section>
        </ShowSearchResultsWhenAvailable>
    );
}
