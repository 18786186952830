import { selectLiveCallIdByBondId } from "@/features/bonds";
import useAddressParams from "./useAddressParams";
import useSelectorArgs from "./useSelectorArgs";

export const useCurrentBondIsLive = () => {
    const { bondId } = useAddressParams();
    const liveCallId = useSelectorArgs(selectLiveCallIdByBondId, bondId);
    return !!liveCallId;
};

export default useCurrentBondIsLive;
