import classNames from "classnames";
import { useCallback, useContext, useMemo } from "react";

import { FileStashContext } from "@/components/managers/AttachmentManager";
import {
    createProposedAttachment,
    isUploadingAttachment,
    ProposedAttachment,
} from "@/domain/attachments";
import { fileToBlobMetadata } from "@/domain/blobs";
import { genLocalAttachmentId } from "@/domain/domain";
import type { DraftTarget } from "@/domain/draftTarget";
import { selectCurrentOrgId, selectCurrentUserId } from "@/features/auth";
import { addAttachmentsToDraft, clearAttachmentsFromDraft, selectDraft } from "@/features/channels";
import { selectLocalAttachmentsById } from "@/features/chats";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { getAttachmentDimensionsNoThrow } from "@/misc/attachments";
import log from "@/misc/log";
import { isMobileBrowser } from "@/misc/mobile";
import { useAppDispatch, useAppSelector } from "@/store/redux";
import MoonLoader from "react-spinners/MoonLoader";

interface AttachmentUploadControlsProps {
    draftTarget: DraftTarget;
    bondIsLive?: boolean;
}

// AttachmentUploadControls is responsible for the UI and all of the interactions with files,
// the fileStash, and the Redux store regarding uploading an attachment to a draft.
//
// It is tested via its only use: MessageComposer.
export default function AttachmentUploadControls(
    props: AttachmentUploadControlsProps,
): React.JSX.Element {
    const { draftTarget } = props;

    const dispatch = useAppDispatch();

    const draft = useSelectorArgs(selectDraft, draftTarget);
    const totalDraftAttachmentsLength = draft?.attachmentIds.length ?? 0;

    const fileStash = useContext(FileStashContext);

    const userId = useAppSelector(selectCurrentUserId);
    const orgId = useAppSelector(selectCurrentOrgId);

    const addAttachment = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
        log.info("Adding attachment(s)");

        if (!e.target.files || e.target.files.length == 0) {
            e.target.value = "";
            return;
        }

        if (!userId || !orgId) {
            const missing = [];
            if (!userId) missing.push("user id");
            if (!orgId) missing.push("org id");
            log.error(`Failed to create attachment: missing ${missing.join(" and ")}`);
            return;
        }

        const newAttachments: ProposedAttachment[] = [];

        const ps = Array.from(e.target.files).map(async file => {
            const localId = genLocalAttachmentId();
            fileStash.set(localId, file);
            const initiatedAt = Date.now();

            const dimensions = await getAttachmentDimensionsNoThrow(file);

            newAttachments.push(
                createProposedAttachment({
                    localId,
                    draftTarget,
                    initiatedAt,
                    metadata: { ...fileToBlobMetadata(file), dimensions },
                    ownership: { uploaderId: userId, orgId },
                }),
            );
        });

        await Promise.allSettled(ps);

        dispatch(addAttachmentsToDraft(newAttachments));

        // Now that we've processed the input, we need to reset the input element, else trying
        // to select the same file again won't fire an onChange event.
        e.target.value = "";
    }, [dispatch, draftTarget, fileStash, orgId, userId]);

    const clearAttachments = useCallback(() => {
        dispatch(clearAttachmentsFromDraft(draftTarget));
    }, [dispatch, draftTarget]);

    const localAttachments = useSelectorArgs(selectLocalAttachmentsById, draft?.attachmentIds);
    const uploadingAttachment = useMemo(
        () => localAttachments.filter(isUploadingAttachment).pop(),
        [localAttachments],
    );

    const isMobile = isMobileBrowser();

    const className = classNames("c-attachment-percentage", {
        "c-attachment-percentage--desktop": !isMobile,
        "c-attachment-percentage--live": props.bondIsLive,
    });

    return (
        <>
            <input
                type="file"
                id="fileUpload"
                multiple={true}
                onChange={addAttachment}
                className={classNames("c-btn-composer c-btn-composer--add", {
                    "c-btn-composer--add-live": props.bondIsLive,
                })}
                title="Attach files"
            />
            {totalDraftAttachmentsLength > 0 && (
                <button
                    onClick={clearAttachments}
                    className={classNames("c-btn-clear-file", {
                        "c-btn-clear-file--live": props.bondIsLive,
                    })}
                    title={`Clear attachment${totalDraftAttachmentsLength > 1 ? `s` : ``}`}
                >
                    <span className="c-btn-clear-file__attachments"></span>
                    {totalDraftAttachmentsLength}
                    <span className="c-btn-clear-file__close"></span>
                </button>
            )}

            {uploadingAttachment && (
                <div
                    className={classNames("c-attachment-progress", {
                        "c-attachment-progress--live": props.bondIsLive,
                    })}
                >
                    <MoonLoader size={16} color="#ffffff" loading={true} />
                    <span className={className}>
                        {uploadingAttachment.percentCompleted.toFixed(0)}%
                    </span>
                </div>
            )}
        </>
    );
}
