import { DateTime, Interval } from "luxon";
import { useCallback, useState } from "react";

import { getDurationStr } from "@/misc/utils";
import useIntervalAction from "./useIntervalAction";

interface UseCountdownProps {
    // Called when the countdown reaches zero
    onDeadline: () => void;
}

// Format the time until a deadline as a stopwatch-style string
// e.g. 1:00:00, 01:00 etc.
export default function useCountdown(props: UseCountdownProps) {
    const { onDeadline } = props;

    const [deadline, setDeadline] = useState<DateTime | undefined>();
    const [durationStr, setDurationStr] = useState<string | undefined>();

    const calculateDurationStr = useCallback((now: DateTime) => {
        if (!deadline) {
            setDurationStr("");
            return;
        }

        if (now >= deadline) {
            onDeadline();
            setDeadline(undefined);
            setDurationStr("");
        }
        else {
            const diff = Interval.fromDateTimes(now, deadline);
            setDurationStr(getDurationStr(diff));
        }
    }, [deadline, onDeadline]);

    useIntervalAction({ action: calculateDurationStr, interval: 1000 });

    return { durationStr, setDeadline };
}
