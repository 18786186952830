import type { Reducer } from "@reduxjs/toolkit";
import { z } from "zod";

import type { AuthState } from "@/features/auth";
import type { BondCreationState } from "@/features/bondCreation";
import type { BondState } from "@/features/bonds";
import type { CallsState } from "@/features/calls";
import type { ChannelsState } from "@/features/channels";
import type { MessagesState } from "@/features/chats";
import type { ConnectionState } from "@/features/connection";
import type { FilterPanelState } from "@/features/filterPanel";
import { IntelState } from "@/features/intel";
import type { InterestState } from "@/features/interest";
import type { DevicesState } from "@/features/mediaDevices";
import type { MetaState } from "@/features/meta";
import type { NotificationsState } from "@/features/notifications";
import type { SearchState } from "@/features/search";
import type { SquadState } from "@/features/squads";
import type { UsersState } from "@/features/users";
import type { IPersist } from "@/persist/types";
import type { setupStore } from "@/store/setup";

export type StoreConfig = {
    persist: boolean | Partial<{ idb: boolean; storage: boolean; }>;
    broadcast: boolean;
    proxy: boolean;
    manageConnection: boolean;
    startListeners: boolean;
    debug: string | boolean;
    manageAuth: boolean;
    perf: boolean;
};

// You can't do this programmatically, as far as I can see.
export type RootState = {
    auth: AuthState;
    bondCreation: BondCreationState;
    bonds: BondState;
    calls: CallsState;
    channels: ChannelsState;
    connection: ConnectionState;
    filterPanel: FilterPanelState;
    intel: IntelState;
    interest: InterestState;
    mediaDevices: DevicesState;
    messages: MessagesState;
    meta: MetaState;
    notifications: NotificationsState;
    search: SearchState;
    squads: SquadState;
    users: UsersState;
};

/** Where is our store running?
 */
export const contextSchema = z.enum(["overseer", "proxyTab", "soloTab"]);

/** Which slices should ignore the state returned from the overseer?
 *
 * TODO: use the fact these slices are `LocalSlices` to determine this.
 */
export const localSliceSchema = z.enum([
    "bondCreation",
    "filterPanel",
    "mediaDevices",
    "meta",
    "search",
]);

export type RootReducerMap = {
    [K in keyof RootState]: Reducer<RootState[K]>;
};

export type PersistorMap<T = RootState> = {
    [K in keyof T]: IPersist<T[K]>;
};

export type RootPersistorMap = PersistorMap<RootState>;

export type AppStore = ReturnType<typeof setupStore>;
export type AppStoreWithCleanup = AppStore & { cleanup: () => void; };
export type AppDispatch = AppStore["dispatch"];

// https://redux-toolkit.js.org/usage/usage-with-typescript#defining-a-pre-typed-createasyncthunk
export type AppAsyncThunkConfig = {
    state: RootState;
    dispatch: AppDispatch;
};
