import { type AsyncThunk, type AsyncThunkAction, TaskAbortError } from "@reduxjs/toolkit";

import { selectConnectedWithUserId } from "@/features/connection";
import { selectStoreConfig } from "@/features/meta";
import type { AnySelector } from "@/features/selectors";
import log from "@/misc/log";
import { diff } from "@/misc/primatives";
import { Optional } from "@/misc/types";
import { actionHasLocalTag, getActionProxyTag } from "@/store/locations";
import {
    type AppAppendListener,
    appAppendListener,
    firstOrStatesDifferBySelectors,
    type ListenerAPI,
    statesDifferBySelectors,
} from "@/store/middleware";
import type { AppAsyncThunkConfig, RootState } from "@/store/types";

/**
 * Two different modes for listener middleware:
 *
 * A) run a new listener whenever state changes
 * - simpler
 * - but no co-ordination between callbacks
 * B) one long-lived listener awaiting state changes
 * - more complex
 * - sequential and singleton-like execution
 * - closer to the model you end up with doing the same in the frontend
 *
 * Both modes are complicated by needing to check "are we connected?", and
 * if so tearing down previous instance state.
 */

type StartConnectedListenerModifiers<T> =
    & {
        appendListener?: AppAppendListener;
        abortOldThunk?: boolean;
    }
    & ({
        argGen?: AnySelector<[], T>;
    } | {
        extraSelectors?: AnySelector<[]>[];
    });

const isArgGen = <T>(
    mods?: StartConnectedListenerModifiers<T>,
): mods is StartConnectedListenerModifiers<T> & { argGen: any; } => !!mods && "argGen" in mods;
const isExtraSelectors = <T>(
    mods?: StartConnectedListenerModifiers<T>,
): mods is StartConnectedListenerModifiers<T> & { extraSelectors: any; } =>
    !!mods && "extraSelectors" in mods;

const constructSelectors = <T>(
    mods: Optional<StartConnectedListenerModifiers<T>>,
): AnySelector<[]>[] => {
    if (isExtraSelectors(mods)) return mods.extraSelectors;
    return [];
};

const constructArg = <T>(
    state: RootState,
    mods: Optional<StartConnectedListenerModifiers<T>>,
): Optional<T> => {
    if (isArgGen(mods)) return mods.argGen(state);
};

/** Type A. Dispatch an action whenever we're in a "truthy" state based
 * on the selectors used.
 *
 * Used for, e.g., opening a streaming RPC when some condition holds.
 *
 * @param f an async-thunk action creator to be dispatched when state changes
 * @param modifiers An optional map containing, optionally,
 * - `extraSelectors` an array of additional selectors to determine when
 * state has "changed" sufficiently
 * - `argGen` - a function to create the argument to be passed to `f` when
 * dispatched
 * - `appendListener` the function to use to add the listener
 * - `abortOldThunk` whether to abort the previous thunk when a new one is started
 * @returns a function that, when called, unsubscribes the listener
 */
export const startConnectedListener = <T, S = T>(
    f: (arg: Optional<T>) => AsyncThunkAction<any, S, AppAsyncThunkConfig>,
    modifiers?: StartConnectedListenerModifiers<T>,
) => {
    const statusChangeSelectors = [selectConnectedWithUserId, ...constructSelectors(modifiers)];
    const statusChanged = statesDifferBySelectors(...statusChangeSelectors);
    const appendListener = modifiers?.appendListener ?? appAppendListener;

    let first = true;
    let lastArg: T | undefined = undefined;

    return appendListener({
        predicate: (action, currentState, previousState) => {
            if (first) {
                first = false;
                return true;
            }

            if (statusChanged(action, currentState, previousState)) return true;

            const thisArg = constructArg(currentState, modifiers);
            if (lastArg != thisArg) {
                lastArg = thisArg;
                return true;
            }

            return false;
        },

        effect: async (_action, api) => {
            const state = api.getState();

            const anySelectorFalse = statusChangeSelectors.some(s => !s(state));
            if (anySelectorFalse) {
                api.cancelActiveListeners();
                return;
            }
            if (modifiers?.abortOldThunk) {
                api.cancelActiveListeners();
            }

            const arg = constructArg(state, modifiers);
            const action = f(arg);
            const thunk = api.dispatch(action);

            try {
                return await api.pause(thunk.unwrap());
            }
            catch (e) {
                if (!(e instanceof TaskAbortError)) {
                    log.warn(`ConnectedListener dispatch failed`, e);
                }
                thunk.abort();
            }
        },
    });
};

/** Type B. Run a function whenever a predicate changes, with the state passed
 * in as an argument.
 *
 * Used for, e.g., opening and keeping open a number of RPC streams
 * (subBondObservers) whilst there is interest in them.
 *
 * @param f a function to create the function to be called on state changes.
 * `f` will be called each time we become connected, allowing previous state to
 * be discarded on disconnect. n.b. no cleanup is performed, except that which
 * would happen naturally, e.g. dispatched streams will have their thunks
 * rejected. The function returned by `f` can be synchronous or asynchronous.
 * @param projector a selector to determine when state has "changed" for the
 * function `f`
 * @param name a string used as part of logging around the listener.
 * @returns a function that, when called, unsubscribes the listener.
 */
export const startConnectedStatefulListener = (
    appendListener: AppAppendListener = appAppendListener,
) =>
(
    f: () => (api: ListenerAPI, state: RootState) => void | Promise<void>,
    projector: AnySelector<[]>,
    name: string,
) => {
    return appendListener({
        predicate: firstOrStatesDifferBySelectors(selectConnectedWithUserId),

        effect: async (_action, api) => {
            const firstState = api.getState();

            if (!selectConnectedWithUserId(firstState)) {
                api.cancelActiveListeners();
                return;
            }

            const thisF = f();

            let previousProjectedState: ReturnType<typeof projector> | undefined;

            try {
                while (!api.signal.aborted) {
                    const state = api.getState();
                    const projectedState = projector(state);

                    if (!previousProjectedState || projectedState != previousProjectedState) {
                        await thisF(api, state);
                        previousProjectedState = projectedState;
                        continue;
                    }

                    await api.take((_, state, __) => projector(state) != previousProjectedState);
                }
            }
            catch (e) {
                if (!(e instanceof TaskAbortError)) {
                    log.warn(`Unexpected middleware exception (${name})`, e);
                }
            }
        },
    });
};

/** Type C. Run a function with semantics like useEffect() whenever connectivity changes. */
export const startConnectedEffectListener = (
    effect: (api: ListenerAPI) => () => void | undefined,
    appendListener: AppAppendListener = appAppendListener,
) => {
    let first = false;
    let cleanup: (() => void) | undefined;

    return appendListener({
        predicate: (_action, currentState, previousState) => {
            if (first) {
                first = false;
                return true;
            }

            const connected = selectConnectedWithUserId(currentState);
            const lastConnected = selectConnectedWithUserId(previousState);
            return connected != lastConnected;
        },
        effect: async (_action, api) => {
            const state = api.getState();
            const connected = selectConnectedWithUserId(state);

            if (cleanup) {
                cleanup();
                cleanup = undefined;
            }

            if (!connected) return;

            cleanup = effect(api);
        },
    });
};

export type StartConnectedStatefulListenerArgs = Parameters<
    ReturnType<typeof startConnectedStatefulListener>
>;

/** Manage a collection of RPC streams based on interest.
 *
 * The result of calling this function is intended to be used as the final
 * arguments to `startConnectedStatefulListener`.
 *
 * @param selector a selector that returns an array of ids. For each id present
 * in the result, a stream will be kept open, until the id is removed from the
 * result.
 * @param thunk the thunk to dispatch to open the correct kind of stream
 * @name a string to identify the manager in the logs
 */
export const multiRpcStreamManager = <T>(
    selector: AnySelector<[], T[]>,
    thunk:
        | AsyncThunk<void, T, AppAsyncThunkConfig>
        | ((arg: T) => AsyncThunkAction<void, unknown, AppAsyncThunkConfig>),
    name: string,
): StartConnectedStatefulListenerArgs => [
    () => {
        const d = new Map<T, { abort: () => void; }>();
        let interest: T[] = [];

        return (api: ListenerAPI, state: RootState) => {
            const newInterest = selector(state);
            const interestDiff = diff(interest, newInterest);

            interestDiff.added?.forEach(id => {
                // Can't reasonably check whether the stream has ended
                // from here.
                if (!d.get(id)) {
                    d.set(id, api.dispatch(thunk(id)));
                }
            });
            interestDiff.removed?.forEach(id => {
                const s = d.get(id);
                d.delete(id);
                s?.abort();
            });

            interest = newInterest;
        };
    },
    selector,
    name,
];

const debugStoreName = (name?: string | boolean): string => {
    if (!name) return "";
    // Value present in config, just to signal the listener should be started
    if (name === true) return "";
    return name + " ";
};

/** Log something on every store dispatch.
 *
 * @param appendListener the function to add the listener
 * @param storeName a prefix to prepend to identify different stores.
 * Useful for tests where multiple stores run in the same JS context.
 * @return a cleanup function to remove the listener
 */
export const startDebugListening = (
    appendListener: AppAppendListener = appAppendListener,
    storeName?: string | boolean,
) => {
    const name = debugStoreName(storeName);
    return appendListener(
        {
            predicate: () => true,
            effect: (action, api) => {
                // For some reason, actions that are filtered out by our
                // proxy dynamicMiddleware still appear here as if they
                // were actioned by the store, even though they have not been!

                const local = actionHasLocalTag(action);
                const proxied = getActionProxyTag(action);
                const { broadcast } = selectStoreConfig(api.getState());

                if (!broadcast && !(local || proxied)) return;

                const actionMode = local ? "local "
                    : proxied ? "proxied " : "";
                const tag = proxied ? ` (${proxied})` : "";
                log.debug(
                    `${name}Dispatched ${actionMode}action ${action.type}${tag}`,
                    action.payload,
                );
            },
        },
    );
};
