import { HealthCheckRequest } from "../../gen/proto/grpc/health/v1/health_pb";

import { grpcHealthService } from "@/api/grpc";
import { updateHasHealthCheckFailedStatus } from "@/features/connection";
import log from "@/misc/log";
import { ListenerAPI } from "@/store/middleware.ts";
import { isSharedWorker, sharedWorkerAvailable } from "@/workers/util";

type Sample = {
    ts: number;
    type: "rtt";
    rttMs: number;
} | {
    ts: number;
    type: "event";
    event: string;
};

function summaryStats(samples: Sample[]) {
    if (samples.length === 0) return undefined;

    const rttSamples = samples.filter(s => s.type === "rtt");
    const justRtts = rttSamples.map(s => s.rttMs);

    if (rttSamples.length === 0) return undefined;

    const sum = justRtts.reduce((a, b) => a + b, 0);
    const mean = sum / justRtts.length;
    const min = Math.min(...justRtts);
    const max = Math.max(...justRtts);
    const count = justRtts.length;
    const median = justRtts.sort((a, b) => a - b)[Math.floor(justRtts.length / 2)];
    const duration = samples[samples.length - 1].ts - samples[0].ts;
    const mostRecent = rttSamples[rttSamples.length - 1];

    return {
        mean,
        median,
        min,
        max,
        count,
        duration,
        mostRecent,
    };
}

export class ConnectionRttWatcher {
    private startTime = performance.now();
    private abortController = new AbortController();

    // Window of most recent RTT samples
    private samples: Sample[] = [];
    // Exponentially weighted moving average of RTT
    private ewma: number = 0;

    private lastReportTime = 0;

    private readonly initialPollDelayMs = 2500;
    private readonly initialPeriodMs = 30000;
    private readonly laterPollDelayMs = 10000;
    private readonly reportIntervalMs = 5 * 60 * 1000;
    private readonly maxSamples = 50;
    public readonly healthCheckTimeoutMs = 3000;

    private readonly healtCheckFailedCallback: () => void;

    constructor(healtCheckFailedCallback?: () => void) {
        this.healtCheckFailedCallback = healtCheckFailedCallback ?? (() => {});
    }

    enable() {
        this.pushSample({ ts: performance.now(), type: "event", event: "enabled" });
        this.abortController.abort();
        this.abortController = new AbortController();

        this.schedule();
    }

    disable() {
        this.pushSample({ ts: performance.now(), type: "event", event: "disabled" });
        this.abortController.abort("Disabled");
    }

    private pushSample(sample: Sample) {
        this.samples.push(sample);
        if (this.samples.length > this.maxSamples) {
            this.samples.shift();
        }
    }

    private schedule() {
        const signal = this.abortController.signal;
        setTimeout(() => this.next(signal), this.nextDelayMs());
    }

    private next(signal: AbortSignal) {
        if (signal.aborted) return;

        const startTime = performance.now();

        // Calling the health check with an empty service name (the default) should
        // always give us an immediate "OK" response. We're not trying to ask if
        // any particular service is healthy, just if the connection is up.
        grpcHealthService
            .check(new HealthCheckRequest(), {
                signal: AbortSignal.any([
                    AbortSignal.timeout(this.healthCheckTimeoutMs),
                    this.abortController.signal,
                ]),
            })
            .then(_resp => {
                const endTime = performance.now();
                this.addSample(endTime, endTime - startTime);
            })
            .then(() => this.schedule())
            .catch(e => {
                if (e === "Disabled") return;
                log.warn("Health check failed", e);
                this.pushSample({
                    ts: performance.now(),
                    type: "event",
                    event: e.name ?? "Unknown exception",
                });
                this.healtCheckFailedCallback();
            });
    }

    private nextDelayMs() {
        if (performance.now() - this.startTime < this.initialPeriodMs) {
            return this.initialPollDelayMs;
        }
        return this.laterPollDelayMs;
    }

    private addSample(now: number, rttMs: number) {
        this.pushSample({ ts: now, rttMs, type: "rtt" });

        if (this.ewma === 0) this.ewma = rttMs;
        else this.ewma = rttMs * 0.2 + this.ewma * 0.8;

        if ((now > this.lastReportTime + this.reportIntervalMs) && this.samples.length > 5) {
            this.logBriefStats();
            this.lastReportTime = now;
        }
    }

    private logBriefStats() {
        const stats = summaryStats(this.samples);
        if (!stats) {
            return;
        }

        log.info(
            `Connection RTT: ewma=${this.ewma.toFixed(2)}ms last=${
                stats.mostRecent.rttMs.toFixed(2)
            }ms stats=${stats.min.toFixed(2)}/${stats.median.toFixed(2)}/${
                stats.max.toFixed(2)
            },${stats.count},${stats.duration.toFixed(2)}`,
        );
    }

    statsDump(): string {
        const stats = summaryStats(this.samples);
        if (!stats) {
            return "No data yet";
        }

        const now = performance.now();

        const since = (ts: number) => `${(now - ts).toFixed(2)}ms ago`;

        const buf = [
            "Connection RTT stats:",
            `EWMA: ${this.ewma.toFixed(2)}ms`,
            `Sample count: ${stats.count}`,
            `Duration: ${stats.duration.toFixed(2)}ms`,
            `Mean: ${stats.mean.toFixed(2)}ms`,
            `Median: ${stats.median.toFixed(2)}ms`,
            `Min: ${stats.min.toFixed(2)}ms`,
            `Max: ${stats.max.toFixed(2)}ms`,
            `Most recent: ${stats.mostRecent.rttMs.toFixed(2)}ms ${since(stats.mostRecent.ts)}`,
            "",
            "Samples:",
        ];

        this.samples.forEach(s => {
            if (s.type === "rtt") buf.push(`  ${since(s.ts)}: ${s.rttMs.toFixed(2)}ms`);
            else buf.push(`  ${since(s.ts)}: ${s.event}`);
        });

        return buf.join("\n");
    }
}

type ConnectionRttMessage = {
    request: "stats";
    cookie: number;
} | {
    request: "stats-reply";
    cookie: number;
    stats: string;
};

function getChannel() {
    return new BroadcastChannel(`connection-rtt`);
}

let currentRttWatcher: ConnectionRttWatcher | null = null;

export async function getConnectionRttStats(): Promise<string | undefined> {
    if (sharedWorkerAvailable()) {
        const channel = getChannel();
        const cookie = Math.random();

        return new Promise<string | undefined>(resolve => {
            const listener = (e: MessageEvent<ConnectionRttMessage>) => {
                if (e.data.request === "stats-reply" && e.data.cookie === cookie) {
                    channel.removeEventListener("message", listener);
                    resolve(e.data.stats);
                }
            };

            channel.addEventListener("message", listener);
            channel.postMessage({ request: "stats", cookie: cookie } as ConnectionRttMessage);
        });
    }

    if (!currentRttWatcher) return undefined;
    return currentRttWatcher.statsDump();
}

export function startConnectionRttWatcher(api: ListenerAPI) {
    if (!currentRttWatcher) {
        const onTimeout = () => {
            api.dispatch(updateHasHealthCheckFailedStatus(true));
        };
        currentRttWatcher = new ConnectionRttWatcher(onTimeout);

        if (isSharedWorker()) {
            const channel = getChannel();

            channel.addEventListener("message", (e: MessageEvent<ConnectionRttMessage>) => {
                if (e.data.request === "stats") {
                    channel.postMessage({
                        request: "stats-reply",
                        cookie: e.data.cookie,
                        stats: currentRttWatcher?.statsDump(),
                    } as ConnectionRttMessage);
                }
            });
        }
    }

    currentRttWatcher.enable();
    return () => currentRttWatcher?.disable();
}

// Export the dump to the global scope for debugging in the browser console
if (typeof globalThis !== "undefined") {
    (globalThis as any).dumpConnectionRttStats = async () =>
        (await getConnectionRttStats())?.split("\n");
}
