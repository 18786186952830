import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
    AnswerDeltaOrOther,
    askQuery,
    DeltaSummarySquadOrOther,
    getBondTitleSuggestion,
    getDeltaSummaryBond,
    getDeltaSummarySquad,
    IntelSummaryStreamingStatus,
    QueryAnswerStatus,
} from "@/api/intel";
import * as d from "@/domain/domain";
import { WithDraftTarget } from "@/domain/draftTarget";
import { QueryId, SummaryRequestStatus } from "@/domain/intel";
import { selectCurrentUserId } from "@/features/auth";
import { createProxiedAsyncThunk } from "@/features/proxiedThunk";
import {
    clearSquadSummary,
    pushDeltaSummarySquad,
    updateSquadKnowledgeMetadata,
    updateSquadSummaryRequestStatus,
} from "@/features/squads";

import { streamThunkHandler, TrimmedThunkAPI, unaryThunkHandler } from "@/features/thunk";
import log from "@/misc/log";
import { Optional, PartialRecord } from "@/misc/types";

type FetchBondTitleSuggestionArgs = {
    bondContentDraft: string;
} & WithDraftTarget;

export const fetchBondTitleSuggestionThunk = createProxiedAsyncThunk(
    "intel/fetchBondTitleSuggestion",
    async ({ bondContentDraft, draftTarget }: FetchBondTitleSuggestionArgs, thunkAPI) => {
        const state = thunkAPI.getState();
        const userId = selectCurrentUserId(state);
        if (!userId) {
            throw thunkAPI.rejectWithValue({
                error: `Tried to fetch bond title suggestion but did not find current user id`,
            });
        }

        const title = await unaryThunkHandler(
            thunkAPI,
            getBondTitleSuggestion({
                userId,
                bondContentDraft,
            }),
            "fetchBondTitleSuggestion",
        );
        return { title, draftTarget };
    },
);

interface fetchDeltaKnowledgeBondArgs {
    bondId: d.BondId;
    lastReadSequenceNumber: number;
}

export const fetchDeltaKnowledgeBondThunk = createProxiedAsyncThunk(
    "intel/fetchDeltaKnowledgeBond",
    async (
        { bondId, lastReadSequenceNumber }: fetchDeltaKnowledgeBondArgs,
        thunkAPI,
    ) => {
        const state = thunkAPI.getState();
        const userId = selectCurrentUserId(state);
        if (!userId) {
            throw thunkAPI.rejectWithValue({
                error: `Tried to fetch bond delta knowledge long but did not find current user id`,
            });
        }

        return await unaryThunkHandler(
            thunkAPI,
            getDeltaSummaryBond({
                userId,
                bondId,
                lastReadSequenceNumber,
            }),
            "fetchDeltaKnowledgeBond",
        );
    },
);

export interface fetchDeltaKnowledgeSquadArg {
    squadId: d.SquadId;
}

export const fetchDeltaKnowledgeSquadThunk = createProxiedAsyncThunk(
    "intel/fetchDeltaKnowledgeSquad",
    async (args: fetchDeltaKnowledgeSquadArg, thunkAPI) => {
        const state = thunkAPI.getState();
        const userId = selectCurrentUserId(state)!;
        if (!userId) {
            throw thunkAPI.rejectWithValue({
                error: `Tried to fetch squad delta knowledge long but did not find current user id`,
            });
        }
        log.info(`Sending fetch delta knowledge squad request, squad id ${args.squadId}`);
        return await streamThunkHandler(
            thunkAPI,
            getDeltaSummarySquad({ userId, squadId: args.squadId }),
            getDeltaSummarySquadParser(args, thunkAPI),
            args.squadId,
        );
    },
);

const getDeltaSummarySquadParser =
    ({ squadId }: fetchDeltaKnowledgeSquadArg, thunkAPI: TrimmedThunkAPI) =>
    (t: DeltaSummarySquadOrOther) => {
        switch (t.case) {
            case "summaryPiece": {
                thunkAPI.dispatch(pushDeltaSummarySquad({ squadId, summary: t.summary }));
                break;
            }
            case "metadata": {
                thunkAPI.dispatch(clearSquadSummary(squadId));
                thunkAPI.dispatch(updateSquadKnowledgeMetadata({ squadId, metadata: t.metadata }));
                break;
            }
            case "status": {
                parseIntelSummaryStatusChange(squadId, t.status, thunkAPI);
                break;
            }
        }
    };

const parseIntelSummaryStatusChange = (
    squadId: d.SquadId,
    status: IntelSummaryStreamingStatus,
    thunkAPI: TrimmedThunkAPI,
) => {
    switch (status) {
        case IntelSummaryStreamingStatus.DONE: {
            thunkAPI.dispatch(
                updateSquadSummaryRequestStatus({
                    squadId,
                    status: SummaryRequestStatus.Done,
                }),
            );
            break;
        }
        case IntelSummaryStreamingStatus.RESPONDING:
        case IntelSummaryStreamingStatus.SENDING_METADATA: {
            thunkAPI.dispatch(
                updateSquadSummaryRequestStatus({
                    squadId,
                    status: SummaryRequestStatus.ProcessingRequest,
                }),
            );
            break;
        }
        case IntelSummaryStreamingStatus.ERROR: {
            thunkAPI.dispatch(
                updateSquadSummaryRequestStatus({
                    squadId,
                    status: SummaryRequestStatus.Error,
                }),
            );
            break;
        }
        default: {
            break;
        }
    }
};

interface QueryArgs {
    question: string;
    queryId: QueryId;
}

// write a function that does the streaming
// createstreamingthunk is just boilerplate removal
// this will be function (arg,thunkapi) and will do streaming as implementation

export const queryThunk = createProxiedAsyncThunk(
    "intel/query",
    async (args: QueryArgs, thunkAPI) => {
        const state = thunkAPI.getState();
        const userId = selectCurrentUserId(state)!;
        const logId = `query-${args.queryId}`;

        return await streamThunkHandler(
            thunkAPI,
            askQuery({ userId, question: args.question }),
            queryStreamParser(args, thunkAPI),
            logId,
        );
    },
);

const queryStreamParser =
    (arg: QueryArgs, thunkAPI: TrimmedThunkAPI) => (t: AnswerDeltaOrOther) => {
        const id = arg.queryId;

        switch (t.case) {
            case "answer": {
                thunkAPI.dispatch(pushQueryAnswer({ queryId: id, answer: t.answer }));
                break;
            }
            case "search": {
                thunkAPI.dispatch(setSearchResults({ queryId: id, results: t.results }));
                break;
            }
            case "progress": {
                thunkAPI.dispatch(setProgress({ queryId: id, status: t.status }));
                break;
            }
        }
    };

interface Query {
    queryId: QueryId;
    question: string;
    answer: string;
    searchResults: string;
    status: QueryAnswerStatus;
}

const emptyQuery = (queryId: QueryId): Query => ({
    queryId,
    question: "",
    answer: "",
    searchResults: "",
    status: QueryAnswerStatus.UNSPECIFIED,
});

export interface IntelState {
    currentQueryId: Optional<QueryId>;
    queries: PartialRecord<QueryId, Query>;
}

const initialState: IntelState = {
    currentQueryId: undefined,
    queries: {},
};

const intelSlice = createSlice({
    name: "intel",
    initialState,
    selectors: {
        currentQueryId: state => state.currentQueryId,
        queries: state => state.queries,
        queryById: (state, queryId: QueryId) => state.queries[queryId],
        queryAnswer: (state, queryId: QueryId) => state.queries[queryId]?.answer,
        searchResults: (state, queryId: QueryId) => state.queries[queryId]?.searchResults,
        queryStatus: (state, queryId: QueryId) => state.queries[queryId]?.status,
        queryQuestion: (state, queryId: QueryId) => state.queries[queryId]?.question,
    },
    reducers: {
        updateQueryId(state, action: PayloadAction<QueryId>) {
            state.currentQueryId = action.payload;
        },
        updateQueryQuestion(
            state,
            { payload: { queryId, question } }: PayloadAction<
                { queryId: QueryId; question: string; }
            >,
        ) {
            if (!state.queries[queryId]) {
                state.queries[queryId] = emptyQuery(queryId);
            }
            state.queries[queryId].question = question;
        },
        pushQueryAnswer(
            state,
            { payload: { queryId, answer } }: PayloadAction<{ queryId: QueryId; answer: string; }>,
        ) {
            if (!state.queries[queryId]) {
                state.queries[queryId] = emptyQuery(queryId);
            }
            state.queries[queryId].answer += answer;
        },
        purgeQuery(state, action: PayloadAction<QueryId>) {
            delete state.queries[action.payload];
            state.currentQueryId = undefined;
        },
        setProgress(
            state,
            { payload: { queryId, status } }: PayloadAction<
                { queryId: QueryId; status: QueryAnswerStatus; }
            >,
        ) {
            if (!state.queries[queryId]) {
                state.queries[queryId] = emptyQuery(queryId);
            }
            state.queries[queryId].status = status;
        },
        setSearchResults(
            state,
            { payload: { queryId, results } }: PayloadAction<
                { queryId: QueryId; results: string; }
            >,
        ) {
            if (!state.queries[queryId]) {
                state.queries[queryId] = emptyQuery(queryId);
            }
            state.queries[queryId].searchResults += results;
        },
    },
});

export const {
    currentQueryId: selectCurrentQueryId,
    queryById: selectQueryById,
    queryAnswer: selectQueryAnswer,
    searchResults: selectQuerySearchResults,
    queryStatus: selectQueryStatus,
    queryQuestion: selectQueryQuestion,
} = intelSlice.selectors;

export const {
    updateQueryQuestion,
    updateQueryId,
    pushQueryAnswer,
    purgeQuery,
    setProgress,
    setSearchResults,
} = intelSlice.actions;

export const reducer = intelSlice.reducer;

export const persistor = {
    stores: {},
};
