import { useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigation, useNavigationType } from "react-router-dom";

import { navStateSchema, pathToView, viewStackUtils, viewUtils } from "@/domain/views";
import { selectCurrentTopLevelView } from "@/features/filterPanel";
import { guessViewStack } from "@/features/views";
import useViewStackNavigate from "@/hooks/navigation/useViewStackNavigate";
import log from "@/misc/log";
import { useAppSelector } from "@/store/redux";

export default function ViewStackManager(): React.JSX.Element {
    // This component has have two roles it must fulfil:
    //
    // 1. adding URL state on first load
    // 2. updating the store on browser back/forward navigations

    const { pathname, state: originalUrlState } = useLocation();
    const currentView = useMemo(() => pathToView(pathname), [pathname]);

    const { state: navState } = useNavigation();
    const currentTopLevelView = useAppSelector(selectCurrentTopLevelView);

    const stackParse = navStateSchema.safeParse(originalUrlState);
    const haveViewStack = stackParse.success;
    const stack = stackParse.data?.viewStack;

    const firstLoadRef = useRef(true);

    const { navigateReplace } = useViewStackNavigate();

    const navType = useNavigationType();

    // Listen for browser navigations.
    useEffect(() => {
        if (navType !== "POP" || navState !== "idle" || !stack) return;

        navigateReplace(stack, { replace: true });
    }, [navType, pathname, navState, stack, navigateReplace]);

    // Ensure we always have the current viewstack in the URL state.
    // That means, on startup, guess what it should be and add it.
    useEffect(() => {
        if (!firstLoadRef.current) return;

        if (viewUtils.isLogin(currentView)) return;

        firstLoadRef.current = false;

        // Minimal fix for understanding "don't do this on /login"
        // TODO: improve integration of login views to viewstacks
        if (!haveViewStack) {
            const stack = guessViewStack(currentView, currentTopLevelView);
            log.info(`No viewstack on URL at ${pathname}`, currentView, stack);

            // TODO: use a 404-like page/view? This currently immediately
            // redirects you to /bond
            const ourStack = stack ?? viewStackUtils.defaultStack;
            navigateReplace(ourStack, { replace: true });
        }
    }, [
        navState,
        pathname,
        currentView,
        currentTopLevelView,
        haveViewStack,
        navigateReplace,
    ]);

    return <></>;
}
