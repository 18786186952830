import * as d from "@/domain/domain";

type UserMaxReadSeqNum = {
    channelId: d.ChannelId;
    sequenceNumber: number;
};

export type SequenceNumberWrapper = {
    case: "sequenceNumber";
    value: UserMaxReadSeqNum;
};

export type DeletedSequenceNumberWrapper = {
    case: "deletedId";
    value: d.ChannelId;
};

export type SequenceNumberOrDeleted = SequenceNumberWrapper | DeletedSequenceNumberWrapper;

export const unreadMessageCount = (sequenceNumber?: number, maxSequenceNumber?: number) => {
    if (maxSequenceNumber === undefined) {
        return 0;
    }

    return maxSequenceNumber - (sequenceNumber || 0);
};
