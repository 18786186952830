import { DiscoverBondFollowButton } from "@/components/gui/BondActions";
import TimeAgo from "@/components/gui/TimeAgo";
import * as d from "@/domain/domain";
import { viewUtils } from "@/domain/views";
import { selectCurrentUserId } from "@/features/auth";
import {
    selectBondById,
    selectBondImageUrl,
    selectBondTitle,
    selectChannelIdByBondId,
    selectDetailedBondSummary,
    selectDiscoverViewScrollTop,
    setDiscoverViewScrollTop,
} from "@/features/bonds";
import { selectIsRead, selectSortedUserIdsWithUnreadPair } from "@/features/channels";
import {
    selectBondIdsForDiscover,
    selectDiscoverFilter,
    setDiscoverFilter,
} from "@/features/filterPanel";
import { selectKnownSquadNames } from "@/features/squads";
import { selectUser } from "@/features/users";
import classNames from "classnames";

import { memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";

import { FilterPill } from "@/components/FilterPill";

import { useInterestedBonds } from "@/hooks/interest/useInterest";
import useViewStackNavigate from "@/hooks/navigation/useViewStackNavigate";
import useBooleanFeatureFlag from "@/hooks/useBooleanFeatureFlag";
import { useColourExtraction } from "@/hooks/useColourExtraction";
import useLoadMoreOnScroll from "@/hooks/useLoadMoreOnScroll";
import useSelectorArgs from "@/hooks/useSelectorArgs";
import { useShallowEqualsMemo } from "@/hooks/useShallowEquals";
import useSortedUsers from "@/hooks/useSortedUsers";
import { isMobileBrowser } from "@/misc/mobile";
import { useAppDispatch, useAppSelector } from "@/store/redux";

const initialNumberOfBondsShown = 12;
const incrementNumberOfBondsShown = 8;

function limitBondIds(bondOverviews: d.BondId[], cardLimit: number) {
    return {
        bondIds: bondOverviews.slice(0, cardLimit),
        isLimited: bondOverviews.length > cardLimit,
    };
}

function UserName({
    userId,
    comma,
    and,
    last,
    additional,
    isRead,
}: {
    userId: d.UserId;
    comma: boolean;
    and: boolean;
    last: boolean;
    additional: number;
    isRead: boolean;
}): React.JSX.Element {
    const currentUserId = useAppSelector(selectCurrentUserId);
    const user = useSelectorArgs(selectUser, userId);
    const classes = classNames("c-mention", {
        "c-mention--read": isRead,
    });
    const userName = userId == currentUserId ? "You" : user?.nickname || "";

    return (
        <span className={classes}>
            {userName}
            {comma && ", "}
            {and && " & "}
            {last && !!additional && ` + ${additional}`}
            {!comma && !and && " "}
        </span>
    );
}

function PreviewBondContributors({
    previewUsers,
    isRead,
}: {
    previewUsers: d.UserId[];
    isRead: boolean;
}): React.JSX.Element {
    const processed = useShallowEqualsMemo(() => {
        const slicedPreviewUsers = previewUsers.slice(0, 3);
        const additionalUsers = previewUsers.length - slicedPreviewUsers.length;

        return {
            slicedPreviewUsers,
            additionalUsers,
        };
    }, [previewUsers]);

    return (
        <>
            {processed.slicedPreviewUsers.map((cid, idx, arr) => {
                const last = idx + 1 === arr.length;
                const and = idx + 2 === arr.length;
                const comma = idx + 2 < arr.length;

                return (
                    <UserName
                        key={cid}
                        userId={cid}
                        comma={comma}
                        and={and}
                        last={last}
                        additional={processed.additionalUsers}
                        isRead={isRead}
                    />
                );
            })}
        </>
    );
}

function BondCardContributors({
    bondId,
}: {
    bondId: d.BondId;
}): React.JSX.Element {
    const channelId = useAppSelector(selectChannelIdByBondId(bondId));
    const sortedUsersWithUnreadPair = useSortedUsers(
        selectSortedUserIdsWithUnreadPair,
        channelId,
    );
    return (
        <PreviewBondContributors
            previewUsers={sortedUsersWithUnreadPair}
            isRead={false}
        />
    );
}

const defaultColour = "rgb(100, 125, 155, 0.6)";

const DiscoverCard = memo(
    ({ bondId }: { bondId: d.BondId; }): React.JSX.Element => {
        const [backgroundColour, setBackgroundColour] = useState(
            defaultColour,
        );
        const loadImageAndExtractColour = useColourExtraction(defaultColour);
        const isMobile = isMobileBrowser();

        const channelId = useAppSelector(selectChannelIdByBondId(bondId));
        const isRead = useSelectorArgs(selectIsRead, channelId);

        const imgSrc = useSelectorArgs(selectBondImageUrl, bondId);

        const bo = useSelectorArgs(selectBondById, bondId);
        const bondTitle = useSelectorArgs(selectBondTitle, bondId);

        const bondSummary = useSelectorArgs(selectDetailedBondSummary, bondId) || "";

        // FIXME: duplicated with BondCard
        const showEmoji = useBooleanFeatureFlag("display-bond-emoji");
        const title = bondTitle.title;

        const emojiElement = showEmoji && bondTitle.emoji ?
            (
                <span
                    className={classNames("c-card-discover__emoji", {
                        "c-card-discover__emoji--desktop": !isMobile,
                    })}
                >
                    {bondTitle.emoji}
                </span>
            ) : null;

        const squadIds = bo?.squadIds || [];
        const squadNames = useSelectorArgs(selectKnownSquadNames, squadIds);
        const timeFrom = bo?.lastActivityAt || 0;

        useEffect(() => {
            loadImageAndExtractColour(imgSrc).then(c => {
                if (c) setBackgroundColour(c);
            });
        }, [imgSrc, loadImageAndExtractColour]);

        const { navigatePush } = useViewStackNavigate();
        const onClick = () => navigatePush(viewUtils.singleBond(bondId));

        const cardClasses = classNames("c-card-discover", {
            "c-card-discover--desktop": !isMobile,
            "c-card-discover--read": isRead,
        });

        const cardImageWrapperClasses = classNames(
            "c-card-discover__image-wrapper",
            {
                "c-card-discover__image-wrapper--desktop": !isMobile,
            },
        );

        const cardImageClasses = classNames("c-card-discover__image", {
            "c-card-discover__image--desktop": !isMobile,
        });

        const cardTitleClasses = classNames("c-card-discover__title", {
            "c-card-discover__title--desktop": !isMobile,
        });

        const cardSummaryClasses = classNames("c-card-discover__summary", {
            "c-card-discover__summary--desktop": !isMobile,
        });

        const cardFooterClasses = classNames("c-card-discover__footer", {
            "c-card-discover__footer--desktop": !isMobile,
        });

        return (
            <div className="c-card-discover-wrapper" onClick={onClick}>
                <div className={cardClasses} style={{ backgroundColor: backgroundColour }}>
                    <figure className={cardImageWrapperClasses}>
                        <img
                            src={imgSrc}
                            alt=""
                            className={cardImageClasses}
                            loading="lazy"
                        />
                    </figure>
                    <div className="c-card-discover__details">
                        <div className="c-discover-meta__squad">{squadNames.join(", ")}</div>
                        <h3 className={cardTitleClasses}>{emojiElement}{title}</h3>
                        <p className={cardSummaryClasses}>{bondSummary}</p>
                    </div>
                    <div className={cardFooterClasses}>
                        <div className="c-discover-meta">
                            <time className="c-discover-meta__time">
                                <TimeAgo from={timeFrom} live={true} />
                            </time>
                        </div>
                        <div className="c-discover-meta">
                            <div className="c-discover-meta__participants">
                                <BondCardContributors bondId={bondId} />
                            </div>
                        </div>
                        <DiscoverBondFollowButton
                            id={bondId}
                            isFollowed={false}
                            isMobile={isMobile}
                        />
                    </div>
                </div>
            </div>
        );
    },
);

export default function DiscoverView(): React.JSX.Element {
    const allBondIds = useAppSelector(selectBondIdsForDiscover);
    const [cardLimit, setCardLimit] = useState(initialNumberOfBondsShown);
    const { bondIds, isLimited } = limitBondIds(allBondIds, cardLimit);
    useInterestedBonds(bondIds);
    const endDivRef = useRef<HTMLDivElement>(null);

    const loadMore = useCallback(() => {
        if (isLimited) {
            setCardLimit(x => x + incrementNumberOfBondsShown);
        }
    }, [isLimited, setCardLimit]);
    const loadMoreOnScroll = useLoadMoreOnScroll(endDivRef, loadMore);

    const dispatch = useAppDispatch();
    const savedScrollPosition = useAppSelector(selectDiscoverViewScrollTop);
    const scrollableAreaRef = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
        const current = scrollableAreaRef.current;
        return () => {
            dispatch(setDiscoverViewScrollTop(current?.scrollTop ?? 0));
        };
    }, [dispatch]);
    useLayoutEffect(() => {
        if (scrollableAreaRef.current) {
            scrollableAreaRef.current?.scrollTo({
                top: savedScrollPosition,
                behavior: "instant",
            });
        }
    }, [savedScrollPosition]);

    return (
        <>
            <FilterPill
                options={["all", "unread"]}
                selectFilterAction={setDiscoverFilter}
                selectFilterSelector={selectDiscoverFilter}
            />
            <div
                className="c-content"
                onScroll={loadMoreOnScroll}
                ref={scrollableAreaRef}
            >
                <div className="c-cards-discover">
                    {bondIds.map(bondId => <DiscoverCard key={bondId} bondId={bondId} />)}
                </div>
                <div ref={endDivRef} />
            </div>
        </>
    );
}
