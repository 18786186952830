import {
    bondHasSquadId,
    bondIsArchived,
    bondIsFollowed,
    bondIsNotArchived,
    bondIsNotFollowed,
    BondOverviewPredicate,
    hasImage,
    heraldHasSquadId,
    heraldIsFollowed,
} from "@/domain/bonds";
import * as d from "@/domain/domain";
import { Optional } from "@/misc/types";
import { createAppSelector, selectCurrentUserId } from "@/store/redux";
import { selectArchivedBondsSet, selectBondHeralds, selectBonds } from "./bonds";
import { bondIsUnread, selectAllStagedSequenceNumbers, selectHasMentions } from "./channels";
import { selectFilter } from "./filterPanel";
import { memoizeOptions } from "./selectors";

// Sort and filter implementations:
const selectBondsForSquad = createAppSelector(
    [selectBonds, (_state, id: Optional<d.SquadId>) => id],
    (previews, id) => {
        return previews.filter(bp => (id && bp.squadIds?.includes(id)) ?? false);
    },
);

// Sort and filter implementations:

// Possible filters:
// My Bonds, no filter:             followed && not dismissed
// My Bonds, dismissed filter:      followed && dismissed
// My Bonds, unread filter:         followed && not dismissed && unread  (?)
// Squad, no filter:                squad
// Squad: unread filter:            squad && unread
export const selectBondIdsForBondList = createAppSelector(
    [
        selectBonds,
        selectCurrentUserId,
        selectArchivedBondsSet,
        selectAllStagedSequenceNumbers,
        selectFilter,
        (_state, squadId: Optional<d.SquadId>) => squadId,
    ],
    (previews, currentUserId, archivedSet, stagedSequenceNumberMap, filter, squadId) => {
        const predicates: BondOverviewPredicate[] = [];

        if (squadId !== undefined) {
            // With a squad filter applied, all bonds for that squad
            predicates.push(bondHasSquadId(squadId));

            // For squads, only the "unread" filter means anything
            if (filter === "unread") {
                predicates.push(bondIsUnread(stagedSequenceNumberMap));
            }
        }
        else {
            // Without, only followed bonds (Inbox)
            predicates.push(bondIsFollowed(currentUserId));

            if (filter === "all") {
                // For My Bonds, the "all" filter actually means "not dismissed"
                predicates.push(bondIsNotArchived(archivedSet));
            }
            else if (filter === "dismissed") {
                predicates.push(bondIsArchived(archivedSet));
            }
            else {
                // For My Bonds, the "unread" filter means "not dismissed and unread"
                predicates.push(bondIsNotArchived(archivedSet));
                predicates.push(bondIsUnread(stagedSequenceNumberMap));
            }
        }

        return previews.filter(bo => predicates.every(p => p(bo))).map(bo => bo.id);
    },
);

/** selectInterestingBondIdsForBondList produces a list of bond IDs which are a
 * superset of the list of bond IDs produced by selectBondIdsForBondList, using
 * only the information available in the bond heralds.
 * These bond IDs should be then used to register interest in the bond, so that
 * the bond can be fetched and filtered properly.
 */
export const selectInterestingBondIdsForBondList = createAppSelector(
    [
        selectBondHeralds,
        (_state, squadId: Optional<d.SquadId>) => squadId,
    ],
    (heralds, squadId) => {
        // With a squad filter applied, include all bonds for that squad
        // Without, include only followed bonds (Inbox)
        const predicate = (squadId !== undefined) ? heraldHasSquadId(squadId) : heraldIsFollowed;
        return heralds.filter(bo => predicate(bo)).map(bo => bo.bondId);
    },
    memoizeOptions.weakMapShallow,
);

// Hackity hack. A quick and dirty frontend only implementation of bonds we might like
// to discover.
export const selectBondIdsForDiscover = createAppSelector(
    [
        selectBonds,
        selectCurrentUserId,
        selectArchivedBondsSet,
        selectAllStagedSequenceNumbers,
    ],
    (previews, currentUserId, archivedSet) => {
        const predicates: BondOverviewPredicate[] = [];

        predicates.push(bondIsNotArchived(archivedSet));
        predicates.push(bondIsNotFollowed(currentUserId));
        predicates.push(hasImage);

        return previews.filter(bo => predicates.every(p => p(bo))).map(bo => bo.id);
    },
);

export const selectUnreadBondsForSquad = createAppSelector(
    [
        selectBondsForSquad,
        state => selectAllStagedSequenceNumbers(state),
    ],
    (bonds, stagedSequenceNumberMap) => bonds.filter(bondIsUnread(stagedSequenceNumberMap)),
    memoizeOptions.weakMap,
);

const selectAllFollowedBonds = createAppSelector(
    [selectBonds, selectCurrentUserId],
    (overviews, currentUserId) => {
        if (!currentUserId) {
            return [];
        }

        return overviews.filter(bo => bo.followers?.includes(currentUserId) ?? false);
    },
);

export const selectNumberOfFollowedUnreadMentions = createAppSelector(
    [
        selectAllFollowedBonds,
        selectAllStagedSequenceNumbers,
        selectArchivedBondsSet,
        state => state,
    ],
    (followed, stagedSequenceNumberMap, archivedBondSet, state) => {
        const isUnread = bondIsUnread(stagedSequenceNumberMap);
        const numMentioned = followed
            .filter(
                bo =>
                    !archivedBondSet.has(bo.id) &&
                    isUnread(bo) &&
                    selectHasMentions(state, bo.channelId),
            ).length;

        return numMentioned;
    },
    memoizeOptions.weakMapShallow,
);

export const selectOfflineNumberOfFollowedUnreadBonds = createAppSelector(
    [
        selectAllFollowedBonds,
        selectAllStagedSequenceNumbers,
        selectArchivedBondsSet,
    ],
    (followed, stagedSequenceNumberMap, archivedBondSet) => {
        const isUnread = bondIsUnread(stagedSequenceNumberMap);
        const numUnread = followed
            .filter(bo => !archivedBondSet.has(bo.id) && isUnread(bo));

        return numUnread.length;
    },
    memoizeOptions.weakMapShallow,
);
