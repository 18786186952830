import { createImpureSelector } from "@/misc/impureSelector";
import { BoxSize, Optional, Point } from "@/misc/types";
import { useEffect, useState } from "react";

interface ClientBounds {
    layoutViewportSize: BoxSize;
    visualViewportSize: Optional<BoxSize>;
    visualViewportOffset: Optional<Point>;
}

/**
 * Return the size of the initial containing block.
 *
 * The layout viewport size (100dvw/dvh) is not directly available in JS,
 * but is equivalent to the size of the initial containing block
 * https://drafts.csswg.org/css2/#containing-block-details
 */
const getICBSize = createImpureSelector(
    () => document.documentElement.clientWidth,
    () => document.documentElement.clientHeight,
    (width, height) => ({ width, height }),
);

/**
 * Return the size of the visual viewport, or undefined if the visual viewport
 * API is not available.
 *
 * The visual viewport size is potentially smaller than the layout viewport;
 * for example, when the on-screen keyboard is up or we are pinch zooming
 */
const getVisualViewportSize = createImpureSelector(
    () => window.visualViewport?.width,
    () => window.visualViewport?.height,
    (width, height) =>
        (width === undefined || height === undefined) ?
            undefined :
            ({ width, height }),
);

/**
 * Return the offset of the visual viewport, or undefined if the visual viewport
 * API is not available.
 *
 * When the visual viewport is smaller than the layout viewport, its offset
 * within the layout viewport is returned here
 */
const getVisualViewportOffset = createImpureSelector(
    () => window.visualViewport?.offsetLeft,
    () => window.visualViewport?.offsetTop,
    (x, y) =>
        (x === undefined || y === undefined) ?
            undefined :
            ({ x, y }),
);

/**
 * Return the memoised current client bounds
 */
const getClientBounds = createImpureSelector(
    getICBSize,
    getVisualViewportSize,
    getVisualViewportOffset,
    (layoutViewportSize, visualViewportSize, visualViewportOffset): ClientBounds => ({
        layoutViewportSize,
        visualViewportSize,
        visualViewportOffset,
    }),
);

export const useClientBounds = (): ClientBounds => {
    // Yes, we have to call getClientBounds with `undefined` because reselect
    // expects at least one argument to every selector (since they are supposed
    // to be pure functions---here we're just using the memoization)
    const [bounds, setBounds] = useState(getClientBounds());

    useEffect(() => {
        const onResize = () => setBounds(getClientBounds());

        document.documentElement.addEventListener("resize", onResize);
        window.visualViewport?.addEventListener("resize", onResize);

        return () => {
            document.documentElement.removeEventListener("resize", onResize);
            window.visualViewport?.removeEventListener("resize", onResize);
        };
    }, []);

    return bounds;
};

export default useClientBounds;
