/** A decent default string hash function.
 *
 * @param s string to hash
 * @return the hash
 */
export const djb2Hash = <S extends string = string>(s: S): number => {
    let h = 0;
    for (let i = 0; i < s.length; i++) {
        h = ((h << 5) - h) + s.charCodeAt(i);
        h |= 0;
    }
    return h >>> 0;
};
