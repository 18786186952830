import { djb2Hash } from "@/misc/hash";
import { modulo } from "@/misc/utils";

export const getGradientFromName = (name: string) => {
    const nameHash = djb2Hash(name);
    const hue = modulo(nameHash, 360);
    const saturation = 40;
    const lightness = 50;
    const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    return color;
};

export type RGB = [number, number, number];
export type HSL = [number, number, number];
export type OKLCH = [number, number, number];

export const rgbToHsl = ([r, g, b]: RGB): HSL => {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    const l = (max + min) / 2;
    let s: number;
    let h = 0;

    if (max === min) {
        h = s = 0; // achromatic
    }
    else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
        }
        h /= 6;
    }

    return [h * 360, s * 100, l * 100];
};

export function rgbToOklch([r, g, b]: RGB): OKLCH {
    // First convert RGB to linear RGB
    const lr = linearize(r / 255);
    const lg = linearize(g / 255);
    const lb = linearize(b / 255);

    // Convert to XYZ
    const x = 0.4124564 * lr + 0.3575761 * lg + 0.1804375 * lb;
    const y = 0.2126729 * lr + 0.7151522 * lg + 0.0721750 * lb;
    const z = 0.0193339 * lr + 0.1191920 * lg + 0.9503041 * lb;

    // Convert to LMS
    const l = 0.8189330101 * x + 0.3618667424 * y - 0.1288597137 * z;
    const m = 0.0329845436 * x + 0.9293118715 * y + 0.0361456387 * z;
    const s = 0.0482003018 * x + 0.2643662691 * y + 0.6338517070 * z;

    // Non-linear transformation
    const lp = cubeRoot(l);
    const mp = cubeRoot(m);
    const sp = cubeRoot(s);

    // Convert to Lab'
    const L = 0.2104542553 * lp + 0.7936177850 * mp - 0.0040720468 * sp;
    const M = 1.9779984951 * lp - 2.4285922050 * mp + 0.4505937099 * sp;
    const S = 0.0259040371 * lp + 0.7827717662 * mp - 0.8086757660 * sp;

    // Calculate Oklch components
    const lightness = L;
    const chroma = Math.sqrt(M * M + S * S);
    let hue = Math.atan2(S, M) * (180 / Math.PI);

    // Normalize hue to 0-360 range
    if (hue < 0) {
        hue += 360;
    }

    // lightness is in the range [0,1], chroma is in the range ~[0,0.37] ?
    return [lightness, chroma, hue];
}

// Helper function to convert sRGB to linear RGB
function linearize(value: number): number {
    if (value <= 0.04045) {
        return value / 12.92;
    }
    return Math.pow((value + 0.055) / 1.055, 2.4);
}

// Helper function for cube root that handles negative numbers
function cubeRoot(value: number): number {
    return Math.sign(value) * Math.pow(Math.abs(value), 1 / 3);
}
