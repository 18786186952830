import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import log from "@/misc/log";

// This is a bit naff -- it's just to work around React re-mounting this component and
// therefore us producing more logging than we strictly need to. Strict mode means we
// otherwise end up with extra log lines.
let lastPathLogged = "";

export function LocationLogger(): React.JSX.Element {
    const { pathname, hash, search } = useLocation();
    const href = `${pathname}${hash}${search}`;

    useEffect(() => {
        if (href !== lastPathLogged) {
            log.info(`Location changed: ${href}`);
            lastPathLogged = href;
        }
    }, [href]);

    return <></>;
}
