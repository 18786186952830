import { createRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { completeAuthRequest, existsPasscode, resendPasscode, verifyPasscode } from "@/api/auth";
import { finalLoginSteps } from "@/auth/login";
import { upsertOrgs } from "@/features/squads";
import useCountdown from "@/hooks/useCountdown";
import useIntervalAction from "@/hooks/useIntervalAction";
import log from "@/misc/log";
import { isMobileBrowser } from "@/misc/mobile";
import { useAppDispatch } from "@/store/redux";
import classNames from "classnames";
import { DateTime } from "luxon";
import { LocationStateSchema } from "./state";
import useRefreshOnLogin from "./useRefreshOnLogin";

const createRefs = (count: number): React.RefObject<HTMLInputElement>[] => {
    const refs: React.RefObject<HTMLInputElement>[] = [];
    for (let i = 0; i < count; i++) {
        refs.push(createRef<HTMLInputElement>());
    }
    return refs;
};

const getCodeLength = (letters: string[]): number => {
    return letters.findIndex(letter => letter === "");
};

const VerificationInput = ({ onSignIn }: { onSignIn: (code: string) => Promise<void>; }) => {
    const [code, setCode] = useState<string[]>(["", "", "", "", "", "", "", ""]);
    const [isValid, setIsValid] = useState(false);
    const inputRefs = useRef<React.RefObject<HTMLInputElement>[]>(createRefs(8));
    const [isSigningIn, setIsSigningIn] = useState(false);
    const [isFailed, setIsFailed] = useState(false);

    const codeLength = useMemo(() => getCodeLength(code), [code]);

    const focusInput = useCallback((index: number) => {
        const targetRef = inputRefs.current[index]?.current;
        if (targetRef) {
            targetRef.disabled = false;
            targetRef.focus();
        }
    }, []);

    useEffect(() => {
        focusInput(codeLength);
    }, [codeLength, focusInput]);

    useEffect(() => {
        const isComplete = code.every(letter => letter !== "");
        const isPartial = code.some(letter => letter !== "");
        setIsValid(isComplete);
        if (isPartial) {
            setIsFailed(false);
        }
    }, [code]);

    const handlePaste = useCallback((pastedData: string) => {
        if (!/^[a-zA-Z]+$/.test(pastedData)) return;

        const newCode = [...code];
        pastedData.substring(0, 8).split("").forEach((letter: string, index: number) => {
            newCode[index] = letter.toUpperCase();
        });
        setCode(newCode);
    }, [code]);

    const handleChange = useCallback((index: number, value: string) => {
        if (!/^[a-zA-Z]*$/.test(value)) return;

        // Pasting from passcode on mobile doesn't trigger onPaste event,
        // instead it types the entire passcode into the first input.
        if (value.length > 1) {
            handlePaste(value);
            return;
        }

        setCode(prevCode => {
            const newCode = [...prevCode];
            newCode[index] = value.toUpperCase();

            return newCode;
        });
    }, [handlePaste]);

    const handleKeyDown = useCallback((index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Backspace" && !code[index] && index > 0) {
            setCode(prevCode => {
                const newCode = [...prevCode];
                newCode[index - 1] = "";
                return newCode;
            });
            focusInput(index - 1);
        }
    }, [code, focusInput]);

    useEffect(() => {
        if (isValid) {
            setIsSigningIn(true);
            onSignIn(code.join(""))
                .then(() => {
                    // Nothing to do here - we'll navigate in the parent component
                })
                .catch(e => {
                    log.error(`Failed to sign in: ${e}`);
                    setCode(["", "", "", "", "", "", "", ""]);
                    setIsFailed(true);
                    setIsValid(false);
                })
                .finally(() => setIsSigningIn(false));
        }
    }, [
        code,
        isValid,
        onSignIn,
        setCode,
        setIsFailed,
    ]);

    const statusDiv = () => {
        if (isFailed) {
            return <div className="c-signin__error">The code is invalid. Please try again.</div>;
        }
        if (!isValid && code.some(x => x !== "")) {
            return <div className="c-signin__error">Please enter all 8 letters</div>;
        }
        if (isSigningIn) {
            return (
                <div className="c-signin__validation">
                    <div className="c-spinner-inline"></div>
                    Verifying…
                </div>
            );
        }
        return <></>;
    };

    return (
        <>
            <div className="c-signin__element c-signin__element--code">
                {code.map((letter, index) => (
                    <input
                        key={index}
                        ref={inputRefs.current[index]}
                        type="text"
                        value={letter}
                        onChange={e => handleChange(index, e.target.value)}
                        onKeyDown={e => handleKeyDown(index, e)}
                        onPaste={e => {
                            e.preventDefault();
                            handlePaste(e.clipboardData.getData("text").slice(0, 8));
                        }}
                        className={`c-signin__input-code ${
                            letter ? "c-signin__input-code--filled" : ""
                        }`}
                        disabled={isSigningIn}
                        aria-label={"Passcode"}
                        onFocus={() => {
                            if (codeLength < 8) {
                                focusInput(codeLength);
                            }
                        }}
                        autoFocus={index === 0}
                    />
                ))}
            </div>
            {statusDiv()}
        </>
    );
};

export default function PasscodeView(): React.JSX.Element {
    const [params, _] = useSearchParams();
    const authReqId = params.get("auth_request_id");

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const locationState = LocationStateSchema.safeParse(location.state);
    const { codeVerifier, isNewUser, isTargetedInvite, userEmail, userId } = locationState.success
        ? locationState.data : {};

    const [resendButtonEnabled, setResendButtonEnabled] = useState(true);
    const enableResendButton = useCallback(() => setResendButtonEnabled(true), []);
    const { durationStr: resendDurationStr, setDeadline: setResendDeadline } = useCountdown({
        onDeadline: enableResendButton,
    });

    const checkPasscodeExists = useCallback(async () => {
        if (!authReqId || !codeVerifier || !userId) {
            log.error("No userId/auth req creds provided");
            return;
        }

        try {
            const resp = await existsPasscode(userId, {
                authRequestId: authReqId,
                codeVerifier,
            });

            if (!resp.isSuccess) {
                log.error(`Failed to check passcode: ${resp.reason}`);
                return;
            }

            const { exists } = resp.response;
            if (!exists) {
                log.info("Passcode no longer exists. Redirecting to login.");
                navigate("/login", { replace: true });
            }
        }
        catch (e) {
            log.error(`Failed to check if passcode exists: ${e}`);
        }
    }, [authReqId, codeVerifier, userId, navigate]);

    useIntervalAction({ action: checkPasscodeExists, interval: 10000 });

    const signIn = useCallback(async (code: string) => {
        if (!authReqId || !codeVerifier || !userId) {
            throw new Error(`Invalid state to sign in (no authReqId, codeVerifier, or userId)`);
        }

        const passcodeResp = await verifyPasscode(userId, code, {
            authRequestId: authReqId,
            codeVerifier,
        });
        if (!passcodeResp.isSuccess) {
            throw new Error(`Failed to sign in: ${passcodeResp.reason}`);
        }

        if (isNewUser) {
            const userDetailsUrl = `/login/details?auth_request_id=${authReqId}`;
            navigate(userDetailsUrl, { replace: true, state: { codeVerifier, userId } });
            return;
        }

        const completeAuthResp = await completeAuthRequest(authReqId, codeVerifier);
        if (!completeAuthResp.isSuccess) {
            throw new Error(`Failed to complete auth request: ${completeAuthResp.reason}`);
        }
        const { callbackUri } = completeAuthResp.response;

        const cbResponse = await fetch(callbackUri, {
            headers: {
                "X-Avos-302-To-200": "true",
            },
        });

        const [_id, usersAndOrgs, redirectUri] = await finalLoginSteps(cbResponse);

        // !!! We never get here (yet). The above function will reload the window.

        // This is pointless, but harmless, today because we're not running on a store that
        // we're going to continue using (after setting the user ID and reloading below
        // we'll transition to an indexedDB). But I leave it here to show we could make
        // use of the org data we're provided in the future.
        dispatch(upsertOrgs(usersAndOrgs.filter(x => !!x.org).map(u => u.org)));

        navigate(redirectUri);
    }, [authReqId, codeVerifier, isNewUser, navigate, userId, dispatch]);

    const resendUserPasscode = useCallback(async () => {
        if (!resendButtonEnabled) {
            return;
        }
        if (!authReqId || !codeVerifier || !userId) {
            log.error("No auth request ID provided");
            return;
        }

        log.info(`Resending passcode for auth request ${authReqId}`);

        setResendButtonEnabled(false);
        setResendDeadline(DateTime.now().plus({ seconds: 7 }));

        try {
            const resp = await resendPasscode(userId, {
                authRequestId: authReqId,
                codeVerifier,
            });
            if (!resp.isSuccess) {
                throw new Error(`Failed to resend passcode: ${resp.reason}`);
            }

            log.info("Passcode resent");
        }
        catch (e) {
            log.error(`Failed to resend passcode: ${e}`);
        }
    }, [authReqId, codeVerifier, resendButtonEnabled, setResendDeadline, userId]);

    useRefreshOnLogin();

    if (!authReqId) {
        log.error("No auth request ID provided");
        return <Navigate to="/login" />;
    }

    if (!userId || !userEmail) {
        log.error("No user ID or email provided");
        return <Navigate to="/login" />;
    }

    const isMobile = isMobileBrowser();
    const columnClasses = classNames("c-section-signin__column c-section-signin__column--dark", {
        "c-section-signin__column--mobile": isMobile,
    });
    const signinClasses = classNames("c-signin", {
        "c-signin--desktop": !isMobile,
    });
    const legendClasses = classNames("c-signin__legend", {
        "c-signin__legend--desktop": !isMobile,
    });

    return (
        <main className="">
            <section className="c-section-signin">
                <div className={columnClasses}>
                    <h1 className="c-logo c-logo--signin">Bond</h1>
                </div>
                <div className="c-section-signin__column">
                    <fieldset className={signinClasses}>
                        <h2 className={legendClasses}>
                            Check your email
                        </h2>
                        <div className="c-signin__message">
                            A link was sent to <strong>{userEmail}</strong>.<br />
                            Continue with the link or enter code:
                        </div>
                        <VerificationInput onSignIn={signIn} />
                        <div className="c-signin__meta">
                            Taking too long?{" "}
                            <a
                                onClick={resendUserPasscode}
                                className={resendButtonEnabled ? "c-signin__link" : ""}
                            >
                                Resend the link
                            </a>
                            {resendDurationStr && ` (${resendDurationStr})`}
                            {!isTargetedInvite ? (
                                <>
                                    {" "}or <Link to="/login" replace>change email</Link>.
                                </>
                            ) : <>.</>}
                        </div>
                    </fieldset>
                </div>
            </section>
        </main>
    );
}
