import { StoreEnhancer } from "redux";
import log from "./log";

const runPerf = true;

const round = (n: number) => Math.round(n * 100) / 100;

export const timeFn = <T>(f: () => T, cb?: (n: number) => void): T => {
    if (!runPerf || !cb) return f();

    const start = performance.now();
    const r = f();
    const end = performance.now();

    cb(round(end - start));

    return r;
};

interface PerformanceMetrics {
    actionTypeDurations: Record<string, number[]>;
}

// Define enhancer options interface
interface EnhancerOptions {
    slowActionThresholdMs?: number; // in milliseconds
    reportIntervalSeconds?: number;
}

const summarisePerformanceJSON = async (metrics: PerformanceMetrics) => {
    log.info(`Action dispatch performance`, JSON.stringify(metrics.actionTypeDurations));
};

/**
 * Creates a Redux enhancer that adds performance monitoring
 * @param options Configuration options for the enhancer
 */
export const createPerformanceEnhancer = (
    options: EnhancerOptions = {},
): StoreEnhancer => {
    const {
        slowActionThresholdMs = 20,
        reportIntervalSeconds = 60,
    } = options;

    let metrics: PerformanceMetrics;

    const replaceMetrics = (): PerformanceMetrics => {
        const original = metrics;
        metrics = {
            actionTypeDurations: {},
        };
        return original;
    };
    replaceMetrics();
    let reportTimeoutHandle: ReturnType<typeof setTimeout> | null = null;

    return createStore => (reducer, preloadedState?) => {
        const store = createStore(reducer, preloadedState);

        return {
            ...store,
            dispatch: action =>
                timeFn(() => store.dispatch(action), n => {
                    if (!metrics.actionTypeDurations[action.type]) {
                        metrics.actionTypeDurations[action.type] = [];
                    }
                    metrics.actionTypeDurations[action.type].push(n);

                    if (n > slowActionThresholdMs) {
                        log.warn(
                            `Slow action detected: ${action.type} took ${n.toFixed(2)}ms`,
                        );
                    }

                    if (!reportTimeoutHandle) {
                        reportTimeoutHandle = setTimeout(() => {
                            const data = replaceMetrics();

                            reportTimeoutHandle = null;

                            summarisePerformanceJSON(data);
                        }, reportIntervalSeconds * 1000);
                        //
                    }
                }),

            // Add method to get performance metrics
            getMetrics: () => ({
                ...metrics,
            }),
        };
    };
};
