import { z } from "zod";

export const LocationStateSchema = z.object({
    codeVerifier: z.string(),
    isNewUser: z.boolean(),
    isTargetedInvite: z.boolean(),
    userEmail: z.string().email(),
    userId: z.string().min(1),
});

export type LocationState = z.infer<typeof LocationStateSchema>;
