import { createEntityAdapter, EntityState, PayloadAction } from "@reduxjs/toolkit";

import {
    DeviceGroup,
    DeviceInfo,
    DeviceKind,
    DeviceKind_Type,
    deviceKinds,
    mediaGroupDeviceKinds,
    toDeviceGroup,
} from "@/domain/mediaDevices";
import { resetStore } from "@/features/auth";
import { createLocalAsyncThunk } from "@/features/localThunk";
import { memoizeOptions } from "@/features/selectors";
import log from "@/misc/log";
import { filterRecordByValue } from "@/misc/primatives";
import { Optional, PartialRecord } from "@/misc/types";
import { checkPersistor, localStorageStore } from "@/persist/shared";
import { storageRead, storageRemove } from "@/persist/storage";
import type { HydrateArgs, PersistenceUpdate } from "@/persist/types";
import { createLocalSlice } from "@/store/localSlice";
import { createAppSelector } from "@/store/redux";
import type { RootState } from "@/store/types";

export enum DevicePermissions {
    None,
    Pending,
    Granted,
    Denied,
}

type RawDeviceId = string;
type TaggedDeviceId = `${DeviceKind_Type}:${RawDeviceId}`;
function extractRawDeviceId(deviceId: TaggedDeviceId): RawDeviceId {
    return deviceId.substring(deviceId.indexOf(":") + 1);
}
function parseRawDeviceId(deviceId: RawDeviceId, deviceKind: DeviceKind_Type): TaggedDeviceId {
    return `${deviceKind}:${deviceId}`;
}

// Use an EntityAdapter to maintain the insertion order when iterating over devices
// (default devices are enumerated first).
// ID by device kind then device ID, so we can store multiple devices with the
// same id (e.g. "default").
const devicesAdapter = createEntityAdapter({
    selectId: (device: DeviceInfo) => parseRawDeviceId(device.id, device.kind),
});

const removeDevicesForGroup = (state: DevicesState, deviceGroup: Optional<DeviceGroup>) => {
    if (deviceGroup) {
        mediaGroupDeviceKinds[deviceGroup].forEach(k => {
            state.devices = devicesAdapter.removeMany(
                state.devices,
                getDeviceIdsByKind(state.devices.entities, k),
            );
        });
    }
    else {
        state.devices = devicesAdapter.removeAll(state.devices);
    }

    return state.devices;
};

function toDeviceKind(kind: MediaDeviceKind): DeviceKind_Type {
    switch (kind) {
        case "audioinput":
            return DeviceKind.AudioInput;
        case "audiooutput":
            return DeviceKind.AudioOutput;
        case "videoinput":
            return DeviceKind.VideoInput;
    }
}

function toDeviceInfo(d: MediaDeviceInfo): DeviceInfo {
    return {
        id: d.deviceId,
        kind: toDeviceKind(d.kind),
        label: d.label,
    };
}

function updateDeviceMap(deviceMap: DeviceMap, kind: DeviceKind_Type, deviceId?: RawDeviceId) {
    if (deviceId) {
        deviceMap[kind] = deviceId;
    }
    else {
        delete deviceMap[kind];
    }
}

function updateDeviceMapFromTagged(
    deviceMap: DeviceMap,
    kind: DeviceKind_Type,
    deviceId: TaggedDeviceId,
) {
    updateDeviceMap(deviceMap, kind, extractRawDeviceId(deviceId));
}

function getDevicesByKind(
    devices: Record<TaggedDeviceId, DeviceInfo>,
    kind: DeviceKind_Type,
): PartialRecord<TaggedDeviceId, DeviceInfo> {
    return filterRecordByValue(devices, (d: DeviceInfo) => d?.kind === kind);
}

const isDeviceInfo = (d: Optional<DeviceInfo>): d is DeviceInfo => !!d;
function getDeviceIdsByKind(
    devices: Record<TaggedDeviceId, DeviceInfo>,
    kind: DeviceKind_Type,
): TaggedDeviceId[] {
    return Object.values(getDevicesByKind(devices, kind)).filter(isDeviceInfo).map(
        d => parseRawDeviceId(d.id, d.kind),
    );
}

type DeviceMap = PartialRecord<DeviceKind_Type, RawDeviceId>;
type PermissionsMap = Record<DeviceGroup, DevicePermissions>;
type SetDeviceArgs = {
    deviceId?: RawDeviceId;
    kind: DeviceKind_Type;
};
type SetTestPermissionsArgs = {
    deviceGroup?: DeviceGroup;
    permissions: DevicePermissions;
};

function getEmptyPermissionsMap(): PermissionsMap {
    return {
        [DeviceGroup.Audio]: DevicePermissions.None,
        [DeviceGroup.Video]: DevicePermissions.None,
    };
}

// Get the constraints for fetching user media for the device kind,
// returning constraints for all devices if undefined.
function getContraints(group?: DeviceGroup): MediaStreamConstraints {
    switch (group) {
        case DeviceGroup.Audio:
            return { audio: true };
        case DeviceGroup.Video:
            return { video: true };
        default:
            return { audio: true, video: true };
    }
}

export const fetchAllDevices = createLocalAsyncThunk(
    "mediaDevices/fetchAllDevices",
    async (group: Optional<DeviceGroup>, thunkAPI) => {
        let stream;
        try {
            // Request device permissions (needed to view device labels),
            // which may have been manually revoked by the user
            log.info("Requesting permissions to enumerate devices...");
            stream = await navigator.mediaDevices.getUserMedia(getContraints(group));

            // Refresh the list of devices now we have permissions
            log.info("Permissions granted. Enumerating devices...");
            const devices = await navigator.mediaDevices.enumerateDevices();
            log.debug("Devices enumerated: ", devices.length);

            // Filter devices for the requested kind
            const kindsForGroup = group ? mediaGroupDeviceKinds[group] : deviceKinds;
            const devicesForKind = devices.filter(d => kindsForGroup.includes(toDeviceKind(d.kind)))
                .map(toDeviceInfo);

            // Set default (i.e. enumerated first) device's label if empty
            kindsForGroup.forEach(kind => {
                const defaultDevice = devicesForKind.find(d => d.kind === kind);
                if (defaultDevice?.label === "") {
                    defaultDevice.label = "Default";
                }
            });

            return devicesForKind;
        }
        catch (err) {
            thunkAPI.abort();
            log.error(err);
            log.info(`Exception when fetching devices: ${err}`);
            return thunkAPI.rejectWithValue({ error: JSON.stringify(err) });
        }
        finally {
            // Close the stream created by `getUserMedia`
            stream?.getTracks().forEach(t => t.stop());
        }
    },
);

export interface DevicesState {
    // The device info, gathered by enumerating devices
    devices: EntityState<DeviceInfo, TaggedDeviceId>;
    // The device permissions, set when enumerating devices
    permissions: PermissionsMap;
    // The currently selected and preferred devices by kind. This allows us to
    // distinguish between devices the user has explicitly selected
    // vs devices used when the preferred device is unavailable.
    currentDevices: DeviceMap;
    preferredDevices: DeviceMap;
}

const getInitialState = (props?: { preferred?: DeviceMap; }): DevicesState => {
    return {
        devices: devicesAdapter.getInitialState(),
        permissions: getEmptyPermissionsMap(),
        currentDevices: {} as DeviceMap,
        preferredDevices: props?.preferred ?? {} as DeviceMap,
    };
};

const updatePermissions = (
    map: PermissionsMap,
    permissions: DevicePermissions,
    deviceGroup?: DeviceGroup,
): PermissionsMap => {
    if (!deviceGroup) {
        return {
            [DeviceGroup.Audio]: permissions,
            [DeviceGroup.Video]: permissions,
        };
    }

    return { ...map, [deviceGroup]: permissions };
};

export const mediaDevicesSlice = createLocalSlice({
    name: "mediaDevices",
    initialState: getInitialState(),
    reducers: {
        resetPermissions: state => {
            state.permissions = getEmptyPermissionsMap();
        },
        setCurrentDevice: (state, action: PayloadAction<SetDeviceArgs>) => {
            const { kind, deviceId } = action.payload;
            // Update both preferred and current device
            updateDeviceMap(state.preferredDevices, kind, deviceId);
            updateDeviceMap(state.currentDevices, kind, deviceId);
        },
        setPreferredDevice: (state, action: PayloadAction<SetDeviceArgs>) => {
            const { kind, deviceId } = action.payload;
            updateDeviceMap(state.preferredDevices, kind, deviceId);
        },
        setTestPermissions: (state, action: PayloadAction<SetTestPermissionsArgs>) => {
            const { deviceGroup, permissions } = action.payload;
            state.permissions = updatePermissions(state.permissions, permissions, deviceGroup);
        },
    },
    selectors: {
        permissions: state => state.permissions,
        currentDevice: (state, kind?: DeviceKind_Type): Optional<RawDeviceId> =>
            kind && state.currentDevices[kind],
        preferredDevice: (state, kind?: DeviceKind_Type): Optional<RawDeviceId> =>
            kind && state.preferredDevices[kind],
    },
    extraReducers: builder => {
        builder.addCase(fetchAllDevices.pending, (state, action) => {
            const deviceGroup = action.meta.arg;

            // Reset permissions before refreshing the list of devices,
            // as the user may have revoked them manually
            state.permissions = updatePermissions(
                state.permissions,
                DevicePermissions.Pending,
                deviceGroup,
            );
        });
        builder.addCase(fetchAllDevices.fulfilled, (state, action) => {
            const deviceGroup = action.meta.arg;
            const newDevices = action.payload;

            // Replace devices for kind (if specified)
            state.devices = removeDevicesForGroup(state, deviceGroup);
            state.devices = devicesAdapter.addMany(state.devices, newDevices);

            for (const kind of Object.values(DeviceKind)) {
                const deviceIds = getDeviceIdsByKind(state.devices.entities, kind);
                if (deviceIds.length == 0) {
                    // Keep original preferred device if no devices of same kind available
                    updateDeviceMap(state.currentDevices, kind, undefined);
                    continue;
                }

                // Update preferred device if it is not yet set
                const preferredDevice = state.preferredDevices[kind];
                if (!preferredDevice) {
                    updateDeviceMapFromTagged(state.preferredDevices, kind, deviceIds[0]);
                }

                // Update current device, choosing preferred device first
                const preferredTaggedId = preferredDevice ? parseRawDeviceId(preferredDevice, kind)
                    : undefined;
                if (preferredTaggedId && state.devices.entities[preferredTaggedId]) {
                    updateDeviceMapFromTagged(state.currentDevices, kind, preferredTaggedId);
                }
                else {
                    updateDeviceMapFromTagged(state.currentDevices, kind, deviceIds[0]);
                }
            }

            state.permissions = updatePermissions(
                state.permissions,
                DevicePermissions.Granted,
                deviceGroup,
            );
        });
        builder.addCase(fetchAllDevices.rejected, (state, action) => {
            const deviceGroup = action.meta.arg;

            state.permissions = updatePermissions(
                state.permissions,
                DevicePermissions.Denied,
                deviceGroup,
            );
            state.devices = removeDevicesForGroup(state, deviceGroup);
        });
        builder.addCase(resetStore, _state => {
            return getInitialState();
        });
    },
});

const adapterSelectors = devicesAdapter.getSelectors((state: RootState) =>
    state.mediaDevices.devices
);

const selectors = mediaDevicesSlice.getSelectors((state: RootState) => state.mediaDevices);
export const {
    currentDevice: selectCurrentDevice,
    preferredDevice: selectPreferredDevice,
} = selectors;

export const { resetPermissions, setTestPermissions } = mediaDevicesSlice.actions;
export function setCurrentDevice(kind: DeviceKind_Type, deviceId?: RawDeviceId) {
    return mediaDevicesSlice.actions.setCurrentDevice({ kind: kind, deviceId: deviceId });
}
export function setPreferredDevice(kind: DeviceKind_Type, deviceId?: RawDeviceId) {
    return mediaDevicesSlice.actions.setPreferredDevice({ kind: kind, deviceId: deviceId });
}

// Use both ids and entities in these selectors as ids will maintain insertion order
const selectDevicesByKind = createAppSelector(
    [adapterSelectors.selectEntities, (_state, kind) => kind],
    (devices, kind): PartialRecord<TaggedDeviceId, DeviceInfo> => getDevicesByKind(devices, kind),
);

export const selectAllDeviceIds = createAppSelector(
    [adapterSelectors.selectIds, selectDevicesByKind],
    (devicesIds, devicesForKind) => {
        return devicesIds.filter(id => devicesForKind[id]).map(extractRawDeviceId);
    },
    memoizeOptions.lruShallow,
);

export const selectDeviceByIdAndKind = (
    state: RootState,
    deviceId: RawDeviceId,
    deviceKind: DeviceKind_Type,
) => adapterSelectors.selectById(state, parseRawDeviceId(deviceId, deviceKind));

export const selectDevicePermissions = createAppSelector(
    [selectors.permissions, (_state, kind: Optional<DeviceKind_Type>) => kind],
    (permissions, deviceKind) =>
        // Grant permissions for non-media devices (i.e. Display)
        deviceKind ? permissions[toDeviceGroup(deviceKind)] : DevicePermissions.Granted,
);

export const reducer = mediaDevicesSlice.reducer;

// Persistence.

const stores = {
    // Stored in `localStorage` to ensure it survives browser restarts.
    preferredDevices: localStorageStore<Partial<DeviceMap>>("mediaDevices/preferred", 1),
};

const persist = (
    previousState: DevicesState,
    currentState: DevicesState,
): PersistenceUpdate[] => {
    if (previousState.preferredDevices == currentState.preferredDevices) {
        return [];
    }

    return [[stores.preferredDevices, currentState.preferredDevices]];
};

const hydrate = async ({ storage }: HydrateArgs) => {
    if (!storage) return getInitialState();

    const preferred = storageRead(stores.preferredDevices);

    return getInitialState({ preferred });
};

const purge = () => {
    storageRemove(stores.preferredDevices);
};

export const persistor = {
    stores,
    hydrate,
    persist,
    purge,
};
checkPersistor<DevicesState>(persistor);
