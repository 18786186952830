import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { reportApnsToken, reportFirebaseToken } from "@/api/client";
import {
    ackNotification as ackNotificationAPI,
    DismissedWrapper,
    dismissNotification as dismissNotificationAPI,
    NotificationOrDismissed,
    NotificationWrapper,
    streamNotifications as streamNotificationsAPI,
} from "@/api/notifications";
import * as d from "@/domain/domain";
import {
    CallStartWebNotificationDetails,
    ChatMessageWebNotificationDetails,
    NotificationPriority,
    WebNotification,
    WebNotificationType,
} from "@/domain/notifications";
import { AppViewStack, viewStackSchema, viewUtils } from "@/domain/views";
import { viewStackUtils } from "@/domain/views";
import { resetStore, selectCurrentUserId } from "@/features/auth";
import { createLocalAsyncThunk } from "@/features/localThunk";
import { createProxiedAsyncThunk } from "@/features/proxiedThunk";
import { createStreamingAsyncThunk } from "@/features/streamingThunk";
import { unaryThunkHandler } from "@/features/thunk";
import { isNativeAndroidPlatform, isNativeIosPlatform, isNativePlatform } from "@/misc/capacitor";
import { Optional } from "@/misc/types";
import { separateDiscriminatedUnion } from "@/misc/utils";
import { checkPersistor } from "@/persist/shared";
import type { RootState } from "@/store/types";
import { selectBondById } from "./bonds";
import { selectSquadIds } from "./squads";

export const dismissNotification = createProxiedAsyncThunk(
    "notifications/dismiss",
    async ({ id }: { id: d.NotificationId; }, thunkAPI) => {
        const state = thunkAPI.getState();
        const userId = selectCurrentUserId(state)!;

        return await unaryThunkHandler(
            thunkAPI,
            dismissNotificationAPI(userId, id),
            `dismissNotification ${id}`,
        );
    },
);

export const ackNotifications = createProxiedAsyncThunk(
    "notifications/ack",
    async (ids: d.NotificationId[], thunkAPI) => {
        if (ids.length === 0) return;

        const state = thunkAPI.getState();
        const userId = selectCurrentUserId(state)!;

        for (let i = 0; i < ids.length; i++) {
            await unaryThunkHandler(
                thunkAPI,
                ackNotificationAPI(userId, ids[i]),
                `ackNotification ${ids[i]}`,
            );
        }
    },
);

export const reportNotificationTokenThunk = createProxiedAsyncThunk(
    "notifications/reportNotificationToken",
    async ({ token }: { token: string; }, thunkAPI) => {
        if (!isNativePlatform) {
            return thunkAPI.rejectWithValue("Not running on a native platform");
        }

        if (!token) {
            return thunkAPI.rejectWithValue("Notification token is empty");
        }

        if (isNativeAndroidPlatform) {
            await unaryThunkHandler(
                thunkAPI,
                reportFirebaseToken(token),
                "reportFirebaseToken",
            );
        }

        if (isNativeIosPlatform) {
            await unaryThunkHandler(
                thunkAPI,
                reportApnsToken(token),
                "reportApnsToken",
            );
        }
    },
);

export const streamNotifications = createStreamingAsyncThunk<
    boolean | undefined,
    NotificationOrDismissed
>(
    "notifications/stream",
    {
        rpc: ({ arg: exclusive, state, signal }) => {
            const userId = selectCurrentUserId(state)!;
            return streamNotificationsAPI(userId, !!exclusive, signal);
        },
        parser: ({ thunkAPI, content }) => {
            const [dismissed, notified] = separateDiscriminatedUnion<
                DismissedWrapper,
                NotificationWrapper
            >(msg => msg.case === "dismissed", content);

            if (dismissed.length) {
                const ids = dismissed.map(msg => msg.id);
                thunkAPI.dispatch(dismissNotifications(ids));
            }

            if (notified.length) {
                const notifs = notified.map(msg => msg.notification);
                thunkAPI.dispatch(streamedNotifications(notifs));
                // TODO: It's not ideal to do 2 dispatches here.
                // But rather than introduce complexity into
                // `ackNotifications` (around dealing with possible
                // failures), let's keep it simple and do the dispatches
                // separately.
                thunkAPI.dispatch(ackNotifications(notifs.map(n => n.id)));
            }
        },
    },
);

const determineViewStack = (
    state: RootState,
    id: d.BondId,
    messageId?: d.MessageId,
): Optional<AppViewStack> => {
    const bond = selectBondById(state, id);
    if (!bond) return;

    const currentUserId = selectCurrentUserId(state);

    const mySquads = selectSquadIds(state);
    const theSquad = bond.squadIds.find(squadId => mySquads.includes(squadId));
    const parentView = theSquad ? viewUtils.squad(theSquad) :
        (currentUserId && bond.followers.includes(currentUserId)) ? viewUtils.inbox()
        : viewUtils.discover();
    const view = viewUtils.singleBond(id, messageId);

    const parentStack = viewStackUtils.defaultStackForView(parentView);
    if (parentStack) {
        return viewStackSchema.parse([...parentStack, view]);
    }
    else {
        // Shouldn't be possible, until the code changes somewhere else...
        return viewStackUtils.fallbackViewForBond(id, messageId);
    }
};

const chatMessageNotificationThunk = createLocalAsyncThunk(
    "notifications/action/chatMessage",
    (
        { messageId, bond: { id } }: ChatMessageWebNotificationDetails,
        thunkAPI,
    ): Optional<AppViewStack> => {
        const state = thunkAPI.getState();
        return determineViewStack(state, id, messageId);
    },
);

const callStartNotificationThunk = createLocalAsyncThunk(
    "notifications/action/callStart",
    ({ bond: { id } }: CallStartWebNotificationDetails, thunkAPI): Optional<AppViewStack> => {
        const state = thunkAPI.getState();
        return determineViewStack(state, id);
    },
);

// Stores formatted notification data for presentation. The thunk should be an
// `AsyncThunkAction` which returns a `string | null` value describing where to
// navigate after completing.
export interface DisplayNotification {
    id: d.NotificationId;
    iconUserId?: d.UserId;
    heading: string;
    content: string;
    timestamp: number;
    sticky: boolean;
    thunk?: any;
}

export function toDisplayNotification(
    notification: WebNotification,
): DisplayNotification {
    const sticky = notification.priority == NotificationPriority.TimeSensitive;
    switch (notification.details.case) {
        case WebNotificationType.Text:
            return {
                id: notification.id,
                heading: "Bond",
                content: notification.details.value.text,
                timestamp: notification.createdTs,
                sticky,
            };
        case WebNotificationType.ChatMessage: {
            const details = notification.details.value;
            return {
                id: notification.id,
                iconUserId: details.senderId,
                heading: details.bond.name,
                content: details.previewText,
                timestamp: notification.eventTs,
                sticky,
                thunk: chatMessageNotificationThunk(details),
            };
        }
        case WebNotificationType.CallStart: {
            const details = notification.details.value;
            return {
                id: notification.id,
                iconUserId: details.initiator.id,
                heading: `${details.bond.name} is live`,
                content: `${details.initiator.name} is live in ${details.bond.name}`,
                timestamp: notification.eventTs,
                sticky,
                thunk: callStartNotificationThunk(details),
            };
        }
    }
}

interface NotificationChangeAdd {
    type: "add";
    notification: WebNotification;
}

interface NotificationChangeRemove {
    type: "remove";
    id: d.NotificationId;
}

type NotificationChange = NotificationChangeAdd | NotificationChangeRemove;

export interface NotificationsState {
    queue: NotificationChange[];
}

const getInitialState = (props?: {
    queue?: NotificationChange[];
}): NotificationsState => ({
    queue: props?.queue || [],
});

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState: getInitialState(),
    reducers: {
        streamed: (state, action: PayloadAction<WebNotification>) => {
            state.queue.push({ type: "add", notification: action.payload });
        },
        streamedMany: (state, { payload: notifs }: PayloadAction<WebNotification[]>) => {
            state.queue.push(
                ...notifs.map(n => ({ type: "add", notification: n }) as NotificationChangeAdd),
            );
        },
        dismissed: (state, action: PayloadAction<d.NotificationId>) => {
            state.queue.push({ type: "remove", id: action.payload });
        },
        dismissMany: (state, { payload: ids }: PayloadAction<d.NotificationId[]>) => {
            state.queue.push(
                ...ids.map(id => ({ type: "remove", id: id }) as NotificationChangeRemove),
            );
        },
        consumeFromQueue: state => {
            state.queue.shift();
        },
    },
    extraReducers: builder => {
        builder.addCase(resetStore, _state => {
            return getInitialState();
        });
    },
});

export const selectNotificationQueue = (state: RootState): Optional<NotificationChange> =>
    state.notifications.queue[0];

export const consumeFromQueue = notificationsSlice.actions.consumeFromQueue;

const { streamedMany: streamedNotifications, dismissMany: dismissNotifications } =
    notificationsSlice.actions;

export default notificationsSlice.reducer;

export const reducer = notificationsSlice.reducer;

// Persistence.

export const persistor = {
    stores: {},
};
checkPersistor<NotificationsState>(persistor);
