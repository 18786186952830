import * as d from "@/domain/domain";
import { isDevEnv } from "@/misc/environment";
import log from "@/misc/log";
import { promiseWithResolvers } from "@/misc/promises";
import { nanoid } from "@reduxjs/toolkit";
import { deleteDB, IDBPDatabase, openDB, OpenDBCallbacks } from "idb";
import { z } from "zod";
import { AuthMachineStorage, AuthRequestStateSchema } from "./types";
import {
    ascii_to_uint8array,
    base64url_decode,
    base64url_encode,
    cbckey_from_string,
    decrypt_cbc,
    encrypt_cbc,
    uint8array_to_ascii,
} from "./util";

// These need to be 16 bytes. The idea is just to provide some obfuscation, not security.
const defaultEncryptionKeyString = "76031c6762678790";
const defaultEncryptionIv = ascii_to_uint8array("fb7ff654b65c40c3");

export class EncryptedStorageWrapper implements AuthMachineStorage {
    constructor(private readonly _next: AuthMachineStorage) {}

    async dispose() {
        if ("dispose" in this._next) return (this._next as any).dispose();
    }

    async load() {
        const data = await this._next.load();
        if (data === null) return null;

        try {
            const ciphertext = base64url_decode(data);
            const plaintext = await decrypt_cbc(
                await cbckey_from_string(defaultEncryptionKeyString),
                defaultEncryptionIv,
                ciphertext,
            );
            const ascii = uint8array_to_ascii(new Uint8Array(plaintext));
            return JSON.parse(ascii);
        }
        catch (_e) {
            return null;
        }
    }

    async save(state: any | null) {
        if (state === null) return this._next.save(state);

        const stateString = JSON.stringify(state);

        const ciphertext = await encrypt_cbc(
            await cbckey_from_string(defaultEncryptionKeyString),
            defaultEncryptionIv,
            ascii_to_uint8array(stateString),
        );
        const base64 = base64url_encode(ciphertext);
        await this._next.save(base64);
    }
}

export class IdbStorage implements AuthMachineStorage {
    private readonly callbacks: OpenDBCallbacks<unknown> = {
        upgrade(database, oldVersion, _newVersion, _transaction, _event) {
            if (oldVersion === 0) {
                database.createObjectStore(IdbStorage.storeName);
            }
        },
    };

    private static readonly storeName = "state";
    private static readonly keyName = "0";
    private dbPromise: Promise<IDBPDatabase<unknown>>;
    private dbName: string;

    constructor(userId: d.UserId) {
        this.dbName = `auth-${userId}`;
        this.dbPromise = openDB(this.dbName, 1, this.callbacks).then(db => {
            db.addEventListener("versionchange", ev => {
                // Close connections when the DB is being deleted
                if (ev.newVersion === null) {
                    db.close();
                }
            });
            return db;
        });
    }

    async dispose(del: boolean = false, wait: boolean = false) {
        const db = await this.getDB();
        db.close();

        if (del) {
            const delPromise = deleteDB(this.dbName)
                .catch(e => {
                    log.warn(`Failed to delete DB ${this.dbName}`, e);
                });
            if (wait) await delPromise;
        }
    }

    private async getDB() {
        return this.dbPromise;
    }

    async load() {
        const db = await this.getDB();

        const data = await db.get(IdbStorage.storeName, IdbStorage.keyName);

        if (data === undefined) return null;

        return data;
    }

    async save(state: any | null) {
        const db = await this.getDB();

        if (state === null) {
            await db.delete(IdbStorage.storeName, IdbStorage.keyName);
            return;
        }

        await db.put(IdbStorage.storeName, state, IdbStorage.keyName);
    }
}

export class BroadcastChannelStorage implements AuthMachineStorage {
    constructor(private readonly userId: d.UserId) {}

    private getBC() {
        // TODO: think about https://developer.mozilla.org/en-US/docs/Web/API/BroadcastChannel/close
        return new BroadcastChannel("auth-storage");
    }

    async load() {
        const bc = this.getBC();
        let cookie = nanoid();
        const { resolve, promise } = promiseWithResolvers<any>();

        const handleMessage = (ev: MessageEvent<any>) => {
            const parseResult = BroadcastChannelStorage.MessageSchema.safeParse(ev.data);

            if (!parseResult.success) return;

            const { type } = parseResult.data;

            if (
                type === "storage-load-response" &&
                parseResult.data.cookie === cookie &&
                parseResult.data.uid === this.userId
            ) {
                resolve(parseResult.data.storage);
            }
            else if (type === "storage-gratuitous-load-complete") {
                cookie = nanoid();
                const msg: BroadcastChannelStorageLoadRequestType = {
                    type: "storage-load-request",
                    cookie,
                    uid: this.userId,
                };
                bc.postMessage(msg);
            }
        };

        bc.addEventListener("message", handleMessage);

        const msg: BroadcastChannelStorageLoadRequestType = {
            type: "storage-load-request",
            cookie,
            uid: this.userId,
        };
        bc.postMessage(msg);

        const ret = await promise;
        bc.removeEventListener("message", handleMessage);

        return ret;
    }

    async save(state: any | null): Promise<void> {
        const bc = this.getBC();
        const cookie = nanoid();
        const { resolve, promise } = promiseWithResolvers<void>();

        const handleMessage = (ev: MessageEvent<any>) => {
            const parseResult = BroadcastChannelStorage.MessageSchema.safeParse(ev.data);
            if (!parseResult.success) return;

            if (
                parseResult.data.type === "storage-save-response" &&
                parseResult.data.cookie === cookie
            ) {
                resolve();
            }
        };
        bc.addEventListener("message", handleMessage);

        const msg: BroadcastChannelStorageSaveRequestType = {
            type: "storage-save-request",
            uid: this.userId,
            cookie,
            storage: state,
        };
        bc.postMessage(msg);

        await promise;

        bc.removeEventListener("message", handleMessage);
    }

    async dispose(del: boolean = false, _wait: boolean = false) {
        if (del) {
            window.localStorage.removeItem(`auth:${this.userId}`);
        }
    }

    // A note on the cookies used in these schemas. We need to handle ordering and
    // races between the tab running the localStorage code, and the shared worker.
    // The cookie allows precise matching of request and response, meaning we don't
    // use an old reply to a new request. The cookie is just a random ID.
    static readonly LoadRequestSchema = z.object({
        type: z.literal("storage-load-request"),
        cookie: z.string(),
        uid: d.userIdSchema,
    });

    static readonly LoadResponseSchema = z.object({
        type: z.literal("storage-load-response"),
        uid: d.userIdSchema,
        cookie: z.string(),
        storage: z.any(),
    });

    static readonly GratuitousLoadSchema = z.object({
        type: z.literal("storage-gratuitous-load-complete"),
    });

    static readonly SaveRequestSchema = z.object({
        type: z.literal("storage-save-request"),
        uid: d.userIdSchema,
        cookie: z.string(),
        storage: z.any(),
    });

    static readonly SaveResponseSchema = z.object({
        type: z.literal("storage-save-response"),
        cookie: z.string(),
    });

    static readonly MessageSchema = z.discriminatedUnion("type", [
        this.LoadRequestSchema,
        this.LoadResponseSchema,
        this.GratuitousLoadSchema,
        this.SaveRequestSchema,
        this.SaveResponseSchema,
    ]);
}

type BroadcastChannelStorageLoadRequestType = z.infer<
    typeof BroadcastChannelStorage.LoadRequestSchema
>;
type BroadcastChannelGratuitousLoadCompleteType = z.infer<
    typeof BroadcastChannelStorage.GratuitousLoadSchema
>;
type BroadcastChannelStorageSaveRequestType = z.infer<
    typeof BroadcastChannelStorage.SaveRequestSchema
>;
type BroadcastChannelStorageSaveResponseSchema = z.infer<
    typeof BroadcastChannelStorage.SaveResponseSchema
>;
type BroadcastChannelStorageLoadResponseType = z.infer<
    typeof BroadcastChannelStorage.LoadResponseSchema
>;

async function idbToLocalStorageMigrate(signal?: AbortSignal) {
    const migrateIdb = async (uid: d.UserId) => {
        const idbName = `auth-${uid}`;
        const lsName = `auth:${uid}`;

        const idbStorage = new IdbStorage(uid);
        const data = await idbStorage.load();
        await idbStorage.dispose(false, true);

        if (data !== null) window.localStorage.setItem(lsName, JSON.stringify(data));

        await deleteDB(idbName);
    };

    for (const db of await indexedDB.databases()) {
        if (signal?.aborted) return;

        try {
            if (db.name?.startsWith(`auth-`)) {
                const uid = d.parseUserUrn(db.name?.split("auth-")[1]);
                migrateIdb(uid).catch(_e => {});
            }
        }
        catch (err) {
            log.warn(`Failed to migrate auth idb ${db.name}`, err);
        }
    }
}

export function broadcastChannelStorageToLocalStorage() {
    const bc = new BroadcastChannel("auth-storage");

    const storageLoad = (uid: d.UserId, cookie: string) => {
        let storage: any = null;

        try {
            storage = window.localStorage.getItem(`auth:${uid}`);
            if (storage !== null) {
                storage = JSON.parse(storage);
            }
        }
        catch (err) {
            log.warn(`Error loading auth from localStorage`, err);
        }

        const msg: BroadcastChannelStorageLoadResponseType = {
            type: "storage-load-response",
            cookie,
            uid: uid,
            storage,
        };
        bc.postMessage(msg);
    };

    const handleMessage = (ev: MessageEvent<any>) => {
        const parseResult = BroadcastChannelStorage.MessageSchema.safeParse(ev.data);

        if (!parseResult.success) return;

        switch (parseResult.data.type) {
            case "storage-load-request":
                storageLoad(parseResult.data.uid, parseResult.data.cookie);
                break;

            case "storage-load-response":
            case "storage-save-response":
            case "storage-gratuitous-load-complete":
                break;

            case "storage-save-request": {
                if (parseResult.data.storage === null) {
                    window.localStorage.removeItem(`auth:${parseResult.data.uid}`);
                }
                else {
                    window.localStorage.setItem(
                        `auth:${parseResult.data.uid}`,
                        JSON.stringify(parseResult.data.storage),
                    );
                }
                const msg: BroadcastChannelStorageSaveResponseSchema = {
                    type: "storage-save-response",
                    cookie: parseResult.data.cookie,
                };
                bc.postMessage(msg);
                break;
            }
        }
    };

    const gratuitousLoad = () => {
        const msg: BroadcastChannelGratuitousLoadCompleteType = {
            type: "storage-gratuitous-load-complete",
        };
        bc.postMessage(msg);
    };

    const controller = new AbortController();

    // Deliberately don't `await` this. It gets cancelled in the cleanup function due to
    // the controller.abort().
    navigator.locks.request("auth-storage", {
        mode: "exclusive",
        signal: controller.signal,
    }, async _lock => {
        const pwr = promiseWithResolvers<Event>();
        controller.signal.addEventListener("abort", pwr.resolve);

        try {
            await idbToLocalStorageMigrate(controller.signal);

            bc.addEventListener("message", handleMessage);
            gratuitousLoad();

            await pwr.promise;
        }
        finally {
            controller.signal.removeEventListener("abort", pwr.resolve);
            bc.removeEventListener("message", handleMessage);
        }
    }).catch(_e => {});

    return () => {
        controller.abort();
    };
}

export class SessionStorage implements AuthMachineStorage {
    private static readonly storeName = "auth/authRequest";

    constructor() {
    }

    async load() {
        return window.sessionStorage.getItem(SessionStorage.storeName) ?? null;
    }

    async save(state: any | null) {
        if (state === null) {
            window.sessionStorage.removeItem(SessionStorage.storeName);
            return;
        }
        window.sessionStorage.setItem(SessionStorage.storeName, state);
    }
}

export function tokenStorage(userId: d.UserId): AuthMachineStorage {
    const baseStorage = new BroadcastChannelStorage(userId);

    if (isDevEnv) {
        return baseStorage;
    }

    return new EncryptedStorageWrapper(baseStorage);
}

export function authRequestStorage() {
    const baseStorage = new SessionStorage();

    if (isDevEnv) {
        return baseStorage;
    }

    return new EncryptedStorageWrapper(baseStorage);
}

export async function loadCodeVerifier(): Promise<string | null> {
    const data = await authRequestStorage().load();
    if (!data) {
        return null;
    }

    return AuthRequestStateSchema.parse(JSON.parse(data)).code_verifier;
}
