import { useEffect, useRef } from "react";

import { clearLoginSessionStorage } from "@/auth/login";
import { SystemMessage } from "@/components/SystemMessage";
import { AuthStatus, selectAuthStatus, updateAuthStatus } from "@/features/auth";
import log from "@/misc/log";
import { useAppDispatch, useAppSelector } from "@/store/redux";

// We want to reload the page to allow setting up the redux store for the correct
// signed-out state. Hence a window.location.href write rather than a react-router
// navigate().
function reloadLogin() {
    window.location.href = "/login";
}

export default function LogoutView(): React.JSX.Element {
    const dispatch = useAppDispatch();
    const authStatus = useAppSelector(selectAuthStatus);
    const initialAuthStatus = useRef(authStatus);

    useEffect(() => {
        clearLoginSessionStorage();

        if (
            initialAuthStatus.current === AuthStatus.ClearingAuthentication ||
            initialAuthStatus.current === AuthStatus.Unauthenticated ||
            initialAuthStatus.current === AuthStatus.Initialising ||
            initialAuthStatus.current === AuthStatus.NotStarted
        ) {
            log.info("/logout: already logged out, loading /login");
            reloadLogin();
        }
        else {
            dispatch(updateAuthStatus(AuthStatus.ClearingAuthentication));
        }
    }, [dispatch]);

    useEffect(() => {
        if (authStatus === AuthStatus.LoggedOutReloadRequired) {
            log.info("/logout: auth state cleared, reloading /login");
            reloadLogin();
        }
    }, [authStatus]);

    return <SystemMessage message="Logging you out" />;
}
