import * as d from "@/domain/domain";
import { useInView } from "react-intersection-observer";
import { useInterestedCalls, useInterestedSquads, useInterestedUsers } from "./useInterest";
import { OneNoneOrMany } from "./useRegisterInterest";

interface UseInViewInterestArgs {
    userIds?: OneNoneOrMany<d.UserId>;
    squadIds?: OneNoneOrMany<d.SquadId>;
    callIds?: OneNoneOrMany<d.CallId>;
}

export function useInViewInterest(args: UseInViewInterestArgs) {
    const { ref: inViewRef, inView } = useInView();

    useInterestedCalls(inView ? args.callIds : undefined);
    useInterestedSquads(inView ? args.squadIds : undefined);
    useInterestedUsers(inView ? args.userIds : undefined);

    return inViewRef;
}
