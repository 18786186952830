import { useCallback } from "react";

import { AppView, viewUtils } from "@/domain/views";
import { selectViewLinkPathAndViewStack } from "@/features/filterPanel";
import useViewStackNavigate from "@/hooks/navigation/useViewStackNavigate";
import useSelectorArgs from "@/hooks/useSelectorArgs";

export type LinkToViewButtonProps = {
    view: AppView;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

// Like a react-router <Link/>, but renders a <button> rather than an <a>.
// In general, for accessibility reasons, one should prefer an <a> for links,
// but given we're an SPA and it's not real navigation, it can make sense to
// use buttons in some cases.
//
// See https://stackoverflow.com/questions/42463263/wrapping-a-react-router-link-in-an-html-button
// for why we don't nest a <Link> inside a <button> or vice versa.
export const LinkToViewButton = (props: LinkToViewButtonProps) => {
    const { view, onClick: propOnClick, ...otherProps } = props;

    const { navigatePush, navigateReplace } = useViewStackNavigate();

    const [_, navState] = useSelectorArgs(selectViewLinkPathAndViewStack, view);

    const onButtonClick: React.MouseEventHandler<HTMLButtonElement> = useCallback(e => {
        const stack = navState?.viewStack;
        if (!stack) throw new Error(`Invalid view ${view.view} for LinkToViewButton`);

        if (viewUtils.isTopLevel(view)) {
            navigateReplace(stack);
        }
        else {
            navigatePush(view);
        }

        propOnClick?.(e);
    }, [propOnClick, navigateReplace, navigatePush, view, navState]);

    return (
        <button {...otherProps} onClick={onButtonClick}>
            {props.children}
        </button>
    );
};

export default LinkToViewButton;
