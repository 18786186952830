// @generated by protoc-gen-es v1.4.2 with parameter "target=js+ts"
// @generated from file users/users.proto (package users, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { BlobId, OrgId, PersonId, PersonSet, UserId, UserSet } from "../domain/domain_pb.js";
import { PersonalInfo } from "../identity/identity_pb.js";
import { PersonalActivity } from "../devices/devices_pb.js";
import { BlobProperties, CompleteBlobRequest, CompleteBlobResponse, CreateBlobResponse, ExpiringSasUrl } from "../blobs/blobs_pb.js";

/**
 * @generated from message users.PersonalAvatar
 */
export const PersonalAvatar = proto3.makeMessageType(
  "users.PersonalAvatar",
  () => [
    { no: 1, name: "person_id", kind: "message", T: PersonId },
    { no: 2, name: "blob_id", kind: "message", T: BlobId },
  ],
);

/**
 * @generated from message users.UserOverview
 */
export const UserOverview = proto3.makeMessageType(
  "users.UserOverview",
  () => [
    { no: 1, name: "id", kind: "message", T: UserId },
    { no: 7, name: "person_id", kind: "message", T: PersonId },
    { no: 2, name: "org_id", kind: "message", T: OrgId },
    { no: 4, name: "personal_info", kind: "message", T: PersonalInfo },
    { no: 5, name: "activity", kind: "message", T: PersonalActivity },
    { no: 6, name: "avatar", kind: "message", T: PersonalAvatar },
  ],
);

/**
 * @generated from message users.PersonOverview
 */
export const PersonOverview = proto3.makeMessageType(
  "users.PersonOverview",
  () => [
    { no: 1, name: "id", kind: "message", T: PersonId },
    { no: 2, name: "personal_info", kind: "message", T: PersonalInfo },
    { no: 3, name: "activity", kind: "message", T: PersonalActivity },
    { no: 4, name: "avatar", kind: "message", T: PersonalAvatar },
  ],
);

/**
 * @generated from message users.GetCohortRequest
 */
export const GetCohortRequest = proto3.makeMessageType(
  "users.GetCohortRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message users.GetCohortResponse
 */
export const GetCohortResponse = proto3.makeMessageType(
  "users.GetCohortResponse",
  () => [
    { no: 1, name: "cohort_ids", kind: "message", T: UserSet },
  ],
);

/**
 * @generated from message users.ListBondmatesRequest
 */
export const ListBondmatesRequest = proto3.makeMessageType(
  "users.ListBondmatesRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message users.ListBondmatesResponse
 */
export const ListBondmatesResponse = proto3.makeMessageType(
  "users.ListBondmatesResponse",
  () => [
    { no: 1, name: "user_ids", kind: "message", T: UserSet },
  ],
);

/**
 * @generated from message users.ListOpenBondSquadmatesRequest
 */
export const ListOpenBondSquadmatesRequest = proto3.makeMessageType(
  "users.ListOpenBondSquadmatesRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message users.ListOpenBondSquadmatesResponse
 */
export const ListOpenBondSquadmatesResponse = proto3.makeMessageType(
  "users.ListOpenBondSquadmatesResponse",
  () => [
    { no: 1, name: "user_ids", kind: "message", T: UserSet },
  ],
);

/**
 * @generated from message users.GetAvatarBlobUrlRequest
 */
export const GetAvatarBlobUrlRequest = proto3.makeMessageType(
  "users.GetAvatarBlobUrlRequest",
  () => [
    { no: 1, name: "blob_id", kind: "message", T: BlobId },
  ],
);

/**
 * @generated from message users.GetAvatarBlobUrlResponse
 */
export const GetAvatarBlobUrlResponse = proto3.makeMessageType(
  "users.GetAvatarBlobUrlResponse",
  () => [
    { no: 3, name: "sas_url", kind: "message", T: ExpiringSasUrl },
    { no: 5, name: "public_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "properties", kind: "message", T: BlobProperties },
  ],
);

/**
 * @generated from message users.CreateUserAvatarImageRequest
 */
export const CreateUserAvatarImageRequest = proto3.makeMessageType(
  "users.CreateUserAvatarImageRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 3, name: "properties", kind: "message", T: BlobProperties },
  ],
);

/**
 * @generated from message users.CreateUserAvatarImageResponse
 */
export const CreateUserAvatarImageResponse = proto3.makeMessageType(
  "users.CreateUserAvatarImageResponse",
  () => [
    { no: 1, name: "response", kind: "message", T: CreateBlobResponse },
  ],
);

/**
 * All values are in the range [0, 1] relative to the original image.
 *
 * @generated from message users.AvatarCropRectangle
 */
export const AvatarCropRectangle = proto3.makeMessageType(
  "users.AvatarCropRectangle",
  () => [
    { no: 1, name: "x", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "y", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "width", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "height", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

/**
 * @generated from message users.CompleteUserAvatarImageRequest
 */
export const CompleteUserAvatarImageRequest = proto3.makeMessageType(
  "users.CompleteUserAvatarImageRequest",
  () => [
    { no: 1, name: "request", kind: "message", T: CompleteBlobRequest },
    { no: 2, name: "crop_rectangle", kind: "message", T: AvatarCropRectangle },
  ],
);

/**
 * @generated from message users.CompleteUserAvatarImageResponse
 */
export const CompleteUserAvatarImageResponse = proto3.makeMessageType(
  "users.CompleteUserAvatarImageResponse",
  () => [
    { no: 1, name: "response", kind: "message", T: CompleteBlobResponse },
  ],
);

/**
 * @generated from message users.GetUsersRequest
 */
export const GetUsersRequest = proto3.makeMessageType(
  "users.GetUsersRequest",
  () => [
    { no: 1, name: "user_ids", kind: "message", T: UserSet },
  ],
);

/**
 * @generated from message users.GetUsersResponse
 */
export const GetUsersResponse = proto3.makeMessageType(
  "users.GetUsersResponse",
  () => [
    { no: 1, name: "user_overviews", kind: "message", T: UserOverview, repeated: true },
  ],
);

/**
 * @generated from message users.SubUsersRequest
 */
export const SubUsersRequest = proto3.makeMessageType(
  "users.SubUsersRequest",
  () => [
    { no: 1, name: "add_to_sub", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "user_ids", kind: "message", T: UserSet },
  ],
);

/**
 * @generated from message users.SubUsersResponse
 */
export const SubUsersResponse = proto3.makeMessageType(
  "users.SubUsersResponse",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "user_overview", kind: "message", T: UserOverview, oneof: "overview_or_deleted" },
    { no: 3, name: "deleted_id", kind: "message", T: UserId, oneof: "overview_or_deleted" },
  ],
);

/**
 * @generated from message users.SubPersonsRequest
 */
export const SubPersonsRequest = proto3.makeMessageType(
  "users.SubPersonsRequest",
  () => [
    { no: 1, name: "add_to_sub", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "person_ids", kind: "message", T: PersonSet },
  ],
);

/**
 * @generated from message users.SubPersonsResponse
 */
export const SubPersonsResponse = proto3.makeMessageType(
  "users.SubPersonsResponse",
  () => [
    { no: 1, name: "person_id", kind: "message", T: PersonId },
    { no: 2, name: "person", kind: "message", T: PersonOverview, oneof: "person_or_deleted" },
    { no: 3, name: "deleted_id", kind: "message", T: PersonId, oneof: "person_or_deleted" },
  ],
);

