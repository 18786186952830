import * as d from "@/domain/domain";
import { Optional } from "@/misc/types";
import { SquadActivity } from "../squads";

type TranslateLatestActivitiesArg = [
    squadId: d.SquadId,
    oldLatestActivity: Optional<d.Timestamp>,
];

export const translateLatestActivities = (
    [squadId, oldLatestActivity]: TranslateLatestActivitiesArg,
): Optional<[d.SquadId, SquadActivity]> => {
    if (!oldLatestActivity) return;

    return [squadId, {
        squadId: squadId,
        dominantPresence: undefined,
        latestActivityAt: oldLatestActivity,
    }];
};
