import { nanoid } from "@reduxjs/toolkit";

const selfId = nanoid(16);

function getChannel() {
    return new BroadcastChannel(`auth`);
}

type AuthChannelMessage = {
    type: "logged-in";
    from: string;
};

export function notifyTabsOfLogin() {
    getChannel().postMessage({ type: "logged-in", from: selfId } as AuthChannelMessage);
}

function subscribeTo<T extends AuthChannelMessage["type"]>(type: T, cb: () => void) {
    const chan = getChannel();
    const wrapperCb = (e: MessageEvent<any>) => {
        if (e.data.type === type && e.data.from !== selfId) {
            cb();
        }
    };
    chan.addEventListener("message", wrapperCb);
    return () => {
        chan.removeEventListener("message", wrapperCb);
    };
}

export function subscribeToLoginEvents(cb: () => void) {
    return subscribeTo("logged-in", cb);
}
