// @generated by protoc-gen-es v1.4.2 with parameter "target=js+ts"
// @generated from file calls/calls.proto (package calls, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { CallEventType, CallId, CallKnowledgeId, CallKnowledgeType, CallParticipationRole, CallSet, CrewId, DeviceId, RtcParticipantId, RtcSessionId, SquadSet, UserId, UserSet } from "../domain/domain_pb.js";

/**
 * @generated from message calls.CallOverview
 */
export const CallOverview = proto3.makeMessageType(
  "calls.CallOverview",
  () => [
    { no: 1, name: "id", kind: "message", T: CallId },
    { no: 2, name: "session_id", kind: "message", T: RtcSessionId },
    { no: 7, name: "initiator_id", kind: "message", T: UserId },
    { no: 3, name: "started_at", kind: "message", T: Timestamp },
    { no: 4, name: "ended_at", kind: "message", T: Timestamp },
    { no: 5, name: "participants", kind: "message", T: CallMember, repeated: true },
    { no: 9, name: "participants_by_contribution", kind: "message", T: UserId, repeated: true },
    { no: 8, name: "knowledge_preview", kind: "message", T: CallKnowledgePreview },
  ],
);

/**
 * @generated from message calls.CallKnowledge
 */
export const CallKnowledge = proto3.makeMessageType(
  "calls.CallKnowledge",
  () => [
    { no: 1, name: "generated", kind: "message", T: Timestamp },
    { no: 7, name: "call_id", kind: "message", T: CallId },
    { no: 8, name: "id", kind: "message", T: CallKnowledgeId },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "summary", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "knowledge_type", kind: "enum", T: proto3.getEnumType(CallKnowledgeType) },
  ],
);

/**
 * @generated from message calls.CallKnowledgePreview
 */
export const CallKnowledgePreview = proto3.makeMessageType(
  "calls.CallKnowledgePreview",
  () => [
    { no: 1, name: "call_id", kind: "message", T: CallId },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "summary", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "detailed_summary", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message calls.CallMember
 */
export const CallMember = proto3.makeMessageType(
  "calls.CallMember",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "role", kind: "message", T: CallParticipationRole },
  ],
);

/**
 * @generated from message calls.CallParticipantIdentifier
 */
export const CallParticipantIdentifier = proto3.makeMessageType(
  "calls.CallParticipantIdentifier",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "device_id", kind: "message", T: DeviceId },
  ],
);

/**
 * @generated from message calls.RtcSessionEvent
 */
export const RtcSessionEvent = proto3.makeMessageType(
  "calls.RtcSessionEvent",
  () => [
    { no: 1, name: "call_id", kind: "message", T: CallId },
    { no: 2, name: "session_id", kind: "message", T: RtcSessionId },
    { no: 3, name: "participant_id", kind: "message", T: DeviceId },
    { no: 4, name: "track_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "timestamp", kind: "message", T: Timestamp },
    { no: 6, name: "call_event_kind", kind: "enum", T: proto3.getEnumType(CallEventType) },
    { no: 7, name: "sequence_num", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "event_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "subject", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message calls.SessionEventData
 */
export const SessionEventData = proto3.makeMessageType(
  "calls.SessionEventData",
  [],
);

/**
 * @generated from message calls.ParticipantEventData
 */
export const ParticipantEventData = proto3.makeMessageType(
  "calls.ParticipantEventData",
  () => [
    { no: 1, name: "device_id", kind: "message", T: DeviceId },
  ],
);

/**
 * @generated from message calls.TrackEventData
 */
export const TrackEventData = proto3.makeMessageType(
  "calls.TrackEventData",
  () => [
    { no: 1, name: "device_id", kind: "message", T: DeviceId },
    { no: 2, name: "track_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message calls.TrackMuteEventData
 */
export const TrackMuteEventData = proto3.makeMessageType(
  "calls.TrackMuteEventData",
  () => [
    { no: 1, name: "device_id", kind: "message", T: DeviceId },
    { no: 2, name: "track_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "is_muted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message calls.TranscriptEventData
 */
export const TranscriptEventData = proto3.makeMessageType(
  "calls.TranscriptEventData",
  () => [
    { no: 1, name: "device_id", kind: "message", T: DeviceId },
    { no: 2, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message calls.CallEvent
 */
export const CallEvent = proto3.makeMessageType(
  "calls.CallEvent",
  () => [
    { no: 1, name: "call_id", kind: "message", T: CallId },
    { no: 2, name: "timestamp", kind: "message", T: Timestamp },
    { no: 4, name: "sequence_num", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "call_start", kind: "message", T: SessionEventData, oneof: "event" },
    { no: 10, name: "call_end", kind: "message", T: SessionEventData, oneof: "event" },
    { no: 6, name: "participant_join", kind: "message", T: ParticipantEventData, oneof: "event" },
    { no: 7, name: "participant_leave", kind: "message", T: ParticipantEventData, oneof: "event" },
    { no: 8, name: "transcript", kind: "message", T: TranscriptEventData, oneof: "event" },
    { no: 11, name: "track_start", kind: "message", T: TrackEventData, oneof: "event" },
    { no: 12, name: "track_end", kind: "message", T: TrackEventData, oneof: "event" },
    { no: 13, name: "track_mute", kind: "message", T: TrackMuteEventData, oneof: "event" },
  ],
);

/**
 * @generated from message calls.CallParameters
 */
export const CallParameters = proto3.makeMessageType(
  "calls.CallParameters",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "initiator_id", kind: "message", T: UserId },
    { no: 2, name: "transcription", kind: "message", T: CallTranscriptionConfig },
  ],
);

/**
 * @generated from message calls.TranscriptKeywordsConfig
 */
export const TranscriptKeywordsConfig = proto3.makeMessageType(
  "calls.TranscriptKeywordsConfig",
  () => [
    { no: 1, name: "contributors", kind: "message", T: UserSet },
    { no: 2, name: "followers", kind: "message", T: UserSet },
    { no: 3, name: "externals", kind: "message", T: UserSet },
    { no: 4, name: "squads", kind: "message", T: SquadSet },
  ],
);

/**
 * @generated from message calls.StartCallRequest
 */
export const StartCallRequest = proto3.makeMessageType(
  "calls.StartCallRequest",
  () => [
    { no: 4, name: "crew_id", kind: "message", T: CrewId },
    { no: 5, name: "params", kind: "message", T: CallParameters },
  ],
);

/**
 * @generated from message calls.StartCallResponse
 */
export const StartCallResponse = proto3.makeMessageType(
  "calls.StartCallResponse",
  () => [
    { no: 2, name: "call_id", kind: "message", T: CallId },
  ],
);

/**
 * @generated from message calls.EndCallRequest
 */
export const EndCallRequest = proto3.makeMessageType(
  "calls.EndCallRequest",
  () => [
    { no: 1, name: "call_id", kind: "message", T: CallId },
  ],
);

/**
 * @generated from message calls.EndCallResponse
 */
export const EndCallResponse = proto3.makeMessageType(
  "calls.EndCallResponse",
  [],
);

/**
 * @generated from message calls.GetCallRequest
 */
export const GetCallRequest = proto3.makeMessageType(
  "calls.GetCallRequest",
  () => [
    { no: 1, name: "call_id", kind: "message", T: CallId },
  ],
);

/**
 * @generated from message calls.GetCallResponse
 */
export const GetCallResponse = proto3.makeMessageType(
  "calls.GetCallResponse",
  () => [
    { no: 1, name: "call_overview", kind: "message", T: CallOverview },
  ],
);

/**
 * @generated from message calls.GetCallsRequest
 */
export const GetCallsRequest = proto3.makeMessageType(
  "calls.GetCallsRequest",
  () => [
    { no: 1, name: "call_ids", kind: "message", T: CallSet },
  ],
);

/**
 * @generated from message calls.GetCallsResponse
 */
export const GetCallsResponse = proto3.makeMessageType(
  "calls.GetCallsResponse",
  () => [
    { no: 1, name: "call_overviews", kind: "message", T: CallOverview, repeated: true },
  ],
);

/**
 * @generated from message calls.GetAccessTokenRequest
 */
export const GetAccessTokenRequest = proto3.makeMessageType(
  "calls.GetAccessTokenRequest",
  () => [
    { no: 1, name: "session_id", kind: "message", T: RtcSessionId },
    { no: 2, name: "user_id", kind: "message", T: UserId },
    { no: 3, name: "device_id", kind: "message", T: DeviceId },
  ],
);

/**
 * @generated from message calls.GetAccessTokenResponse
 */
export const GetAccessTokenResponse = proto3.makeMessageType(
  "calls.GetAccessTokenResponse",
  () => [
    { no: 1, name: "access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "participant_id", kind: "message", T: RtcParticipantId },
  ],
);

/**
 * @generated from message calls.FakeDataInsertRequest
 */
export const FakeDataInsertRequest = proto3.makeMessageType(
  "calls.FakeDataInsertRequest",
  () => [
    { no: 1, name: "call_events", kind: "message", T: RtcSessionEvent, repeated: true },
    { no: 2, name: "session_id", kind: "message", T: RtcSessionId },
    { no: 3, name: "start_time", kind: "message", T: Timestamp },
    { no: 5, name: "initiator_id", kind: "message", T: UserId },
    { no: 7, name: "crew_id", kind: "message", T: CrewId },
  ],
);

/**
 * @generated from message calls.FakeDataInsertResponse
 */
export const FakeDataInsertResponse = proto3.makeMessageType(
  "calls.FakeDataInsertResponse",
  () => [
    { no: 1, name: "call_id", kind: "message", T: CallId },
  ],
);

/**
 * @generated from message calls.AddCallKnowledgeRequest
 */
export const AddCallKnowledgeRequest = proto3.makeMessageType(
  "calls.AddCallKnowledgeRequest",
  () => [
    { no: 1, name: "call_id", kind: "message", T: CallId },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "summary", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "knowledge_type", kind: "enum", T: proto3.getEnumType(CallKnowledgeType) },
  ],
);

/**
 * @generated from message calls.AddCallKnowledgeResponse
 */
export const AddCallKnowledgeResponse = proto3.makeMessageType(
  "calls.AddCallKnowledgeResponse",
  [],
);

/**
 * @generated from message calls.GetCallKnowledgeByTypeRequest
 */
export const GetCallKnowledgeByTypeRequest = proto3.makeMessageType(
  "calls.GetCallKnowledgeByTypeRequest",
  () => [
    { no: 1, name: "call_id", kind: "message", T: CallId },
    { no: 6, name: "knowledge_type", kind: "enum", T: proto3.getEnumType(CallKnowledgeType) },
  ],
);

/**
 * @generated from message calls.GetCallKnowledgeByTypeResponse
 */
export const GetCallKnowledgeByTypeResponse = proto3.makeMessageType(
  "calls.GetCallKnowledgeByTypeResponse",
  () => [
    { no: 1, name: "call_knowledges", kind: "message", T: CallKnowledge, repeated: true },
  ],
);

/**
 * @generated from message calls.GetCallKnowledgePreviewRequest
 */
export const GetCallKnowledgePreviewRequest = proto3.makeMessageType(
  "calls.GetCallKnowledgePreviewRequest",
  () => [
    { no: 1, name: "call_id", kind: "message", T: CallId },
  ],
);

/**
 * @generated from message calls.GetCallKnowledgePreviewResponse
 */
export const GetCallKnowledgePreviewResponse = proto3.makeMessageType(
  "calls.GetCallKnowledgePreviewResponse",
  () => [
    { no: 1, name: "preview", kind: "message", T: CallKnowledgePreview },
  ],
);

/**
 * @generated from message calls.GetCallEventsRequest
 */
export const GetCallEventsRequest = proto3.makeMessageType(
  "calls.GetCallEventsRequest",
  () => [
    { no: 1, name: "call_id", kind: "message", T: CallId },
    { no: 3, name: "event_kinds", kind: "enum", T: proto3.getEnumType(CallEventType), repeated: true },
  ],
);

/**
 * @generated from message calls.GetCallEventsResponse
 */
export const GetCallEventsResponse = proto3.makeMessageType(
  "calls.GetCallEventsResponse",
  () => [
    { no: 2, name: "call_events", kind: "message", T: CallEvent, repeated: true },
  ],
);

/**
 * @generated from message calls.FilterCallsWithTranscriptsRequest
 */
export const FilterCallsWithTranscriptsRequest = proto3.makeMessageType(
  "calls.FilterCallsWithTranscriptsRequest",
  () => [
    { no: 1, name: "call_ids", kind: "message", T: CallSet },
  ],
);

/**
 * @generated from message calls.FilterCallsWithTranscriptsResponse
 */
export const FilterCallsWithTranscriptsResponse = proto3.makeMessageType(
  "calls.FilterCallsWithTranscriptsResponse",
  () => [
    { no: 1, name: "call_ids_with_transcripts", kind: "message", T: CallSet },
  ],
);

/**
 * @generated from message calls.ListCallParticipantsRequest
 */
export const ListCallParticipantsRequest = proto3.makeMessageType(
  "calls.ListCallParticipantsRequest",
  () => [
    { no: 1, name: "call_id", kind: "message", T: CallId },
  ],
);

/**
 * @generated from message calls.ListCallParticipantsResponse
 */
export const ListCallParticipantsResponse = proto3.makeMessageType(
  "calls.ListCallParticipantsResponse",
  () => [
    { no: 3, name: "call_participant_identifiers", kind: "message", T: CallParticipantIdentifier, repeated: true },
  ],
);

/**
 * @generated from message calls.SubCallsRequest
 */
export const SubCallsRequest = proto3.makeMessageType(
  "calls.SubCallsRequest",
  () => [
    { no: 1, name: "add_to_sub", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "call_ids", kind: "message", T: CallSet },
  ],
);

/**
 * @generated from message calls.SubCallsResponse
 */
export const SubCallsResponse = proto3.makeMessageType(
  "calls.SubCallsResponse",
  () => [
    { no: 1, name: "call", kind: "message", T: CallOverview, oneof: "call_or_deleted" },
    { no: 2, name: "deleted_id", kind: "message", T: CallId, oneof: "call_or_deleted" },
  ],
);

/**
 * @generated from message calls.GetCallInitiatorRequest
 */
export const GetCallInitiatorRequest = proto3.makeMessageType(
  "calls.GetCallInitiatorRequest",
  () => [
    { no: 1, name: "call_id", kind: "message", T: CallId },
  ],
);

/**
 * @generated from message calls.GetCallInitiatorResponse
 */
export const GetCallInitiatorResponse = proto3.makeMessageType(
  "calls.GetCallInitiatorResponse",
  () => [
    { no: 1, name: "initiator_id", kind: "message", T: UserId },
  ],
);

/**
 * @generated from message calls.CallTranscriptionConfig
 */
export const CallTranscriptionConfig = proto3.makeMessageType(
  "calls.CallTranscriptionConfig",
  () => [
    { no: 1, name: "language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "keywords_config", kind: "message", T: TranscriptKeywordsConfig },
  ],
);

