import { clearLoginSessionStorage, saveLoginRedirectUri } from "@/auth/login";
import { hasOidcState } from "@/auth/stateMachine";
import { broadcastChannelStorageToLocalStorage } from "@/auth/storage";
import {
    AuthStatus,
    getLocalPreferredUserOrgId,
    selectAuthStatus,
    startAuthForUser,
} from "@/features/auth";
import log from "@/misc/log";
import { useAppSelector } from "@/store/redux";
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

// Just a hack around strict mode meaning a useEffect() with no dependencies gets called twice
function useOnceCall(cb: () => void, condition = true) {
    const isCalledRef = useRef(false);

    useEffect(() => {
        if (condition && !isCalledRef.current) {
            isCalledRef.current = true;
            cb();
        }
    }, [cb, condition]);
}

export default function AuthenticationStartup(): React.JSX.Element {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const authStatus = useAppSelector(selectAuthStatus);

    useOnceCall(() => {
        (async () => {
            const uid = getLocalPreferredUserOrgId();

            if (uid && await hasOidcState(uid.userId)) {
                log.info("Starting auth with OIDC state");
                dispatch(startAuthForUser(uid));
            }
            else if (pathname.startsWith("/login")) {
                // If we're already on a login view, there is nothing to do
            }
            else {
                const user = uid ? `User ${uid.userId}` : `No saved user ID`;
                throw new Error(
                    `No auth state to start from. ${user}: loading login view`,
                );
            }
        })().catch(e => {
            log.error(`Failed to initialise auth: ${e}`);

            saveLoginRedirectUri(new URL(window.location.href).pathname);

            // It's possible we've started the store in the wrong state, e.g. if we
            // had a saved user ID. So we should refresh here to get it into a sane
            // state.
            if (!pathname.startsWith("/login")) window.location.href = "/login";
        });
    });

    useEffect(() => {
        if (authStatus == AuthStatus.Authenticated) {
            clearLoginSessionStorage();
        }
    }, [authStatus]);

    useEffect(() => {
        return broadcastChannelStorageToLocalStorage();
    }, []);

    return <></>;
}
