import { DiscoverFilterOptions, FilterOptions } from "@/features/filterPanel";
import useOutsideClick from "@/hooks/useOutsideClick";
import { useAppDispatch, useAppSelector } from "@/store/redux";
import { RootState } from "@/store/types";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import classNames from "classnames";
import React, { useRef, useState } from "react";

type AnyFilterOptions = FilterOptions | DiscoverFilterOptions;
type FilterPillProps<T extends AnyFilterOptions> = {
    options: T[];
    selectFilterAction: ActionCreatorWithPayload<T>;
    selectFilterSelector: (state: RootState) => T;
};

const FilterButton = <T extends AnyFilterOptions>({
    name,
    current,
    onClick,
}: {
    name: T;
    current: T;
    onClick: (nf: T) => void;
}) => {
    const classes = classNames("c-filter-dropdown__option", {
        "is-selected": current === name,
    });
    return (
        <button className={classes} onClick={() => onClick(name)}>
            {name}
        </button>
    );
};

export const FilterPill = <T extends AnyFilterOptions>({
    options,
    selectFilterAction,
    selectFilterSelector,
}: FilterPillProps<T>): React.JSX.Element => {
    const dispatch = useAppDispatch();
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const toggleFilter = () => setIsFilterVisible(z => !z);
    const handleFilterChange = (newFilter: T) => {
        dispatch(selectFilterAction(newFilter));
        setIsFilterVisible(false);
    };
    const filter = useAppSelector(selectFilterSelector);
    const isFilterEnabled = filter !== "all";
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const [popoverRef] = useOutsideClick<HTMLDivElement>(
        (target: EventTarget | null) => {
            if (buttonRef.current?.contains(target as Node)) return;
            setIsFilterVisible(false);
        },
    );
    const buttonClasses = classNames("c-btn-filter", {
        "is-selected": isFilterEnabled,
    });
    const divClasses = classNames("c-filter-dropdown__options", {
        "is-visible": isFilterVisible,
    });
    return (
        <div className="c-bonds-presence-wrapper">
            <div className="c-filter-dropdown">
                <button
                    onClick={toggleFilter}
                    className={buttonClasses}
                    ref={buttonRef}
                    title={`Showing ${filter} bonds`}
                >
                    {filter}
                </button>
                <div className={divClasses} ref={popoverRef}>
                    {options.map(name => (
                        <FilterButton
                            name={name}
                            current={filter}
                            onClick={handleFilterChange}
                            key={name}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};
