import { AsyncThunk, createSlice, PayloadAction, TaskAbortError } from "@reduxjs/toolkit";

import * as d from "@/domain/domain";
import asyncIterableQueue from "@/ds/asyncIterableQueue";
import { selectConnectedWithUserId } from "@/features/connection";
import type { AnySelector } from "@/features/selectors";
import { Counter, CounterState, createCounter } from "@/misc/counter";
import log from "@/misc/log";
import { diff, mapRecordByValue } from "@/misc/primatives";
import type { Diff } from "@/misc/types";
import { checkPersistor } from "@/persist/shared";
import {
    type AppAppendListener,
    appAppendListener,
    firstOrStatesDifferBySelectors,
    statesDifferBySelectors,
} from "@/store/middleware";
import type { AppAsyncThunkConfig, RootState } from "@/store/types";

type StreamDiffThunk<T> = AsyncThunk<
    void,
    AsyncIterableIterator<Diff<T>>,
    AppAsyncThunkConfig
>;

// TODO: this is very similar to `startConnectedStatefulListener`, except
// it needs `api` to create the thunk to be dispatched as part of the stateful
// setup. Think about how these two uses could be combined into a single
// utility function.
export const startManagingInterest = <T>(
    idSelector: AnySelector<[], T[]>,
    streamThunk: StreamDiffThunk<T>,
    name: string,
    appendListener: AppAppendListener = appAppendListener,
) => {
    const innerPredicate = statesDifferBySelectors(idSelector);

    return appendListener({
        predicate: firstOrStatesDifferBySelectors(selectConnectedWithUserId),

        effect: async (_action, api) => {
            const firstState = api.getState();

            if (!selectConnectedWithUserId(firstState)) {
                api.cancelActiveListeners();
                return;
            }

            const queue = asyncIterableQueue<Diff<T>>();
            const thunk = api.dispatch(streamThunk(queue));

            let lastState: RootState | undefined;

            let interest = idSelector(firstState);
            queue.push({ added: interest });

            try {
                while (!api.signal.aborted) {
                    const state = api.getState();
                    if (state == lastState) {
                        await api.take(innerPredicate);
                        continue;
                    }

                    lastState = state;

                    const newInterest = idSelector(state);
                    const interestDiff = diff(interest, newInterest);
                    interest = newInterest;

                    if (interestDiff.added.length > 0 || interestDiff.removed.length > 0) {
                        queue.push(interestDiff);
                    }
                }
            }
            catch (e) {
                if (!(e instanceof TaskAbortError)) {
                    log.warn(`Unexpected middleware exception (stream ${name})`, e);
                }
            }

            thunk.abort("disconnected");
            // This gives an easy way to test that the listener has completed.
            queue.clear();
        },
    });
};

export enum MetaInterestCounterKey {
    ActiveForNotifications = "activeForNotifications",
    AnyTabActive = "anyTabActive",
    BlockHotkey = "blockHotkey",
    RaiseBondView = "raiseBondView",
    BlockMsgCompletion = "blockMsgCompletion",
    Typing = "typingActivity",
    FollowedUnreadCount = "followedUnreadCount",
}

const counters = {
    bonds: createCounter<d.BondId>(),
    calls: createCounter<d.CallId>(),
    meta: createCounter<MetaInterestCounterKey>(),
    orgs: createCounter<d.OrgId>(),
    squads: createCounter<d.SquadId>(),
    users: createCounter<d.UserId>(),
    persons: createCounter<d.PersonId>(),
    visibleBonds: createCounter<d.BondId>(),
    invitedBonds: createCounter<d.BondId>(),
    visibleChannels: createCounter<d.ChannelId>(),
};
type Counters = typeof counters;

type CounterToStateType<T> = T extends Counter<infer S> ? CounterState<S> : never;
export type InterestState = { [K in keyof Counters]: CounterToStateType<Counters[K]>; };

const getInitialState = (): InterestState =>
    mapRecordByValue(counters, (_, v) => v.getInitialState()) as InterestState;

export type InterestUpdate<T> = { action: "add" | "remove"; ids: T[]; };
type UpdateArgs<T> = PayloadAction<InterestUpdate<T>>;

export const interestSlice = createSlice({
    name: "interest",
    initialState: getInitialState(),
    selectors: {
        bonds: state => state.bonds,
        calls: state => state.calls,
        meta: state => state.meta,
        metaKey: (state, interestKey: MetaInterestCounterKey) => state.meta.includes(interestKey),
        orgs: state => state.orgs,
        squads: state => state.squads,
        users: state => state.users,
        persons: state => state.persons,
        visibleBonds: state => state.visibleBonds,
        invitedBonds: state => state.invitedBonds,
        visibleChannels: state => state.visibleChannels,
    },
    reducers: {
        updateBondInterest: (
            state,
            { payload: { action, ids } }: UpdateArgs<d.BondId>,
        ) => {
            const f = action === "add" ? counters.bonds.add
                : counters.bonds.remove;
            state.bonds = f(state.bonds, ...ids);
        },
        updateCallInterest: (
            state,
            { payload: { action, ids } }: UpdateArgs<d.CallId>,
        ) => {
            const f = action === "add" ? counters.calls.add : counters.calls.remove;
            state.calls = f(state.calls, ...ids);
        },
        updateMetaInterest: (
            state,
            { payload: { action, ids } }: UpdateArgs<MetaInterestCounterKey>,
        ) => {
            const f = action === "add" ? counters.meta.add : counters.meta.remove;
            state.meta = f(state.meta, ...ids);
        },
        updateOrgInterest: (
            state,
            { payload: { action, ids } }: UpdateArgs<d.OrgId>,
        ) => {
            const f = action === "add" ? counters.orgs.add : counters.orgs.remove;
            state.orgs = f(state.orgs, ...ids);
        },
        updateSquadInterest: (
            state,
            { payload: { action, ids } }: UpdateArgs<d.SquadId>,
        ) => {
            const f = action === "add" ? counters.squads.add : counters.squads.remove;
            state.squads = f(state.squads, ...ids);
        },
        updateUserInterest: (
            state,
            { payload: { action, ids } }: UpdateArgs<d.UserId>,
        ) => {
            const f = action === "add" ? counters.users.add : counters.users.remove;
            state.users = f(state.users, ...ids);
        },
        updatePersonInterest: (
            state,
            { payload: { action, ids } }: UpdateArgs<d.PersonId>,
        ) => {
            const f = action === "add" ? counters.persons.add : counters.persons.remove;
            state.persons = f(state.persons, ...ids);
        },
        updateVisibleBondInterest: (
            state,
            { payload: { action, ids } }: UpdateArgs<d.BondId>,
        ) => {
            const f = action === "add" ? counters.visibleBonds.add
                : counters.visibleBonds.remove;
            state.visibleBonds = f(state.visibleBonds, ...ids);
        },
        updateInvitedBondInterest: (
            state,
            { payload: { action, ids } }: UpdateArgs<d.BondId>,
        ) => {
            const f = action === "add" ? counters.invitedBonds.add
                : counters.invitedBonds.remove;
            state.invitedBonds = f(state.invitedBonds, ...ids);
        },
        updateVisibleChannelInterest: (
            state,
            { payload: { action, ids } }: UpdateArgs<d.ChannelId>,
        ) => {
            const f = action === "add" ? counters.visibleChannels.add
                : counters.visibleChannels.remove;
            state.visibleChannels = f(state.visibleChannels, ...ids);
        },
    },
});

export const {
    bonds: selectBondInterest,
    calls: selectCallsInterest,
    meta: selectMetaInterest,
    metaKey: selectInterestInKey,
    orgs: selectOrgsInterest,
    squads: selectSquadsInterest,
    users: selectUsersInterest,
    persons: selectPersonsInterest,
    visibleBonds: selectVisibleBondInterest,
    invitedBonds: selectInvitedBondInterest,
    visibleChannels: selectVisibleChannelInterest,
} = interestSlice.getSelectors<RootState>(state => state.interest);

export const {
    updateBondInterest,
    updateCallInterest,
    updateMetaInterest,
    updateOrgInterest,
    updateSquadInterest,
    updateUserInterest,
    updatePersonInterest,
    updateVisibleBondInterest,
    updateInvitedBondInterest,
    updateVisibleChannelInterest,
} = interestSlice.actions;

export const selectTypingInterest = (state: RootState) =>
    selectInterestInKey(state, MetaInterestCounterKey.Typing);

export const selectActiveForNotifications = (state: RootState) =>
    selectInterestInKey(state, MetaInterestCounterKey.ActiveForNotifications);

export const selectAnyTabActive = (state: RootState) =>
    selectInterestInKey(state, MetaInterestCounterKey.AnyTabActive);

export const selectDisplayingFollowedUnreadCount = (state: RootState) =>
    selectInterestInKey(state, MetaInterestCounterKey.FollowedUnreadCount);

export const reducer = interestSlice.reducer;

export const persistor = {
    stores: {},
};
checkPersistor<InterestState>(persistor);
